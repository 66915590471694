.suggestion-list {
  -webkit-box-shadow: rgba(0, 0, 0, 0.175) 1px -3px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.175) 1px -3px 12px;
  box-shadow: rgba(0, 0, 0, 0.175) 1px -3px 12px; }

.signup-team__container .has-error .control-label:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg); }
  to {
    transform: scale(1) rotate(360deg); } }

@keyframes highlight {
  from {
    background: rgba(255, 255, 0, 0.5); }
  to {
    background: none; } }

.padding-top {
  padding-top: 7px; }
  .padding-top.half {
    padding-top: 3px; }
  .padding-top.x2 {
    padding-top: 14px; }
  .padding-top.x3 {
    padding-top: 21px; }

.padding-bottom {
  padding-bottom: 7px; }
  .padding-bottom.half {
    padding-bottom: 3px; }
  .padding-bottom.x1 {
    padding-bottom: 4px; }
  .padding-bottom.x2 {
    padding-bottom: 14px; }
  .padding-bottom.x3 {
    padding-bottom: 21px; }
  .padding-bottom .control-label {
    font-weight: 600; }
    .padding-bottom .control-label.text-left {
      text-align: left; }

.padding-left {
  padding-left: 7px; }
  .padding-left.x2 {
    padding-left: 14px; }
  .padding-left.x3 {
    padding-left: 21px; }

.padding-right {
  padding-right: 7px; }
  .padding-right.x2 {
    padding-right: 14px; }
  .padding-right.x3 {
    padding-right: 21px; }

.margin-right {
  margin-right: 7px; }
  .margin-right.margin-right--half {
    margin-right: 3px; }
  .margin-right.x2 {
    margin-right: 14px; }
  .margin-right.x3 {
    margin-right: 21px; }

.margin-left {
  margin-left: 7px; }
  .margin-left.x2 {
    margin-left: 14px; }
  .margin-left.x3 {
    margin-left: 21px; }

.margin-top {
  margin-top: 7px; }
  .margin-top.half {
    margin-top: 4px; }
  .margin-top.x2 {
    margin-top: 14px; }
  .margin-top.x3 {
    margin-top: 21px; }

.margin-bottom {
  margin-bottom: 7px; }
  .margin-bottom.x2 {
    margin-bottom: 14px; }
  .margin-bottom.x3 {
    margin-bottom: 21px; }

.d-block {
  display: block; }

b,
strong {
  font-weight: 600; }

a {
  color: #2389d7;
  cursor: pointer;
  text-decoration: none;
  word-break: break-word; }
  a:focus, a:hover {
    color: #1c6dab; }

.color--link {
  color: #2389d7;
  cursor: pointer; }
  .color--link:hover, .color--link:focus {
    text-decoration: underline; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif; }

.light {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6; }

.word-break--all {
  word-break: break-all; }

.whitespace--nowrap {
  white-space: nowrap; }

.whitespace--normal {
  white-space: normal; }

.overflow--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis; }

ul,
ol {
  margin-bottom: .11em;
  margin-top: 3px;
  padding-left: 22px; }

.fa.fa-margin--left {
  margin-left: 3px; }

.fa.fa-margin--right {
  margin-right: 3px; }

.font-weight--normal {
  font-weight: normal; }

.cursor--pointer {
  cursor: pointer; }

/* open-sans-300 - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/static/files/357d675366cce0fbb6ca5e1474ae489c.eot);
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url(/static/files/357d675366cce0fbb6ca5e1474ae489c.eot?#iefix) format("embedded-opentype"), url(/static/files/ea284cc760cad1896d4c917f1e546210.woff2) format("woff2"), url(/static/files/3b3cbaef084e27f7fa05d9fe31afb287.woff) format("woff"), url(/static/files/1bf71be111189e76987a4bb9b3115cb7.ttf) format("truetype"), url(/static/files/6b317ec8f0068f923f3a4238bd45fe52.svg#OpenSans) format("svg");
  /* Legacy iOS */ }

/* open-sans-300italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(/static/files/9a599f54797d2ae5a61a3243b4e816fb.eot);
  /* IE9 Compat Modes */
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url(/static/files/9a599f54797d2ae5a61a3243b4e816fb.eot?#iefix) format("embedded-opentype"), url(/static/files/3ea4cffdf67ebf2ad373fb96b903065d.woff2) format("woff2"), url(/static/files/9c9017a313819aa54e959ffe8e7d27ea.woff) format("woff"), url(/static/files/6943fb6fd4200f3d073469325c6acdc9.ttf) format("truetype"), url(/static/files/54640a849595eb55643eb029a938287f.svg#OpenSans) format("svg");
  /* Legacy iOS */ }

/* open-sans-regular - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/files/98255d04bb0ce821171ea055fdfcc53f.eot);
  /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"), url(/static/files/98255d04bb0ce821171ea055fdfcc53f.eot?#iefix) format("embedded-opentype"), url(/static/files/6fd1f924cd0bea5d5f74a1365e12184d.woff2) format("woff2"), url(/static/files/60bdb28dc8230486c5b0e868c82fb5dc.woff) format("woff"), url(/static/files/629a55a7e793da068dc580d184cc0e31.ttf) format("truetype"), url(/static/files/7c19a7ee85ce3f38c5ad38334ecc5fe4.svg#OpenSans) format("svg");
  /* Legacy iOS */ }

/* open-sans-italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/static/files/b07fc5b16bb5ecf336f31a8e993c9865.eot);
  /* IE9 Compat Modes */
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(/static/files/b07fc5b16bb5ecf336f31a8e993c9865.eot?#iefix) format("embedded-opentype"), url(/static/files/f01a4402103c9ef9350607c0f739b49d.woff2) format("woff2"), url(/static/files/7aee35e0d937a1fa3456645603d200e6.woff) format("woff"), url(/static/files/c7dcce084c445260a266f92db56f5517.ttf) format("truetype"), url(/static/files/c5912ce083e90c9cbbf6879311c9c301.svg#OpenSans) format("svg");
  /* Legacy iOS */ }

/* open-sans-600 - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(/static/files/ce26ce4629f1e78f666544143a88c286.eot);
  /* IE9 Compat Modes */
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(/static/files/ce26ce4629f1e78f666544143a88c286.eot?#iefix) format("embedded-opentype"), url(/static/files/aa22ff6fd92ecdf402f09a8180354751.woff2) format("woff2"), url(/static/files/2418db91905ed032bf3a30ee72dd6a1a.woff) format("woff"), url(/static/files/33f225b8f5f7d6b34a0926f58f96c1e9.ttf) format("truetype"), url(/static/files/027a0df9ff85dbd6a1917683db8a5e7d.svg#OpenSans) format("svg");
  /* Legacy iOS */ }

/* open-sans-600italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url(/static/files/ca4e3b468e0d24ae948b162e83fc225d.eot);
  /* IE9 Compat Modes */
  src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic"), url(/static/files/ca4e3b468e0d24ae948b162e83fc225d.eot?#iefix) format("embedded-opentype"), url(/static/files/ca4d226c2c041ce9d9e946e7f7eb297e.woff2) format("woff2"), url(/static/files/cc62fb5c139ff4ec74ef7b3474dcc7f2.woff) format("woff"), url(/static/files/73f7301a9cd7a086295401eefe0c998f.ttf) format("truetype"), url(/static/files/54401c6d9bf4636900a252d84868405c.svg#OpenSans) format("svg");
  /* Legacy iOS */ }

.app__body.font--open_sans {
  font-family: 'Open Sans', sans-serif; }

html,
body {
  height: 100%; }
  html.sticky,
  body.sticky {
    height: auto;
    overflow: auto; }

body {
  background: whitesmoke;
  position: relative;
  width: 100%; }
  body.announcement-bar--fixed {
    padding-top: 24px; }

.sticky {
  background: white; }
  .sticky > .channel-view {
    height: 100vh;
    padding: 0 15px;
    overflow: auto; }
  .sticky .inner-wrap {
    width: 100%; }
    .sticky .inner-wrap > .row.content {
      min-height: calc(100vh - 89px); }
      @media (max-width: 768px) {
        .sticky .inner-wrap > .row.content {
          min-height: calc(100vh - 187px); } }

.help-page {
  overflow: hidden;
  padding: 3em 0; }

.inner-wrap {
  height: 100%; }
  .inner-wrap.sticky {
    overflow: auto; }
  .inner-wrap > .row.main {
    height: 100%;
    position: relative; }

.container-fluid {
  *zoom: 1;
  height: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch; }
  .container-fluid:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden; }

.channel-view {
  overflow: hidden;
  *zoom: 1;
  height: 100%;
  position: relative; }

img {
  max-width: 100%; }
  img.rounded {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%; }

.modal .modal-content {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 2px; }

.modal .about-modal .modal-content .modal-header {
  background: transparent;
  border: none;
  color: inherit;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd; }
  .modal .about-modal .modal-content .modal-header .close {
    color: inherit;
    font-weight: normal;
    right: 15px;
    font-size: 30px;
    opacity: .5; }
  .modal .about-modal .modal-content .modal-header .more-modal__image {
    margin-right: 12px; }
  .modal .about-modal .modal-content .modal-header .modal-title {
    color: inherit;
    font-size: 17px;
    font-weight: 600; }
  .modal .about-modal .modal-content .modal-header .icon {
    margin: 0 5px 0 8px;
    position: relative;
    top: 1px; }
    .modal .about-modal .modal-content .modal-header .icon svg {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
      height: 14px;
      width: 14px; }

.modal .about-modal .modal-body {
  padding: 15px 25px;
  max-height: calc(100vh - 220px); }

.modal .about-modal .modal-footer .btn {
  font-size: 14px;
  height: 40px;
  padding: 0 20px;
  font-weight: 600;
  border-radius: 4px; }

.modal .about-modal label {
  margin-bottom: 8px; }

.modal .about-modal .form-group {
  margin-bottom: 24px; }

.modal .about-modal .select-suggestion-container {
  margin: 0; }
  .modal .about-modal .select-suggestion-container .form-control {
    height: 36px;
    padding: 8px 12px; }
  .modal .about-modal .select-suggestion-container:after {
    top: 9px;
    right: 12px;
    font-size: 20px; }

.modal .about-modal .form-control {
  padding: 8px 12px;
  font-size: 14px; }
  .modal .about-modal .form-control[type=input] {
    height: 36px; }

.modal .about-modal.large .modal-body {
  padding-bottom: 20px; }

.modal .about-modal .about-modal__content {
  overflow: hidden;
  *zoom: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 1em 0 3em; }

.modal .about-modal .about-modal__copyright {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
  margin-top: .5em; }

.modal .about-modal .about-modal__footer {
  font-size: 13.5px; }

.modal .about-modal .about-modal__title {
  line-height: 1.5;
  margin: 0 0 10px; }

.modal .about-modal .about-modal__subtitle {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6; }

.modal .about-modal .about-modal__hash {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
  overflow: hidden;
  font-size: .75em;
  text-align: right; }
  .modal .about-modal .about-modal__hash p:first-child {
    float: left;
    text-align: left; }

.modal .about-modal .about-modal__notice {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  font-size: .79em;
  text-align: right; }
  .modal .about-modal .about-modal__notice p:first-child {
    text-align: left; }

.modal .about-modal .about-modal__logo {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  opacity: 0.9;
  padding: 0 40px 0 20px; }
  .modal .about-modal .about-modal__logo svg {
    height: 125px;
    width: 125px; }
  .modal .about-modal .about-modal__logo path {
    fill: inherit; }

.access-history__table {
  display: table;
  line-height: 1.6;
  padding-top: 15px;
  width: 100%; }
  .access-history__table:first-child {
    padding: 0; }
  .access-history__table > div {
    display: table-cell;
    vertical-align: top; }
  .access-history__table .access__date {
    font-size: 15px;
    font-weight: 600;
    width: 190px; }
  .access-history__table .access__report {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px; }
  .access-history__table .report__info {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }

.animation--highlight:before {
  animation: highlight 1.5s ease;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: inline-block; }

.activity-log__table {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: table;
  line-height: 1.8;
  padding: 15px 0;
  width: 100%; }
  .activity-log__table:first-child {
    border: none;
    padding-top: 0; }
  .activity-log__table > div {
    display: table-cell;
    vertical-align: top; }
  .activity-log__table .activity-log__report {
    width: 80%; }
  .activity-log__table .activity-log__action {
    text-align: right; }
  .activity-log__table .report__platform {
    font-size: 15px;
    font-weight: 600; }
    .activity-log__table .report__platform .fa {
      margin-right: 6px; }
  .activity-log__table .report__info {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }

.session-help-text {
  padding: 0 0 20px; }

.admin-console__wrapper {
  height: calc(100% - 55px);
  overflow: auto;
  margin-top: 65px; }

.admin-console {
  -webkit-overflow-scrolling: touch;
  color: #333;
  height: 100%;
  margin-left: 220px;
  overflow: auto;
  padding: 10px 20px 0; }
  .admin-console .table {
    margin-bottom: 0; }
  .admin-console .color-picker__popover {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 5; }
  .admin-console .dropdown-menu .divider {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .admin-console .filtered-user-list {
    height: calc(100vh - 120px); }
  .admin-console .Select-value-label {
    white-space: nowrap; }
  .admin-console .inner-wrap {
    position: absolute;
    width: 100%; }
  .admin-console h3 {
    font-weight: 600;
    margin: 1em 0;
    padding-bottom: .5em; }
  .admin-console h4 {
    font-weight: 600;
    margin-bottom: 2em; }
  .admin-console .form-control {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15); }
    .admin-console .form-control:focus {
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      border-color: #66afe9;
      outline: 0; }
    .admin-console .form-control.disabled {
      background-color: whitesmoke; }
    .admin-console .form-control .placeholder-text {
      color: rgba(0, 0, 0, 0.3); }
  .admin-console .table.table-margin--none {
    margin-bottom: 0; }
  .admin-console .btn.btn-spacing--right {
    margin-right: 10px; }
  .admin-console .btn .fa {
    margin-right: 7px; }
    .admin-console .btn .fa.margin-left {
      margin-left: 7px;
      margin-right: 0; }
  .admin-console .filter-control .fa {
    font-size: 12px; }
  .admin-console .log__panel {
    background-color: white;
    border: 1px solid #ddd;
    height: calc(100vh - 280px);
    margin-top: 14px;
    overflow: scroll;
    padding: 10px;
    width: 100%; }
  .admin-console.admin {
    background-color: #f1f1f1;
    min-height: 600px;
    overflow: auto;
    padding: 0 40px 20px; }
  .admin-console .wrapper--fixed {
    max-width: 920px; }
  .admin-console .form-horizontal {
    margin: 1em 0 7em; }
    .admin-console .form-horizontal .control-label {
      font-weight: 600;
      padding-right: 0;
      text-align: left; }
    .admin-console .form-horizontal .has-error .control-label {
      font-weight: normal; }
    .admin-console .form-horizontal .form-group {
      margin-bottom: 25px; }
      .admin-console .form-horizontal .form-group.half {
        margin-bottom: 14px; }
    .admin-console .form-horizontal .file__upload {
      display: inline-block;
      margin: 0 10px 10px 0;
      position: relative; }
      .admin-console .form-horizontal .file__upload input {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        cursor: pointer;
        height: 100%;
        left: 0;
        padding-left: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5; }
        .admin-console .form-horizontal .file__upload input[disabled] {
          cursor: not-allowed; }
    .admin-console .form-horizontal .alert {
      display: inline-block;
      margin: 1em 0 0;
      padding: 5px 7px;
      position: relative;
      top: 1px; }
      .admin-console .form-horizontal .alert .fa {
        margin-right: 5px; }
      .admin-console .form-horizontal .alert.alert-transparent {
        background: white;
        border: 1px solid #ddd;
        margin: 0;
        padding: 12px 14px;
        width: 100%; }
      .admin-console .form-horizontal .alert hr {
        border-color: #ddd;
        margin: .8em 0; }
      .admin-console .form-horizontal .alert div:last-child hr {
        display: none; }
  .admin-console .banner {
    background: white;
    border: 1px solid #ddd;
    font-size: .95em;
    margin: 1em 0 2em;
    padding: .8em 1.5rem; }
    .admin-console .banner.banner--url {
      padding: 1.2em; }
    .admin-console .banner .banner__url {
      background: rgba(0, 0, 0, 0.07);
      border-radius: 2px;
      padding: .7em 1em;
      word-break: break-all; }
    .admin-console .banner .banner__heading {
      font-size: 1.5em;
      margin-bottom: 0.5em; }
    .admin-console .banner.warning {
      background: #e60000; }
  .admin-console .popover {
    border-radius: 3px;
    font-size: .95em;
    width: 100%; }
  .admin-console .panel {
    background-color: transparent;
    border: none; }
  .admin-console .panel-default > .panel-heading {
    background-color: transparent;
    padding: 10px 0; }
  .admin-console .panel-default .panel-body {
    padding: 30px 0 10px; }
  .admin-console .panel-group {
    margin-bottom: 50px; }
  .admin-console .panel-title {
    font-size: 24px;
    line-height: 1.5; }
    .admin-console .panel-title a {
      overflow: hidden;
      *zoom: 1;
      display: block;
      text-decoration: none; }
      .admin-console .panel-title a.collapsed .fa-minus {
        display: none; }
      .admin-console .panel-title a.collapsed .fa-plus {
        display: inline-block; }
      .admin-console .panel-title a .fa {
        color: #aaa;
        float: right;
        font-size: 18px;
        margin-top: 8px; }
      .admin-console .panel-title a .fa-plus {
        display: none; }
  .admin-console .more-modal__list .filtered-user-list .filter-controls {
    padding-bottom: 1em; }
  .admin-console .more-modal__list .filter-row {
    margin: 10px 0; }
  .admin-console .member-list-holder {
    background: white;
    overflow: visible;
    padding: 5px 0; }
    .admin-console .member-list-holder .more-modal__list {
      overflow: visible; }
    .admin-console .member-list-holder .more-modal__row:last-child {
      border: none; }
  .admin-console .member-count {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    margin-top: 8px; }
  .admin-console .admin-console-header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 220px);
    margin: 0 0 0 220px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: normal;
    z-index: 100;
    align-items: center; }
    .admin-console .admin-console-header.with-back {
      padding: 0; }
      .admin-console .admin-console-header.with-back .back {
        display: flex;
        font-size: 1.6rem;
        width: 60px;
        height: 60px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 20px;
        align-items: center;
        justify-content: space-around;
        text-decoration: none; }
        .admin-console .admin-console-header.with-back .back:before {
          top: 1px;
          position: relative; }
  .admin-console .admin-console-save {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 220px);
    margin: 0 0 0 220px;
    padding: 13px 0;
    z-index: 100; }
    .admin-console .admin-console-save .save-button {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
      background: rgba(0, 0, 0, 0.15);
      min-width: 100px;
      height: 34px;
      margin: 0 10px 0 13px;
      color: rgba(0, 0, 0, 0.5); }
      .admin-console .admin-console-save .save-button.btn-primary {
        background: #2389d7;
        color: white; }
    .admin-console .admin-console-save .cancel-button {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px;
      -webkit-transition: all 0.15s ease;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.15s ease;
      -o-transition: all 0.15s ease;
      transition: all 0.15s ease false;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      height: 34px;
      margin: 0 10px 0 0;
      text-decoration: none; }
      .admin-console .admin-console-save .cancel-button:hover {
        background-color: #2389d7;
        color: white; }
    .admin-console .admin-console-save .error-message {
      overflow: hidden; }
      .admin-console .admin-console-save .error-message .control-label {
        padding: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 40px; }
    .admin-console .admin-console-save .reset-defaults-btn {
      position: absolute;
      right: 0;
      padding-right: 10px;
      padding-left: 10px; }
  .admin-console .status-icon-unknown {
    color: gray; }
  .admin-console .status-icon-success {
    color: #69c169; }
  .admin-console .status-icon-warning {
    color: #eac262; }
  .admin-console .status-icon-error {
    color: #ea6262; }
  .admin-console .suggestion--selected {
    background-color: #e2e2e2; }

.job-table__cancel-button {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 0;
  text-decoration: none;
  vertical-align: middle; }

.job-table__table {
  background-color: white;
  border: 1px solid #ddd;
  margin: 20px 0 10px;
  max-height: 240px;
  min-height: 100px;
  overflow: auto;
  padding: 5px;
  width: 100%; }

.brand-img {
  margin-bottom: 1.5em;
  max-width: 150px; }

.admin-console__disabled-text {
  color: #777;
  margin: 10px 0 0 15px; }

.admin-sidebar {
  background: #333;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 220px;
  z-index: 10; }
  body.announcement-bar--fixed .admin-sidebar {
    top: 24px; }
  .admin-sidebar .dropdown-menu {
    max-height: 80vh;
    max-width: 200px;
    min-width: 200px;
    overflow: auto; }
  .admin-sidebar .team__header {
    background: transparent; }
  .admin-sidebar .nav-pills__container {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    background: #111;
    height: calc(100% - 68px);
    margin-top: 1px;
    padding-bottom: 20px;
    position: relative; }
  .admin-sidebar .sidebar-category .category-title {
    background: rgba(255, 255, 255, 0.15);
    color: white;
    line-height: 15.4px;
    padding: 10px; }
    .admin-sidebar .sidebar-category .category-title .category-icon {
      font-size: 15px;
      margin-right: 7px;
      overflow: hidden;
      right: 12px;
      text-align: center;
      top: 6px;
      vertical-align: bottom;
      width: 16px; }
      .admin-sidebar .sidebar-category .category-title .category-icon.fa-user {
        font-size: 14px; }
      .admin-sidebar .sidebar-category .category-title .category-icon.fa-bar-chart {
        font-size: 16px; }
  .admin-sidebar .sidebar-category .sections {
    padding: 5px 0; }
  .admin-sidebar .sidebar-section > .sidebar-section-title {
    position: relative; }
  .admin-sidebar .sidebar-section .nav__sub-menu {
    margin-bottom: 7px; }
    .admin-sidebar .sidebar-section .nav__sub-menu:empty {
      display: none; }
  .admin-sidebar .sections.sections--settings .sidebar-section-title {
    text-transform: uppercase; }
    .admin-sidebar .sections.sections--settings .sidebar-section-title:hover, .admin-sidebar .sections.sections--settings .sidebar-section-title:focus {
      color: rgba(255, 255, 255, 0.5); }
  .admin-sidebar .sidebar-section-title {
    padding: 7px 35px 7px 15px; }
  .admin-sidebar .sidebar-subsection-title {
    padding: 7px 35px 7px 30px; }
  .admin-sidebar .sidebar-section-title,
  .admin-sidebar .sidebar-subsection-title {
    color: rgba(255, 255, 255, 0.5);
    display: block;
    font-size: 13px;
    position: relative; }
    .admin-sidebar .sidebar-section-title:focus,
    .admin-sidebar .sidebar-subsection-title:focus {
      text-decoration: none; }
    .admin-sidebar .sidebar-section-title:hover,
    .admin-sidebar .sidebar-subsection-title:hover {
      color: #4ba1e2;
      text-decoration: none; }
    .admin-sidebar .sidebar-section-title--active,
    .admin-sidebar .sidebar-subsection-title--active {
      background: rgba(255, 255, 255, 0.1);
      color: white; }
      .admin-sidebar .sidebar-section-title--active:hover, .admin-sidebar .sidebar-section-title--active:focus,
      .admin-sidebar .sidebar-subsection-title--active:hover,
      .admin-sidebar .sidebar-subsection-title--active:focus {
        background: rgba(255, 255, 255, 0.1);
        color: white; }
      .admin-sidebar .sidebar-section-title--active:after,
      .admin-sidebar .sidebar-subsection-title--active:after {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        color: whitesmoke;
        content: "\F0D9";
        display: inline-block;
        font: normal normal normal 26px/1 FontAwesome;
        position: absolute;
        right: -1px;
        text-rendering: auto;
        top: 3px; }
  .admin-sidebar .sidebar-subsection-title--active:after {
    top: 4px; }
  .admin-sidebar .menu-icon--right {
    font-size: 18px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 12px;
    text-align: center;
    top: 8px;
    width: 20px; }
    .admin-sidebar .menu-icon--right .fa {
      color: white;
      font-size: 13px;
      position: relative;
      right: -2px; }
    .admin-sidebar .menu-icon--right.menu__close {
      cursor: pointer;
      right: 10px;
      top: 3px; }

.email-connection-test {
  margin-top: -15px; }

.s3-connection-test {
  margin-top: -15px; }

.recycle-db {
  margin-top: 50px !important; }

.cluster-status {
  height: 24px;
  width: 24px; }

.config-hash {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px; }

.system-users__filter-row {
  display: flex;
  margin-left: 15px;
  margin-right: 15px; }
  .system-users__filter-row .system-users__filter {
    flex: 1;
    margin-right: 15px; }
  .system-users__filter-row .system-users__team-filter-label {
    margin-right: 15px; }
  .system-users__filter-row .system-users__team-filter {
    display: inline-block;
    width: 200px; }
  .system-users__filter-row label {
    font-weight: normal; }

.manage-teams .modal-body {
  padding: 15px 15px 0; }

.manage-teams .manage-teams__user {
  align-items: center;
  display: flex; }

.manage-teams .manage-teams__teams {
  border-top: 1px solid #ddd;
  margin: 1em 0 .3em; }
  .manage-teams .manage-teams__teams .btn-link.danger {
    color: #c55151; }

.manage-teams .member-row--padded {
  padding-left: 20px; }
  .manage-teams .member-row--padded strong {
    margin-right: 10px; }

.manage-teams .manage-row--inner {
  padding: 15px 0 4px; }
  .manage-teams .manage-row--inner + div {
    border-top: 1px solid #ddd; }

.manage-teams .manage-teams__profile-picture {
  border-radius: 20px;
  height: 40px;
  width: 40px; }

.manage-teams .manage-teams__info {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap; }
  .manage-teams .manage-teams__info .manage-teams__name {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis; }
  .manage-teams .manage-teams__info .manage-teams__email {
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis; }

.manage-teams .manage-teams__system-admin {
  margin-left: 10px;
  opacity: 0.5;
  padding-right: 10px; }

.manage-teams .manage-teams__teams {
  margin-top: 1em; }
  .manage-teams .manage-teams__teams .manage-row__empty {
    padding: 9px 0; }

.manage-teams .manage-teams__team {
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 7px 10px; }
  .manage-teams .manage-teams__team .btn {
    font-size: .9em; }
  .manage-teams .manage-teams__team .dropdown {
    padding: 6px 0; }

.manage-teams .manage-teams__team-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis; }

.manage-teams .manage-teams__team-actions {
  margin-left: 10px; }
  .manage-teams .manage-teams__team-actions .dropdown-toggle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.discard-changes-modal .modal-dialog .btn-default, .discard-changes-modal .modal-dialog .btn-default:hover, .discard-changes-modal .modal-dialog .btn-default:active, .discard-changes-modal .modal-dialog .btn-default:focus {
  color: #2389d7;
  background-color: transparent;
  border: none; }

.admin-setting-user__dropdown {
  position: relative; }
  .admin-setting-user__dropdown:before {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    content: '';
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 11px;
    top: 15px;
    width: 0; }

.admin-setting-user__image {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  display: block;
  font-size: 15px;
  height: 16px;
  line-height: 16px;
  margin-right: 7px;
  margin-top: 3px;
  text-align: center;
  width: 16px; }
  .admin-setting-user__image .admin-setting-user--align {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.admin-setting-user__fullname {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5; }

.password__group-addon {
  min-width: 150px;
  text-align: left; }

.password__group-addon-space {
  margin-bottom: 20px; }

#error-tooltip .tooltip-inner {
  max-width: none; }

.backstage {
  height: 100vh;
  width: 100vw; }

.backstage-navbar {
  background: white;
  height: 41px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  z-index: 10; }

.backstage-navbar__back {
  color: inherit;
  text-decoration: none; }
  .backstage-navbar__back .fa {
    font-size: 1.1em;
    font-weight: bold;
    margin-right: 7px; }
  .backstage-navbar__back:hover, .backstage-navbar__back:active {
    color: inherit; }

.backstage-body {
  background-color: whitesmoke;
  bottom: 0;
  display: inline-block;
  height: calc(100vh - 41px);
  overflow: auto;
  top: 0;
  width: 100%; }

.backstage-content {
  background-color: whitesmoke;
  margin: 46px auto;
  max-width: 1200px;
  padding-left: 135px;
  vertical-align: top; }

.backstage-sidebar {
  padding: 46px 20px;
  position: absolute;
  vertical-align: top;
  width: 260px; }
  .backstage-sidebar ul {
    list-style: none;
    padding: 0; }
  .backstage-sidebar > ul {
    overflow: hidden;
    *zoom: 1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid rgba(0, 0, 0, 0.15); }

.backstage-sidebar__category + .backstage-sidebar__category .category-title {
  border-top: none; }

.backstage-sidebar__category .category-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  color: black;
  display: block;
  line-height: 35px;
  padding: 0 10px;
  position: relative; }
  .backstage-sidebar__category .category-title .fa {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    position: relative;
    text-align: center;
    top: 1px;
    width: 20px; }
  .backstage-sidebar__category .category-title .fa-smile-o {
    font-size: 17px; }

.backstage-sidebar__category .category-title--active {
  background-color: #2389d7;
  color: white; }
  .backstage-sidebar__category .category-title--active .fa {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }

.backstage-sidebar__category .category-title__text {
  left: 2.5em;
  position: absolute; }

.backstage-sidebar__category .sections {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.backstage-sidebar__category .section-title,
.backstage-sidebar__category .subsection-title {
  display: block;
  font-size: .95em;
  line-height: 29px;
  padding-left: 2em;
  text-decoration: none; }

.backstage-sidebar__category .subsection-title {
  padding-left: 3em; }

.backstage-sidebar__category .section-title--active,
.backstage-sidebar__category .subsection-title--active {
  background-color: #2389d7;
  color: white;
  font-weight: 600; }

.backstage-header__divider {
  color: rgba(0, 0, 0, 0.3);
  margin: 0 10px; }

.backstage-header {
  overflow: hidden;
  *zoom: 1;
  margin-bottom: 20px;
  width: 100%; }
  .backstage-header h1 {
    float: left;
    font-size: 20px;
    margin: 5px 0; }
  .backstage-header .add-link {
    float: right; }

.backstage-filters {
  display: flex;
  flex-direction: row;
  width: 100%; }

.backstage-filters__sort {
  flex-grow: 1;
  flex-shrink: 0;
  line-height: 30px; }
  .backstage-filters__sort .filter-sort {
    text-decoration: none; }
    .backstage-filters__sort .filter-sort.filter-sort--active {
      color: inherit;
      cursor: default; }
  .backstage-filters__sort .divider {
    margin-left: 8px;
    margin-right: 8px; }

.backstage-filter__search {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 270px; }
  .backstage-filter__search .fa {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
    left: 11px;
    position: absolute;
    top: 11px; }
  .backstage-filter__search input {
    background: white;
    padding-left: 30px; }

.backstage-list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 15px;
  min-height: 50px; }

.backstage-list__help {
  display: block;
  margin: 1em 0; }

.backstage-list__item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 20px 15px;
  position: relative; }
  .backstage-list__item:last-child {
    border: none; }
  .backstage-list__item .item-details {
    color: rgba(0, 0, 0, 0.5);
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis; }
  .backstage-list__item .item-details__row + .item-details__row {
    overflow: hidden;
    *zoom: 1;
    text-overflow: ellipsis; }
  .backstage-list__item .item-details__name {
    color: black;
    font-weight: 600; }
  .backstage-list__item .item-details__trigger {
    margin-left: 6px; }
  .backstage-list__item .item-details__description,
  .backstage-list__item .item-details__content_type,
  .backstage-list__item .item-details__token,
  .backstage-list__item .item-details__trigger-words,
  .backstage-list__item .item-details__trigger-when,
  .backstage-list__item .item-details__url,
  .backstage-list__item .item-details__creation {
    display: inline-block;
    margin-top: 10px;
    vertical-align: top; }
    .backstage-list__item .item-details__description:empty,
    .backstage-list__item .item-details__content_type:empty,
    .backstage-list__item .item-details__token:empty,
    .backstage-list__item .item-details__trigger-words:empty,
    .backstage-list__item .item-details__trigger-when:empty,
    .backstage-list__item .item-details__url:empty,
    .backstage-list__item .item-details__creation:empty {
      display: none; }
  .backstage-list__item .item-details__trigger-words {
    white-space: nowrap; }
  .backstage-list__item .item-actions {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 20px; }

.backstage-form {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 40px 30px 30px;
  position: relative; }
  .backstage-form.backstage-form__confirmation {
    padding: 30px 30px 20px; }
  .backstage-form label {
    font-weight: normal; }
  .backstage-form .backstage-form__title {
    margin: 5px 0 1.5em; }
  .backstage-form .radio input,
  .backstage-form .checkbox input {
    height: 16px;
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
    width: 16px; }
  .backstage-form .form-control {
    background: white; }
    .backstage-form .form-control:focus {
      border-color: #2389d7; }
  .backstage-form .form__help {
    color: rgba(0, 0, 0, 0.5);
    font-size: .95em;
    margin-top: 1em; }
    .backstage-form .form__help + .form__help {
      margin-top: .4em; }

.backstage-form__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 2.5em;
  padding-top: 1.8em;
  text-align: right; }
  .backstage-form__footer .has-error {
    float: left;
    margin: 0; }

.integration__icon {
  position: absolute;
  height: 100px;
  width: 100px;
  right: 20px; }
  .integration__icon.integration-list__icon {
    top: 50px; }

.integration-option {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: inline-block;
  margin: 0 30px 30px 0;
  min-height: 230px;
  padding: 20px;
  text-align: center;
  vertical-align: top;
  width: 290px; }
  .integration-option:last-child {
    margin-right: 0; }
  .integration-option:hover {
    color: default;
    text-decoration: none; }

.integration-option__image {
  height: 80px;
  margin: .5em 0 .7em; }

.integration-option__title {
  color: black;
  margin-bottom: 10px; }

.integration-option__description {
  color: rgba(0, 0, 0, 0.5); }

.emoji-list__table {
  width: 100%; }
  .emoji-list__table .backstage-list__item {
    display: table-row; }
  .emoji-list__table .backstage-list__empty td {
    padding: 15px 20px; }

.emoji-list__table-header {
  font-weight: bold; }

.emoji-list__name {
  padding: 20px 0 20px 15px;
  width: 30%; }

.emoji-list__image {
  padding: 15px 0;
  width: 15%; }

.emoji-list__creator {
  padding: 15px 0;
  width: 40%; }

.emoji-list__actions {
  padding: 20px 15px 20px 0;
  width: 15%; }

.add-emoji__upload {
  display: inline-block;
  margin: 0 10px 10px 0;
  position: relative; }
  .add-emoji__upload input {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5; }

.add-emoji__filename,
.add-emoji__preview {
  padding-top: 7px; }

.compliance-panel__table,
.audit-panel__table,
.cluster-panel__table {
  background-color: white;
  border: 1px solid 1px solid #ddd;
  margin-top: 10px;
  max-height: 70vh;
  min-height: 100px;
  overflow: auto;
  padding: 5px;
  width: 100%; }

.compliance-panel .row > .form-group,
.audit-panel .row > .form-group {
  padding-left: 0; }
  .compliance-panel .row > .form-group:first-child,
  .audit-panel .row > .form-group:first-child {
    padding-left: 15px; }

.compliance-panel .row label,
.audit-panel .row label {
  font-weight: 600; }

.compliance-panel .fa-refresh,
.audit-panel .fa-refresh {
  margin-right: 5px; }

.compliance-panel {
  margin-bottom: 3em; }

.docs__page {
  line-height: 1.7;
  padding-bottom: 20px; }
  .docs__page > div {
    margin: 0 auto;
    max-width: 100%;
    padding: 0 15px;
    width: 1170px; }
  .docs__page h1.markdown__heading {
    border-bottom: 1px solid #ddd;
    margin: 1em 0 1em;
    padding-bottom: 1rem; }

body.error .container-fluid {
  display: table;
  height: 90%; }

body.error .error__container {
  color: #555;
  display: table-cell;
  margin: 0 auto;
  max-width: 800px;
  padding: 5em 0;
  text-align: left;
  vertical-align: top; }

body.error .error__icon {
  color: #ccc;
  font-size: 4em; }

body.error h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin: .8em 0 .5em; }

body.error p {
  font-size: 1.2em; }

.get-app {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 20px 30px 30px;
  position: absolute;
  top: 0;
  width: 100%; }
  .get-app hr {
    border-top: 1px solid #ddd; }
  .get-app .get-app__header {
    color: #666;
    font-size: 20px;
    font-weight: bold;
    text-align: center; }
  .get-app .get-app__screenshot {
    border-bottom: 1px solid #ddd;
    display: block;
    margin: auto; }
  .get-app .get-app__continue-with-browser {
    display: block;
    margin-top: 40px;
    padding-bottom: 30px;
    text-align: center; }
  .get-app .btn-primary {
    display: block;
    font-size: 18px;
    margin: 2rem 0 3rem;
    font-weight: 600;
    border-radius: 4px;
    line-height: 40px; }

.get-android-app .get-app__header {
  text-align: left; }

.get-android-app .get-android-app__icon {
  width: 60px; }

.get-android-app .get-android-app__app-info {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle; }
  .get-android-app .get-android-app__app-info .get-android-app__app-name {
    color: #666;
    display: block;
    font-size: 13px;
    font-weight: bold; }
  .get-android-app .get-android-app__app-info .get-android-app__app-creator {
    color: #aaa;
    display: block;
    font-size: 10px; }

.get-android-app .get-app__screenshot {
  width: 240px; }

.get-ios-app .get-app__screenshot {
  width: 180px; }

.get-ios-app .get-ios-app__app-store-link {
  display: block;
  margin: auto;
  margin-bottom: 30px;
  width: 180px; }

.get-ios-app .get-ios-app__already-have-it {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center; }

.loading-screen {
  display: table;
  height: 100%;
  padding: 60px;
  text-align: center;
  width: 100%; }
  .loading-screen .loading__content {
    display: table-cell;
    font-size: 0;
    vertical-align: middle; }
    .loading-screen .loading__content h3 {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      margin: 0 .2em; }
    .loading-screen .loading__content .round {
      -webkit-animation: move 0.75s infinite linear;
      -moz-animation: move 0.75s infinite linear;
      -ms-animation: move 0.75s infinite linear;
      -o-animation: move 0.75s infinite linear;
      animation: move 0.75s infinite linear;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      background-color: #444444;
      display: inline-block;
      height: 4px;
      margin: 0 2px;
      opacity: .1;
      width: 4px; }
    .loading-screen .loading__content .round-1 {
      -webkit-animation-delay: 0.2s;
      -moz-animation-delay: 0.2s;
      -ms-animation-delay: 0.2s;
      -o-animation-delay: 0.2s;
      animation-delay: 0.2s; }
    .loading-screen .loading__content .round-2 {
      -webkit-animation-delay: 0.4s;
      -moz-animation-delay: 0.4s;
      -ms-animation-delay: 0.4s;
      -o-animation-delay: 0.4s;
      animation-delay: 0.4s; }
    .loading-screen .loading__content .round-3 {
      -webkit-animation-delay: 0.6s;
      -moz-animation-delay: 0.6s;
      -ms-animation-delay: 0.6s;
      -o-animation-delay: 0.6s;
      animation-delay: 0.6s; }

@-moz-keyframes move {
  from {
    opacity: 1; }
  to {
    opacity: .1; } }

@-webkit-keyframes move {
  from {
    opacity: 1; }
  to {
    opacity: .1; } }

@-o-keyframes move {
  from {
    opacity: 1; }
  to {
    opacity: .1; } }

@-ms-keyframes move {
  .loading-screen .loading__content from {
    opacity: 1; }
  .loading-screen .loading__content to {
    opacity: .1; } }

@keyframes move {
  from {
    opacity: 1; }
  to {
    opacity: .1; } }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important; }
  .post-list__content,
  .post-list__table,
  .app__content {
    display: block; }
  a:not(.mention-link),
  a:not(.mention-link):visited {
    text-decoration: underline; }
  a[href^=http]:after {
    content: " <" attr(href) "> "; }
  tr,
  img,
  pre,
  blockquote,
  code,
  .post,
  .date-separator {
    page-break-inside: avoid; }
  .post__header {
    page-break-after: avoid; }
  thead {
    display: table-header-group; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  #sidebar-left,
  #sidebar-right,
  #channel-header,
  #post-create,
  #post-list .more-messages-text,
  .post .post__header .col__reply,
  .post .post__content > div.post__img,
  .attachment__content,
  .post__body .img-div,
  .post-image__thumbnail,
  .post.post--system,
  #archive-link-home,
  .channel-intro {
    display: none; }
  #post-list,
  #channel_view,
  #post-list .post-list-holder-by-time {
    overflow: visible;
    overflow-y: visible; }
  #app-content {
    margin-left: 0; }
  #channel_view .inner-wrap.move--left {
    margin-right: 0; }
  .post-image__columns > div:first-child:before {
    content: 'Attachments:';
    display: block;
    font-weight: bold; }
  .post-image__column {
    width: inherit;
    display: inline;
    height: inherit;
    background: transparent;
    border-color: transparent;
    border: 0 none; }
  .post-image__details {
    width: 100%;
    border: 0 none;
    line-height: 98px; }
    .post-image__details .post-image__name:before {
      content: '['; }
    .post-image__details .post-image__name:after {
      content: ": " attr(href) "]"; }
    .post-image__details .post-image__name + div {
      display: none; }
  .date-separator .separator__hr,
  .new-separator .separator__hr {
    top: 1.7em; } }

.user-settings {
  min-height: 300px; }
  .user-settings .table-responsive {
    max-height: 300px;
    max-width: 560px; }
  .user-settings .authorized-apps__help {
    font-size: 13px;
    font-weight: 400;
    margin-top: 7px; }
  .user-settings .authorized-apps__wrapper {
    padding: 10px 0; }
  .user-settings .authorized-app {
    display: inline-block;
    width: 100%; }
    .user-settings .authorized-app:not(:last-child) {
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px; }
    .user-settings .authorized-app .authorized-app__name {
      font-weight: 600; }
    .user-settings .authorized-app .authorized-app__url {
      font-size: 13px;
      font-weight: 400; }
    .user-settings .authorized-app .authorized-app__description,
    .user-settings .authorized-app .authorized-app__deauthorize {
      font-size: 13px;
      margin: 5px 0; }

.modal .settings-modal {
  width: 800px; }
  .modal .settings-modal .modal-back {
    font-size: 27px;
    font-weight: normal;
    height: 40px;
    left: 0;
    line-height: 32px;
    position: absolute;
    text-align: center;
    top: 12px;
    width: 50px; }
    .modal .settings-modal .modal-back .fa {
      height: 100%;
      left: 0;
      line-height: inherit;
      position: absolute;
      width: 100%; }
  .modal .settings-modal .modal-body {
    margin: 0 auto;
    min-height: calc(100% - 62px);
    padding: 0; }
  .modal .settings-modal li {
    list-style: none; }
  .modal .settings-modal label {
    font-weight: 600; }
    .modal .settings-modal label.text-left {
      text-align: left; }
    .modal .settings-modal label.has-error {
      font-weight: normal; }
  .modal .settings-modal .no-padding--left {
    padding-left: 0; }
  .modal .settings-modal .profile-img {
    border-radius: 100%;
    height: 128px;
    width: 128px; }
  .modal .settings-modal .profile-img-preview {
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 100%;
    height: 128px;
    width: 128px; }
  .modal .settings-modal .profile-img__container {
    height: 128px;
    position: relative;
    width: 128px; }
  .modal .settings-modal .profile-img__remove {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.15s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease false;
    background: black;
    color: white;
    height: 26px;
    position: absolute;
    right: 8px;
    text-decoration: none;
    top: 4px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .modal .settings-modal .profile-img__remove span {
      font-size: 22px; }
  .modal .settings-modal .team-img-preview,
  .modal .settings-modal .team-img__container {
    height: 128px;
    position: relative;
    width: 128px; }
    .modal .settings-modal .team-img-preview img,
    .modal .settings-modal .team-img__container img {
      height: auto;
      width: 100%; }
    .modal .settings-modal .team-img-preview .img-preview__image,
    .modal .settings-modal .team-img__container .img-preview__image {
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
      border-radius: 6px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      overflow: hidden; }
    .modal .settings-modal .team-img-preview div,
    .modal .settings-modal .team-img__container div {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-color: white;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%; }
  .modal .settings-modal .team-img__remove {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.15s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease false;
    background: black;
    color: white;
    height: 24px;
    line-height: 23px;
    position: absolute;
    right: -8px;
    text-align: center;
    text-decoration: none;
    top: -8px;
    width: 24px; }
    .modal .settings-modal .team-img__remove span {
      font-size: 22px;
      left: .5px;
      position: relative; }
  .modal .settings-modal .settings-table {
    display: table;
    margin: 0 auto;
    max-width: 1000px;
    table-layout: fixed;
    width: 100%; }
    .modal .settings-modal .settings-table > div {
      display: table-cell;
      vertical-align: top; }
    .modal .settings-modal .settings-table .nav {
      position: fixed;
      width: 179px; }
      .modal .settings-modal .settings-table .nav.position--top {
        top: 57px; }
    .modal .settings-modal .settings-table .security-links {
      margin-right: 20px; }
      .modal .settings-modal .settings-table .security-links .fa {
        margin-right: 6px; }
    .modal .settings-modal .settings-table .settings-links {
      width: 180px; }
    .modal .settings-modal .settings-table .settings-content {
      padding: 0 20px 30px; }
      .modal .settings-modal .settings-table .settings-content .modal-header {
        display: none; }
      .modal .settings-modal .settings-table .settings-content .section-min {
        padding: 1em 0;
        margin-bottom: 0;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        *zoom: 1; }
        .modal .settings-modal .settings-table .settings-content .section-min:hover {
          background: #f9f9f9; }
        .modal .settings-modal .settings-table .settings-content .section-min:hover .fa {
          display: inline-block; }
        .modal .settings-modal .settings-table .settings-content .section-min:hover .section-edit {
          text-decoration: underline; }
      .modal .settings-modal .settings-table .settings-content .section-max {
        overflow: hidden;
        *zoom: 1;
        background: rgba(0, 0, 0, 0.05);
        margin-bottom: 0;
        padding: 1em 0 1.3em; }
        .modal .settings-modal .settings-table .settings-content .section-max .section-title {
          margin-bottom: 10px; }
      .modal .settings-modal .settings-table .settings-content .timezone-container {
        overflow: visible;
        display: table;
        width: 100%; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .theme-group .input-group-addon {
        padding: 4px 5px;
        width: 40px; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .theme-group img {
        border: 1px solid rgba(0, 0, 0, 0.15);
        width: 29px; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .group--code select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        appearance: none;
        padding-right: 25px; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .group--code:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\F0D7';
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        pointer-events: none;
        position: absolute;
        right: 50px;
        text-rendering: auto;
        top: 11px;
        z-index: 5; }
        .browser--ie .modal .settings-modal .settings-table .settings-content .appearance-section .group--code:before {
          display: none; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .premade-themes {
        margin-bottom: 10px; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .premade-themes .theme-label {
          font-weight: 400;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .premade-themes img {
          border: 3px solid transparent; }
          .modal .settings-modal .settings-table .settings-content .appearance-section .premade-themes img:hover {
            cursor: pointer; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .premade-themes .active img {
          border-color: #2389d7; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements {
        padding-left: 15px; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements .element {
          margin-right: 10px; }
          .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements .element:nth-child(2n) {
            margin-right: 0; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__header {
        border-bottom: 1px solid;
        cursor: pointer;
        font-size: 0.96429em;
        font-weight: 600;
        margin: 10px 20px 0 0;
        padding: 1px 0 10px; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__header .fa-minus {
          display: none; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__header.open .fa-minus {
          display: inline-block; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__header.open .fa-plus {
          display: none; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__header .header__icon {
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
          opacity: 0.5;
          float: right; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__body {
        -webkit-border-radius: 0 0 3px 3px;
        -moz-border-radius: 0 0 3px 3px;
        -ms-border-radius: 0 0 3px 3px;
        -o-border-radius: 0 0 3px 3px;
        border-radius: 0 0 3px 3px;
        *zoom: 1;
        background-color: rgba(255, 255, 255, 0.05);
        display: none;
        margin: 0 20px 0 0;
        padding: 20px 0 0 20px; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__body:after {
          content: " ";
          display: block;
          height: 0;
          clear: both;
          overflow: hidden;
          visibility: hidden; }
        .modal .settings-modal .settings-table .settings-content .appearance-section .theme-elements__body.open {
          display: block; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .custom-label {
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .input-group-addon {
        background: transparent; }
      .modal .settings-modal .settings-table .settings-content .appearance-section .radio label {
        font-weight: 600; }
      .modal .settings-modal .settings-table .settings-content .section-title {
        font-weight: 600;
        margin-bottom: 5px;
        padding-right: 50px; }
      .modal .settings-modal .settings-table .settings-content .section-edit {
        margin-bottom: 5px;
        text-align: right; }
        .modal .settings-modal .settings-table .settings-content .section-edit .fa {
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
          opacity: 0.5;
          display: none;
          font-size: 12px;
          margin-right: 5px; }
          .modal .settings-modal .settings-table .settings-content .section-edit .fa.fa-chevron-down {
            margin-right: 0;
            position: relative;
            top: -1px; }
      .modal .settings-modal .settings-table .settings-content .section-describe {
        overflow: hidden;
        *zoom: 1;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
        opacity: 0.7;
        text-overflow: ellipsis;
        white-space: pre; }
      .modal .settings-modal .settings-table .settings-content .divider-dark {
        border-bottom: 1px solid #aaa; }
      .modal .settings-modal .settings-table .settings-content .divider-light {
        border-bottom: 1px solid lightgrey; }
        .modal .settings-modal .settings-table .settings-content .divider-light + .divider-light {
          display: none; }
        .modal .settings-modal .settings-table .settings-content .divider-light + .divider-dark {
          display: none; }
      .modal .settings-modal .settings-table .settings-content .setting-list {
        list-style-type: none;
        padding: 0; }
      .modal .settings-modal .settings-table .settings-content .setting-box__item:first-child {
        padding-top: 3px; }
      .modal .settings-modal .settings-table .settings-content .setting-box__item:last-child hr {
        display: none; }
      .modal .settings-modal .settings-table .settings-content .setting-box__token-id {
        margin: 4px 0; }
      .modal .settings-modal .settings-table .settings-content .setting-box__inline-error {
        margin-left: 5px; }
      .modal .settings-modal .settings-table .settings-content .setting-list__hint {
        margin-top: 20px; }
      .modal .settings-modal .settings-table .settings-content .fa-wrapper {
        margin: 0 5px; }
      .modal .settings-modal .settings-table .settings-content .resend-verification-wrapper:before {
        content: "\A0   "; }
      .modal .settings-modal .settings-table .settings-content .mentions-input {
        margin-top: 10px; }
      .modal .settings-modal .settings-table .settings-content .setting-list-item {
        margin-top: 7px; }
      .modal .settings-modal .settings-table .settings-content .has-error {
        color: #a94442; }
      .modal .settings-modal .settings-table .settings-content .file-status {
        color: #555;
        font-size: 13px;
        margin-top: 8px; }
      .modal .settings-modal .settings-table .settings-content .confirm-import {
        margin: 10px 0;
        padding: 4px 10px; }

.modal .nav-pills > li {
  margin: 0; }
  .modal .nav-pills > li button {
    border-radius: 0;
    color: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 8px 15px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  .modal .nav-pills > li .icon {
    font-size: 15px;
    margin-right: 10px;
    text-align: center;
    top: 2px;
    width: 14px; }
  .modal .nav-pills > li:hover button {
    background-color: rgba(0, 0, 0, 0.1); }
  .modal .nav-pills > li.active div {
    background-color: #e1e1e1;
    color: #111; }
    .modal .nav-pills > li.active div:before {
      background: black;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 5px; }
  .modal .nav-pills > li.active button,
  .modal .nav-pills > li.active button:hover,
  .modal .nav-pills > li.active button:focus {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0;
    font-weight: 400;
    position: relative; }

.modal h3 {
  font-size: 1.42857em; }

.channel-settings {
  padding: 0 10px; }

.tab-header {
  margin-bottom: 1em; }

.setting-name {
  color: grey;
  font-weight: 500; }

.sel-btn {
  margin-right: 5px; }

.member-div {
  border-bottom: 1px solid lightgrey;
  margin: 0;
  padding: 2px;
  position: relative;
  width: 100%; }
  .member-div:first-child {
    border-top: 1px solid lightgrey; }
  .member-div .post-profile-img {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    margin-right: 8px; }

.member-role .fa,
.member-drop .fa {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  font-size: 12px;
  margin-right: 5px; }

.member-role .member-menu,
.member-drop .member-menu {
  right: 110%;
  top: -50%; }

.member-invite {
  position: absolute;
  right: 10px;
  top: 7px; }

.member-menu {
  left: auto;
  right: 0; }

.member-list {
  overflow-x: visible;
  width: 100%; }

.member-page {
  padding-top: 50px; }

.active-border {
  border: 1px solid red; }

.color-btn {
  margin: 4px; }

.no-resize {
  resize: none; }

.user-settings__submit-checkbox {
  padding-top: 0px;
  padding-bottom: 20px; }

.app__body .modal .shortcuts-modal {
  margin-top: 50px;
  width: 1100px; }
  .app__body .modal .shortcuts-modal .shortcuts-content .modal-header {
    background: transparent;
    border: none;
    color: inherit;
    padding: 20px 40px 20px; }
    .app__body .modal .shortcuts-modal .shortcuts-content .modal-header .close {
      color: inherit;
      font-size: 28px;
      font-weight: normal;
      overflow: hidden;
      right: 35px;
      top: 35px; }
    .app__body .modal .shortcuts-modal .shortcuts-content .modal-header .modal-title {
      color: inherit;
      font-size: 20px; }
      .app__body .modal .shortcuts-modal .shortcuts-content .modal-header .modal-title > div:first-child {
        margin-bottom: 2.5em; }
      .app__body .modal .shortcuts-modal .shortcuts-content .modal-header .modal-title .shortcut-line {
        margin: 17px 0; }
        .app__body .modal .shortcuts-modal .shortcuts-content .modal-header .modal-title .shortcut-line span:first-child {
          margin-right: 5px; }
        .app__body .modal .shortcuts-modal .shortcuts-content .modal-header .modal-title .shortcut-line .shortcut-key {
          border-radius: 3px;
          font-size: .9em;
          font-weight: 500;
          margin: 5px 0 5px 5px;
          padding: 1px 5px; }
  .app__body .modal .shortcuts-modal .modal-body {
    max-height: calc(100vh - 67px);
    padding: 0 40px 20px; }
  .app__body .modal .shortcuts-modal .section > div:first-child {
    margin-bottom: 2.5em; }
  .app__body .modal .shortcuts-modal .section .shortcut-line {
    margin: 17px 0; }
    .app__body .modal .shortcuts-modal .section .shortcut-line span:first-child {
      margin-right: 5px; }
    .app__body .modal .shortcuts-modal .section .shortcut-line .shortcut-key {
      border-radius: 3px;
      font-size: 12px;
      font-weight: 500;
      margin: 5px 0 5px 5px;
      padding: 1px 5px; }
  .app__body .modal .shortcuts-modal .section-title {
    font-size: 18px;
    margin: 1.5em 0; }
  .app__body .modal .shortcuts-modal .subsection {
    border-left: 2px solid;
    padding-left: 15px; }
  .app__body .modal .shortcuts-modal .info__label {
    margin: 35px 0 10px;
    text-align: center; }

.signup-header {
  background: whitesmoke;
  line-height: 33px;
  padding: 0 1em .2em;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5; }
  .signup-header .fa {
    margin-right: 5px; }

.signup-team__container {
  margin: 0 auto;
  max-width: 400px;
  padding: 100px 0 50px;
  position: relative; }
  .signup-team__container .medium-center {
    width: 70%;
    margin-left: auto;
    margin-right: auto; }
  .signup-team__container .terms-of-service__markdown {
    background-color: #fff;
    overflow-y: auto;
    padding-top: 3em;
    padding-bottom: 3em;
    min-width: 100vw; }
  .signup-team__container.branded {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    max-width: 900px; }
    .signup-team__container.branded .signup__markdown {
      -webkit-flex: 1.3 0 0;
      flex: 1.3 0 0;
      padding-right: 80px; }
      .signup-team__container.branded .signup__markdown ul + p,
      .signup-team__container.branded .signup__markdown ol + p {
        margin-top: .6em; }
      .signup-team__container.branded .signup__markdown p + ul,
      .signup-team__container.branded .signup__markdown p + ol {
        margin-top: .6em; }
      .signup-team__container.branded .signup__markdown img {
        max-width: 450px; }
      .signup-team__container.branded .signup__markdown p {
        color: gray;
        line-height: 1.6em;
        margin: 0;
        white-space: pre-wrap;
        width: 100%;
        word-break: break-word; }
    .signup-team__container.branded .signup__content {
      -webkit-flex: 1 0 0;
      flex: 1 0 0; }
  .signup-team__container.padding--less {
    padding-top: 70px; }
  .signup-team__container.terms-of-service__container {
    padding-top: 33px;
    max-width: 100%; }
  .signup-team__container .terms-of-service__footer {
    padding-top: .5em;
    padding-bottom: .5em; }
    .signup-team__container .terms-of-service__footer .terms-of-service__button-group {
      padding-top: 1em;
      padding-bottom: 1em; }
    .signup-team__container .terms-of-service__footer .terms-of-service__server-error {
      padding-bottom: 1em; }
  .signup-team__container.max-width--full {
    max-width: 100%; }
  .signup-team__container .form-control:focus {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .signup-team__container .terms-of-service-error__height--fill {
    min-height: calc(100vh - 107px - 4em);
    height: calc(100vh - 107px - 4em); }
  .signup-team__container .terms-of-service__height--fill {
    min-height: calc(100vh - 69px - 3em);
    height: calc(100vh - 69px - 3em); }
  .signup-team__container .medium-center {
    width: 70%;
    margin-left: auto;
    margin-right: auto; }
  .signup-team__container h1,
  .signup-team__container h2,
  .signup-team__container h3,
  .signup-team__container h4,
  .signup-team__container h5,
  .signup-team__container h6,
  .signup-team__container p {
    line-height: 1.3; }
  .signup-team__container h1 {
    font-weight: 600; }
  .signup-team__container h2 {
    font-size: 2.14286em;
    font-weight: 600;
    letter-spacing: -.5px;
    margin-bottom: .8em; }
  .signup-team__container h3 {
    font-size: 1.5em;
    font-weight: 600;
    margin: 0 0 1.3em; }
  .signup-team__container h4 {
    font-size: 1.42857em;
    font-weight: 600;
    margin-bottom: 1em; }
  .signup-team__container h5 {
    font-size: 1.14286em; }
  .signup-team__container hr {
    margin: 2em 0; }
  .signup-team__container p {
    color: #555555;
    line-height: 1.5;
    margin-bottom: 1em; }
  .signup-team__container .input-group.input-group--limit {
    table-layout: fixed;
    width: 100%; }
    .signup-team__container .input-group.input-group--limit .tooltip-inner {
      word-wrap: break-word; }
    .signup-team__container .input-group.input-group--limit .form-control {
      display: table-cell;
      text-align: left;
      width: 100%; }
    .signup-team__container .input-group.input-group--limit .input-group-addon {
      display: table-cell;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      width: 50%; }
  .signup-team__container .inner__content {
    margin: 30px 0 20px; }
  .signup-team__container .block--gray {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    background: #f2f2f2;
    display: inline-block;
    font-weight: 600;
    padding: .85em 1.2em; }
  .signup-team__container form {
    margin-bottom: .8em; }
  .signup-team__container .signup-team-confirm__container {
    padding: 100px 0; }
  .signup-team__container .signup-team-logo {
    display: none;
    margin: 0 0 2em;
    width: 210px; }
  .signup-team__container .signup-team-login {
    font-weight: 600;
    padding-bottom: 10px; }
  .signup-team__container .signup-team__name {
    font-size: 2.2em;
    font-weight: 600;
    margin: .5em 0 0; }
  .signup-team__container .signup-team__subdomain {
    font-size: 1.5em;
    font-weight: 300;
    margin: .2em 0 1.2em;
    text-transform: uppercase; }
  .signup-team__container .form-control {
    height: 2.71429em; }
  .signup-team__container .or__container {
    background: #dddddd;
    height: 1px;
    margin: 2em 0;
    margin: 2.5em 0 2.5em -1rem;
    text-align: left; }
    .signup-team__container .or__container span {
      background: #ffffff;
      display: inline-block;
      font-size: 1.14286em;
      font-weight: 600;
      line-height: 20px;
      position: relative;
      text-align: center;
      top: -10px;
      width: 40px; }
  .signup-team__container ul {
    margin-bottom: 0;
    padding-left: 18px; }
  .signup-team__container .btn {
    font-size: 1em;
    font-weight: 600;
    margin-right: 5px;
    padding: 0.5em 1.07143em; }
    .signup-team__container .btn .fa {
      font-size: 17px;
      margin-right: 8px; }
      .signup-team__container .btn .fa.fa--margin-top {
        position: relative;
        top: 1px; }
    .signup-team__container .btn .icon {
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      background-size: 100% 100%;
      display: inline-block;
      height: 18px;
      margin-right: 12px;
      position: relative;
      text-align: center;
      width: 18px; }
    .signup-team__container .btn.btn-custom-login {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px;
      color: white;
      display: block;
      height: 40px;
      margin: 1em 0;
      min-width: 200px;
      padding: 0 24px;
      text-align: left;
      display: flex;
      align-items: center;
      width: 200px; }
      .signup-team__container .btn.btn-custom-login > span {
        position: relative;
        display: flex;
        align-items: center; }
        .signup-team__container .btn.btn-custom-login > span > span {
          line-height: normal; }
      .signup-team__container .btn.btn-custom-login.gitlab {
        background: #554488; }
        .signup-team__container .btn.btn-custom-login.gitlab:hover {
          background: #403366; }
        .signup-team__container .btn.btn-custom-login.gitlab .icon {
          background-image: url(/static/files/bf61680806a56e50a7857eeeea863f01.png); }
      .signup-team__container .btn.btn-custom-login.google {
        background: #dd4b39; }
        .signup-team__container .btn.btn-custom-login.google:hover {
          background: #c23321; }
        .signup-team__container .btn.btn-custom-login.google .icon {
          background-image: url(/static/files/397aed0dda1c7cfb0b905dbf777a3f54.png); }
      .signup-team__container .btn.btn-custom-login.office365 {
        background: #0079d6; }
        .signup-team__container .btn.btn-custom-login.office365:hover {
          background: #005ca3; }
        .signup-team__container .btn.btn-custom-login.office365 .icon {
          background-image: url(/static/files/6aebc8a4331d19618706a547d8cccffe.png); }
      .signup-team__container .btn.btn-custom-login.ldap {
        background: #3AA1CF; }
        .signup-team__container .btn.btn-custom-login.ldap:hover {
          background: #2a84ac; }
      .signup-team__container .btn.btn-custom-login.email {
        background: #2389d7; }
        .signup-team__container .btn.btn-custom-login.email:hover {
          background: #1c6dab; }
      .signup-team__container .btn.btn-custom-login.saml {
        background: #34a28b; }
        .signup-team__container .btn.btn-custom-login.saml:hover {
          background: #287b6a; }
      .signup-team__container .btn.btn-custom-login.btn--full {
        max-width: 350px;
        text-align: left;
        width: 100%; }
      .signup-team__container .btn.btn-custom-login.btn--large {
        width: 300px; }
    .signup-team__container .btn.btn-default {
      color: #444444; }
    .signup-team__container .btn .fa {
      position: relative; }
      .signup-team__container .btn .fa.fa-check, .signup-team__container .btn .fa.fa-refresh {
        font-size: .9em;
        left: -2px;
        margin-right: .5em; }
      .signup-team__container .btn .fa.fa-chevron-right {
        font-size: .8em;
        right: -5px;
        top: 0; }
      .signup-team__container .btn .fa.fa-chevron-left {
        font-size: .8em;
        left: -2px;
        top: 0px; }
  .signup-team__container .has-error.no-padding {
    padding: 0; }
  .signup-team__container .has-error .control-label {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    background: #f2f2f2;
    color: #999999;
    font-size: 14px;
    font-weight: normal;
    margin: 1em 0 0;
    padding: .7em 1em;
    width: 100%; }
    .signup-team__container .has-error .control-label:before {
      color: #aaaaaa;
      content: '\F071';
      margin-right: 4px; }
  .signup-team__container .reset-form {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    font-size: .95em;
    position: relative; }
    .signup-team__container .reset-form p {
      color: inherit; }
  .signup-team__container h1.margin--top-none,
  .signup-team__container h2.margin--top-none,
  .signup-team__container h3.margin--top-none,
  .signup-team__container h4.margin--top-none,
  .signup-team__container h5.margin--top-none,
  .signup-team__container h6.margin--top-none {
    margin-top: 0; }
  .signup-team__container h1.margin--bottom-none,
  .signup-team__container h2.margin--bottom-none,
  .signup-team__container h3.margin--bottom-none,
  .signup-team__container h4.margin--bottom-none,
  .signup-team__container h5.margin--bottom-none,
  .signup-team__container h6.margin--bottom-none {
    margin-bottom: 0; }
  .signup-team__container h1.margin--less,
  .signup-team__container h2.margin--less,
  .signup-team__container h3.margin--less,
  .signup-team__container h4.margin--less,
  .signup-team__container h5.margin--less,
  .signup-team__container h6.margin--less {
    margin-bottom: .3em; }
  .signup-team__container h1.sub-heading,
  .signup-team__container h2.sub-heading,
  .signup-team__container h3.sub-heading,
  .signup-team__container h4.sub-heading,
  .signup-team__container h5.sub-heading,
  .signup-team__container h6.sub-heading {
    font-weight: 400;
    margin-bottom: 0; }
  .signup-team__container h1.color--light,
  .signup-team__container h2.color--light,
  .signup-team__container h3.color--light,
  .signup-team__container h4.color--light,
  .signup-team__container h5.color--light,
  .signup-team__container h6.color--light {
    font-weight: 300; }
  .signup-team__container p.margin--extra {
    margin-bottom: 1.5em; }
  .signup-team__container p.margin--less {
    margin-bottom: .3em; }
  .signup-team__container p .black, .signup-team__container p.black {
    color: #000000; }
  .signup-team__container .color--light {
    color: #777777; }
  .signup-team__container .margin--extra {
    margin-top: 2.5em; }
  .signup-team__container .margin--extra-2x {
    margin-top: 5em; }

.signup-team-all {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #dddddd;
  margin: 0 0 20px; }
  .signup-team-all .signup-team-dir {
    *zoom: 1;
    background: #fafafa;
    border-top: 1px solid #d5d5d5;
    position: relative; }
    .signup-team-all .signup-team-dir:after {
      content: " ";
      display: block;
      height: 0;
      clear: both;
      overflow: hidden;
      visibility: hidden; }
    .signup-team-all .signup-team-dir:first-child {
      border: none; }
    .signup-team-all .signup-team-dir .tooltip {
      margin: 10px 0 0 14px; }
    .signup-team-all .signup-team-dir .icon {
      cursor: pointer;
      float: left;
      margin: 16px 7px 0 15px;
      opacity: .45; }
      .signup-team-all .signup-team-dir .icon svg {
        height: 20px;
        width: 20px; }
    .signup-team-all .signup-team-dir a {
      color: inherit;
      display: block;
      font-size: 1.1em;
      height: 3.5em;
      line-height: 3.6em;
      padding: 0 15px; }
  .signup-team-all .signup-team-dir-err {
    background: #fafafa;
    border-top: 1px solid #d5d5d5;
    color: inherit;
    padding: 5px 15px; }
    .signup-team-all .signup-team-dir-err:first-child {
      border: none; }
  .signup-team-all .signup-team-dir__name {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 50px); }
  .signup-team-all .signup-team__icon {
    color: rgba(0, 0, 0, 0.5);
    float: right;
    font-size: 1.5em;
    line-height: 25px;
    position: relative;
    top: .66em; }
    .signup-team-all .signup-team__icon.fa-spin {
      font-size: .9em;
      right: -2px;
      top: 16px; }
    .signup-team-all .signup-team__icon.fa-info-circle {
      float: left;
      line-height: 1.5em;
      margin-right: .3em;
      padding-left: .5em;
      font-size: 1.5em;
      top: 11px; }

.authorize-box {
  border: 1px solid black;
  height: 280px;
  margin: 100px auto;
  width: 500px; }

.authorize-inner {
  padding: 20px; }

.authorize-btn {
  margin-right: 6px; }

.verify_panel {
  margin: 60px auto auto;
  max-width: 380px; }

.team_statistics .total-count {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 1em 0;
  text-align: center;
  width: 100%; }
  .team_statistics .total-count .title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 13px;
    font-weight: 400;
    padding: 7px 10px;
    text-align: left; }
    .team_statistics .total-count .title span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 20px); }
    .team_statistics .total-count .title .fa {
      color: #555555;
      float: right;
      font-size: 16px;
      margin: 0; }
  .team_statistics .total-count .content {
    color: #555555;
    font-size: 30px;
    font-weight: 600;
    overflow: auto;
    padding: .3em 0 .35em; }

.team_statistics .total-count--day {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  clear: both;
  height: 275px;
  margin: 10px;
  padding: 5px 10px 10px;
  width: 760px; }
  .team_statistics .total-count--day > div {
    font-size: 18px;
    font-weight: 300; }

.team_statistics .recent-active-users table {
  table-layout: fixed;
  width: 100%; }

.team_statistics .recent-active-users .content {
  max-height: 400px;
  overflow: auto;
  padding: 0; }

.team_statistics .recent-active-users tr:first-child td {
  border-top: none; }

.team_statistics .recent-active-users tr td {
  overflow: hidden;
  *zoom: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 13px;
  font-weight: 400;
  padding: 5px 5px 6px;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .team_statistics .recent-active-users tr td:first-child {
    border-left: none; }

.team_statistics .team-statistics__header-row {
  display: flex; }

.team_statistics .team-statistics__header {
  display: inline-block;
  flex: 1;
  margin-right: 15px;
  overflow: hidden;
  padding-bottom: .5em; }
  .team_statistics .team-statistics__header > h3 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.team_statistics .team-statistics__team-filter {
  display: inline-block;
  width: 200px; }

.alert {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  padding: 8px 12px; }

.alert-grey {
  background: #f2f2f2;
  color: #999999; }

.alert--confirm {
  display: inline-block;
  float: left;
  margin: 1px 0 0 10px;
  padding: 4px 10px; }

.alert .btn-close {
  font-size: 21px;
  line-height: 20px;
  float: right; }
  .alert .btn-close, .alert .btn-close:link, .alert .btn-close:visited, .alert .btn-close:hover, .alert .btn-close:focus, .alert .btn-close:active {
    color: inherit;
    text-decoration: none; }

.announcement-bar {
  background-color: #1f7bc1;
  color: white;
  padding: 2px 30px;
  height: 24px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9999; }
  .announcement-bar.announcement-bar--fixed {
    overflow: hidden;
    padding: 1px 30px;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .announcement-bar a {
    color: inherit !important;
    text-decoration: underline; }
    .announcement-bar a:hover, .announcement-bar a:active, .announcement-bar a:focus {
      color: inherit !important; }
    .announcement-bar a.announcement-bar__close {
      color: white;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-weight: 600;
      padding: 0 10px;
      position: absolute;
      right: 0;
      text-decoration: none;
      top: -2px; }
      .announcement-bar a.announcement-bar__close:hover {
        color: white;
        text-decoration: none; }
  .announcement-bar .fa-wrapper {
    margin: 0 5px; }
  .announcement-bar .resend-verification-wrapper:before {
    content: "\A0   "; }

.announcement-bar-critical {
  background-color: #B0171F; }

.announcement-bar-developer {
  background-color: purple; }

.announcement-bar-success {
  color: #3c763d;
  background-color: #dff0d8; }

.channel-view .badge {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  font-size: 11px;
  height: 17px;
  line-height: 16px;
  min-width: 18px;
  padding: 0 5px;
  position: relative; }

.channel-view .team-sidebar .badge {
  border: 1px solid transparent;
  position: absolute;
  right: -8px;
  top: -6px;
  height: auto;
  padding: 0px 6px;
  z-index: 5; }

.channel-view .navbar-toggle .badge {
  position: absolute;
  top: 10px;
  left: 8px; }

button.style--none {
  background: transparent;
  border: none;
  padding: 0; }
  button.style--none:focus {
    outline: 0; }
  button.style--none.btn {
    padding: 6px 12px; }
  button.style--none.btn--block {
    text-align: left;
    width: 100%; }

.btn {
  -webkit-transition: all 0.15s ease;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease false;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px; }
  .btn.btn-primary {
    background: #2389d7;
    border-color: transparent; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active {
      background: #1c6dab; }
  .btn.btn-default {
    background: rgba(0, 0, 0, 0.7);
    color: white; }
    .btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default:active {
      background: rgba(0, 0, 0, 0.8);
      color: white; }
  .btn.btn-danger {
    color: white; }
    .app__body .btn.btn-danger {
      color: white; }
      .app__body .btn.btn-danger:hover, .app__body .btn.btn-danger:focus, .app__body .btn.btn-danger:active {
        color: white; }
    .btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger:active {
      color: white; }
  .btn.btn-transparent {
    background: transparent;
    border: none;
    padding: 7px 12px; }
  .btn.btn-inactive {
    background: rgba(0, 0, 0, 0.15);
    border-color: transparent;
    color: white; }
  .btn .fa {
    margin-right: 3px; }
  .btn.btn-link:active, .btn.btn-link:focus {
    outline: none; }

.dropdown-menu {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .dropdown-menu.colorpicker {
    min-width: 125px;
    z-index: 2500; }
  .dropdown-menu .dropdown-item__plugin {
    display: flex;
    justify-content: center; }
    .dropdown-menu .dropdown-item__plugin:empty {
      display: none; }
  .dropdown-menu .fa {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    margin-right: 5px; }
  .dropdown-menu .divider {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
    opacity: 0.15;
    margin: 5px 0; }
    .dropdown-menu .divider + .divider {
      display: none; }
    .dropdown-menu .divider:first-child, .dropdown-menu .divider:last-child {
      display: none; }
    .dropdown-menu .divider:nth-last-of-type(3) {
      display: none; }
  .dropdown-menu div > a,
  .dropdown-menu li > a {
    color: inherit;
    padding: 5px 16px;
    text-decoration: none; }
    .dropdown-menu div > a:focus, .dropdown-menu div > a:hover,
    .dropdown-menu li > a:focus,
    .dropdown-menu li > a:hover {
      color: inherit; }
  .dropdown-menu > li > button {
    clear: both;
    color: inherit;
    cursor: pointer;
    display: block;
    font-weight: normal;
    line-height: 1.42857143;
    padding: 5px 15px;
    text-align: left;
    white-space: nowrap;
    width: 100%; }

.open > .dropdown-menu__content {
  display: block; }
  .open > .dropdown-menu__content > .dropdown-menu {
    display: block; }

.dropdown-menu__content {
  display: none; }

.dropdown-menu__icons li .fa {
  width: 25px;
  text-align: center;
  margin-left: -5px; }

.reacticon {
  display: inline-block;
  fill: inherit;
  opacity: .7;
  position: relative;
  top: 2px;
  vertical-align: middle;
  visibility: hidden; }

.emoticon {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 21px;
  min-height: 1em;
  min-width: 1em;
  vertical-align: middle;
  width: 21px; }

.emoticon-suggestion {
  overflow: hidden;
  *zoom: 1;
  cursor: pointer;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  width: 100%; }
  .emoticon-suggestion > div:first-child {
    text-align: center;
    width: 30px; }

.emoticon-suggestion__image {
  margin-top: -2px;
  max-height: 20px;
  max-width: 20px;
  padding: 0;
  text-align: center;
  vertical-align: middle; }

.emoji-picker {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  overflow: hidden;
  *zoom: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 3px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  margin-right: 3px;
  position: absolute;
  width: 315px;
  z-index: 999; }
  .browser--ie .emoji-picker {
    width: 325px; }
  .app__content .emoji-picker {
    margin-right: 0; }
  .emoji-picker.bottom {
    margin-top: 10px; }
  .emoji-picker .nav-tabs {
    margin-bottom: 0px;
    height: 34px;
    display: flex;
    justify-content: flex-end; }
    .emoji-picker .nav-tabs li {
      flex: 1; }
      .emoji-picker .nav-tabs li a {
        border: none !important;
        border-bottom: 2px solid transparent !important;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        opacity: 0.7;
        outline: none;
        height: 34px;
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 9px; }
    .emoji-picker .nav-tabs li.active a {
      opacity: 1;
      height: 34px;
      margin-left: 5px;
      margin-right: 5px;
      padding-top: 9px; }
    .emoji-picker .nav-tabs svg {
      top: 2px; }

.emoji-picker__categories {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px; }
  .emoji-picker__categories .emoji-picker__category {
    color: #333333;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    width: 15px; }
    .emoji-picker__categories .emoji-picker__category--selected, .emoji-picker__categories .emoji-picker__category:hover {
      color: #666666; }
    .emoji-picker__categories .emoji-picker__category.disable {
      pointer-events: none; }

.emoji-picker__search-container {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  margin: 8px 7px 1px 7px; }
  .emoji-picker__search-container .emoji-picker__search-icon {
    padding-left: 9px;
    padding-top: 6px;
    position: absolute;
    font-size: 14px; }
  .emoji-picker__search-container .emoji-picker__search {
    border-width: 0px;
    border-radius: inherit;
    padding: 2px 0 2px 27px;
    width: 100%;
    height: 26px;
    font-size: 13px; }
    .emoji-picker__search-container .emoji-picker__search:focus {
      outline: none; }

.emoji-picker__items {
  border-top-style: solid;
  border-top-width: 1px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 8px 8px;
  position: relative;
  width: 100%; }
  .emoji-picker__items .emoji-picker__container {
    min-height: 100%;
    position: relative;
    width: 100%; }
  .emoji-picker__items .emoji-picker__category-header {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 3px;
    padding-top: 3px; }
  .emoji-picker__items .emoji-picker__preview_sprite {
    display: inline-block;
    height: 45px;
    margin: 3px;
    padding: 3px;
    vertical-align: top; }
  .emoji-picker__items .emoji-picker-items__container .emoji-picker__item {
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    height: 26px;
    margin: 0;
    overflow: hidden;
    padding: 2px;
    position: relative;
    vertical-align: middle;
    width: 26px;
    margin: 0 3px 1px; }
    .emoji-picker__items .emoji-picker-items__container .emoji-picker__item > div {
      text-align: center; }
      .emoji-picker__items .emoji-picker-items__container .emoji-picker__item > div > img {
        left: -21px;
        position: relative;
        top: -21px; }
        .emoji-picker__items .emoji-picker-items__container .emoji-picker__item > div > img.emoji-shipit {
          left: -26px;
          top: -28px; }
        .emoji-picker__items .emoji-picker-items__container .emoji-picker__item > div > img.emoji-category--custom {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1);
          height: auto;
          left: auto;
          max-height: 22px;
          max-width: 22px;
          top: auto;
          width: auto; }
  .emoji-picker__items .emoji-picker-items__container > span {
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 21px;
    margin: 3px;
    overflow: hidden;
    padding: 0;
    text-align: center;
    vertical-align: top;
    width: 21px; }
  .emoji-picker__items .emoji-picker__item-wrapper .emoji-picker__item {
    font-size: 18px;
    height: auto;
    margin: 0 auto;
    max-height: 21px;
    max-width: 21px;
    min-height: 0;
    min-width: 0;
    width: auto; }

.emoji-picker__preview {
  align-items: stretch;
  border-top: 1px solid;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  height: 50px;
  justify-content: flex-start;
  padding: 7px 10px 0;
  position: relative;
  vertical-align: middle;
  width: 278px; }
  .emoji-picker__preview.emoji-picker__preview-placeholder {
    padding: 12px; }
  .emoji-picker__preview > div {
    display: table-cell;
    vertical-align: middle; }
  .emoji-picker__preview .emoji-picker__preview-image-label-box {
    margin-left: 3px; }
    .emoji-picker__preview .emoji-picker__preview-image-label-box .emoji-picker__preview-name {
      font-size: 13px;
      font-weight: bold; }
    .emoji-picker__preview .emoji-picker__preview-image-label-box .emoji-picker__preview-aliases {
      display: block;
      font-size: 12px;
      font-weight: bold; }
  .emoji-picker__preview .emoji-picker__preview-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-height: 36px;
    width: 42px;
    max-width: 42px;
    text-align: center; }
    .emoji-picker__preview .emoji-picker__preview-image-box .sprite-preview {
      border: none;
      display: inline-block;
      height: 36px;
      padding: 0;
      vertical-align: middle;
      width: 36px; }
  .emoji-picker__preview .emoji-picker__preview-image {
    max-height: 36px;
    max-width: 42px; }

.emojisprite-preview {
  -webkit-transform: scale(0.55);
  -moz-transform: scale(0.55);
  -ms-transform: scale(0.55);
  -o-transform: scale(0.55);
  transform: scale(0.55);
  background-repeat: no-repeat;
  cursor: pointer;
  height: 64px;
  max-width: none;
  padding: 0 10px 0 0;
  transform-origin: 0 0;
  width: 64px; }

.emojisprite {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -ms-transform: scale(0.35);
  -o-transform: scale(0.35);
  transform: scale(0.35);
  background-repeat: no-repeat;
  border-radius: 18px;
  height: 64px;
  max-width: none;
  width: 64px; }

.emojisprite-loading {
  background-image: none !important;
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -ms-transform: scale(0.35);
  -o-transform: scale(0.35);
  transform: scale(0.35);
  background-repeat: no-repeat;
  border-radius: 18px;
  cursor: pointer;
  height: 64px;
  max-width: none;
  width: 64px; }

.emoji-category-people-1 {
  background-image: url(/static/files/c600c40cc82899187c4a0c856a8b5a89.png); }

.emoji-263a-fe0f {
  background-position: -0px -0px; }

.emoji-1f601 {
  background-position: -64px -0px; }

.emoji-1f602 {
  background-position: -0px -64px; }

.emoji-1f603 {
  background-position: -64px -64px; }

.emoji-1f604 {
  background-position: -128px -0px; }

.emoji-1f605 {
  background-position: -128px -64px; }

.emoji-1f606 {
  background-position: -0px -128px; }

.emoji-1f607 {
  background-position: -64px -128px; }

.emoji-1f609 {
  background-position: -128px -128px; }

.emoji-1f60a {
  background-position: -192px -0px; }

.emoji-1f60b {
  background-position: -192px -64px; }

.emoji-1f60c {
  background-position: -192px -128px; }

.emoji-1f60d {
  background-position: -0px -192px; }

.emoji-1f60e {
  background-position: -64px -192px; }

.emoji-1f60f {
  background-position: -128px -192px; }

.emoji-1f612 {
  background-position: -192px -192px; }

.emoji-1f614 {
  background-position: -256px -0px; }

.emoji-1f615 {
  background-position: -256px -64px; }

.emoji-1f616 {
  background-position: -256px -128px; }

.emoji-1f617 {
  background-position: -256px -192px; }

.emoji-1f618 {
  background-position: -0px -256px; }

.emoji-1f619 {
  background-position: -64px -256px; }

.emoji-1f61a {
  background-position: -128px -256px; }

.emoji-1f61b {
  background-position: -192px -256px; }

.emoji-1f61c {
  background-position: -256px -256px; }

.emoji-1f61d {
  background-position: -320px -0px; }

.emoji-1f61e {
  background-position: -320px -64px; }

.emoji-1f61f {
  background-position: -320px -128px; }

.emoji-1f620 {
  background-position: -320px -192px; }

.emoji-1f621 {
  background-position: -320px -256px; }

.emoji-1f623 {
  background-position: -0px -320px; }

.emoji-1f624 {
  background-position: -64px -320px; }

.emoji-1f629 {
  background-position: -128px -320px; }

.emoji-1f62b {
  background-position: -192px -320px; }

.emoji-1f641 {
  background-position: -256px -320px; }

.emoji-1f642 {
  background-position: -320px -320px; }

.emoji-1f643 {
  background-position: -384px -0px; }

.emoji-1f911 {
  background-position: -384px -64px; }

.emoji-1f913 {
  background-position: -384px -128px; }

.emoji-1f917 {
  background-position: -384px -192px; }

.emoji-1f920 {
  background-position: -384px -256px; }

.emoji-1f921 {
  background-position: -384px -320px; }

.emoji-1f923 {
  background-position: -0px -384px; }

.emoji-2639-fe0f {
  background-position: -64px -384px; }

.emoji-1f600 {
  background-position: -128px -384px; }

.emoji-category-people-2 {
  background-image: url(/static/files/bd76cb7fa021ede9c9c40e0d11b70af3.png); }

.emoji-2620-fe0f {
  background-position: -0px -0px; }

.emoji-1f479 {
  background-position: -64px -0px; }

.emoji-1f47a {
  background-position: -0px -64px; }

.emoji-1f47b {
  background-position: -64px -64px; }

.emoji-1f47d {
  background-position: -128px -0px; }

.emoji-1f47e {
  background-position: -128px -64px; }

.emoji-1f47f {
  background-position: -0px -128px; }

.emoji-1f480 {
  background-position: -64px -128px; }

.emoji-1f4a9 {
  background-position: -128px -128px; }

.emoji-1f608 {
  background-position: -192px -0px; }

.emoji-1f610 {
  background-position: -192px -64px; }

.emoji-1f611 {
  background-position: -192px -128px; }

.emoji-1f613 {
  background-position: -0px -192px; }

.emoji-1f622 {
  background-position: -64px -192px; }

.emoji-1f625 {
  background-position: -128px -192px; }

.emoji-1f626 {
  background-position: -192px -192px; }

.emoji-1f627 {
  background-position: -256px -0px; }

.emoji-1f628 {
  background-position: -256px -64px; }

.emoji-1f62a {
  background-position: -256px -128px; }

.emoji-1f62c {
  background-position: -256px -192px; }

.emoji-1f62d {
  background-position: -0px -256px; }

.emoji-1f62e {
  background-position: -64px -256px; }

.emoji-1f62f {
  background-position: -128px -256px; }

.emoji-1f630 {
  background-position: -192px -256px; }

.emoji-1f631 {
  background-position: -256px -256px; }

.emoji-1f632 {
  background-position: -320px -0px; }

.emoji-1f633 {
  background-position: -320px -64px; }

.emoji-1f634 {
  background-position: -320px -128px; }

.emoji-1f635 {
  background-position: -320px -192px; }

.emoji-1f636 {
  background-position: -320px -256px; }

.emoji-1f637 {
  background-position: -0px -320px; }

.emoji-1f638 {
  background-position: -64px -320px; }

.emoji-1f639 {
  background-position: -128px -320px; }

.emoji-1f63a {
  background-position: -192px -320px; }

.emoji-1f644 {
  background-position: -256px -320px; }

.emoji-1f910 {
  background-position: -320px -320px; }

.emoji-1f912 {
  background-position: -384px -0px; }

.emoji-1f914 {
  background-position: -384px -64px; }

.emoji-1f915 {
  background-position: -384px -128px; }

.emoji-1f916 {
  background-position: -384px -192px; }

.emoji-1f922 {
  background-position: -384px -256px; }

.emoji-1f924 {
  background-position: -384px -320px; }

.emoji-1f925 {
  background-position: -0px -384px; }

.emoji-1f927 {
  background-position: -64px -384px; }

.emoji-1f383 {
  background-position: -128px -384px; }

.emoji-category-people-3 {
  background-image: url(/static/files/73f83114ca8493f215854ce20071cd10.png); }

.emoji-270d-fe0f {
  background-position: -0px -0px; }

.emoji-1f443 {
  background-position: -64px -0px; }

.emoji-1f444 {
  background-position: -0px -64px; }

.emoji-1f445 {
  background-position: -64px -64px; }

.emoji-1f446 {
  background-position: -128px -0px; }

.emoji-1f447 {
  background-position: -128px -64px; }

.emoji-1f448 {
  background-position: -0px -128px; }

.emoji-1f449 {
  background-position: -64px -128px; }

.emoji-1f44a {
  background-position: -128px -128px; }

.emoji-1f44b {
  background-position: -192px -0px; }

.emoji-1f44c {
  background-position: -192px -64px; }

.emoji-1f44d {
  background-position: -192px -128px; }

.emoji-1f44e {
  background-position: -0px -192px; }

.emoji-1f44f {
  background-position: -64px -192px; }

.emoji-1f450 {
  background-position: -128px -192px; }

.emoji-1f463 {
  background-position: -192px -192px; }

.emoji-1f484 {
  background-position: -256px -0px; }

.emoji-1f485 {
  background-position: -256px -64px; }

.emoji-1f48b {
  background-position: -256px -128px; }

.emoji-1f48d {
  background-position: -256px -192px; }

.emoji-1f4aa {
  background-position: -0px -256px; }

.emoji-1f590 {
  background-position: -64px -256px; }

.emoji-1f595 {
  background-position: -128px -256px; }

.emoji-1f596 {
  background-position: -192px -256px; }

.emoji-1f63b {
  background-position: -256px -256px; }

.emoji-1f63c {
  background-position: -320px -0px; }

.emoji-1f63d {
  background-position: -320px -64px; }

.emoji-1f63e {
  background-position: -320px -128px; }

.emoji-1f63f {
  background-position: -320px -192px; }

.emoji-1f640 {
  background-position: -320px -256px; }

.emoji-1f64c {
  background-position: -0px -320px; }

.emoji-1f64f {
  background-position: -64px -320px; }

.emoji-1f918 {
  background-position: -128px -320px; }

.emoji-1f919 {
  background-position: -192px -320px; }

.emoji-1f91a {
  background-position: -256px -320px; }

.emoji-1f91b {
  background-position: -320px -320px; }

.emoji-1f91c {
  background-position: -384px -0px; }

.emoji-1f91d {
  background-position: -384px -64px; }

.emoji-1f91e {
  background-position: -384px -128px; }

.emoji-1f933 {
  background-position: -384px -192px; }

.emoji-261d-fe0f {
  background-position: -384px -256px; }

.emoji-270a {
  background-position: -384px -320px; }

.emoji-270b {
  background-position: -0px -384px; }

.emoji-270c-fe0f {
  background-position: -64px -384px; }

.emoji-1f442 {
  background-position: -128px -384px; }

.emoji-category-people-4 {
  background-image: url(/static/files/846e32e5d2c594f217f6f574de2817c9.png); }

.emoji-1f5e3 {
  background-position: -0px -0px; }

.emoji-1f441 {
  background-position: -64px -0px; }

.emoji-1f464 {
  background-position: -0px -64px; }

.emoji-1f465 {
  background-position: -64px -64px; }

.emoji-1f466 {
  background-position: -128px -0px; }

.emoji-1f467 {
  background-position: -128px -64px; }

.emoji-1f468 {
  background-position: -0px -128px; }

.emoji-1f468-200d-1f33e {
  background-position: -64px -128px; }

.emoji-1f468-200d-1f373 {
  background-position: -128px -128px; }

.emoji-1f468-200d-1f393 {
  background-position: -192px -0px; }

.emoji-1f468-200d-1f3a4 {
  background-position: -192px -64px; }

.emoji-1f468-200d-1f3eb {
  background-position: -192px -128px; }

.emoji-1f468-200d-1f3ed {
  background-position: -0px -192px; }

.emoji-1f468-200d-1f4bb {
  background-position: -64px -192px; }

.emoji-1f468-200d-1f4bc {
  background-position: -128px -192px; }

.emoji-1f468-200d-1f527 {
  background-position: -192px -192px; }

.emoji-1f468-200d-2695-fe0f {
  background-position: -256px -0px; }

.emoji-1f469 {
  background-position: -256px -64px; }

.emoji-1f469-200d-1f33e {
  background-position: -256px -128px; }

.emoji-1f469-200d-1f373 {
  background-position: -256px -192px; }

.emoji-1f469-200d-1f393 {
  background-position: -0px -256px; }

.emoji-1f469-200d-1f3a4 {
  background-position: -64px -256px; }

.emoji-1f469-200d-1f3eb {
  background-position: -128px -256px; }

.emoji-1f469-200d-1f3ed {
  background-position: -192px -256px; }

.emoji-1f469-200d-1f4bb {
  background-position: -256px -256px; }

.emoji-1f469-200d-1f4bc {
  background-position: -320px -0px; }

.emoji-1f469-200d-1f527 {
  background-position: -320px -64px; }

.emoji-1f469-200d-2695-fe0f {
  background-position: -320px -128px; }

.emoji-1f46e {
  background-position: -320px -192px; }

.emoji-1f46e-200d-2640-fe0f {
  background-position: -320px -256px; }

.emoji-1f471 {
  background-position: -0px -320px; }

.emoji-1f471-200d-2640-fe0f {
  background-position: -64px -320px; }

.emoji-1f472 {
  background-position: -128px -320px; }

.emoji-1f473 {
  background-position: -192px -320px; }

.emoji-1f473-200d-2640-fe0f {
  background-position: -256px -320px; }

.emoji-1f474 {
  background-position: -320px -320px; }

.emoji-1f475 {
  background-position: -384px -0px; }

.emoji-1f476 {
  background-position: -384px -64px; }

.emoji-1f477 {
  background-position: -384px -128px; }

.emoji-1f477-200d-2640-fe0f {
  background-position: -384px -192px; }

.emoji-1f482 {
  background-position: -384px -256px; }

.emoji-1f482-200d-2640-fe0f {
  background-position: -384px -320px; }

.emoji-1f575-fe0f {
  background-position: -0px -384px; }

.emoji-1f575-fe0f-200d-2640-fe0f {
  background-position: -64px -384px; }

.emoji-1f440 {
  background-position: -128px -384px; }

.emoji-category-people-5 {
  background-image: url(/static/files/2f9bb89629a8f96a7cdd18a5b1f41038.png); }

.emoji-1f937-200d-2642-fe0f {
  background-position: -0px -0px; }

.emoji-1f468-200d-1f3a8 {
  background-position: -64px -0px; }

.emoji-1f468-200d-1f52c {
  background-position: -0px -64px; }

.emoji-1f468-200d-1f680 {
  background-position: -64px -64px; }

.emoji-1f468-200d-1f692 {
  background-position: -128px -0px; }

.emoji-1f468-200d-2696-fe0f {
  background-position: -128px -64px; }

.emoji-1f468-200d-2708-fe0f {
  background-position: -0px -128px; }

.emoji-1f469-200d-1f3a8 {
  background-position: -64px -128px; }

.emoji-1f469-200d-1f52c {
  background-position: -128px -128px; }

.emoji-1f469-200d-1f680 {
  background-position: -192px -0px; }

.emoji-1f469-200d-1f692 {
  background-position: -192px -64px; }

.emoji-1f469-200d-2696-fe0f {
  background-position: -192px -128px; }

.emoji-1f469-200d-2708-fe0f {
  background-position: -0px -192px; }

.emoji-1f470 {
  background-position: -64px -192px; }

.emoji-1f478 {
  background-position: -128px -192px; }

.emoji-1f47c {
  background-position: -192px -192px; }

.emoji-1f481 {
  background-position: -256px -0px; }

.emoji-1f481-200d-2642-fe0f {
  background-position: -256px -64px; }

.emoji-1f483 {
  background-position: -256px -128px; }

.emoji-1f486 {
  background-position: -256px -192px; }

.emoji-1f486-200d-2642-fe0f {
  background-position: -0px -256px; }

.emoji-1f487 {
  background-position: -64px -256px; }

.emoji-1f487-200d-2642-fe0f {
  background-position: -128px -256px; }

.emoji-1f574 {
  background-position: -192px -256px; }

.emoji-1f57a {
  background-position: -256px -256px; }

.emoji-1f645 {
  background-position: -320px -0px; }

.emoji-1f645-200d-2642-fe0f {
  background-position: -320px -64px; }

.emoji-1f646 {
  background-position: -320px -128px; }

.emoji-1f646-200d-2642-fe0f {
  background-position: -320px -192px; }

.emoji-1f647 {
  background-position: -320px -256px; }

.emoji-1f647-200d-2640-fe0f {
  background-position: -0px -320px; }

.emoji-1f64b {
  background-position: -64px -320px; }

.emoji-1f64b-200d-2642-fe0f {
  background-position: -128px -320px; }

.emoji-1f64d {
  background-position: -192px -320px; }

.emoji-1f64d-200d-2642-fe0f {
  background-position: -256px -320px; }

.emoji-1f64e {
  background-position: -320px -320px; }

.emoji-1f64e-200d-2642-fe0f {
  background-position: -384px -0px; }

.emoji-1f926-200d-2640-fe0f {
  background-position: -384px -64px; }

.emoji-1f926-200d-2642-fe0f {
  background-position: -384px -128px; }

.emoji-1f930 {
  background-position: -384px -192px; }

.emoji-1f934 {
  background-position: -384px -256px; }

.emoji-1f935 {
  background-position: -384px -320px; }

.emoji-1f936 {
  background-position: -0px -384px; }

.emoji-1f937-200d-2640-fe0f {
  background-position: -64px -384px; }

.emoji-1f385 {
  background-position: -128px -384px; }

.emoji-category-people-6 {
  background-image: url(/static/files/c2cd560e75ce1f5066e50699fed27fa5.png); }

.emoji-1f6b6-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f3c3-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f454 {
  background-position: -0px -64px; }

.emoji-1f455 {
  background-position: -64px -64px; }

.emoji-1f456 {
  background-position: -128px -0px; }

.emoji-1f457 {
  background-position: -128px -64px; }

.emoji-1f45a {
  background-position: -0px -128px; }

.emoji-1f468-200d-1f466 {
  background-position: -64px -128px; }

.emoji-1f468-200d-1f466-200d-1f466 {
  background-position: -128px -128px; }

.emoji-1f468-200d-1f467 {
  background-position: -192px -0px; }

.emoji-1f468-200d-1f467-200d-1f466 {
  background-position: -192px -64px; }

.emoji-1f468-200d-1f467-200d-1f467 {
  background-position: -192px -128px; }

.emoji-1f468-200d-1f468-200d-1f466 {
  background-position: -0px -192px; }

.emoji-1f468-200d-1f468-200d-1f466-200d-1f466 {
  background-position: -64px -192px; }

.emoji-1f468-200d-1f468-200d-1f467 {
  background-position: -128px -192px; }

.emoji-1f468-200d-1f468-200d-1f467-200d-1f466 {
  background-position: -192px -192px; }

.emoji-1f468-200d-1f468-200d-1f467-200d-1f467 {
  background-position: -256px -0px; }

.emoji-1f468-200d-1f469-200d-1f466-200d-1f466 {
  background-position: -256px -64px; }

.emoji-1f468-200d-1f469-200d-1f467 {
  background-position: -256px -128px; }

.emoji-1f468-200d-1f469-200d-1f467-200d-1f466 {
  background-position: -256px -192px; }

.emoji-1f468-200d-1f469-200d-1f467-200d-1f467 {
  background-position: -0px -256px; }

.emoji-1f468-200d-2764-fe0f-200d-1f468 {
  background-position: -64px -256px; }

.emoji-1f468-200d-2764-fe0f-200d-1f48b-200d-1f468 {
  background-position: -128px -256px; }

.emoji-1f469-200d-1f466 {
  background-position: -192px -256px; }

.emoji-1f469-200d-1f466-200d-1f466 {
  background-position: -256px -256px; }

.emoji-1f469-200d-1f467 {
  background-position: -320px -0px; }

.emoji-1f469-200d-1f467-200d-1f466 {
  background-position: -320px -64px; }

.emoji-1f469-200d-1f467-200d-1f467 {
  background-position: -320px -128px; }

.emoji-1f469-200d-1f469-200d-1f466 {
  background-position: -320px -192px; }

.emoji-1f469-200d-1f469-200d-1f466-200d-1f466 {
  background-position: -320px -256px; }

.emoji-1f469-200d-1f469-200d-1f467 {
  background-position: -0px -320px; }

.emoji-1f469-200d-1f469-200d-1f467-200d-1f466 {
  background-position: -64px -320px; }

.emoji-1f469-200d-1f469-200d-1f467-200d-1f467 {
  background-position: -128px -320px; }

.emoji-1f469-200d-2764-fe0f-200d-1f469 {
  background-position: -192px -320px; }

.emoji-1f469-200d-2764-fe0f-200d-1f48b-200d-1f469 {
  background-position: -256px -320px; }

.emoji-1f46a {
  background-position: -320px -320px; }

.emoji-1f46b {
  background-position: -384px -0px; }

.emoji-1f46c {
  background-position: -384px -64px; }

.emoji-1f46d {
  background-position: -384px -128px; }

.emoji-1f46f {
  background-position: -384px -192px; }

.emoji-1f46f-200d-2642-fe0f {
  background-position: -384px -256px; }

.emoji-1f48f {
  background-position: -384px -320px; }

.emoji-1f491 {
  background-position: -0px -384px; }

.emoji-1f6b6 {
  background-position: -64px -384px; }

.emoji-1f3c3 {
  background-position: -128px -384px; }

.emoji-category-people-7 {
  background-image: url(/static/files/2831760a99423bac17d0853458db0fb7.png); }

.emoji-26d1 {
  background-position: -0px -0px; }

.emoji-1f392 {
  background-position: -64px -0px; }

.emoji-1f393 {
  background-position: -0px -64px; }

.emoji-1f3a9 {
  background-position: -64px -64px; }

.emoji-1f451 {
  background-position: -128px -0px; }

.emoji-1f452 {
  background-position: -128px -64px; }

.emoji-1f453 {
  background-position: -0px -128px; }

.emoji-1f458 {
  background-position: -64px -128px; }

.emoji-1f459 {
  background-position: -128px -128px; }

.emoji-1f45b {
  background-position: -192px -0px; }

.emoji-1f45c {
  background-position: -192px -64px; }

.emoji-1f45d {
  background-position: -192px -128px; }

.emoji-1f45e {
  background-position: -0px -192px; }

.emoji-1f45f {
  background-position: -64px -192px; }

.emoji-1f460 {
  background-position: -128px -192px; }

.emoji-1f461 {
  background-position: -192px -192px; }

.emoji-1f462 {
  background-position: -256px -0px; }

.emoji-1f4bc {
  background-position: -256px -64px; }

.emoji-1f576 {
  background-position: -256px -128px; }

.emoji-2602-fe0f {
  background-position: -256px -192px; }

.emoji-1f302 {
  background-position: -0px -256px; }

.emoji-category-nature-1 {
  background-image: url(/static/files/9bfc48db2a32efaa6ff3f46c5b0d4898.png); }

.emoji-1f98b {
  background-position: -0px -0px; }

.emoji-1f40d {
  background-position: -64px -0px; }

.emoji-1f412 {
  background-position: -0px -64px; }

.emoji-1f414 {
  background-position: -64px -64px; }

.emoji-1f417 {
  background-position: -128px -0px; }

.emoji-1f41a {
  background-position: -128px -64px; }

.emoji-1f41b {
  background-position: -0px -128px; }

.emoji-1f41c {
  background-position: -64px -128px; }

.emoji-1f41d {
  background-position: -128px -128px; }

.emoji-1f41e {
  background-position: -192px -0px; }

.emoji-1f422 {
  background-position: -192px -64px; }

.emoji-1f423 {
  background-position: -192px -128px; }

.emoji-1f424 {
  background-position: -0px -192px; }

.emoji-1f425 {
  background-position: -64px -192px; }

.emoji-1f426 {
  background-position: -128px -192px; }

.emoji-1f427 {
  background-position: -192px -192px; }

.emoji-1f428 {
  background-position: -256px -0px; }

.emoji-1f42d {
  background-position: -256px -64px; }

.emoji-1f42e {
  background-position: -256px -128px; }

.emoji-1f42f {
  background-position: -256px -192px; }

.emoji-1f430 {
  background-position: -0px -256px; }

.emoji-1f431 {
  background-position: -64px -256px; }

.emoji-1f434 {
  background-position: -128px -256px; }

.emoji-1f435 {
  background-position: -192px -256px; }

.emoji-1f436 {
  background-position: -256px -256px; }

.emoji-1f437 {
  background-position: -320px -0px; }

.emoji-1f438 {
  background-position: -320px -64px; }

.emoji-1f439 {
  background-position: -320px -128px; }

.emoji-1f43a {
  background-position: -320px -192px; }

.emoji-1f43b {
  background-position: -320px -256px; }

.emoji-1f43c {
  background-position: -0px -320px; }

.emoji-1f43d {
  background-position: -64px -320px; }

.emoji-1f577 {
  background-position: -128px -320px; }

.emoji-1f578 {
  background-position: -192px -320px; }

.emoji-1f648 {
  background-position: -256px -320px; }

.emoji-1f649 {
  background-position: -320px -320px; }

.emoji-1f64a {
  background-position: -384px -0px; }

.emoji-1f981 {
  background-position: -384px -64px; }

.emoji-1f984 {
  background-position: -384px -128px; }

.emoji-1f985 {
  background-position: -384px -192px; }

.emoji-1f986 {
  background-position: -384px -256px; }

.emoji-1f987 {
  background-position: -384px -320px; }

.emoji-1f989 {
  background-position: -0px -384px; }

.emoji-1f98a {
  background-position: -64px -384px; }

.emoji-1f40c {
  background-position: -128px -384px; }

.emoji-category-nature-2 {
  background-image: url(/static/files/f8aa00225b7d9a468c5ec45237c1f4ae.png); }

.emoji-1f991 {
  background-position: -0px -0px; }

.emoji-1f384 {
  background-position: -64px -0px; }

.emoji-1f400 {
  background-position: -0px -64px; }

.emoji-1f401 {
  background-position: -64px -64px; }

.emoji-1f402 {
  background-position: -128px -0px; }

.emoji-1f403 {
  background-position: -128px -64px; }

.emoji-1f404 {
  background-position: -0px -128px; }

.emoji-1f405 {
  background-position: -64px -128px; }

.emoji-1f406 {
  background-position: -128px -128px; }

.emoji-1f407 {
  background-position: -192px -0px; }

.emoji-1f408 {
  background-position: -192px -64px; }

.emoji-1f409 {
  background-position: -192px -128px; }

.emoji-1f40a {
  background-position: -0px -192px; }

.emoji-1f40b {
  background-position: -64px -192px; }

.emoji-1f40e {
  background-position: -128px -192px; }

.emoji-1f40f {
  background-position: -192px -192px; }

.emoji-1f410 {
  background-position: -256px -0px; }

.emoji-1f411 {
  background-position: -256px -64px; }

.emoji-1f413 {
  background-position: -256px -128px; }

.emoji-1f415 {
  background-position: -256px -192px; }

.emoji-1f416 {
  background-position: -0px -256px; }

.emoji-1f418 {
  background-position: -64px -256px; }

.emoji-1f419 {
  background-position: -128px -256px; }

.emoji-1f41f {
  background-position: -192px -256px; }

.emoji-1f420 {
  background-position: -256px -256px; }

.emoji-1f421 {
  background-position: -320px -0px; }

.emoji-1f429 {
  background-position: -320px -64px; }

.emoji-1f42a {
  background-position: -320px -128px; }

.emoji-1f42b {
  background-position: -320px -192px; }

.emoji-1f42c {
  background-position: -320px -256px; }

.emoji-1f432 {
  background-position: -0px -320px; }

.emoji-1f433 {
  background-position: -64px -320px; }

.emoji-1f43e {
  background-position: -128px -320px; }

.emoji-1f43f {
  background-position: -192px -320px; }

.emoji-1f54a {
  background-position: -256px -320px; }

.emoji-1f980 {
  background-position: -320px -320px; }

.emoji-1f982 {
  background-position: -384px -0px; }

.emoji-1f983 {
  background-position: -384px -64px; }

.emoji-1f988 {
  background-position: -384px -128px; }

.emoji-1f98c {
  background-position: -384px -192px; }

.emoji-1f98d {
  background-position: -384px -256px; }

.emoji-1f98e {
  background-position: -384px -320px; }

.emoji-1f98f {
  background-position: -0px -384px; }

.emoji-1f990 {
  background-position: -64px -384px; }

.emoji-1f335 {
  background-position: -128px -384px; }

.emoji-category-nature-3 {
  background-image: url(/static/files/f7e428364c511a394e1fe40d08c4cb40.png); }

.emoji-2b50-fe0f {
  background-position: -0px -0px; }

.emoji-1f30e {
  background-position: -64px -0px; }

.emoji-1f30f {
  background-position: -0px -64px; }

.emoji-1f311 {
  background-position: -64px -64px; }

.emoji-1f312 {
  background-position: -128px -0px; }

.emoji-1f313 {
  background-position: -128px -64px; }

.emoji-1f314 {
  background-position: -0px -128px; }

.emoji-1f315 {
  background-position: -64px -128px; }

.emoji-1f316 {
  background-position: -128px -128px; }

.emoji-1f317 {
  background-position: -192px -0px; }

.emoji-1f318 {
  background-position: -192px -64px; }

.emoji-1f319 {
  background-position: -192px -128px; }

.emoji-1f31a {
  background-position: -0px -192px; }

.emoji-1f31b {
  background-position: -64px -192px; }

.emoji-1f31c {
  background-position: -128px -192px; }

.emoji-1f31d {
  background-position: -192px -192px; }

.emoji-1f31e {
  background-position: -256px -0px; }

.emoji-1f31f {
  background-position: -256px -64px; }

.emoji-1f331 {
  background-position: -256px -128px; }

.emoji-1f332 {
  background-position: -256px -192px; }

.emoji-1f333 {
  background-position: -0px -256px; }

.emoji-1f334 {
  background-position: -64px -256px; }

.emoji-1f337 {
  background-position: -128px -256px; }

.emoji-1f338 {
  background-position: -192px -256px; }

.emoji-1f339 {
  background-position: -256px -256px; }

.emoji-1f33a {
  background-position: -320px -0px; }

.emoji-1f33b {
  background-position: -320px -64px; }

.emoji-1f33c {
  background-position: -320px -128px; }

.emoji-1f33e {
  background-position: -320px -192px; }

.emoji-1f33f {
  background-position: -320px -256px; }

.emoji-1f340 {
  background-position: -0px -320px; }

.emoji-1f341 {
  background-position: -64px -320px; }

.emoji-1f342 {
  background-position: -128px -320px; }

.emoji-1f343 {
  background-position: -192px -320px; }

.emoji-1f344 {
  background-position: -256px -320px; }

.emoji-1f38b {
  background-position: -320px -320px; }

.emoji-1f38d {
  background-position: -384px -0px; }

.emoji-1f490 {
  background-position: -384px -64px; }

.emoji-1f4ab {
  background-position: -384px -128px; }

.emoji-1f525 {
  background-position: -384px -192px; }

.emoji-1f940 {
  background-position: -384px -256px; }

.emoji-2618-fe0f {
  background-position: -384px -320px; }

.emoji-26a1-fe0f {
  background-position: -0px -384px; }

.emoji-2728 {
  background-position: -64px -384px; }

.emoji-1f30d {
  background-position: -128px -384px; }

.emoji-category-nature-4 {
  background-image: url(/static/files/6e2ff7215341fa0d387ff501abc9d66f.png); }

.emoji-2744-fe0f {
  background-position: -0px -0px; }

.emoji-1f30a {
  background-position: -64px -0px; }

.emoji-1f324 {
  background-position: -0px -64px; }

.emoji-1f325 {
  background-position: -64px -64px; }

.emoji-1f326 {
  background-position: -128px -0px; }

.emoji-1f327 {
  background-position: -128px -64px; }

.emoji-1f328 {
  background-position: -0px -128px; }

.emoji-1f329 {
  background-position: -64px -128px; }

.emoji-1f32a {
  background-position: -128px -128px; }

.emoji-1f32b {
  background-position: -192px -0px; }

.emoji-1f32c {
  background-position: -192px -64px; }

.emoji-1f4a5 {
  background-position: -192px -128px; }

.emoji-1f4a6 {
  background-position: -0px -192px; }

.emoji-1f4a7 {
  background-position: -64px -192px; }

.emoji-1f4a8 {
  background-position: -128px -192px; }

.emoji-2600-fe0f {
  background-position: -192px -192px; }

.emoji-2601-fe0f {
  background-position: -256px -0px; }

.emoji-2603-fe0f {
  background-position: -256px -64px; }

.emoji-2604-fe0f {
  background-position: -256px -128px; }

.emoji-2614-fe0f {
  background-position: -256px -192px; }

.emoji-26c4-fe0f {
  background-position: -0px -256px; }

.emoji-26c5-fe0f {
  background-position: -64px -256px; }

.emoji-26c8 {
  background-position: -128px -256px; }

.emoji-1f308 {
  background-position: -192px -256px; }

.emoji-category-foods-1 {
  background-image: url(/static/files/9e3bf2ae03975c851d189d1cca2d1550.png); }

.emoji-1f9c0 {
  background-position: -0px -0px; }

.emoji-1f32e {
  background-position: -64px -0px; }

.emoji-1f32f {
  background-position: -0px -64px; }

.emoji-1f330 {
  background-position: -64px -64px; }

.emoji-1f336 {
  background-position: -128px -0px; }

.emoji-1f33d {
  background-position: -128px -64px; }

.emoji-1f345 {
  background-position: -0px -128px; }

.emoji-1f346 {
  background-position: -64px -128px; }

.emoji-1f347 {
  background-position: -128px -128px; }

.emoji-1f348 {
  background-position: -192px -0px; }

.emoji-1f349 {
  background-position: -192px -64px; }

.emoji-1f34a {
  background-position: -192px -128px; }

.emoji-1f34b {
  background-position: -0px -192px; }

.emoji-1f34c {
  background-position: -64px -192px; }

.emoji-1f34d {
  background-position: -128px -192px; }

.emoji-1f34e {
  background-position: -192px -192px; }

.emoji-1f34f {
  background-position: -256px -0px; }

.emoji-1f350 {
  background-position: -256px -64px; }

.emoji-1f351 {
  background-position: -256px -128px; }

.emoji-1f352 {
  background-position: -256px -192px; }

.emoji-1f353 {
  background-position: -0px -256px; }

.emoji-1f354 {
  background-position: -64px -256px; }

.emoji-1f355 {
  background-position: -128px -256px; }

.emoji-1f356 {
  background-position: -192px -256px; }

.emoji-1f357 {
  background-position: -256px -256px; }

.emoji-1f35e {
  background-position: -320px -0px; }

.emoji-1f35f {
  background-position: -320px -64px; }

.emoji-1f360 {
  background-position: -320px -128px; }

.emoji-1f364 {
  background-position: -320px -192px; }

.emoji-1f36f {
  background-position: -320px -256px; }

.emoji-1f373 {
  background-position: -0px -320px; }

.emoji-1f950 {
  background-position: -64px -320px; }

.emoji-1f951 {
  background-position: -128px -320px; }

.emoji-1f952 {
  background-position: -192px -320px; }

.emoji-1f953 {
  background-position: -256px -320px; }

.emoji-1f954 {
  background-position: -320px -320px; }

.emoji-1f955 {
  background-position: -384px -0px; }

.emoji-1f956 {
  background-position: -384px -64px; }

.emoji-1f957 {
  background-position: -384px -128px; }

.emoji-1f959 {
  background-position: -384px -192px; }

.emoji-1f95a {
  background-position: -384px -256px; }

.emoji-1f95c {
  background-position: -384px -320px; }

.emoji-1f95d {
  background-position: -0px -384px; }

.emoji-1f95e {
  background-position: -64px -384px; }

.emoji-1f32d {
  background-position: -128px -384px; }

.emoji-category-foods-2 {
  background-image: url(/static/files/239dec5c4a7f1909c491cac6d03e2c46.png); }

.emoji-2615-fe0f {
  background-position: -0px -0px; }

.emoji-1f359 {
  background-position: -64px -0px; }

.emoji-1f35a {
  background-position: -0px -64px; }

.emoji-1f35b {
  background-position: -64px -64px; }

.emoji-1f35c {
  background-position: -128px -0px; }

.emoji-1f35d {
  background-position: -128px -64px; }

.emoji-1f361 {
  background-position: -0px -128px; }

.emoji-1f362 {
  background-position: -64px -128px; }

.emoji-1f363 {
  background-position: -128px -128px; }

.emoji-1f365 {
  background-position: -192px -0px; }

.emoji-1f366 {
  background-position: -192px -64px; }

.emoji-1f367 {
  background-position: -192px -128px; }

.emoji-1f368 {
  background-position: -0px -192px; }

.emoji-1f369 {
  background-position: -64px -192px; }

.emoji-1f36a {
  background-position: -128px -192px; }

.emoji-1f36b {
  background-position: -192px -192px; }

.emoji-1f36c {
  background-position: -256px -0px; }

.emoji-1f36d {
  background-position: -256px -64px; }

.emoji-1f36e {
  background-position: -256px -128px; }

.emoji-1f370 {
  background-position: -256px -192px; }

.emoji-1f371 {
  background-position: -0px -256px; }

.emoji-1f372 {
  background-position: -64px -256px; }

.emoji-1f374 {
  background-position: -128px -256px; }

.emoji-1f375 {
  background-position: -192px -256px; }

.emoji-1f376 {
  background-position: -256px -256px; }

.emoji-1f377 {
  background-position: -320px -0px; }

.emoji-1f378 {
  background-position: -320px -64px; }

.emoji-1f379 {
  background-position: -320px -128px; }

.emoji-1f37a {
  background-position: -320px -192px; }

.emoji-1f37b {
  background-position: -320px -256px; }

.emoji-1f37c {
  background-position: -0px -320px; }

.emoji-1f37d {
  background-position: -64px -320px; }

.emoji-1f37e {
  background-position: -128px -320px; }

.emoji-1f37f {
  background-position: -192px -320px; }

.emoji-1f382 {
  background-position: -256px -320px; }

.emoji-1f942 {
  background-position: -320px -320px; }

.emoji-1f943 {
  background-position: -384px -0px; }

.emoji-1f944 {
  background-position: -384px -64px; }

.emoji-1f958 {
  background-position: -384px -128px; }

.emoji-1f95b {
  background-position: -384px -192px; }

.emoji-1f358 {
  background-position: -384px -256px; }

.emoji-category-activity-1 {
  background-image: url(/static/files/797826864d81a24fe2fb89935eec8db9.png); }

.emoji-26f9-fe0f-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f3b1 {
  background-position: -64px -0px; }

.emoji-1f3be {
  background-position: -0px -64px; }

.emoji-1f3bf {
  background-position: -64px -64px; }

.emoji-1f3c0 {
  background-position: -128px -0px; }

.emoji-1f3c2 {
  background-position: -128px -64px; }

.emoji-1f3c4 {
  background-position: -0px -128px; }

.emoji-1f3c4-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f3c7 {
  background-position: -128px -128px; }

.emoji-1f3c8 {
  background-position: -192px -0px; }

.emoji-1f3c9 {
  background-position: -192px -64px; }

.emoji-1f3ca {
  background-position: -192px -128px; }

.emoji-1f3ca-200d-2640-fe0f {
  background-position: -0px -192px; }

.emoji-1f3cb-fe0f {
  background-position: -64px -192px; }

.emoji-1f3cb-fe0f-200d-2640-fe0f {
  background-position: -128px -192px; }

.emoji-1f3cc-fe0f {
  background-position: -192px -192px; }

.emoji-1f3cc-fe0f-200d-2640-fe0f {
  background-position: -256px -0px; }

.emoji-1f3cf {
  background-position: -256px -64px; }

.emoji-1f3d0 {
  background-position: -256px -128px; }

.emoji-1f3d1 {
  background-position: -256px -192px; }

.emoji-1f3d2 {
  background-position: -0px -256px; }

.emoji-1f3d3 {
  background-position: -64px -256px; }

.emoji-1f3f8 {
  background-position: -128px -256px; }

.emoji-1f3f9 {
  background-position: -192px -256px; }

.emoji-1f6a3 {
  background-position: -256px -256px; }

.emoji-1f6a3-200d-2640-fe0f {
  background-position: -320px -0px; }

.emoji-1f938-200d-2640-fe0f {
  background-position: -320px -64px; }

.emoji-1f938-200d-2642-fe0f {
  background-position: -320px -128px; }

.emoji-1f93a {
  background-position: -320px -192px; }

.emoji-1f93c-200d-2640-fe0f {
  background-position: -320px -256px; }

.emoji-1f93c-200d-2642-fe0f {
  background-position: -0px -320px; }

.emoji-1f93d-200d-2640-fe0f {
  background-position: -64px -320px; }

.emoji-1f93d-200d-2642-fe0f {
  background-position: -128px -320px; }

.emoji-1f93e-200d-2640-fe0f {
  background-position: -192px -320px; }

.emoji-1f93e-200d-2642-fe0f {
  background-position: -256px -320px; }

.emoji-1f945 {
  background-position: -320px -320px; }

.emoji-1f94a {
  background-position: -384px -0px; }

.emoji-1f94b {
  background-position: -384px -64px; }

.emoji-26bd-fe0f {
  background-position: -384px -128px; }

.emoji-26be-fe0f {
  background-position: -384px -192px; }

.emoji-26f3-fe0f {
  background-position: -384px -256px; }

.emoji-26f7 {
  background-position: -384px -320px; }

.emoji-26f8 {
  background-position: -0px -384px; }

.emoji-26f9-fe0f {
  background-position: -64px -384px; }

.emoji-1f3a3 {
  background-position: -128px -384px; }

.emoji-category-activity-2 {
  background-image: url(/static/files/e44974211915d7a1607b4df5b4a5d814.png); }

.emoji-1f949 {
  background-position: -0px -0px; }

.emoji-1f397 {
  background-position: -64px -0px; }

.emoji-1f39f {
  background-position: -0px -64px; }

.emoji-1f3a4 {
  background-position: -64px -64px; }

.emoji-1f3a7 {
  background-position: -128px -0px; }

.emoji-1f3a8 {
  background-position: -128px -64px; }

.emoji-1f3aa {
  background-position: -0px -128px; }

.emoji-1f3ab {
  background-position: -64px -128px; }

.emoji-1f3ac {
  background-position: -128px -128px; }

.emoji-1f3ad {
  background-position: -192px -0px; }

.emoji-1f3ae {
  background-position: -192px -64px; }

.emoji-1f3af {
  background-position: -192px -128px; }

.emoji-1f3b0 {
  background-position: -0px -192px; }

.emoji-1f3b2 {
  background-position: -64px -192px; }

.emoji-1f3b3 {
  background-position: -128px -192px; }

.emoji-1f3b7 {
  background-position: -192px -192px; }

.emoji-1f3b8 {
  background-position: -256px -0px; }

.emoji-1f3b9 {
  background-position: -256px -64px; }

.emoji-1f3ba {
  background-position: -256px -128px; }

.emoji-1f3bb {
  background-position: -256px -192px; }

.emoji-1f3bc {
  background-position: -0px -256px; }

.emoji-1f3bd {
  background-position: -64px -256px; }

.emoji-1f3c5 {
  background-position: -128px -256px; }

.emoji-1f3c6 {
  background-position: -192px -256px; }

.emoji-1f3f5 {
  background-position: -256px -256px; }

.emoji-1f6b4 {
  background-position: -320px -0px; }

.emoji-1f6b4-200d-2640-fe0f {
  background-position: -320px -64px; }

.emoji-1f6b5 {
  background-position: -320px -128px; }

.emoji-1f6b5-200d-2640-fe0f {
  background-position: -320px -192px; }

.emoji-1f939-200d-2640-fe0f {
  background-position: -320px -256px; }

.emoji-1f939-200d-2642-fe0f {
  background-position: -0px -320px; }

.emoji-1f941 {
  background-position: -64px -320px; }

.emoji-1f947 {
  background-position: -128px -320px; }

.emoji-1f948 {
  background-position: -192px -320px; }

.emoji-1f396 {
  background-position: -256px -320px; }

.emoji-category-places-1 {
  background-image: url(/static/files/330e63672c9012ff5b1c8aa40ac45508.png); }

.emoji-2708-fe0f {
  background-position: -0px -0px; }

.emoji-1f3ce {
  background-position: -64px -0px; }

.emoji-1f4ba {
  background-position: -0px -64px; }

.emoji-1f680 {
  background-position: -64px -64px; }

.emoji-1f681 {
  background-position: -128px -0px; }

.emoji-1f682 {
  background-position: -128px -64px; }

.emoji-1f683 {
  background-position: -0px -128px; }

.emoji-1f684 {
  background-position: -64px -128px; }

.emoji-1f685 {
  background-position: -128px -128px; }

.emoji-1f686 {
  background-position: -192px -0px; }

.emoji-1f687 {
  background-position: -192px -64px; }

.emoji-1f688 {
  background-position: -192px -128px; }

.emoji-1f689 {
  background-position: -0px -192px; }

.emoji-1f68a {
  background-position: -64px -192px; }

.emoji-1f68b {
  background-position: -128px -192px; }

.emoji-1f68c {
  background-position: -192px -192px; }

.emoji-1f68d {
  background-position: -256px -0px; }

.emoji-1f68e {
  background-position: -256px -64px; }

.emoji-1f690 {
  background-position: -256px -128px; }

.emoji-1f691 {
  background-position: -256px -192px; }

.emoji-1f692 {
  background-position: -0px -256px; }

.emoji-1f693 {
  background-position: -64px -256px; }

.emoji-1f694 {
  background-position: -128px -256px; }

.emoji-1f695 {
  background-position: -192px -256px; }

.emoji-1f696 {
  background-position: -256px -256px; }

.emoji-1f697 {
  background-position: -320px -0px; }

.emoji-1f698 {
  background-position: -320px -64px; }

.emoji-1f699 {
  background-position: -320px -128px; }

.emoji-1f69a {
  background-position: -320px -192px; }

.emoji-1f69b {
  background-position: -320px -256px; }

.emoji-1f69c {
  background-position: -0px -320px; }

.emoji-1f69d {
  background-position: -64px -320px; }

.emoji-1f69e {
  background-position: -128px -320px; }

.emoji-1f69f {
  background-position: -192px -320px; }

.emoji-1f6a0 {
  background-position: -256px -320px; }

.emoji-1f6a1 {
  background-position: -320px -320px; }

.emoji-1f6a8 {
  background-position: -384px -0px; }

.emoji-1f6b2 {
  background-position: -384px -64px; }

.emoji-1f6e9 {
  background-position: -384px -128px; }

.emoji-1f6eb {
  background-position: -384px -192px; }

.emoji-1f6ec {
  background-position: -384px -256px; }

.emoji-1f6f0 {
  background-position: -384px -320px; }

.emoji-1f6f4 {
  background-position: -0px -384px; }

.emoji-1f6f5 {
  background-position: -64px -384px; }

.emoji-1f3cd {
  background-position: -128px -384px; }

.emoji-category-places-2 {
  background-image: url(/static/files/adcdd9254b34160b4120039ceff2a811.png); }

.emoji-26fd-fe0f {
  background-position: -0px -0px; }

.emoji-1f3a0 {
  background-position: -64px -0px; }

.emoji-1f3a1 {
  background-position: -0px -64px; }

.emoji-1f3a2 {
  background-position: -64px -64px; }

.emoji-1f3d4 {
  background-position: -128px -0px; }

.emoji-1f3d5 {
  background-position: -128px -64px; }

.emoji-1f3d6 {
  background-position: -0px -128px; }

.emoji-1f3d7 {
  background-position: -64px -128px; }

.emoji-1f3d8 {
  background-position: -128px -128px; }

.emoji-1f3da {
  background-position: -192px -0px; }

.emoji-1f3dc {
  background-position: -192px -64px; }

.emoji-1f3dd {
  background-position: -192px -128px; }

.emoji-1f3df {
  background-position: -0px -192px; }

.emoji-1f3e0 {
  background-position: -64px -192px; }

.emoji-1f3e1 {
  background-position: -128px -192px; }

.emoji-1f3e2 {
  background-position: -192px -192px; }

.emoji-1f3e3 {
  background-position: -256px -0px; }

.emoji-1f3ec {
  background-position: -256px -64px; }

.emoji-1f3ed {
  background-position: -256px -128px; }

.emoji-1f3ef {
  background-position: -256px -192px; }

.emoji-1f3f0 {
  background-position: -0px -256px; }

.emoji-1f5fa {
  background-position: -64px -256px; }

.emoji-1f5fb {
  background-position: -128px -256px; }

.emoji-1f5fc {
  background-position: -192px -256px; }

.emoji-1f5fd {
  background-position: -256px -256px; }

.emoji-1f5ff {
  background-position: -320px -0px; }

.emoji-1f68f {
  background-position: -320px -64px; }

.emoji-1f6a2 {
  background-position: -320px -128px; }

.emoji-1f6a4 {
  background-position: -320px -192px; }

.emoji-1f6a5 {
  background-position: -320px -256px; }

.emoji-1f6a6 {
  background-position: -0px -320px; }

.emoji-1f6a7 {
  background-position: -64px -320px; }

.emoji-1f6e3 {
  background-position: -128px -320px; }

.emoji-1f6e4 {
  background-position: -192px -320px; }

.emoji-1f6e5 {
  background-position: -256px -320px; }

.emoji-1f6f3 {
  background-position: -320px -320px; }

.emoji-1f6f6 {
  background-position: -384px -0px; }

.emoji-2693-fe0f {
  background-position: -384px -64px; }

.emoji-26f0 {
  background-position: -384px -128px; }

.emoji-26f1 {
  background-position: -384px -192px; }

.emoji-26f2-fe0f {
  background-position: -384px -256px; }

.emoji-26f4 {
  background-position: -384px -320px; }

.emoji-26f5-fe0f {
  background-position: -0px -384px; }

.emoji-26fa-fe0f {
  background-position: -64px -384px; }

.emoji-1f30b {
  background-position: -128px -384px; }

.emoji-category-places-3 {
  background-image: url(/static/files/e46de0f74a1813c4886c3c2a6bfc1c8e.png); }

.emoji-26ea-fe0f {
  background-position: -0px -0px; }

.emoji-1f303 {
  background-position: -64px -0px; }

.emoji-1f304 {
  background-position: -0px -64px; }

.emoji-1f305 {
  background-position: -64px -64px; }

.emoji-1f306 {
  background-position: -128px -0px; }

.emoji-1f307 {
  background-position: -128px -64px; }

.emoji-1f309 {
  background-position: -0px -128px; }

.emoji-1f30c {
  background-position: -64px -128px; }

.emoji-1f320 {
  background-position: -128px -128px; }

.emoji-1f386 {
  background-position: -192px -0px; }

.emoji-1f387 {
  background-position: -192px -64px; }

.emoji-1f391 {
  background-position: -192px -128px; }

.emoji-1f3d9 {
  background-position: -0px -192px; }

.emoji-1f3db {
  background-position: -64px -192px; }

.emoji-1f3de {
  background-position: -128px -192px; }

.emoji-1f3e4 {
  background-position: -192px -192px; }

.emoji-1f3e5 {
  background-position: -256px -0px; }

.emoji-1f3e6 {
  background-position: -256px -64px; }

.emoji-1f3e8 {
  background-position: -256px -128px; }

.emoji-1f3e9 {
  background-position: -256px -192px; }

.emoji-1f3ea {
  background-position: -0px -256px; }

.emoji-1f3eb {
  background-position: -64px -256px; }

.emoji-1f492 {
  background-position: -128px -256px; }

.emoji-1f54b {
  background-position: -192px -256px; }

.emoji-1f54c {
  background-position: -256px -256px; }

.emoji-1f54d {
  background-position: -320px -0px; }

.emoji-1f5fe {
  background-position: -320px -64px; }

.emoji-26e9 {
  background-position: -320px -128px; }

.emoji-1f301 {
  background-position: -320px -192px; }

.emoji-category-objects-1 {
  background-image: url(/static/files/28b6b9fe8b4c9a8e61fbe320575bb569.png); }

.emoji-260e-fe0f {
  background-position: -0px -0px; }

.emoji-1f39a {
  background-position: -64px -0px; }

.emoji-1f39b {
  background-position: -0px -64px; }

.emoji-1f39e {
  background-position: -64px -64px; }

.emoji-1f3a5 {
  background-position: -128px -0px; }

.emoji-1f4a1 {
  background-position: -128px -64px; }

.emoji-1f4bb {
  background-position: -0px -128px; }

.emoji-1f4bd {
  background-position: -64px -128px; }

.emoji-1f4be {
  background-position: -128px -128px; }

.emoji-1f4bf {
  background-position: -192px -0px; }

.emoji-1f4c0 {
  background-position: -192px -64px; }

.emoji-1f4de {
  background-position: -192px -128px; }

.emoji-1f4df {
  background-position: -0px -192px; }

.emoji-1f4e0 {
  background-position: -64px -192px; }

.emoji-1f4e1 {
  background-position: -128px -192px; }

.emoji-1f4f1 {
  background-position: -192px -192px; }

.emoji-1f4f2 {
  background-position: -256px -0px; }

.emoji-1f4f7 {
  background-position: -256px -64px; }

.emoji-1f4f8 {
  background-position: -256px -128px; }

.emoji-1f4f9 {
  background-position: -256px -192px; }

.emoji-1f4fa {
  background-position: -0px -256px; }

.emoji-1f4fb {
  background-position: -64px -256px; }

.emoji-1f4fc {
  background-position: -128px -256px; }

.emoji-1f4fd {
  background-position: -192px -256px; }

.emoji-1f50b {
  background-position: -256px -256px; }

.emoji-1f50c {
  background-position: -320px -0px; }

.emoji-1f526 {
  background-position: -320px -64px; }

.emoji-1f56f {
  background-position: -320px -128px; }

.emoji-1f570 {
  background-position: -320px -192px; }

.emoji-1f579 {
  background-position: -320px -256px; }

.emoji-1f5a5 {
  background-position: -0px -320px; }

.emoji-1f5a8 {
  background-position: -64px -320px; }

.emoji-1f5b1 {
  background-position: -128px -320px; }

.emoji-1f5b2 {
  background-position: -192px -320px; }

.emoji-1f5d1 {
  background-position: -256px -320px; }

.emoji-1f5dc {
  background-position: -320px -320px; }

.emoji-1f6e2 {
  background-position: -384px -0px; }

.emoji-231a-fe0f {
  background-position: -384px -64px; }

.emoji-231b-fe0f {
  background-position: -384px -128px; }

.emoji-2328-fe0f {
  background-position: -384px -192px; }

.emoji-23f0 {
  background-position: -384px -256px; }

.emoji-23f1 {
  background-position: -384px -320px; }

.emoji-23f2 {
  background-position: -0px -384px; }

.emoji-23f3 {
  background-position: -64px -384px; }

.emoji-1f399 {
  background-position: -128px -384px; }

.emoji-category-objects-2 {
  background-image: url(/static/files/fe9423612ebc33594661291f6457f730.png); }

.emoji-26d3 {
  background-position: -0px -0px; }

.emoji-1f3fa {
  background-position: -64px -0px; }

.emoji-1f488 {
  background-position: -0px -64px; }

.emoji-1f489 {
  background-position: -64px -64px; }

.emoji-1f48a {
  background-position: -128px -0px; }

.emoji-1f48e {
  background-position: -128px -64px; }

.emoji-1f4a3 {
  background-position: -0px -128px; }

.emoji-1f4b0 {
  background-position: -64px -128px; }

.emoji-1f4b3 {
  background-position: -128px -128px; }

.emoji-1f4b4 {
  background-position: -192px -0px; }

.emoji-1f4b5 {
  background-position: -192px -64px; }

.emoji-1f4b6 {
  background-position: -192px -128px; }

.emoji-1f4b7 {
  background-position: -0px -192px; }

.emoji-1f4b8 {
  background-position: -64px -192px; }

.emoji-1f4ff {
  background-position: -128px -192px; }

.emoji-1f511 {
  background-position: -192px -192px; }

.emoji-1f527 {
  background-position: -256px -0px; }

.emoji-1f528 {
  background-position: -256px -64px; }

.emoji-1f529 {
  background-position: -256px -128px; }

.emoji-1f52a {
  background-position: -256px -192px; }

.emoji-1f52b {
  background-position: -0px -256px; }

.emoji-1f52c {
  background-position: -64px -256px; }

.emoji-1f52d {
  background-position: -128px -256px; }

.emoji-1f52e {
  background-position: -192px -256px; }

.emoji-1f573 {
  background-position: -256px -256px; }

.emoji-1f5dd {
  background-position: -320px -0px; }

.emoji-1f5e1 {
  background-position: -320px -64px; }

.emoji-1f6ac {
  background-position: -320px -128px; }

.emoji-1f6b0 {
  background-position: -320px -192px; }

.emoji-1f6bd {
  background-position: -320px -256px; }

.emoji-1f6bf {
  background-position: -0px -320px; }

.emoji-1f6c0 {
  background-position: -64px -320px; }

.emoji-1f6c1 {
  background-position: -128px -320px; }

.emoji-1f6ce {
  background-position: -192px -320px; }

.emoji-1f6e0 {
  background-position: -256px -320px; }

.emoji-1f6e1 {
  background-position: -320px -320px; }

.emoji-2692 {
  background-position: -384px -0px; }

.emoji-2694-fe0f {
  background-position: -384px -64px; }

.emoji-2696-fe0f {
  background-position: -384px -128px; }

.emoji-2697-fe0f {
  background-position: -384px -192px; }

.emoji-2699-fe0f {
  background-position: -384px -256px; }

.emoji-26b0-fe0f {
  background-position: -384px -320px; }

.emoji-26b1-fe0f {
  background-position: -0px -384px; }

.emoji-26cf {
  background-position: -64px -384px; }

.emoji-1f321 {
  background-position: -128px -384px; }

.emoji-category-objects-3 {
  background-image: url(/static/files/cb2a2e44858dd1a410559d284cb23706.png); }

.emoji-2709-fe0f {
  background-position: -0px -0px; }

.emoji-1f381 {
  background-position: -64px -0px; }

.emoji-1f388 {
  background-position: -0px -64px; }

.emoji-1f389 {
  background-position: -64px -64px; }

.emoji-1f38a {
  background-position: -128px -0px; }

.emoji-1f38e {
  background-position: -128px -64px; }

.emoji-1f38f {
  background-position: -0px -128px; }

.emoji-1f390 {
  background-position: -64px -128px; }

.emoji-1f3ee {
  background-position: -128px -128px; }

.emoji-1f3f7 {
  background-position: -192px -0px; }

.emoji-1f48c {
  background-position: -192px -64px; }

.emoji-1f4c3 {
  background-position: -192px -128px; }

.emoji-1f4c4 {
  background-position: -0px -192px; }

.emoji-1f4c5 {
  background-position: -64px -192px; }

.emoji-1f4c6 {
  background-position: -128px -192px; }

.emoji-1f4c7 {
  background-position: -192px -192px; }

.emoji-1f4c8 {
  background-position: -256px -0px; }

.emoji-1f4c9 {
  background-position: -256px -64px; }

.emoji-1f4ca {
  background-position: -256px -128px; }

.emoji-1f4d1 {
  background-position: -256px -192px; }

.emoji-1f4dc {
  background-position: -0px -256px; }

.emoji-1f4e4 {
  background-position: -64px -256px; }

.emoji-1f4e5 {
  background-position: -128px -256px; }

.emoji-1f4e6 {
  background-position: -192px -256px; }

.emoji-1f4e7 {
  background-position: -256px -256px; }

.emoji-1f4e8 {
  background-position: -320px -0px; }

.emoji-1f4e9 {
  background-position: -320px -64px; }

.emoji-1f4ea {
  background-position: -320px -128px; }

.emoji-1f4eb {
  background-position: -320px -192px; }

.emoji-1f4ec {
  background-position: -320px -256px; }

.emoji-1f4ed {
  background-position: -0px -320px; }

.emoji-1f4ee {
  background-position: -64px -320px; }

.emoji-1f4ef {
  background-position: -128px -320px; }

.emoji-1f5bc {
  background-position: -192px -320px; }

.emoji-1f5c3 {
  background-position: -256px -320px; }

.emoji-1f5d2 {
  background-position: -320px -320px; }

.emoji-1f5d3 {
  background-position: -384px -0px; }

.emoji-1f5f3 {
  background-position: -384px -64px; }

.emoji-1f6aa {
  background-position: -384px -128px; }

.emoji-1f6cb {
  background-position: -384px -192px; }

.emoji-1f6cc {
  background-position: -384px -256px; }

.emoji-1f6cd {
  background-position: -384px -320px; }

.emoji-1f6cf {
  background-position: -0px -384px; }

.emoji-1f6d2 {
  background-position: -64px -384px; }

.emoji-1f380 {
  background-position: -128px -384px; }

.emoji-category-objects-4 {
  background-image: url(/static/files/bbf9fc9496c1ca27aa433c2783c47f4c.png); }

.emoji-2712-fe0f {
  background-position: -0px -0px; }

.emoji-1f4c2 {
  background-position: -64px -0px; }

.emoji-1f4cb {
  background-position: -0px -64px; }

.emoji-1f4cc {
  background-position: -64px -64px; }

.emoji-1f4cd {
  background-position: -128px -0px; }

.emoji-1f4ce {
  background-position: -128px -64px; }

.emoji-1f4cf {
  background-position: -0px -128px; }

.emoji-1f4d0 {
  background-position: -64px -128px; }

.emoji-1f4d2 {
  background-position: -128px -128px; }

.emoji-1f4d3 {
  background-position: -192px -0px; }

.emoji-1f4d4 {
  background-position: -192px -64px; }

.emoji-1f4d5 {
  background-position: -192px -128px; }

.emoji-1f4d6 {
  background-position: -0px -192px; }

.emoji-1f4d7 {
  background-position: -64px -192px; }

.emoji-1f4d8 {
  background-position: -128px -192px; }

.emoji-1f4d9 {
  background-position: -192px -192px; }

.emoji-1f4da {
  background-position: -256px -0px; }

.emoji-1f4dd {
  background-position: -256px -64px; }

.emoji-1f4f0 {
  background-position: -256px -128px; }

.emoji-1f50d {
  background-position: -256px -192px; }

.emoji-1f50e {
  background-position: -0px -256px; }

.emoji-1f50f {
  background-position: -64px -256px; }

.emoji-1f510 {
  background-position: -128px -256px; }

.emoji-1f512 {
  background-position: -192px -256px; }

.emoji-1f513 {
  background-position: -256px -256px; }

.emoji-1f516 {
  background-position: -320px -0px; }

.emoji-1f517 {
  background-position: -320px -64px; }

.emoji-1f587 {
  background-position: -320px -128px; }

.emoji-1f58a {
  background-position: -320px -192px; }

.emoji-1f58b {
  background-position: -320px -256px; }

.emoji-1f58c {
  background-position: -0px -320px; }

.emoji-1f58d {
  background-position: -64px -320px; }

.emoji-1f5c2 {
  background-position: -128px -320px; }

.emoji-1f5c4 {
  background-position: -192px -320px; }

.emoji-1f5de {
  background-position: -256px -320px; }

.emoji-2702-fe0f {
  background-position: -320px -320px; }

.emoji-270f-fe0f {
  background-position: -384px -0px; }

.emoji-1f4c1 {
  background-position: -384px -64px; }

.emoji-category-symbols-1 {
  background-image: url(/static/files/6c65e52ed042d987b857c3d089bbe469.png); }

.emoji-2764-fe0f {
  background-position: -0px -0px; }

.emoji-1f251 {
  background-position: -64px -0px; }

.emoji-1f493 {
  background-position: -0px -64px; }

.emoji-1f494 {
  background-position: -64px -64px; }

.emoji-1f495 {
  background-position: -128px -0px; }

.emoji-1f496 {
  background-position: -128px -64px; }

.emoji-1f497 {
  background-position: -0px -128px; }

.emoji-1f498 {
  background-position: -64px -128px; }

.emoji-1f499 {
  background-position: -128px -128px; }

.emoji-1f49a {
  background-position: -192px -0px; }

.emoji-1f49b {
  background-position: -192px -64px; }

.emoji-1f49c {
  background-position: -192px -128px; }

.emoji-1f49d {
  background-position: -0px -192px; }

.emoji-1f49e {
  background-position: -64px -192px; }

.emoji-1f49f {
  background-position: -128px -192px; }

.emoji-1f52f {
  background-position: -192px -192px; }

.emoji-1f549 {
  background-position: -256px -0px; }

.emoji-1f54e {
  background-position: -256px -64px; }

.emoji-1f5a4 {
  background-position: -256px -128px; }

.emoji-1f6d0 {
  background-position: -256px -192px; }

.emoji-2622-fe0f {
  background-position: -0px -256px; }

.emoji-2623-fe0f {
  background-position: -64px -256px; }

.emoji-2626-fe0f {
  background-position: -128px -256px; }

.emoji-262a-fe0f {
  background-position: -192px -256px; }

.emoji-262e-fe0f {
  background-position: -256px -256px; }

.emoji-262f-fe0f {
  background-position: -320px -0px; }

.emoji-2638-fe0f {
  background-position: -320px -64px; }

.emoji-2648-fe0f {
  background-position: -320px -128px; }

.emoji-2649-fe0f {
  background-position: -320px -192px; }

.emoji-264a-fe0f {
  background-position: -320px -256px; }

.emoji-264b-fe0f {
  background-position: -0px -320px; }

.emoji-264c-fe0f {
  background-position: -64px -320px; }

.emoji-264d-fe0f {
  background-position: -128px -320px; }

.emoji-264e-fe0f {
  background-position: -192px -320px; }

.emoji-264f-fe0f {
  background-position: -256px -320px; }

.emoji-2650-fe0f {
  background-position: -320px -320px; }

.emoji-2651-fe0f {
  background-position: -384px -0px; }

.emoji-2652-fe0f {
  background-position: -384px -64px; }

.emoji-2653-fe0f {
  background-position: -384px -128px; }

.emoji-269b-fe0f {
  background-position: -384px -192px; }

.emoji-26ce {
  background-position: -384px -256px; }

.emoji-271d-fe0f {
  background-position: -384px -320px; }

.emoji-2721-fe0f {
  background-position: -0px -384px; }

.emoji-2763-fe0f {
  background-position: -64px -384px; }

.emoji-1f194 {
  background-position: -128px -384px; }

.emoji-category-symbols-2 {
  background-image: url(/static/files/287a7ddfd5238f21225162f0c9806a7e.png); }

.emoji-3299-fe0f {
  background-position: -0px -0px; }

.emoji-1f171-fe0f {
  background-position: -64px -0px; }

.emoji-1f17e-fe0f {
  background-position: -0px -64px; }

.emoji-1f18e {
  background-position: -64px -64px; }

.emoji-1f191 {
  background-position: -128px -0px; }

.emoji-1f198 {
  background-position: -128px -64px; }

.emoji-1f19a {
  background-position: -0px -128px; }

.emoji-1f21a-fe0f {
  background-position: -64px -128px; }

.emoji-1f232 {
  background-position: -128px -128px; }

.emoji-1f234 {
  background-position: -192px -0px; }

.emoji-1f235 {
  background-position: -192px -64px; }

.emoji-1f236 {
  background-position: -192px -128px; }

.emoji-1f237-fe0f {
  background-position: -0px -192px; }

.emoji-1f238 {
  background-position: -64px -192px; }

.emoji-1f239 {
  background-position: -128px -192px; }

.emoji-1f23a {
  background-position: -192px -192px; }

.emoji-1f250 {
  background-position: -256px -0px; }

.emoji-1f4a2 {
  background-position: -256px -64px; }

.emoji-1f4ae {
  background-position: -256px -128px; }

.emoji-1f4af {
  background-position: -256px -192px; }

.emoji-1f4db {
  background-position: -0px -256px; }

.emoji-1f4f3 {
  background-position: -64px -256px; }

.emoji-1f4f4 {
  background-position: -128px -256px; }

.emoji-1f4f5 {
  background-position: -192px -256px; }

.emoji-1f51e {
  background-position: -256px -256px; }

.emoji-1f6ab {
  background-position: -320px -0px; }

.emoji-1f6ad {
  background-position: -320px -64px; }

.emoji-1f6af {
  background-position: -320px -128px; }

.emoji-1f6b1 {
  background-position: -320px -192px; }

.emoji-1f6b3 {
  background-position: -320px -256px; }

.emoji-1f6b7 {
  background-position: -0px -320px; }

.emoji-1f6d1 {
  background-position: -64px -320px; }

.emoji-203c-fe0f {
  background-position: -128px -320px; }

.emoji-2049-fe0f {
  background-position: -192px -320px; }

.emoji-2668-fe0f {
  background-position: -256px -320px; }

.emoji-26d4-fe0f {
  background-position: -320px -320px; }

.emoji-2734-fe0f {
  background-position: -384px -0px; }

.emoji-274c {
  background-position: -384px -64px; }

.emoji-2753 {
  background-position: -384px -128px; }

.emoji-2754 {
  background-position: -384px -192px; }

.emoji-2755 {
  background-position: -384px -256px; }

.emoji-2757-fe0f {
  background-position: -384px -320px; }

.emoji-2b55-fe0f {
  background-position: -0px -384px; }

.emoji-3297-fe0f {
  background-position: -64px -384px; }

.emoji-1f170-fe0f {
  background-position: -128px -384px; }

.emoji-category-symbols-3 {
  background-image: url(/static/files/b6ab2d9e3d177e3288b84ec52a67f55e.png); }

.emoji-303d-fe0f {
  background-position: -0px -0px; }

.emoji-1f196 {
  background-position: -64px -0px; }

.emoji-1f197 {
  background-position: -0px -64px; }

.emoji-1f201 {
  background-position: -64px -64px; }

.emoji-1f202-fe0f {
  background-position: -128px -0px; }

.emoji-1f22f-fe0f {
  background-position: -128px -64px; }

.emoji-1f233 {
  background-position: -0px -128px; }

.emoji-1f300 {
  background-position: -64px -128px; }

.emoji-1f310 {
  background-position: -128px -128px; }

.emoji-1f3a6 {
  background-position: -192px -0px; }

.emoji-1f3e7 {
  background-position: -192px -64px; }

.emoji-1f4a0 {
  background-position: -192px -128px; }

.emoji-1f4a4 {
  background-position: -0px -192px; }

.emoji-1f4b9 {
  background-position: -64px -192px; }

.emoji-1f4f6 {
  background-position: -128px -192px; }

.emoji-1f505 {
  background-position: -192px -192px; }

.emoji-1f506 {
  background-position: -256px -0px; }

.emoji-1f520 {
  background-position: -256px -64px; }

.emoji-1f521 {
  background-position: -256px -128px; }

.emoji-1f523 {
  background-position: -256px -192px; }

.emoji-1f524 {
  background-position: -0px -256px; }

.emoji-1f530 {
  background-position: -64px -256px; }

.emoji-1f531 {
  background-position: -128px -256px; }

.emoji-1f6ae {
  background-position: -192px -256px; }

.emoji-1f6b8 {
  background-position: -256px -256px; }

.emoji-1f6b9 {
  background-position: -320px -0px; }

.emoji-1f6ba {
  background-position: -320px -64px; }

.emoji-1f6bb {
  background-position: -320px -128px; }

.emoji-1f6bc {
  background-position: -320px -192px; }

.emoji-1f6be {
  background-position: -320px -256px; }

.emoji-1f6c2 {
  background-position: -0px -320px; }

.emoji-1f6c3 {
  background-position: -64px -320px; }

.emoji-1f6c4 {
  background-position: -128px -320px; }

.emoji-1f6c5 {
  background-position: -192px -320px; }

.emoji-2139-fe0f {
  background-position: -256px -320px; }

.emoji-24c2-fe0f {
  background-position: -320px -320px; }

.emoji-267b-fe0f {
  background-position: -384px -0px; }

.emoji-267f-fe0f {
  background-position: -384px -64px; }

.emoji-269c-fe0f {
  background-position: -384px -128px; }

.emoji-26a0-fe0f {
  background-position: -384px -192px; }

.emoji-2705 {
  background-position: -384px -256px; }

.emoji-2733-fe0f {
  background-position: -384px -320px; }

.emoji-2747-fe0f {
  background-position: -0px -384px; }

.emoji-274e {
  background-position: -64px -384px; }

.emoji-1f17f-fe0f {
  background-position: -128px -384px; }

.emoji-category-symbols-4 {
  background-image: url(/static/files/d6b23a79ae01fbfaf9c7882072a94805.png); }

.emoji-2b07-fe0f {
  background-position: -0px -0px; }

.emoji-002a-fe0f-20e3 {
  background-position: -64px -0px; }

.emoji-0030-fe0f-20e3 {
  background-position: -0px -64px; }

.emoji-0031-fe0f-20e3 {
  background-position: -64px -64px; }

.emoji-0032-fe0f-20e3 {
  background-position: -128px -0px; }

.emoji-0033-fe0f-20e3 {
  background-position: -128px -64px; }

.emoji-0034-fe0f-20e3 {
  background-position: -0px -128px; }

.emoji-0035-fe0f-20e3 {
  background-position: -64px -128px; }

.emoji-0036-fe0f-20e3 {
  background-position: -128px -128px; }

.emoji-0037-fe0f-20e3 {
  background-position: -192px -0px; }

.emoji-0038-fe0f-20e3 {
  background-position: -192px -64px; }

.emoji-0039-fe0f-20e3 {
  background-position: -192px -128px; }

.emoji-1f192 {
  background-position: -0px -192px; }

.emoji-1f193 {
  background-position: -64px -192px; }

.emoji-1f195 {
  background-position: -128px -192px; }

.emoji-1f199 {
  background-position: -192px -192px; }

.emoji-1f51f {
  background-position: -256px -0px; }

.emoji-1f522 {
  background-position: -256px -64px; }

.emoji-1f53c {
  background-position: -256px -128px; }

.emoji-1f53d {
  background-position: -256px -192px; }

.emoji-2194-fe0f {
  background-position: -0px -256px; }

.emoji-2195-fe0f {
  background-position: -64px -256px; }

.emoji-2196-fe0f {
  background-position: -128px -256px; }

.emoji-2197-fe0f {
  background-position: -192px -256px; }

.emoji-2198-fe0f {
  background-position: -256px -256px; }

.emoji-2199-fe0f {
  background-position: -320px -0px; }

.emoji-21a9-fe0f {
  background-position: -320px -64px; }

.emoji-21aa-fe0f {
  background-position: -320px -128px; }

.emoji-23e9 {
  background-position: -320px -192px; }

.emoji-23ea {
  background-position: -320px -256px; }

.emoji-23eb {
  background-position: -0px -320px; }

.emoji-23ec {
  background-position: -64px -320px; }

.emoji-23ed {
  background-position: -128px -320px; }

.emoji-23ee {
  background-position: -192px -320px; }

.emoji-23ef {
  background-position: -256px -320px; }

.emoji-23f8 {
  background-position: -320px -320px; }

.emoji-23f9 {
  background-position: -384px -0px; }

.emoji-23fa {
  background-position: -384px -64px; }

.emoji-25b6-fe0f {
  background-position: -384px -128px; }

.emoji-25c0-fe0f {
  background-position: -384px -192px; }

.emoji-27a1-fe0f {
  background-position: -384px -256px; }

.emoji-2934-fe0f {
  background-position: -384px -320px; }

.emoji-2b05-fe0f {
  background-position: -0px -384px; }

.emoji-2b06-fe0f {
  background-position: -64px -384px; }

.emoji-0023-fe0f-20e3 {
  background-position: -128px -384px; }

.emoji-category-symbols-5 {
  background-image: url(/static/files/fc626a0674f0031c1d7c6e370de0b5df.png); }

.emoji-3030-fe0f {
  background-position: -0px -0px; }

.emoji-00ae-fe0f {
  background-position: -64px -0px; }

.emoji-1f3b5 {
  background-position: -0px -64px; }

.emoji-1f3b6 {
  background-position: -64px -64px; }

.emoji-1f4b1 {
  background-position: -128px -0px; }

.emoji-1f4b2 {
  background-position: -128px -64px; }

.emoji-1f500 {
  background-position: -0px -128px; }

.emoji-1f501 {
  background-position: -64px -128px; }

.emoji-1f502 {
  background-position: -128px -128px; }

.emoji-1f503 {
  background-position: -192px -0px; }

.emoji-1f504 {
  background-position: -192px -64px; }

.emoji-1f518 {
  background-position: -192px -128px; }

.emoji-1f519 {
  background-position: -0px -192px; }

.emoji-1f51a {
  background-position: -64px -192px; }

.emoji-1f51b {
  background-position: -128px -192px; }

.emoji-1f51c {
  background-position: -192px -192px; }

.emoji-1f51d {
  background-position: -256px -0px; }

.emoji-1f532 {
  background-position: -256px -64px; }

.emoji-1f533 {
  background-position: -256px -128px; }

.emoji-1f534 {
  background-position: -256px -192px; }

.emoji-1f535 {
  background-position: -0px -256px; }

.emoji-1f536 {
  background-position: -64px -256px; }

.emoji-1f537 {
  background-position: -128px -256px; }

.emoji-1f538 {
  background-position: -192px -256px; }

.emoji-1f539 {
  background-position: -256px -256px; }

.emoji-1f53a {
  background-position: -320px -0px; }

.emoji-1f53b {
  background-position: -320px -64px; }

.emoji-2122-fe0f {
  background-position: -320px -128px; }

.emoji-25aa-fe0f {
  background-position: -320px -192px; }

.emoji-25ab-fe0f {
  background-position: -320px -256px; }

.emoji-25fc-fe0f {
  background-position: -0px -320px; }

.emoji-25fd-fe0f {
  background-position: -64px -320px; }

.emoji-25fe-fe0f {
  background-position: -128px -320px; }

.emoji-2611-fe0f {
  background-position: -192px -320px; }

.emoji-26aa-fe0f {
  background-position: -256px -320px; }

.emoji-26ab-fe0f {
  background-position: -320px -320px; }

.emoji-2714-fe0f {
  background-position: -384px -0px; }

.emoji-2716-fe0f {
  background-position: -384px -64px; }

.emoji-2795 {
  background-position: -384px -128px; }

.emoji-2796 {
  background-position: -384px -192px; }

.emoji-2797 {
  background-position: -384px -256px; }

.emoji-27b0 {
  background-position: -384px -320px; }

.emoji-27bf {
  background-position: -0px -384px; }

.emoji-2935-fe0f {
  background-position: -64px -384px; }

.emoji-00a9-fe0f {
  background-position: -128px -384px; }

.emoji-category-symbols-6 {
  background-image: url(/static/files/aa5c9582f2a29d7890d25f79d0a38778.png); }

.emoji-2b1c-fe0f {
  background-position: -0px -0px; }

.emoji-1f0cf {
  background-position: -64px -0px; }

.emoji-1f3b4 {
  background-position: -0px -64px; }

.emoji-1f441-200d-1f5e8 {
  background-position: -64px -64px; }

.emoji-1f4ac {
  background-position: -128px -0px; }

.emoji-1f4ad {
  background-position: -128px -64px; }

.emoji-1f4e2 {
  background-position: -0px -128px; }

.emoji-1f4e3 {
  background-position: -64px -128px; }

.emoji-1f507 {
  background-position: -128px -128px; }

.emoji-1f508 {
  background-position: -192px -0px; }

.emoji-1f509 {
  background-position: -192px -64px; }

.emoji-1f50a {
  background-position: -192px -128px; }

.emoji-1f514 {
  background-position: -0px -192px; }

.emoji-1f515 {
  background-position: -64px -192px; }

.emoji-1f550 {
  background-position: -128px -192px; }

.emoji-1f551 {
  background-position: -192px -192px; }

.emoji-1f552 {
  background-position: -256px -0px; }

.emoji-1f553 {
  background-position: -256px -64px; }

.emoji-1f554 {
  background-position: -256px -128px; }

.emoji-1f555 {
  background-position: -256px -192px; }

.emoji-1f556 {
  background-position: -0px -256px; }

.emoji-1f557 {
  background-position: -64px -256px; }

.emoji-1f558 {
  background-position: -128px -256px; }

.emoji-1f559 {
  background-position: -192px -256px; }

.emoji-1f55a {
  background-position: -256px -256px; }

.emoji-1f55b {
  background-position: -320px -0px; }

.emoji-1f55c {
  background-position: -320px -64px; }

.emoji-1f55d {
  background-position: -320px -128px; }

.emoji-1f55e {
  background-position: -320px -192px; }

.emoji-1f55f {
  background-position: -320px -256px; }

.emoji-1f560 {
  background-position: -0px -320px; }

.emoji-1f561 {
  background-position: -64px -320px; }

.emoji-1f562 {
  background-position: -128px -320px; }

.emoji-1f563 {
  background-position: -192px -320px; }

.emoji-1f564 {
  background-position: -256px -320px; }

.emoji-1f565 {
  background-position: -320px -320px; }

.emoji-1f566 {
  background-position: -384px -0px; }

.emoji-1f5ef {
  background-position: -384px -64px; }

.emoji-25fb-fe0f {
  background-position: -384px -128px; }

.emoji-2660-fe0f {
  background-position: -384px -192px; }

.emoji-2663-fe0f {
  background-position: -384px -256px; }

.emoji-2665-fe0f {
  background-position: -384px -320px; }

.emoji-2666-fe0f {
  background-position: -0px -384px; }

.emoji-2b1b-fe0f {
  background-position: -64px -384px; }

.emoji-1f004-fe0f {
  background-position: -128px -384px; }

.emoji-category-symbols-7 {
  background-image: url(/static/files/9948e40b52b5a90a8a8d5e92bb9d680f.png); }

.emoji-1f567 {
  background-position: -0px -0px; }

.emoji-category-flags-1 {
  background-image: url(/static/files/241eaa9f91b445f0f03a668aa8a4a71a.png); }

.emoji-1f6a9 {
  background-position: -0px -0px; }

.emoji-1f1e6-1f1eb {
  background-position: -64px -0px; }

.emoji-1f1e6-1f1ec {
  background-position: -0px -64px; }

.emoji-1f1e6-1f1ee {
  background-position: -64px -64px; }

.emoji-1f1e6-1f1f1 {
  background-position: -128px -0px; }

.emoji-1f1e6-1f1f2 {
  background-position: -128px -64px; }

.emoji-1f1e6-1f1f4 {
  background-position: -0px -128px; }

.emoji-1f1e6-1f1f6 {
  background-position: -64px -128px; }

.emoji-1f1e6-1f1f7 {
  background-position: -128px -128px; }

.emoji-1f1e6-1f1f8 {
  background-position: -192px -0px; }

.emoji-1f1e6-1f1f9 {
  background-position: -192px -64px; }

.emoji-1f1e6-1f1fa {
  background-position: -192px -128px; }

.emoji-1f1e6-1f1fc {
  background-position: -0px -192px; }

.emoji-1f1e6-1f1fd {
  background-position: -64px -192px; }

.emoji-1f1e6-1f1ff {
  background-position: -128px -192px; }

.emoji-1f1e7-1f1e6 {
  background-position: -192px -192px; }

.emoji-1f1e7-1f1e7 {
  background-position: -256px -0px; }

.emoji-1f1e7-1f1e9 {
  background-position: -256px -64px; }

.emoji-1f1e7-1f1ea {
  background-position: -256px -128px; }

.emoji-1f1e7-1f1eb {
  background-position: -256px -192px; }

.emoji-1f1e7-1f1ec {
  background-position: -0px -256px; }

.emoji-1f1e7-1f1ed {
  background-position: -64px -256px; }

.emoji-1f1e7-1f1ee {
  background-position: -128px -256px; }

.emoji-1f1e7-1f1ef {
  background-position: -192px -256px; }

.emoji-1f1e7-1f1f2 {
  background-position: -256px -256px; }

.emoji-1f1e7-1f1f3 {
  background-position: -320px -0px; }

.emoji-1f1e7-1f1f4 {
  background-position: -320px -64px; }

.emoji-1f1e7-1f1f6 {
  background-position: -320px -128px; }

.emoji-1f1e7-1f1f7 {
  background-position: -320px -192px; }

.emoji-1f1e7-1f1f8 {
  background-position: -320px -256px; }

.emoji-1f1e7-1f1f9 {
  background-position: -0px -320px; }

.emoji-1f1e7-1f1fc {
  background-position: -64px -320px; }

.emoji-1f1e7-1f1fe {
  background-position: -128px -320px; }

.emoji-1f1e7-1f1ff {
  background-position: -192px -320px; }

.emoji-1f1e8-1f1f2 {
  background-position: -256px -320px; }

.emoji-1f1e8-1f1fb {
  background-position: -320px -320px; }

.emoji-1f1e9-1f1ff {
  background-position: -384px -0px; }

.emoji-1f1ee-1f1f4 {
  background-position: -384px -64px; }

.emoji-1f1f0-1f1ed {
  background-position: -384px -128px; }

.emoji-1f1fb-1f1ec {
  background-position: -384px -192px; }

.emoji-1f3c1 {
  background-position: -384px -256px; }

.emoji-1f3f3-fe0f {
  background-position: -384px -320px; }

.emoji-1f3f3-fe0f-200d-1f308 {
  background-position: -0px -384px; }

.emoji-1f3f4 {
  background-position: -64px -384px; }

.emoji-1f1e6-1f1e9 {
  background-position: -128px -384px; }

.emoji-category-flags-2 {
  background-image: url(/static/files/f69f380f64a43ac88efdff05d97a3376.png); }

.emoji-1f1f9-1f1eb {
  background-position: -0px -0px; }

.emoji-1f1e8-1f1e8 {
  background-position: -64px -0px; }

.emoji-1f1e8-1f1e9 {
  background-position: -0px -64px; }

.emoji-1f1e8-1f1eb {
  background-position: -64px -64px; }

.emoji-1f1e8-1f1ec {
  background-position: -128px -0px; }

.emoji-1f1e8-1f1ee {
  background-position: -128px -64px; }

.emoji-1f1e8-1f1f0 {
  background-position: -0px -128px; }

.emoji-1f1e8-1f1f1 {
  background-position: -64px -128px; }

.emoji-1f1e8-1f1f3 {
  background-position: -128px -128px; }

.emoji-1f1e8-1f1f4 {
  background-position: -192px -0px; }

.emoji-1f1e8-1f1f7 {
  background-position: -192px -64px; }

.emoji-1f1e8-1f1fa {
  background-position: -192px -128px; }

.emoji-1f1e8-1f1fc {
  background-position: -0px -192px; }

.emoji-1f1e8-1f1fd {
  background-position: -64px -192px; }

.emoji-1f1e8-1f1fe {
  background-position: -128px -192px; }

.emoji-1f1e8-1f1ff {
  background-position: -192px -192px; }

.emoji-1f1e9-1f1ea {
  background-position: -256px -0px; }

.emoji-1f1e9-1f1ef {
  background-position: -256px -64px; }

.emoji-1f1e9-1f1f0 {
  background-position: -256px -128px; }

.emoji-1f1e9-1f1f2 {
  background-position: -256px -192px; }

.emoji-1f1e9-1f1f4 {
  background-position: -0px -256px; }

.emoji-1f1ea-1f1e8 {
  background-position: -64px -256px; }

.emoji-1f1ea-1f1ea {
  background-position: -128px -256px; }

.emoji-1f1ea-1f1ec {
  background-position: -192px -256px; }

.emoji-1f1ea-1f1f7 {
  background-position: -256px -256px; }

.emoji-1f1ea-1f1f9 {
  background-position: -320px -0px; }

.emoji-1f1ea-1f1fa {
  background-position: -320px -64px; }

.emoji-1f1eb-1f1ee {
  background-position: -320px -128px; }

.emoji-1f1eb-1f1ef {
  background-position: -320px -192px; }

.emoji-1f1eb-1f1f0 {
  background-position: -320px -256px; }

.emoji-1f1eb-1f1f4 {
  background-position: -0px -320px; }

.emoji-1f1eb-1f1f7 {
  background-position: -64px -320px; }

.emoji-1f1ec-1f1e6 {
  background-position: -128px -320px; }

.emoji-1f1ec-1f1ea {
  background-position: -192px -320px; }

.emoji-1f1ec-1f1eb {
  background-position: -256px -320px; }

.emoji-1f1ec-1f1f2 {
  background-position: -320px -320px; }

.emoji-1f1ec-1f1f6 {
  background-position: -384px -0px; }

.emoji-1f1ed-1f1f7 {
  background-position: -384px -64px; }

.emoji-1f1ee-1f1e8 {
  background-position: -384px -128px; }

.emoji-1f1f0-1f1f2 {
  background-position: -384px -192px; }

.emoji-1f1f0-1f1fe {
  background-position: -384px -256px; }

.emoji-1f1f5-1f1eb {
  background-position: -384px -320px; }

.emoji-1f1f8-1f1fb {
  background-position: -0px -384px; }

.emoji-1f1f9-1f1e9 {
  background-position: -64px -384px; }

.emoji-1f1e8-1f1e6 {
  background-position: -128px -384px; }

.emoji-category-flags-3 {
  background-image: url(/static/files/5137f2581aa904b025bfc9f75e649f32.png); }

.emoji-1f38c {
  background-position: -0px -0px; }

.emoji-1f1ec-1f1ec {
  background-position: -64px -0px; }

.emoji-1f1ec-1f1ed {
  background-position: -0px -64px; }

.emoji-1f1ec-1f1ee {
  background-position: -64px -64px; }

.emoji-1f1ec-1f1f1 {
  background-position: -128px -0px; }

.emoji-1f1ec-1f1f3 {
  background-position: -128px -64px; }

.emoji-1f1ec-1f1f5 {
  background-position: -0px -128px; }

.emoji-1f1ec-1f1f7 {
  background-position: -64px -128px; }

.emoji-1f1ec-1f1f9 {
  background-position: -128px -128px; }

.emoji-1f1ec-1f1fa {
  background-position: -192px -0px; }

.emoji-1f1ec-1f1fc {
  background-position: -192px -64px; }

.emoji-1f1ec-1f1fe {
  background-position: -192px -128px; }

.emoji-1f1ed-1f1f0 {
  background-position: -0px -192px; }

.emoji-1f1ed-1f1f3 {
  background-position: -64px -192px; }

.emoji-1f1ed-1f1f9 {
  background-position: -128px -192px; }

.emoji-1f1ed-1f1fa {
  background-position: -192px -192px; }

.emoji-1f1ee-1f1e9 {
  background-position: -256px -0px; }

.emoji-1f1ee-1f1ea {
  background-position: -256px -64px; }

.emoji-1f1ee-1f1f1 {
  background-position: -256px -128px; }

.emoji-1f1ee-1f1f2 {
  background-position: -256px -192px; }

.emoji-1f1ee-1f1f3 {
  background-position: -0px -256px; }

.emoji-1f1ee-1f1f6 {
  background-position: -64px -256px; }

.emoji-1f1ee-1f1f7 {
  background-position: -128px -256px; }

.emoji-1f1ee-1f1f8 {
  background-position: -192px -256px; }

.emoji-1f1ee-1f1f9 {
  background-position: -256px -256px; }

.emoji-1f1ef-1f1ea {
  background-position: -320px -0px; }

.emoji-1f1ef-1f1f2 {
  background-position: -320px -64px; }

.emoji-1f1ef-1f1f4 {
  background-position: -320px -128px; }

.emoji-1f1ef-1f1f5 {
  background-position: -320px -192px; }

.emoji-1f1f0-1f1ea {
  background-position: -320px -256px; }

.emoji-1f1f0-1f1ec {
  background-position: -0px -320px; }

.emoji-1f1f0-1f1ee {
  background-position: -64px -320px; }

.emoji-1f1f0-1f1fc {
  background-position: -128px -320px; }

.emoji-1f1f0-1f1ff {
  background-position: -192px -320px; }

.emoji-1f1f1-1f1e6 {
  background-position: -256px -320px; }

.emoji-1f1f1-1f1e7 {
  background-position: -320px -320px; }

.emoji-1f1f1-1f1ee {
  background-position: -384px -0px; }

.emoji-1f1f1-1f1f7 {
  background-position: -384px -64px; }

.emoji-1f1f1-1f1f8 {
  background-position: -384px -128px; }

.emoji-1f1f1-1f1f9 {
  background-position: -384px -192px; }

.emoji-1f1f1-1f1fa {
  background-position: -384px -256px; }

.emoji-1f1f1-1f1fb {
  background-position: -384px -320px; }

.emoji-1f1f1-1f1fe {
  background-position: -0px -384px; }

.emoji-1f1fd-1f1f0 {
  background-position: -64px -384px; }

.emoji-1f1ec-1f1e9 {
  background-position: -128px -384px; }

.emoji-category-flags-4 {
  background-image: url(/static/files/4510799241e4533e363ce643b4ecb730.png); }

.emoji-1f1fe-1f1f9 {
  background-position: -0px -0px; }

.emoji-1f1f0-1f1f5 {
  background-position: -64px -0px; }

.emoji-1f1f2-1f1e6 {
  background-position: -0px -64px; }

.emoji-1f1f2-1f1e8 {
  background-position: -64px -64px; }

.emoji-1f1f2-1f1e9 {
  background-position: -128px -0px; }

.emoji-1f1f2-1f1ea {
  background-position: -128px -64px; }

.emoji-1f1f2-1f1ec {
  background-position: -0px -128px; }

.emoji-1f1f2-1f1ed {
  background-position: -64px -128px; }

.emoji-1f1f2-1f1f0 {
  background-position: -128px -128px; }

.emoji-1f1f2-1f1f1 {
  background-position: -192px -0px; }

.emoji-1f1f2-1f1f2 {
  background-position: -192px -64px; }

.emoji-1f1f2-1f1f3 {
  background-position: -192px -128px; }

.emoji-1f1f2-1f1f4 {
  background-position: -0px -192px; }

.emoji-1f1f2-1f1f5 {
  background-position: -64px -192px; }

.emoji-1f1f2-1f1f6 {
  background-position: -128px -192px; }

.emoji-1f1f2-1f1f7 {
  background-position: -192px -192px; }

.emoji-1f1f2-1f1f8 {
  background-position: -256px -0px; }

.emoji-1f1f2-1f1f9 {
  background-position: -256px -64px; }

.emoji-1f1f2-1f1fa {
  background-position: -256px -128px; }

.emoji-1f1f2-1f1fb {
  background-position: -256px -192px; }

.emoji-1f1f2-1f1fc {
  background-position: -0px -256px; }

.emoji-1f1f2-1f1fd {
  background-position: -64px -256px; }

.emoji-1f1f2-1f1fe {
  background-position: -128px -256px; }

.emoji-1f1f2-1f1ff {
  background-position: -192px -256px; }

.emoji-1f1f3-1f1e6 {
  background-position: -256px -256px; }

.emoji-1f1f3-1f1e8 {
  background-position: -320px -0px; }

.emoji-1f1f3-1f1ea {
  background-position: -320px -64px; }

.emoji-1f1f3-1f1eb {
  background-position: -320px -128px; }

.emoji-1f1f3-1f1ec {
  background-position: -320px -192px; }

.emoji-1f1f3-1f1ee {
  background-position: -320px -256px; }

.emoji-1f1f3-1f1f1 {
  background-position: -0px -320px; }

.emoji-1f1f3-1f1f4 {
  background-position: -64px -320px; }

.emoji-1f1f3-1f1f5 {
  background-position: -128px -320px; }

.emoji-1f1f3-1f1f7 {
  background-position: -192px -320px; }

.emoji-1f1f3-1f1fa {
  background-position: -256px -320px; }

.emoji-1f1f3-1f1ff {
  background-position: -320px -320px; }

.emoji-1f1f4-1f1f2 {
  background-position: -384px -0px; }

.emoji-1f1f5-1f1e6 {
  background-position: -384px -64px; }

.emoji-1f1f5-1f1ea {
  background-position: -384px -128px; }

.emoji-1f1f5-1f1ec {
  background-position: -384px -192px; }

.emoji-1f1f5-1f1f0 {
  background-position: -384px -256px; }

.emoji-1f1f5-1f1f8 {
  background-position: -384px -320px; }

.emoji-1f1f5-1f1fc {
  background-position: -0px -384px; }

.emoji-1f1f5-1f1fe {
  background-position: -64px -384px; }

.emoji-1f1eb-1f1f2 {
  background-position: -128px -384px; }

.emoji-category-flags-5 {
  background-image: url(/static/files/2329b45ceb0004a8dcd8f2927a02d670.png); }

.emoji-1f1ff-1f1e6 {
  background-position: -0px -0px; }

.emoji-1f1e8-1f1ed {
  background-position: -64px -0px; }

.emoji-1f1ea-1f1f8 {
  background-position: -0px -64px; }

.emoji-1f1ec-1f1f8 {
  background-position: -64px -64px; }

.emoji-1f1f0-1f1f3 {
  background-position: -128px -0px; }

.emoji-1f1f0-1f1f7 {
  background-position: -128px -64px; }

.emoji-1f1f1-1f1e8 {
  background-position: -0px -128px; }

.emoji-1f1f1-1f1f0 {
  background-position: -64px -128px; }

.emoji-1f1f5-1f1ed {
  background-position: -128px -128px; }

.emoji-1f1f5-1f1f1 {
  background-position: -192px -0px; }

.emoji-1f1f5-1f1f2 {
  background-position: -192px -64px; }

.emoji-1f1f5-1f1f3 {
  background-position: -192px -128px; }

.emoji-1f1f5-1f1f7 {
  background-position: -0px -192px; }

.emoji-1f1f5-1f1f9 {
  background-position: -64px -192px; }

.emoji-1f1f6-1f1e6 {
  background-position: -128px -192px; }

.emoji-1f1f7-1f1ea {
  background-position: -192px -192px; }

.emoji-1f1f7-1f1f4 {
  background-position: -256px -0px; }

.emoji-1f1f7-1f1f8 {
  background-position: -256px -64px; }

.emoji-1f1f7-1f1fa {
  background-position: -256px -128px; }

.emoji-1f1f7-1f1fc {
  background-position: -256px -192px; }

.emoji-1f1f8-1f1e6 {
  background-position: -0px -256px; }

.emoji-1f1f8-1f1e7 {
  background-position: -64px -256px; }

.emoji-1f1f8-1f1e8 {
  background-position: -128px -256px; }

.emoji-1f1f8-1f1e9 {
  background-position: -192px -256px; }

.emoji-1f1f8-1f1ea {
  background-position: -256px -256px; }

.emoji-1f1f8-1f1ec {
  background-position: -320px -0px; }

.emoji-1f1f8-1f1ed {
  background-position: -320px -64px; }

.emoji-1f1f8-1f1ee {
  background-position: -320px -128px; }

.emoji-1f1f8-1f1f0 {
  background-position: -320px -192px; }

.emoji-1f1f8-1f1f1 {
  background-position: -320px -256px; }

.emoji-1f1f8-1f1f2 {
  background-position: -0px -320px; }

.emoji-1f1f8-1f1f3 {
  background-position: -64px -320px; }

.emoji-1f1f8-1f1f4 {
  background-position: -128px -320px; }

.emoji-1f1f8-1f1f7 {
  background-position: -192px -320px; }

.emoji-1f1f8-1f1f8 {
  background-position: -256px -320px; }

.emoji-1f1f8-1f1f9 {
  background-position: -320px -320px; }

.emoji-1f1f8-1f1fd {
  background-position: -384px -0px; }

.emoji-1f1f8-1f1fe {
  background-position: -384px -64px; }

.emoji-1f1f8-1f1ff {
  background-position: -384px -128px; }

.emoji-1f1f9-1f1ef {
  background-position: -384px -192px; }

.emoji-1f1f9-1f1fc {
  background-position: -384px -256px; }

.emoji-1f1f9-1f1ff {
  background-position: -384px -320px; }

.emoji-1f1fb-1f1e8 {
  background-position: -0px -384px; }

.emoji-1f1fc-1f1f8 {
  background-position: -64px -384px; }

.emoji-1f1e7-1f1f1 {
  background-position: -128px -384px; }

.emoji-category-flags-6 {
  background-image: url(/static/files/79ccb3cfd5e055af28e2462e08ab169c.png); }

.emoji-1f1ff-1f1fc {
  background-position: -0px -0px; }

.emoji-1f1ea-1f1ed {
  background-position: -64px -0px; }

.emoji-1f1ec-1f1e7 {
  background-position: -0px -64px; }

.emoji-1f1f9-1f1e8 {
  background-position: -64px -64px; }

.emoji-1f1f9-1f1ec {
  background-position: -128px -0px; }

.emoji-1f1f9-1f1ed {
  background-position: -128px -64px; }

.emoji-1f1f9-1f1f0 {
  background-position: -0px -128px; }

.emoji-1f1f9-1f1f1 {
  background-position: -64px -128px; }

.emoji-1f1f9-1f1f2 {
  background-position: -128px -128px; }

.emoji-1f1f9-1f1f3 {
  background-position: -192px -0px; }

.emoji-1f1f9-1f1f4 {
  background-position: -192px -64px; }

.emoji-1f1f9-1f1f7 {
  background-position: -192px -128px; }

.emoji-1f1f9-1f1f9 {
  background-position: -0px -192px; }

.emoji-1f1f9-1f1fb {
  background-position: -64px -192px; }

.emoji-1f1fa-1f1e6 {
  background-position: -128px -192px; }

.emoji-1f1fa-1f1ec {
  background-position: -192px -192px; }

.emoji-1f1fa-1f1f8 {
  background-position: -256px -0px; }

.emoji-1f1fa-1f1fe {
  background-position: -256px -64px; }

.emoji-1f1fa-1f1ff {
  background-position: -256px -128px; }

.emoji-1f1fb-1f1e6 {
  background-position: -256px -192px; }

.emoji-1f1fb-1f1ea {
  background-position: -0px -256px; }

.emoji-1f1fb-1f1ee {
  background-position: -64px -256px; }

.emoji-1f1fb-1f1f3 {
  background-position: -128px -256px; }

.emoji-1f1fb-1f1fa {
  background-position: -192px -256px; }

.emoji-1f1fc-1f1eb {
  background-position: -256px -256px; }

.emoji-1f1fe-1f1ea {
  background-position: -320px -0px; }

.emoji-1f1ff-1f1f2 {
  background-position: -320px -64px; }

.emoji-1f1e6-1f1ea {
  background-position: -320px -128px; }

.emoji-category-custom-1 {
  background-image: url(/static/files/123132a2d3386a7b0ad709acc37f5837.png); }

.emoji-shipit {
  background-position: -0px -0px;
  width: 75px;
  height: 75px; }

.emoji-feelsgood {
  background-position: -75px -0px; }

.emoji-finnadie {
  background-position: -0px -75px; }

.emoji-goberserk {
  background-position: -64px -75px; }

.emoji-godmode {
  background-position: -139px -0px; }

.emoji-hurtrealbad {
  background-position: -139px -64px; }

.emoji-mattermost {
  background-position: -0px -139px; }

.emoji-neckbeard {
  background-position: -64px -139px; }

.emoji-octocat {
  background-position: -128px -139px; }

.emoji-rage1 {
  background-position: -203px -0px; }

.emoji-rage2 {
  background-position: -203px -64px; }

.emoji-rage3 {
  background-position: -203px -128px; }

.emoji-rage4 {
  background-position: -0px -203px; }

.emoji-suspect {
  background-position: -64px -203px; }

.emoji-bowtie {
  background-position: -128px -203px; }

.emoji-trollface {
  background-position: -192px -203px; }

.emoji-category-skintone-1 {
  background-image: url(/static/files/fd295788005ba262b60a51a83bd7a320.png); }

.emoji-270a-1f3ff {
  background-position: -0px -0px; }

.emoji-1f44a-1f3fc {
  background-position: -64px -0px; }

.emoji-1f44a-1f3fd {
  background-position: -0px -64px; }

.emoji-1f44a-1f3fe {
  background-position: -64px -64px; }

.emoji-1f44a-1f3ff {
  background-position: -128px -0px; }

.emoji-1f44d-1f3fb {
  background-position: -128px -64px; }

.emoji-1f44d-1f3fc {
  background-position: -0px -128px; }

.emoji-1f44d-1f3fd {
  background-position: -64px -128px; }

.emoji-1f44d-1f3fe {
  background-position: -128px -128px; }

.emoji-1f44d-1f3ff {
  background-position: -192px -0px; }

.emoji-1f44e-1f3fb {
  background-position: -192px -64px; }

.emoji-1f44e-1f3fc {
  background-position: -192px -128px; }

.emoji-1f44e-1f3fd {
  background-position: -0px -192px; }

.emoji-1f44e-1f3fe {
  background-position: -64px -192px; }

.emoji-1f44e-1f3ff {
  background-position: -128px -192px; }

.emoji-1f44f-1f3fb {
  background-position: -192px -192px; }

.emoji-1f44f-1f3fc {
  background-position: -256px -0px; }

.emoji-1f44f-1f3fd {
  background-position: -256px -64px; }

.emoji-1f44f-1f3fe {
  background-position: -256px -128px; }

.emoji-1f44f-1f3ff {
  background-position: -256px -192px; }

.emoji-1f450-1f3fb {
  background-position: -0px -256px; }

.emoji-1f450-1f3fc {
  background-position: -64px -256px; }

.emoji-1f450-1f3fd {
  background-position: -128px -256px; }

.emoji-1f450-1f3fe {
  background-position: -192px -256px; }

.emoji-1f450-1f3ff {
  background-position: -256px -256px; }

.emoji-1f64c-1f3fb {
  background-position: -320px -0px; }

.emoji-1f64c-1f3fc {
  background-position: -320px -64px; }

.emoji-1f64c-1f3fd {
  background-position: -320px -128px; }

.emoji-1f64c-1f3fe {
  background-position: -320px -192px; }

.emoji-1f64c-1f3ff {
  background-position: -320px -256px; }

.emoji-1f64f-1f3fb {
  background-position: -0px -320px; }

.emoji-1f64f-1f3fc {
  background-position: -64px -320px; }

.emoji-1f64f-1f3fd {
  background-position: -128px -320px; }

.emoji-1f64f-1f3fe {
  background-position: -192px -320px; }

.emoji-1f64f-1f3ff {
  background-position: -256px -320px; }

.emoji-1f91b-1f3fb {
  background-position: -320px -320px; }

.emoji-1f91b-1f3fc {
  background-position: -384px -0px; }

.emoji-1f91b-1f3fd {
  background-position: -384px -64px; }

.emoji-1f91b-1f3fe {
  background-position: -384px -128px; }

.emoji-1f91b-1f3ff {
  background-position: -384px -192px; }

.emoji-270a-1f3fb {
  background-position: -384px -256px; }

.emoji-270a-1f3fc {
  background-position: -384px -320px; }

.emoji-270a-1f3fd {
  background-position: -0px -384px; }

.emoji-270a-1f3fe {
  background-position: -64px -384px; }

.emoji-1f44a-1f3fb {
  background-position: -128px -384px; }

.emoji-category-skintone-2 {
  background-image: url(/static/files/593cfaefcee052d4d3c8d1aff052c8f0.png); }

.emoji-270c-1f3ff {
  background-position: -0px -0px; }

.emoji-1f446-1f3fc {
  background-position: -64px -0px; }

.emoji-1f446-1f3fd {
  background-position: -0px -64px; }

.emoji-1f446-1f3fe {
  background-position: -64px -64px; }

.emoji-1f446-1f3ff {
  background-position: -128px -0px; }

.emoji-1f447-1f3fb {
  background-position: -128px -64px; }

.emoji-1f447-1f3fc {
  background-position: -0px -128px; }

.emoji-1f447-1f3fd {
  background-position: -64px -128px; }

.emoji-1f447-1f3fe {
  background-position: -128px -128px; }

.emoji-1f447-1f3ff {
  background-position: -192px -0px; }

.emoji-1f448-1f3fb {
  background-position: -192px -64px; }

.emoji-1f448-1f3fc {
  background-position: -192px -128px; }

.emoji-1f448-1f3fd {
  background-position: -0px -192px; }

.emoji-1f448-1f3fe {
  background-position: -64px -192px; }

.emoji-1f448-1f3ff {
  background-position: -128px -192px; }

.emoji-1f449-1f3fb {
  background-position: -192px -192px; }

.emoji-1f449-1f3fc {
  background-position: -256px -0px; }

.emoji-1f449-1f3fd {
  background-position: -256px -64px; }

.emoji-1f449-1f3fe {
  background-position: -256px -128px; }

.emoji-1f449-1f3ff {
  background-position: -256px -192px; }

.emoji-1f44c-1f3fb {
  background-position: -0px -256px; }

.emoji-1f44c-1f3fc {
  background-position: -64px -256px; }

.emoji-1f44c-1f3fd {
  background-position: -128px -256px; }

.emoji-1f44c-1f3fe {
  background-position: -192px -256px; }

.emoji-1f44c-1f3ff {
  background-position: -256px -256px; }

.emoji-1f918-1f3fb {
  background-position: -320px -0px; }

.emoji-1f918-1f3fc {
  background-position: -320px -64px; }

.emoji-1f918-1f3fd {
  background-position: -320px -128px; }

.emoji-1f918-1f3fe {
  background-position: -320px -192px; }

.emoji-1f918-1f3ff {
  background-position: -320px -256px; }

.emoji-1f91c-1f3fb {
  background-position: -0px -320px; }

.emoji-1f91c-1f3fc {
  background-position: -64px -320px; }

.emoji-1f91c-1f3fd {
  background-position: -128px -320px; }

.emoji-1f91c-1f3fe {
  background-position: -192px -320px; }

.emoji-1f91c-1f3ff {
  background-position: -256px -320px; }

.emoji-1f91e-1f3fb {
  background-position: -320px -320px; }

.emoji-1f91e-1f3fc {
  background-position: -384px -0px; }

.emoji-1f91e-1f3fd {
  background-position: -384px -64px; }

.emoji-1f91e-1f3fe {
  background-position: -384px -128px; }

.emoji-1f91e-1f3ff {
  background-position: -384px -192px; }

.emoji-270c-1f3fb {
  background-position: -384px -256px; }

.emoji-270c-1f3fc {
  background-position: -384px -320px; }

.emoji-270c-1f3fd {
  background-position: -0px -384px; }

.emoji-270c-1f3fe {
  background-position: -64px -384px; }

.emoji-1f446-1f3fb {
  background-position: -128px -384px; }

.emoji-category-skintone-3 {
  background-image: url(/static/files/ba0e6c0a4e4561d472bf71221d55e54d.png); }

.emoji-270b-1f3ff {
  background-position: -0px -0px; }

.emoji-1f44b-1f3fc {
  background-position: -64px -0px; }

.emoji-1f44b-1f3fd {
  background-position: -0px -64px; }

.emoji-1f44b-1f3fe {
  background-position: -64px -64px; }

.emoji-1f44b-1f3ff {
  background-position: -128px -0px; }

.emoji-1f4aa-1f3fb {
  background-position: -128px -64px; }

.emoji-1f4aa-1f3fc {
  background-position: -0px -128px; }

.emoji-1f4aa-1f3fd {
  background-position: -64px -128px; }

.emoji-1f4aa-1f3fe {
  background-position: -128px -128px; }

.emoji-1f4aa-1f3ff {
  background-position: -192px -0px; }

.emoji-1f590-1f3fb {
  background-position: -192px -64px; }

.emoji-1f590-1f3fc {
  background-position: -192px -128px; }

.emoji-1f590-1f3fd {
  background-position: -0px -192px; }

.emoji-1f590-1f3fe {
  background-position: -64px -192px; }

.emoji-1f590-1f3ff {
  background-position: -128px -192px; }

.emoji-1f595-1f3fb {
  background-position: -192px -192px; }

.emoji-1f595-1f3fc {
  background-position: -256px -0px; }

.emoji-1f595-1f3fd {
  background-position: -256px -64px; }

.emoji-1f595-1f3fe {
  background-position: -256px -128px; }

.emoji-1f595-1f3ff {
  background-position: -256px -192px; }

.emoji-1f596-1f3fb {
  background-position: -0px -256px; }

.emoji-1f596-1f3fc {
  background-position: -64px -256px; }

.emoji-1f596-1f3fd {
  background-position: -128px -256px; }

.emoji-1f596-1f3fe {
  background-position: -192px -256px; }

.emoji-1f596-1f3ff {
  background-position: -256px -256px; }

.emoji-1f919-1f3fb {
  background-position: -320px -0px; }

.emoji-1f919-1f3fc {
  background-position: -320px -64px; }

.emoji-1f919-1f3fd {
  background-position: -320px -128px; }

.emoji-1f919-1f3fe {
  background-position: -320px -192px; }

.emoji-1f919-1f3ff {
  background-position: -320px -256px; }

.emoji-1f91a-1f3fb {
  background-position: -0px -320px; }

.emoji-1f91a-1f3fc {
  background-position: -64px -320px; }

.emoji-1f91a-1f3fd {
  background-position: -128px -320px; }

.emoji-1f91a-1f3fe {
  background-position: -192px -320px; }

.emoji-1f91a-1f3ff {
  background-position: -256px -320px; }

.emoji-261d-1f3fb {
  background-position: -320px -320px; }

.emoji-261d-1f3fc {
  background-position: -384px -0px; }

.emoji-261d-1f3fd {
  background-position: -384px -64px; }

.emoji-261d-1f3fe {
  background-position: -384px -128px; }

.emoji-261d-1f3ff {
  background-position: -384px -192px; }

.emoji-270b-1f3fb {
  background-position: -384px -256px; }

.emoji-270b-1f3fc {
  background-position: -384px -320px; }

.emoji-270b-1f3fd {
  background-position: -0px -384px; }

.emoji-270b-1f3fe {
  background-position: -64px -384px; }

.emoji-1f44b-1f3fb {
  background-position: -128px -384px; }

.emoji-category-skintone-4 {
  background-image: url(/static/files/168471584d9b2d2d833ad65d6eaa6012.png); }

.emoji-270d-1f3ff {
  background-position: -0px -0px; }

.emoji-1f442-1f3fc {
  background-position: -64px -0px; }

.emoji-1f442-1f3fd {
  background-position: -0px -64px; }

.emoji-1f442-1f3fe {
  background-position: -64px -64px; }

.emoji-1f442-1f3ff {
  background-position: -128px -0px; }

.emoji-1f443-1f3fb {
  background-position: -128px -64px; }

.emoji-1f443-1f3fc {
  background-position: -0px -128px; }

.emoji-1f443-1f3fd {
  background-position: -64px -128px; }

.emoji-1f443-1f3fe {
  background-position: -128px -128px; }

.emoji-1f443-1f3ff {
  background-position: -192px -0px; }

.emoji-1f466-1f3fb {
  background-position: -192px -64px; }

.emoji-1f466-1f3fc {
  background-position: -192px -128px; }

.emoji-1f466-1f3fd {
  background-position: -0px -192px; }

.emoji-1f466-1f3fe {
  background-position: -64px -192px; }

.emoji-1f466-1f3ff {
  background-position: -128px -192px; }

.emoji-1f467-1f3fb {
  background-position: -192px -192px; }

.emoji-1f467-1f3fc {
  background-position: -256px -0px; }

.emoji-1f467-1f3fd {
  background-position: -256px -64px; }

.emoji-1f467-1f3fe {
  background-position: -256px -128px; }

.emoji-1f467-1f3ff {
  background-position: -256px -192px; }

.emoji-1f468-1f3fb {
  background-position: -0px -256px; }

.emoji-1f468-1f3fc {
  background-position: -64px -256px; }

.emoji-1f468-1f3fd {
  background-position: -128px -256px; }

.emoji-1f468-1f3fe {
  background-position: -192px -256px; }

.emoji-1f468-1f3ff {
  background-position: -256px -256px; }

.emoji-1f476-1f3fb {
  background-position: -320px -0px; }

.emoji-1f476-1f3fc {
  background-position: -320px -64px; }

.emoji-1f476-1f3fd {
  background-position: -320px -128px; }

.emoji-1f476-1f3fe {
  background-position: -320px -192px; }

.emoji-1f476-1f3ff {
  background-position: -320px -256px; }

.emoji-1f485-1f3fb {
  background-position: -0px -320px; }

.emoji-1f485-1f3fc {
  background-position: -64px -320px; }

.emoji-1f485-1f3fd {
  background-position: -128px -320px; }

.emoji-1f485-1f3fe {
  background-position: -192px -320px; }

.emoji-1f485-1f3ff {
  background-position: -256px -320px; }

.emoji-1f933-1f3fb {
  background-position: -320px -320px; }

.emoji-1f933-1f3fc {
  background-position: -384px -0px; }

.emoji-1f933-1f3fd {
  background-position: -384px -64px; }

.emoji-1f933-1f3fe {
  background-position: -384px -128px; }

.emoji-1f933-1f3ff {
  background-position: -384px -192px; }

.emoji-270d-1f3fb {
  background-position: -384px -256px; }

.emoji-270d-1f3fc {
  background-position: -384px -320px; }

.emoji-270d-1f3fd {
  background-position: -0px -384px; }

.emoji-270d-1f3fe {
  background-position: -64px -384px; }

.emoji-1f442-1f3fb {
  background-position: -128px -384px; }

.emoji-category-skintone-5 {
  background-image: url(/static/files/103d61bf56d18016ccf5b642b16c5d0d.png); }

.emoji-1f475-1f3ff {
  background-position: -0px -0px; }

.emoji-1f469-1f3fc {
  background-position: -64px -0px; }

.emoji-1f469-1f3fd {
  background-position: -0px -64px; }

.emoji-1f469-1f3fe {
  background-position: -64px -64px; }

.emoji-1f469-1f3ff {
  background-position: -128px -0px; }

.emoji-1f471-1f3fb {
  background-position: -128px -64px; }

.emoji-1f471-1f3fb-200d-2640-fe0f {
  background-position: -0px -128px; }

.emoji-1f471-1f3fc {
  background-position: -64px -128px; }

.emoji-1f471-1f3fc-200d-2640-fe0f {
  background-position: -128px -128px; }

.emoji-1f471-1f3fd {
  background-position: -192px -0px; }

.emoji-1f471-1f3fd-200d-2640-fe0f {
  background-position: -192px -64px; }

.emoji-1f471-1f3fe {
  background-position: -192px -128px; }

.emoji-1f471-1f3fe-200d-2640-fe0f {
  background-position: -0px -192px; }

.emoji-1f471-1f3ff {
  background-position: -64px -192px; }

.emoji-1f471-1f3ff-200d-2640-fe0f {
  background-position: -128px -192px; }

.emoji-1f472-1f3fb {
  background-position: -192px -192px; }

.emoji-1f472-1f3fc {
  background-position: -256px -0px; }

.emoji-1f472-1f3fd {
  background-position: -256px -64px; }

.emoji-1f472-1f3fe {
  background-position: -256px -128px; }

.emoji-1f472-1f3ff {
  background-position: -256px -192px; }

.emoji-1f473-1f3fb {
  background-position: -0px -256px; }

.emoji-1f473-1f3fc {
  background-position: -64px -256px; }

.emoji-1f473-1f3fd {
  background-position: -128px -256px; }

.emoji-1f473-1f3fe {
  background-position: -192px -256px; }

.emoji-1f473-1f3ff {
  background-position: -256px -256px; }

.emoji-1f474-1f3fb {
  background-position: -320px -0px; }

.emoji-1f474-1f3fc {
  background-position: -320px -64px; }

.emoji-1f474-1f3fd {
  background-position: -320px -128px; }

.emoji-1f474-1f3fe {
  background-position: -320px -192px; }

.emoji-1f474-1f3ff {
  background-position: -320px -256px; }

.emoji-1f475-1f3fb {
  background-position: -0px -320px; }

.emoji-1f475-1f3fc {
  background-position: -64px -320px; }

.emoji-1f475-1f3fd {
  background-position: -128px -320px; }

.emoji-1f475-1f3fe {
  background-position: -192px -320px; }

.emoji-1f469-1f3fb {
  background-position: -256px -320px; }

.emoji-category-skintone-6 {
  background-image: url(/static/files/15cd6885135bbc2551624e6107bf17fb.png); }

.emoji-1f477-1f3ff-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f46e-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f46e-1f3fc {
  background-position: -0px -64px; }

.emoji-1f46e-1f3fc-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f46e-1f3fd {
  background-position: -128px -0px; }

.emoji-1f46e-1f3fd-200d-2640-fe0f {
  background-position: -128px -64px; }

.emoji-1f46e-1f3fe {
  background-position: -0px -128px; }

.emoji-1f46e-1f3fe-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f46e-1f3ff {
  background-position: -128px -128px; }

.emoji-1f46e-1f3ff-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f473-1f3fb {
  background-position: -192px -64px; }

.emoji-1f473-1f3fb-200d-2640-fe0f {
  background-position: -192px -128px; }

.emoji-1f473-1f3fc {
  background-position: -0px -192px; }

.emoji-1f473-1f3fc-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f473-1f3fd {
  background-position: -128px -192px; }

.emoji-1f473-1f3fd-200d-2640-fe0f {
  background-position: -192px -192px; }

.emoji-1f473-1f3fe {
  background-position: -256px -0px; }

.emoji-1f473-1f3fe-200d-2640-fe0f {
  background-position: -256px -64px; }

.emoji-1f473-1f3ff {
  background-position: -256px -128px; }

.emoji-1f473-1f3ff-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f477-1f3fb {
  background-position: -0px -256px; }

.emoji-1f477-1f3fb-200d-2640-fe0f {
  background-position: -64px -256px; }

.emoji-1f477-1f3fc {
  background-position: -128px -256px; }

.emoji-1f477-1f3fc-200d-2640-fe0f {
  background-position: -192px -256px; }

.emoji-1f477-1f3fd {
  background-position: -256px -256px; }

.emoji-1f477-1f3fd-200d-2640-fe0f {
  background-position: -320px -0px; }

.emoji-1f477-1f3fe {
  background-position: -320px -64px; }

.emoji-1f477-1f3fe-200d-2640-fe0f {
  background-position: -320px -128px; }

.emoji-1f477-1f3ff {
  background-position: -320px -192px; }

.emoji-1f46e-1f3fb {
  background-position: -320px -256px; }

.emoji-category-skintone-7 {
  background-image: url(/static/files/4d1e15318477a36767095d559d92ef9b.png); }

.emoji-1f575-1f3ff-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f477-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f477-1f3fc {
  background-position: -0px -64px; }

.emoji-1f477-1f3fc-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f477-1f3fd {
  background-position: -128px -0px; }

.emoji-1f477-1f3fd-200d-2640-fe0f {
  background-position: -128px -64px; }

.emoji-1f477-1f3fe {
  background-position: -0px -128px; }

.emoji-1f477-1f3fe-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f477-1f3ff {
  background-position: -128px -128px; }

.emoji-1f477-1f3ff-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f482-1f3fb {
  background-position: -192px -64px; }

.emoji-1f482-1f3fb-200d-2640-fe0f {
  background-position: -192px -128px; }

.emoji-1f482-1f3fc {
  background-position: -0px -192px; }

.emoji-1f482-1f3fc-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f482-1f3fd {
  background-position: -128px -192px; }

.emoji-1f482-1f3fd-200d-2640-fe0f {
  background-position: -192px -192px; }

.emoji-1f482-1f3fe {
  background-position: -256px -0px; }

.emoji-1f482-1f3fe-200d-2640-fe0f {
  background-position: -256px -64px; }

.emoji-1f482-1f3ff {
  background-position: -256px -128px; }

.emoji-1f482-1f3ff-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f575-1f3fb {
  background-position: -0px -256px; }

.emoji-1f575-1f3fb-200d-2640-fe0f {
  background-position: -64px -256px; }

.emoji-1f575-1f3fc {
  background-position: -128px -256px; }

.emoji-1f575-1f3fc-200d-2640-fe0f {
  background-position: -192px -256px; }

.emoji-1f575-1f3fd {
  background-position: -256px -256px; }

.emoji-1f575-1f3fd-200d-2640-fe0f {
  background-position: -320px -0px; }

.emoji-1f575-1f3fe {
  background-position: -320px -64px; }

.emoji-1f575-1f3fe-200d-2640-fe0f {
  background-position: -320px -128px; }

.emoji-1f575-1f3ff {
  background-position: -320px -192px; }

.emoji-1f477-1f3fb {
  background-position: -320px -256px; }

.emoji-category-skintone-8 {
  background-image: url(/static/files/0d9e0699b3114ee48c3868d0bcdbabbb.png); }

.emoji-1f575-1f3ff-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f468-1f3fc {
  background-position: -64px -0px; }

.emoji-1f468-1f3fd {
  background-position: -0px -64px; }

.emoji-1f468-1f3fe {
  background-position: -64px -64px; }

.emoji-1f468-1f3ff {
  background-position: -128px -0px; }

.emoji-1f469-1f3fb {
  background-position: -128px -64px; }

.emoji-1f469-1f3fc {
  background-position: -0px -128px; }

.emoji-1f469-1f3fd {
  background-position: -64px -128px; }

.emoji-1f469-1f3fe {
  background-position: -128px -128px; }

.emoji-1f469-1f3ff {
  background-position: -192px -0px; }

.emoji-1f575-1f3fb-200d-2640-fe0f {
  background-position: -192px -64px; }

.emoji-1f575-1f3fc-200d-2640-fe0f {
  background-position: -192px -128px; }

.emoji-1f575-1f3fd-200d-2640-fe0f {
  background-position: -0px -192px; }

.emoji-1f575-1f3fe-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f468-1f3fb {
  background-position: -128px -192px; }

.emoji-category-skintone-9 {
  background-image: url(/static/files/3431f604c8600acf837d27bd6f7aba65.png); }

.emoji-1f469-1f3ff {
  background-position: -0px -0px; }

.emoji-1f468-1f3fc {
  background-position: -64px -0px; }

.emoji-1f468-1f3fd {
  background-position: -0px -64px; }

.emoji-1f468-1f3fe {
  background-position: -64px -64px; }

.emoji-1f468-1f3ff {
  background-position: -128px -0px; }

.emoji-1f469-1f3fb {
  background-position: -128px -64px; }

.emoji-1f469-1f3fc {
  background-position: -0px -128px; }

.emoji-1f469-1f3fd {
  background-position: -64px -128px; }

.emoji-1f469-1f3fe {
  background-position: -128px -128px; }

.emoji-1f468-1f3fb {
  background-position: -192px -0px; }

.emoji-category-skintone-10 {
  background-image: url(/static/files/3431f604c8600acf837d27bd6f7aba65.png); }

.emoji-1f469-1f3ff {
  background-position: -0px -0px; }

.emoji-1f468-1f3fc {
  background-position: -64px -0px; }

.emoji-1f468-1f3fd {
  background-position: -0px -64px; }

.emoji-1f468-1f3fe {
  background-position: -64px -64px; }

.emoji-1f468-1f3ff {
  background-position: -128px -0px; }

.emoji-1f469-1f3fb {
  background-position: -128px -64px; }

.emoji-1f469-1f3fc {
  background-position: -0px -128px; }

.emoji-1f469-1f3fd {
  background-position: -64px -128px; }

.emoji-1f469-1f3fe {
  background-position: -128px -128px; }

.emoji-1f468-1f3fb {
  background-position: -192px -0px; }

.emoji-category-skintone-11 {
  background-image: url(/static/files/28305e675a6b1fa4820f566646f2e814.png); }

.emoji-1f936-1f3ff {
  background-position: -0px -0px; }

.emoji-1f385-1f3fc {
  background-position: -64px -0px; }

.emoji-1f385-1f3fd {
  background-position: -0px -64px; }

.emoji-1f385-1f3fe {
  background-position: -64px -64px; }

.emoji-1f385-1f3ff {
  background-position: -128px -0px; }

.emoji-1f468-1f3fb {
  background-position: -128px -64px; }

.emoji-1f468-1f3fc {
  background-position: -0px -128px; }

.emoji-1f468-1f3fd {
  background-position: -64px -128px; }

.emoji-1f468-1f3fe {
  background-position: -128px -128px; }

.emoji-1f468-1f3ff {
  background-position: -192px -0px; }

.emoji-1f469-1f3fb {
  background-position: -192px -64px; }

.emoji-1f469-1f3fc {
  background-position: -192px -128px; }

.emoji-1f469-1f3fd {
  background-position: -0px -192px; }

.emoji-1f469-1f3fe {
  background-position: -64px -192px; }

.emoji-1f469-1f3ff {
  background-position: -128px -192px; }

.emoji-1f478-1f3fb {
  background-position: -192px -192px; }

.emoji-1f478-1f3fc {
  background-position: -256px -0px; }

.emoji-1f478-1f3fd {
  background-position: -256px -64px; }

.emoji-1f478-1f3fe {
  background-position: -256px -128px; }

.emoji-1f478-1f3ff {
  background-position: -256px -192px; }

.emoji-1f936-1f3fb {
  background-position: -0px -256px; }

.emoji-1f936-1f3fc {
  background-position: -64px -256px; }

.emoji-1f936-1f3fd {
  background-position: -128px -256px; }

.emoji-1f936-1f3fe {
  background-position: -192px -256px; }

.emoji-1f385-1f3fb {
  background-position: -256px -256px; }

.emoji-category-skintone-12 {
  background-image: url(/static/files/95b3c7a4adc62b07c4c744527f9fe078.png); }

.emoji-1f935-1f3ff {
  background-position: -0px -0px; }

.emoji-1f470-1f3fc {
  background-position: -64px -0px; }

.emoji-1f470-1f3fd {
  background-position: -0px -64px; }

.emoji-1f470-1f3fe {
  background-position: -64px -64px; }

.emoji-1f470-1f3ff {
  background-position: -128px -0px; }

.emoji-1f47c-1f3fb {
  background-position: -128px -64px; }

.emoji-1f47c-1f3fc {
  background-position: -0px -128px; }

.emoji-1f47c-1f3fd {
  background-position: -64px -128px; }

.emoji-1f47c-1f3fe {
  background-position: -128px -128px; }

.emoji-1f47c-1f3ff {
  background-position: -192px -0px; }

.emoji-1f647-1f3fb {
  background-position: -192px -64px; }

.emoji-1f647-1f3fb-200d-2640-fe0f {
  background-position: -192px -128px; }

.emoji-1f647-1f3fc {
  background-position: -0px -192px; }

.emoji-1f647-1f3fc-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f647-1f3fd {
  background-position: -128px -192px; }

.emoji-1f647-1f3fd-200d-2640-fe0f {
  background-position: -192px -192px; }

.emoji-1f647-1f3fe {
  background-position: -256px -0px; }

.emoji-1f647-1f3fe-200d-2640-fe0f {
  background-position: -256px -64px; }

.emoji-1f647-1f3ff {
  background-position: -256px -128px; }

.emoji-1f647-1f3ff-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f930-1f3fb {
  background-position: -0px -256px; }

.emoji-1f930-1f3fc {
  background-position: -64px -256px; }

.emoji-1f930-1f3fd {
  background-position: -128px -256px; }

.emoji-1f930-1f3fe {
  background-position: -192px -256px; }

.emoji-1f930-1f3ff {
  background-position: -256px -256px; }

.emoji-1f934-1f3fb {
  background-position: -320px -0px; }

.emoji-1f934-1f3fc {
  background-position: -320px -64px; }

.emoji-1f934-1f3fd {
  background-position: -320px -128px; }

.emoji-1f934-1f3fe {
  background-position: -320px -192px; }

.emoji-1f934-1f3ff {
  background-position: -320px -256px; }

.emoji-1f935-1f3fb {
  background-position: -0px -320px; }

.emoji-1f935-1f3fc {
  background-position: -64px -320px; }

.emoji-1f935-1f3fd {
  background-position: -128px -320px; }

.emoji-1f935-1f3fe {
  background-position: -192px -320px; }

.emoji-1f470-1f3fb {
  background-position: -256px -320px; }

.emoji-category-skintone-13 {
  background-image: url(/static/files/5a12c65f40eabbf5a63b462e10aeb514.png); }

.emoji-1f646-1f3ff-200d-2642-fe0f {
  background-position: -0px -0px; }

.emoji-1f481-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f481-1f3fb-200d-2642-fe0f {
  background-position: -0px -64px; }

.emoji-1f481-1f3fc {
  background-position: -64px -64px; }

.emoji-1f481-1f3fc-200d-2640-fe0f {
  background-position: -128px -0px; }

.emoji-1f481-1f3fc-200d-2642-fe0f {
  background-position: -128px -64px; }

.emoji-1f481-1f3fd {
  background-position: -0px -128px; }

.emoji-1f481-1f3fd-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f481-1f3fd-200d-2642-fe0f {
  background-position: -128px -128px; }

.emoji-1f481-1f3fe {
  background-position: -192px -0px; }

.emoji-1f481-1f3fe-200d-2640-fe0f {
  background-position: -192px -64px; }

.emoji-1f481-1f3fe-200d-2642-fe0f {
  background-position: -192px -128px; }

.emoji-1f481-1f3ff {
  background-position: -0px -192px; }

.emoji-1f481-1f3ff-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f481-1f3ff-200d-2642-fe0f {
  background-position: -128px -192px; }

.emoji-1f645-1f3fb {
  background-position: -192px -192px; }

.emoji-1f645-1f3fb-200d-2640-fe0f {
  background-position: -256px -0px; }

.emoji-1f645-1f3fb-200d-2642-fe0f {
  background-position: -256px -64px; }

.emoji-1f645-1f3fc {
  background-position: -256px -128px; }

.emoji-1f645-1f3fc-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f645-1f3fc-200d-2642-fe0f {
  background-position: -0px -256px; }

.emoji-1f645-1f3fd {
  background-position: -64px -256px; }

.emoji-1f645-1f3fd-200d-2640-fe0f {
  background-position: -128px -256px; }

.emoji-1f645-1f3fd-200d-2642-fe0f {
  background-position: -192px -256px; }

.emoji-1f645-1f3fe {
  background-position: -256px -256px; }

.emoji-1f645-1f3fe-200d-2640-fe0f {
  background-position: -320px -0px; }

.emoji-1f645-1f3fe-200d-2642-fe0f {
  background-position: -320px -64px; }

.emoji-1f645-1f3ff {
  background-position: -320px -128px; }

.emoji-1f645-1f3ff-200d-2640-fe0f {
  background-position: -320px -192px; }

.emoji-1f645-1f3ff-200d-2642-fe0f {
  background-position: -320px -256px; }

.emoji-1f646-1f3fb {
  background-position: -0px -320px; }

.emoji-1f646-1f3fb-200d-2640-fe0f {
  background-position: -64px -320px; }

.emoji-1f646-1f3fb-200d-2642-fe0f {
  background-position: -128px -320px; }

.emoji-1f646-1f3fc {
  background-position: -192px -320px; }

.emoji-1f646-1f3fc-200d-2640-fe0f {
  background-position: -256px -320px; }

.emoji-1f646-1f3fc-200d-2642-fe0f {
  background-position: -320px -320px; }

.emoji-1f646-1f3fd {
  background-position: -384px -0px; }

.emoji-1f646-1f3fd-200d-2640-fe0f {
  background-position: -384px -64px; }

.emoji-1f646-1f3fd-200d-2642-fe0f {
  background-position: -384px -128px; }

.emoji-1f646-1f3fe {
  background-position: -384px -192px; }

.emoji-1f646-1f3fe-200d-2640-fe0f {
  background-position: -384px -256px; }

.emoji-1f646-1f3fe-200d-2642-fe0f {
  background-position: -384px -320px; }

.emoji-1f646-1f3ff {
  background-position: -0px -384px; }

.emoji-1f646-1f3ff-200d-2640-fe0f {
  background-position: -64px -384px; }

.emoji-1f481-1f3fb {
  background-position: -128px -384px; }

.emoji-category-skintone-14 {
  background-image: url(/static/files/4ad8f697ed18c9e640eecfdd6776bb77.png); }

.emoji-1f937-1f3ff-200d-2642-fe0f {
  background-position: -0px -0px; }

.emoji-1f64b-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f64b-1f3fb-200d-2642-fe0f {
  background-position: -0px -64px; }

.emoji-1f64b-1f3fc {
  background-position: -64px -64px; }

.emoji-1f64b-1f3fc-200d-2640-fe0f {
  background-position: -128px -0px; }

.emoji-1f64b-1f3fc-200d-2642-fe0f {
  background-position: -128px -64px; }

.emoji-1f64b-1f3fd {
  background-position: -0px -128px; }

.emoji-1f64b-1f3fd-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f64b-1f3fd-200d-2642-fe0f {
  background-position: -128px -128px; }

.emoji-1f64b-1f3fe {
  background-position: -192px -0px; }

.emoji-1f64b-1f3fe-200d-2640-fe0f {
  background-position: -192px -64px; }

.emoji-1f64b-1f3fe-200d-2642-fe0f {
  background-position: -192px -128px; }

.emoji-1f64b-1f3ff {
  background-position: -0px -192px; }

.emoji-1f64b-1f3ff-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f64b-1f3ff-200d-2642-fe0f {
  background-position: -128px -192px; }

.emoji-1f926-1f3fb {
  background-position: -192px -192px; }

.emoji-1f926-1f3fb-200d-2640-fe0f {
  background-position: -256px -0px; }

.emoji-1f926-1f3fb-200d-2642-fe0f {
  background-position: -256px -64px; }

.emoji-1f926-1f3fc {
  background-position: -256px -128px; }

.emoji-1f926-1f3fc-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f926-1f3fc-200d-2642-fe0f {
  background-position: -0px -256px; }

.emoji-1f926-1f3fd {
  background-position: -64px -256px; }

.emoji-1f926-1f3fd-200d-2640-fe0f {
  background-position: -128px -256px; }

.emoji-1f926-1f3fd-200d-2642-fe0f {
  background-position: -192px -256px; }

.emoji-1f926-1f3fe {
  background-position: -256px -256px; }

.emoji-1f926-1f3fe-200d-2640-fe0f {
  background-position: -320px -0px; }

.emoji-1f926-1f3fe-200d-2642-fe0f {
  background-position: -320px -64px; }

.emoji-1f926-1f3ff {
  background-position: -320px -128px; }

.emoji-1f926-1f3ff-200d-2640-fe0f {
  background-position: -320px -192px; }

.emoji-1f926-1f3ff-200d-2642-fe0f {
  background-position: -320px -256px; }

.emoji-1f937-1f3fb {
  background-position: -0px -320px; }

.emoji-1f937-1f3fb-200d-2640-fe0f {
  background-position: -64px -320px; }

.emoji-1f937-1f3fb-200d-2642-fe0f {
  background-position: -128px -320px; }

.emoji-1f937-1f3fc {
  background-position: -192px -320px; }

.emoji-1f937-1f3fc-200d-2640-fe0f {
  background-position: -256px -320px; }

.emoji-1f937-1f3fc-200d-2642-fe0f {
  background-position: -320px -320px; }

.emoji-1f937-1f3fd {
  background-position: -384px -0px; }

.emoji-1f937-1f3fd-200d-2640-fe0f {
  background-position: -384px -64px; }

.emoji-1f937-1f3fd-200d-2642-fe0f {
  background-position: -384px -128px; }

.emoji-1f937-1f3fe {
  background-position: -384px -192px; }

.emoji-1f937-1f3fe-200d-2640-fe0f {
  background-position: -384px -256px; }

.emoji-1f937-1f3fe-200d-2642-fe0f {
  background-position: -384px -320px; }

.emoji-1f937-1f3ff {
  background-position: -0px -384px; }

.emoji-1f937-1f3ff-200d-2640-fe0f {
  background-position: -64px -384px; }

.emoji-1f64b-1f3fb {
  background-position: -128px -384px; }

.emoji-category-skintone-15 {
  background-image: url(/static/files/b4a0190bc3199d3514804eb37223497b.png); }

.emoji-1f64e-1f3ff-200d-2642-fe0f {
  background-position: -0px -0px; }

.emoji-1f487-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f487-1f3fb-200d-2642-fe0f {
  background-position: -0px -64px; }

.emoji-1f487-1f3fc {
  background-position: -64px -64px; }

.emoji-1f487-1f3fc-200d-2640-fe0f {
  background-position: -128px -0px; }

.emoji-1f487-1f3fc-200d-2642-fe0f {
  background-position: -128px -64px; }

.emoji-1f487-1f3fd {
  background-position: -0px -128px; }

.emoji-1f487-1f3fd-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f487-1f3fd-200d-2642-fe0f {
  background-position: -128px -128px; }

.emoji-1f487-1f3fe {
  background-position: -192px -0px; }

.emoji-1f487-1f3fe-200d-2640-fe0f {
  background-position: -192px -64px; }

.emoji-1f487-1f3fe-200d-2642-fe0f {
  background-position: -192px -128px; }

.emoji-1f487-1f3ff {
  background-position: -0px -192px; }

.emoji-1f487-1f3ff-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f487-1f3ff-200d-2642-fe0f {
  background-position: -128px -192px; }

.emoji-1f64d-1f3fb {
  background-position: -192px -192px; }

.emoji-1f64d-1f3fb-200d-2640-fe0f {
  background-position: -256px -0px; }

.emoji-1f64d-1f3fb-200d-2642-fe0f {
  background-position: -256px -64px; }

.emoji-1f64d-1f3fc {
  background-position: -256px -128px; }

.emoji-1f64d-1f3fc-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f64d-1f3fc-200d-2642-fe0f {
  background-position: -0px -256px; }

.emoji-1f64d-1f3fd {
  background-position: -64px -256px; }

.emoji-1f64d-1f3fd-200d-2640-fe0f {
  background-position: -128px -256px; }

.emoji-1f64d-1f3fd-200d-2642-fe0f {
  background-position: -192px -256px; }

.emoji-1f64d-1f3fe {
  background-position: -256px -256px; }

.emoji-1f64d-1f3fe-200d-2640-fe0f {
  background-position: -320px -0px; }

.emoji-1f64d-1f3fe-200d-2642-fe0f {
  background-position: -320px -64px; }

.emoji-1f64d-1f3ff {
  background-position: -320px -128px; }

.emoji-1f64d-1f3ff-200d-2640-fe0f {
  background-position: -320px -192px; }

.emoji-1f64d-1f3ff-200d-2642-fe0f {
  background-position: -320px -256px; }

.emoji-1f64e-1f3fb {
  background-position: -0px -320px; }

.emoji-1f64e-1f3fb-200d-2640-fe0f {
  background-position: -64px -320px; }

.emoji-1f64e-1f3fb-200d-2642-fe0f {
  background-position: -128px -320px; }

.emoji-1f64e-1f3fc {
  background-position: -192px -320px; }

.emoji-1f64e-1f3fc-200d-2640-fe0f {
  background-position: -256px -320px; }

.emoji-1f64e-1f3fc-200d-2642-fe0f {
  background-position: -320px -320px; }

.emoji-1f64e-1f3fd {
  background-position: -384px -0px; }

.emoji-1f64e-1f3fd-200d-2640-fe0f {
  background-position: -384px -64px; }

.emoji-1f64e-1f3fd-200d-2642-fe0f {
  background-position: -384px -128px; }

.emoji-1f64e-1f3fe {
  background-position: -384px -192px; }

.emoji-1f64e-1f3fe-200d-2640-fe0f {
  background-position: -384px -256px; }

.emoji-1f64e-1f3fe-200d-2642-fe0f {
  background-position: -384px -320px; }

.emoji-1f64e-1f3ff {
  background-position: -0px -384px; }

.emoji-1f64e-1f3ff-200d-2640-fe0f {
  background-position: -64px -384px; }

.emoji-1f487-1f3fb {
  background-position: -128px -384px; }

.emoji-category-skintone-16 {
  background-image: url(/static/files/38aa368f132828119a4cff9b2cfdeb94.png); }

.emoji-1f6b6-1f3ff-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f483-1f3fc {
  background-position: -64px -0px; }

.emoji-1f483-1f3fd {
  background-position: -0px -64px; }

.emoji-1f483-1f3fe {
  background-position: -64px -64px; }

.emoji-1f483-1f3ff {
  background-position: -128px -0px; }

.emoji-1f486-1f3fb {
  background-position: -128px -64px; }

.emoji-1f486-1f3fb-200d-2640-fe0f {
  background-position: -0px -128px; }

.emoji-1f486-1f3fb-200d-2642-fe0f {
  background-position: -64px -128px; }

.emoji-1f486-1f3fc {
  background-position: -128px -128px; }

.emoji-1f486-1f3fc-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f486-1f3fc-200d-2642-fe0f {
  background-position: -192px -64px; }

.emoji-1f486-1f3fd {
  background-position: -192px -128px; }

.emoji-1f486-1f3fd-200d-2640-fe0f {
  background-position: -0px -192px; }

.emoji-1f486-1f3fd-200d-2642-fe0f {
  background-position: -64px -192px; }

.emoji-1f486-1f3fe {
  background-position: -128px -192px; }

.emoji-1f486-1f3fe-200d-2640-fe0f {
  background-position: -192px -192px; }

.emoji-1f486-1f3fe-200d-2642-fe0f {
  background-position: -256px -0px; }

.emoji-1f486-1f3ff {
  background-position: -256px -64px; }

.emoji-1f486-1f3ff-200d-2640-fe0f {
  background-position: -256px -128px; }

.emoji-1f486-1f3ff-200d-2642-fe0f {
  background-position: -256px -192px; }

.emoji-1f574-1f3fb {
  background-position: -0px -256px; }

.emoji-1f574-1f3fc {
  background-position: -64px -256px; }

.emoji-1f574-1f3fd {
  background-position: -128px -256px; }

.emoji-1f574-1f3fe {
  background-position: -192px -256px; }

.emoji-1f574-1f3ff {
  background-position: -256px -256px; }

.emoji-1f57a-1f3fb {
  background-position: -320px -0px; }

.emoji-1f57a-1f3fc {
  background-position: -320px -64px; }

.emoji-1f57a-1f3fd {
  background-position: -320px -128px; }

.emoji-1f57a-1f3fe {
  background-position: -320px -192px; }

.emoji-1f57a-1f3ff {
  background-position: -320px -256px; }

.emoji-1f6b6-1f3fb {
  background-position: -0px -320px; }

.emoji-1f6b6-1f3fb-200d-2640-fe0f {
  background-position: -64px -320px; }

.emoji-1f6b6-1f3fc {
  background-position: -128px -320px; }

.emoji-1f6b6-1f3fc-200d-2640-fe0f {
  background-position: -192px -320px; }

.emoji-1f6b6-1f3fd {
  background-position: -256px -320px; }

.emoji-1f6b6-1f3fd-200d-2640-fe0f {
  background-position: -320px -320px; }

.emoji-1f6b6-1f3fe {
  background-position: -384px -0px; }

.emoji-1f6b6-1f3fe-200d-2640-fe0f {
  background-position: -384px -64px; }

.emoji-1f6b6-1f3ff {
  background-position: -384px -128px; }

.emoji-1f483-1f3fb {
  background-position: -384px -192px; }

.emoji-category-skintone-17 {
  background-image: url(/static/files/5856b3279d99d37693c45a72dfbd3f82.png); }

.emoji-1f6b6-1f3ff-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f3c3-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f3c3-1f3fc {
  background-position: -0px -64px; }

.emoji-1f3c3-1f3fc-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f3c3-1f3fd {
  background-position: -128px -0px; }

.emoji-1f3c3-1f3fd-200d-2640-fe0f {
  background-position: -128px -64px; }

.emoji-1f3c3-1f3fe {
  background-position: -0px -128px; }

.emoji-1f3c3-1f3fe-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f3c3-1f3ff {
  background-position: -128px -128px; }

.emoji-1f3c3-1f3ff-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f468-1f3fb {
  background-position: -192px -64px; }

.emoji-1f468-1f3fc {
  background-position: -192px -128px; }

.emoji-1f468-1f3fd {
  background-position: -0px -192px; }

.emoji-1f468-1f3fe {
  background-position: -64px -192px; }

.emoji-1f468-1f3ff {
  background-position: -128px -192px; }

.emoji-1f469-1f3fb {
  background-position: -192px -192px; }

.emoji-1f469-1f3fc {
  background-position: -256px -0px; }

.emoji-1f469-1f3fd {
  background-position: -256px -64px; }

.emoji-1f469-1f3fe {
  background-position: -256px -128px; }

.emoji-1f469-1f3ff {
  background-position: -256px -192px; }

.emoji-1f6b6-1f3fb-200d-2640-fe0f {
  background-position: -0px -256px; }

.emoji-1f6b6-1f3fc-200d-2640-fe0f {
  background-position: -64px -256px; }

.emoji-1f6b6-1f3fd-200d-2640-fe0f {
  background-position: -128px -256px; }

.emoji-1f6b6-1f3fe-200d-2640-fe0f {
  background-position: -192px -256px; }

.emoji-1f3c3-1f3fb {
  background-position: -256px -256px; }

.emoji-category-skintone-18 {
  background-image: url(/static/files/3431f604c8600acf837d27bd6f7aba65.png); }

.emoji-1f469-1f3ff {
  background-position: -0px -0px; }

.emoji-1f468-1f3fc {
  background-position: -64px -0px; }

.emoji-1f468-1f3fd {
  background-position: -0px -64px; }

.emoji-1f468-1f3fe {
  background-position: -64px -64px; }

.emoji-1f468-1f3ff {
  background-position: -128px -0px; }

.emoji-1f469-1f3fb {
  background-position: -128px -64px; }

.emoji-1f469-1f3fc {
  background-position: -0px -128px; }

.emoji-1f469-1f3fd {
  background-position: -64px -128px; }

.emoji-1f469-1f3fe {
  background-position: -128px -128px; }

.emoji-1f468-1f3fb {
  background-position: -192px -0px; }

.emoji-category-skintone-19 {
  background-image: url(/static/files/3431f604c8600acf837d27bd6f7aba65.png); }

.emoji-1f469-1f3ff {
  background-position: -0px -0px; }

.emoji-1f468-1f3fc {
  background-position: -64px -0px; }

.emoji-1f468-1f3fd {
  background-position: -0px -64px; }

.emoji-1f468-1f3fe {
  background-position: -64px -64px; }

.emoji-1f468-1f3ff {
  background-position: -128px -0px; }

.emoji-1f469-1f3fb {
  background-position: -128px -64px; }

.emoji-1f469-1f3fc {
  background-position: -0px -128px; }

.emoji-1f469-1f3fd {
  background-position: -64px -128px; }

.emoji-1f469-1f3fe {
  background-position: -128px -128px; }

.emoji-1f468-1f3fb {
  background-position: -192px -0px; }

.emoji-category-skintone-20 {
  background-image: url(/static/files/66609f5c83c5e368332484596c14e880.png); }

.emoji-1f469-1f3ff {
  background-position: -0px -0px; }

.emoji-1f3cb-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f3cb-1f3fc {
  background-position: -0px -64px; }

.emoji-1f3cb-1f3fc-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f3cb-1f3fd {
  background-position: -128px -0px; }

.emoji-1f3cb-1f3fd-200d-2640-fe0f {
  background-position: -128px -64px; }

.emoji-1f3cb-1f3fe {
  background-position: -0px -128px; }

.emoji-1f3cb-1f3fe-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f3cb-1f3ff {
  background-position: -128px -128px; }

.emoji-1f3cb-1f3ff-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f468-1f3fb {
  background-position: -192px -64px; }

.emoji-1f468-1f3fc {
  background-position: -192px -128px; }

.emoji-1f468-1f3fd {
  background-position: -0px -192px; }

.emoji-1f468-1f3fe {
  background-position: -64px -192px; }

.emoji-1f468-1f3ff {
  background-position: -128px -192px; }

.emoji-1f469-1f3fb {
  background-position: -192px -192px; }

.emoji-1f469-1f3fc {
  background-position: -256px -0px; }

.emoji-1f469-1f3fd {
  background-position: -256px -64px; }

.emoji-1f469-1f3fe {
  background-position: -256px -128px; }

.emoji-1f3cb-1f3fb {
  background-position: -256px -192px; }

.emoji-category-skintone-21 {
  background-image: url(/static/files/17c77dc2331b7eebcfafbdb604d09232.png); }

.emoji-26f9-1f3ff-200d-2640-fe0f {
  background-position: -0px -0px; }

.emoji-1f3cb-1f3fc-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f3cb-1f3fd-200d-2640-fe0f {
  background-position: -0px -64px; }

.emoji-1f3cb-1f3fe-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f3cb-1f3ff-200d-2640-fe0f {
  background-position: -128px -0px; }

.emoji-1f938-1f3fb {
  background-position: -128px -64px; }

.emoji-1f938-1f3fb-200d-2640-fe0f {
  background-position: -0px -128px; }

.emoji-1f938-1f3fb-200d-2642-fe0f {
  background-position: -64px -128px; }

.emoji-1f938-1f3fc {
  background-position: -128px -128px; }

.emoji-1f938-1f3fc-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f938-1f3fc-200d-2642-fe0f {
  background-position: -192px -64px; }

.emoji-1f938-1f3fd {
  background-position: -192px -128px; }

.emoji-1f938-1f3fd-200d-2640-fe0f {
  background-position: -0px -192px; }

.emoji-1f938-1f3fd-200d-2642-fe0f {
  background-position: -64px -192px; }

.emoji-1f938-1f3fe {
  background-position: -128px -192px; }

.emoji-1f938-1f3fe-200d-2640-fe0f {
  background-position: -192px -192px; }

.emoji-1f938-1f3fe-200d-2642-fe0f {
  background-position: -256px -0px; }

.emoji-1f938-1f3ff {
  background-position: -256px -64px; }

.emoji-1f938-1f3ff-200d-2640-fe0f {
  background-position: -256px -128px; }

.emoji-1f938-1f3ff-200d-2642-fe0f {
  background-position: -256px -192px; }

.emoji-1f93e-1f3fb {
  background-position: -0px -256px; }

.emoji-1f93e-1f3fc {
  background-position: -64px -256px; }

.emoji-1f93e-1f3fd {
  background-position: -128px -256px; }

.emoji-1f93e-1f3fe {
  background-position: -192px -256px; }

.emoji-1f93e-1f3ff {
  background-position: -256px -256px; }

.emoji-26f9-1f3fb {
  background-position: -320px -0px; }

.emoji-26f9-1f3fb-200d-2640-fe0f {
  background-position: -320px -64px; }

.emoji-26f9-1f3fc {
  background-position: -320px -128px; }

.emoji-26f9-1f3fc-200d-2640-fe0f {
  background-position: -320px -192px; }

.emoji-26f9-1f3fd {
  background-position: -320px -256px; }

.emoji-26f9-1f3fd-200d-2640-fe0f {
  background-position: -0px -320px; }

.emoji-26f9-1f3fe {
  background-position: -64px -320px; }

.emoji-26f9-1f3fe-200d-2640-fe0f {
  background-position: -128px -320px; }

.emoji-26f9-1f3ff {
  background-position: -192px -320px; }

.emoji-1f3cb-1f3fb-200d-2640-fe0f {
  background-position: -256px -320px; }

.emoji-category-skintone-22 {
  background-image: url(/static/files/63370c9e60f9e91f82d45e7665b91ab7.png); }

.emoji-1f93e-1f3ff-200d-2642-fe0f {
  background-position: -0px -0px; }

.emoji-1f3c4-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f3c4-1f3fc {
  background-position: -0px -64px; }

.emoji-1f3c4-1f3fc-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f3c4-1f3fd {
  background-position: -128px -0px; }

.emoji-1f3c4-1f3fd-200d-2640-fe0f {
  background-position: -128px -64px; }

.emoji-1f3c4-1f3fe {
  background-position: -0px -128px; }

.emoji-1f3c4-1f3fe-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f3c4-1f3ff {
  background-position: -128px -128px; }

.emoji-1f3c4-1f3ff-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f3cc-1f3fb {
  background-position: -192px -64px; }

.emoji-1f3cc-1f3fb-200d-2640-fe0f {
  background-position: -192px -128px; }

.emoji-1f3cc-1f3fc {
  background-position: -0px -192px; }

.emoji-1f3cc-1f3fc-200d-2640-fe0f {
  background-position: -64px -192px; }

.emoji-1f3cc-1f3fd {
  background-position: -128px -192px; }

.emoji-1f3cc-1f3fd-200d-2640-fe0f {
  background-position: -192px -192px; }

.emoji-1f3cc-1f3fe {
  background-position: -256px -0px; }

.emoji-1f3cc-1f3fe-200d-2640-fe0f {
  background-position: -256px -64px; }

.emoji-1f3cc-1f3ff {
  background-position: -256px -128px; }

.emoji-1f3cc-1f3ff-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f93e-1f3fb-200d-2640-fe0f {
  background-position: -0px -256px; }

.emoji-1f93e-1f3fb-200d-2642-fe0f {
  background-position: -64px -256px; }

.emoji-1f93e-1f3fc-200d-2640-fe0f {
  background-position: -128px -256px; }

.emoji-1f93e-1f3fc-200d-2642-fe0f {
  background-position: -192px -256px; }

.emoji-1f93e-1f3fd-200d-2640-fe0f {
  background-position: -256px -256px; }

.emoji-1f93e-1f3fd-200d-2642-fe0f {
  background-position: -320px -0px; }

.emoji-1f93e-1f3fe-200d-2640-fe0f {
  background-position: -320px -64px; }

.emoji-1f93e-1f3fe-200d-2642-fe0f {
  background-position: -320px -128px; }

.emoji-1f93e-1f3ff-200d-2640-fe0f {
  background-position: -320px -192px; }

.emoji-1f3c4-1f3fb {
  background-position: -320px -256px; }

.emoji-category-skintone-23 {
  background-image: url(/static/files/e86779aa51058b5a592829195f2d2e7b.png); }

.emoji-1f93d-1f3ff-200d-2642-fe0f {
  background-position: -0px -0px; }

.emoji-1f3c4-1f3fc-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f3c4-1f3fd-200d-2640-fe0f {
  background-position: -0px -64px; }

.emoji-1f3c4-1f3fe-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f3c4-1f3ff-200d-2640-fe0f {
  background-position: -128px -0px; }

.emoji-1f3ca-1f3fb {
  background-position: -128px -64px; }

.emoji-1f3ca-1f3fb-200d-2640-fe0f {
  background-position: -0px -128px; }

.emoji-1f3ca-1f3fc {
  background-position: -64px -128px; }

.emoji-1f3ca-1f3fc-200d-2640-fe0f {
  background-position: -128px -128px; }

.emoji-1f3ca-1f3fd {
  background-position: -192px -0px; }

.emoji-1f3ca-1f3fd-200d-2640-fe0f {
  background-position: -192px -64px; }

.emoji-1f3ca-1f3fe {
  background-position: -192px -128px; }

.emoji-1f3ca-1f3fe-200d-2640-fe0f {
  background-position: -0px -192px; }

.emoji-1f3ca-1f3ff {
  background-position: -64px -192px; }

.emoji-1f3ca-1f3ff-200d-2640-fe0f {
  background-position: -128px -192px; }

.emoji-1f6a3-1f3fb {
  background-position: -192px -192px; }

.emoji-1f6a3-1f3fc {
  background-position: -256px -0px; }

.emoji-1f6a3-1f3fd {
  background-position: -256px -64px; }

.emoji-1f6a3-1f3fe {
  background-position: -256px -128px; }

.emoji-1f6a3-1f3ff {
  background-position: -256px -192px; }

.emoji-1f93d-1f3fb {
  background-position: -0px -256px; }

.emoji-1f93d-1f3fb-200d-2640-fe0f {
  background-position: -64px -256px; }

.emoji-1f93d-1f3fb-200d-2642-fe0f {
  background-position: -128px -256px; }

.emoji-1f93d-1f3fc {
  background-position: -192px -256px; }

.emoji-1f93d-1f3fc-200d-2640-fe0f {
  background-position: -256px -256px; }

.emoji-1f93d-1f3fc-200d-2642-fe0f {
  background-position: -320px -0px; }

.emoji-1f93d-1f3fd {
  background-position: -320px -64px; }

.emoji-1f93d-1f3fd-200d-2640-fe0f {
  background-position: -320px -128px; }

.emoji-1f93d-1f3fd-200d-2642-fe0f {
  background-position: -320px -192px; }

.emoji-1f93d-1f3fe {
  background-position: -320px -256px; }

.emoji-1f93d-1f3fe-200d-2640-fe0f {
  background-position: -0px -320px; }

.emoji-1f93d-1f3fe-200d-2642-fe0f {
  background-position: -64px -320px; }

.emoji-1f93d-1f3ff {
  background-position: -128px -320px; }

.emoji-1f93d-1f3ff-200d-2640-fe0f {
  background-position: -192px -320px; }

.emoji-1f3c4-1f3fb-200d-2640-fe0f {
  background-position: -256px -320px; }

.emoji-category-skintone-24 {
  background-image: url(/static/files/f832f2844a21fc25eaa7ca3c153b15d1.png); }

.emoji-1f6b5-1f3ff {
  background-position: -0px -0px; }

.emoji-1f3c7-1f3fc {
  background-position: -64px -0px; }

.emoji-1f3c7-1f3fd {
  background-position: -0px -64px; }

.emoji-1f3c7-1f3fe {
  background-position: -64px -64px; }

.emoji-1f3c7-1f3ff {
  background-position: -128px -0px; }

.emoji-1f6a3-1f3fb {
  background-position: -128px -64px; }

.emoji-1f6a3-1f3fb-200d-2640-fe0f {
  background-position: -0px -128px; }

.emoji-1f6a3-1f3fc {
  background-position: -64px -128px; }

.emoji-1f6a3-1f3fc-200d-2640-fe0f {
  background-position: -128px -128px; }

.emoji-1f6a3-1f3fd {
  background-position: -192px -0px; }

.emoji-1f6a3-1f3fd-200d-2640-fe0f {
  background-position: -192px -64px; }

.emoji-1f6a3-1f3fe {
  background-position: -192px -128px; }

.emoji-1f6a3-1f3fe-200d-2640-fe0f {
  background-position: -0px -192px; }

.emoji-1f6a3-1f3ff {
  background-position: -64px -192px; }

.emoji-1f6a3-1f3ff-200d-2640-fe0f {
  background-position: -128px -192px; }

.emoji-1f6b4-1f3fb {
  background-position: -192px -192px; }

.emoji-1f6b4-1f3fb-200d-2640-fe0f {
  background-position: -256px -0px; }

.emoji-1f6b4-1f3fc {
  background-position: -256px -64px; }

.emoji-1f6b4-1f3fc-200d-2640-fe0f {
  background-position: -256px -128px; }

.emoji-1f6b4-1f3fd {
  background-position: -256px -192px; }

.emoji-1f6b4-1f3fd-200d-2640-fe0f {
  background-position: -0px -256px; }

.emoji-1f6b4-1f3fe {
  background-position: -64px -256px; }

.emoji-1f6b4-1f3fe-200d-2640-fe0f {
  background-position: -128px -256px; }

.emoji-1f6b4-1f3ff {
  background-position: -192px -256px; }

.emoji-1f6b4-1f3ff-200d-2640-fe0f {
  background-position: -256px -256px; }

.emoji-1f6b5-1f3fb {
  background-position: -320px -0px; }

.emoji-1f6b5-1f3fc {
  background-position: -320px -64px; }

.emoji-1f6b5-1f3fd {
  background-position: -320px -128px; }

.emoji-1f6b5-1f3fe {
  background-position: -320px -192px; }

.emoji-1f3c7-1f3fb {
  background-position: -320px -256px; }

.emoji-category-skintone-25 {
  background-image: url(/static/files/974d2986c17832c5e2a9cad49af73023.png); }

.emoji-1f939-1f3ff-200d-2642-fe0f {
  background-position: -0px -0px; }

.emoji-1f6b5-1f3fb-200d-2640-fe0f {
  background-position: -64px -0px; }

.emoji-1f6b5-1f3fc {
  background-position: -0px -64px; }

.emoji-1f6b5-1f3fc-200d-2640-fe0f {
  background-position: -64px -64px; }

.emoji-1f6b5-1f3fd {
  background-position: -128px -0px; }

.emoji-1f6b5-1f3fd-200d-2640-fe0f {
  background-position: -128px -64px; }

.emoji-1f6b5-1f3fe {
  background-position: -0px -128px; }

.emoji-1f6b5-1f3fe-200d-2640-fe0f {
  background-position: -64px -128px; }

.emoji-1f6b5-1f3ff {
  background-position: -128px -128px; }

.emoji-1f6b5-1f3ff-200d-2640-fe0f {
  background-position: -192px -0px; }

.emoji-1f6c0-1f3fb {
  background-position: -192px -64px; }

.emoji-1f6c0-1f3fc {
  background-position: -192px -128px; }

.emoji-1f6c0-1f3fd {
  background-position: -0px -192px; }

.emoji-1f6c0-1f3fe {
  background-position: -64px -192px; }

.emoji-1f6c0-1f3ff {
  background-position: -128px -192px; }

.emoji-1f939-1f3fb {
  background-position: -192px -192px; }

.emoji-1f939-1f3fb-200d-2640-fe0f {
  background-position: -256px -0px; }

.emoji-1f939-1f3fb-200d-2642-fe0f {
  background-position: -256px -64px; }

.emoji-1f939-1f3fc {
  background-position: -256px -128px; }

.emoji-1f939-1f3fc-200d-2640-fe0f {
  background-position: -256px -192px; }

.emoji-1f939-1f3fc-200d-2642-fe0f {
  background-position: -0px -256px; }

.emoji-1f939-1f3fd {
  background-position: -64px -256px; }

.emoji-1f939-1f3fd-200d-2640-fe0f {
  background-position: -128px -256px; }

.emoji-1f939-1f3fd-200d-2642-fe0f {
  background-position: -192px -256px; }

.emoji-1f939-1f3fe {
  background-position: -256px -256px; }

.emoji-1f939-1f3fe-200d-2640-fe0f {
  background-position: -320px -0px; }

.emoji-1f939-1f3fe-200d-2642-fe0f {
  background-position: -320px -64px; }

.emoji-1f939-1f3ff {
  background-position: -320px -128px; }

.emoji-1f939-1f3ff-200d-2640-fe0f {
  background-position: -320px -192px; }

.emoji-1f6b5-1f3fb {
  background-position: -320px -256px; }

.file-preview__container {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  height: 115px;
  margin: 1px 0 5px;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: auto;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.file-preview {
  overflow: hidden;
  *zoom: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: inline-block;
  height: 100px;
  margin-right: 10px;
  position: relative;
  width: 120px; }
  .file-preview .spinner {
    height: 32px;
    left: 50%;
    margin-left: -16px;
    margin-top: -16px;
    position: absolute;
    top: 50%;
    width: 32px; }

.file-preview__remove {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 0 2px;
  height: 28px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 28px; }
  .file-preview__remove i {
    color: white;
    cursor: pointer;
    font-size: 16px;
    line-height: 29px;
    position: relative;
    right: auto;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
    top: auto;
    z-index: 5; }

.image-comment {
  background-position: left top;
  background-repeat: no-repeat;
  height: 300px;
  width: 300px; }

.file-icon {
  height: 100%;
  width: 100%; }
  .file-icon.audio {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/b91d0c9cfeeece180e10cbfd2349ef36.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.video {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/db68240cc0d5750dc7bd3017b5cd99c1.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.ppt {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/315df0b6f08fd2ef5e3fa5ca3637c049.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.generic {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/3b04828cf6cd748ebe069943af1b0922.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.code {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/48c4c1b39579c8d20df959b3eb5ec009.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.excel {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/6cf08f698e3fd94f7820460e7b06de6b.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.word {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/3b04828cf6cd748ebe069943af1b0922.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.pdf {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/6985640fbb09579efcef83815416a0be.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.patch {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/b39e486c59c610c47276d7c56ea5e69f.png);
    background-position: center;
    background-repeat: no-repeat; }
  .file-icon.image {
    -webkit-background-size: 37% auto;
    -moz-background-size: 37% auto;
    -o-background-size: 37% auto;
    background-size: 37% auto;
    background-color: white;
    background-image: url(/static/files/4936af177ebc5d3259c3b1077206b4f3.svg);
    background-position: center;
    background-repeat: no-repeat; }

.pdf-preview-spacer {
  height: 5px; }

.pdf-max-pages {
  background: white;
  bottom: 0;
  display: block;
  left: 0;
  position: relative;
  width: 100%; }
  .pdf-max-pages span {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.8);
    bottom: 15px;
    color: white;
    display: inline-block;
    height: 3em;
    line-height: 3em;
    padding: 0 1.5em;
    position: relative; }

.file-view--single {
  margin: 5px 0;
  position: relative; }
  .file-view--single .file__image-loading {
    min-height: 100px; }
  .file-view--single .file__image {
    *zoom: 1; }
    .file-view--single .file__image:after {
      content: " ";
      display: block;
      height: 0;
      clear: both;
      overflow: hidden;
      visibility: hidden; }
    .file-view--single .file__image .image-name {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%; }
      .file-view--single .file__image .image-name div {
        display: inline;
        margin-left: 5px; }
    .file-view--single .file__image .image-container {
      position: relative;
      max-width: 1024px;
      width: 100%;
      height: 100%; }
    .file-view--single .file__image .image-preload {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: inline-block; }
    .file-view--single .file__image .image-loaded {
      -webkit-transition: all 0.1s linear;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.1s linear;
      -o-transition: all 0.1s linear;
      transition: all 0.1s linear false;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
      bottom: 0;
      cursor: pointer;
      display: inline-flex;
      min-height: 40px;
      min-width: 40px;
      max-height: 350px;
      max-width: 100%;
      align-content: center;
      justify-content: center;
      opacity: 0;
      overflow: hidden;
      position: relative;
      z-index: 2; }
      .file-view--single .file__image .image-loaded:hover {
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1); }
      .file-view--single .file__image .image-loaded img {
        -webkit-transition: all 0.1s linear;
        -webkit-transition-delay: 0s;
        -moz-transition: all 0.1s linear;
        -o-transition: all 0.1s linear;
        transition: all 0.1s linear false;
        align-self: center;
        max-height: 350px; }
        .file-view--single .file__image .image-loaded img.min-preview {
          max-height: 50px;
          max-width: 100%; }
        .file-view--single .file__image .image-loaded img.min-preview--portrait {
          max-height: initial;
          max-width: 50px; }
    .file-view--single .file__image .image-fade-in {
      opacity: 1; }
  .file-view--single .file-details__name {
    display: block;
    font-size: 14px;
    font-weight: bold; }
  .file-view--single .file-details__name:hover {
    cursor: pointer;
    text-decoration: underline; }

.post-image__columns {
  display: flex;
  flex-flow: wrap;
  position: relative; }

.post-image__column {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  height: 100px;
  margin: 5px 10px 5px 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 320px; }
  .post__content .post-image__column {
    cursor: pointer; }
  .post-image__column--placeholder {
    visibility: hidden; }
  .post-image__column.file-preview {
    flex-shrink: 0;
    flex-wrap: nowrap;
    margin: 0 10px 0 0; }
    .post-image__column.file-preview .post-image__details .post-image__name {
      white-space: normal; }

.post-image__load {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  height: 100%;
  width: 100%; }

.post-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: white;
  background-repeat: no-repeat;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%; }
  .post-image.small {
    background-position: center; }
  .post-image.normal {
    background-position: top left; }
  .post-image .spinner .file__loading {
    left: 50%;
    margin-left: -16px;
    margin-top: -16px;
    position: absolute;
    top: 50%; }
  .post-image .file__loaded {
    max-width: initial; }
    .post-image .file__loaded.landscape, .post-image .file__loaded.quadrat {
      height: 100px; }
    .post-image .file__loaded.portrait {
      width: 120px; }

.post-image__thumbnail {
  cursor: -webkit- zoom-in;
  cursor: zoom- zoom-in;
  flex: none;
  height: 100%;
  width: 100px; }

.post-image__details {
  overflow: hidden;
  *zoom: 1;
  position: relative;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  font-size: 13px;
  height: 100%;
  padding-right: 50px;
  width: 100%; }
  .post-image__details .post-image__detail_wrapper {
    padding-left: 15px;
    width: 100%; }
    .post--compact .post-image__details .post-image__detail_wrapper {
      padding-left: 8px; }
    .post-image__details .post-image__detail_wrapper .post-image__detail {
      height: 100%;
      line-height: normal;
      width: 100%; }
      .post-image__details .post-image__detail_wrapper .post-image__detail .post-image__name {
        display: block;
        font-weight: 600;
        margin-bottom: 3px;
        outline: none;
        text-decoration: none; }
      .post-image__details .post-image__detail_wrapper .post-image__detail .post-image__type {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
        opacity: 0.6; }
      .post-image__details .post-image__detail_wrapper .post-image__detail .post-image__size {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
        opacity: 0.6;
        margin-left: 4px; }
  .post-image__details .post-image__download {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    line-height: 0;
    padding: 0 5px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 50px; }
    .post-image__details .post-image__download svg {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7;
      -webkit-transition: all 0.1s linear;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.1s linear;
      -o-transition: all 0.1s linear;
      transition: all 0.1s linear false;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      border: 1px solid;
      display: inline-block;
      height: 30px;
      width: 30px; }
      .post-image__details .post-image__download svg:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
  .post-image__details .post-image__progressBar {
    margin-bottom: 0px;
    height: 7px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin-left: -15px;
    border-radius: 0px; }
  .post-image__details .progress-bar {
    background-color: #285ab9; }
  .post-image__details .post-image__uploadingTxt {
    opacity: 0.6; }

.file-details__container {
  display: -webkit-flex;
  display: flex;
  background: white; }
  .file-details__container .file-details {
    height: 270px;
    padding: 14px;
    text-align: left;
    vertical-align: top;
    width: 320px; }
    .file-details__container .file-details .file-details__name {
      color: rgba(0, 0, 0, 0.9);
      font-size: 16px;
      margin: 5px 0;
      word-break: break-word; }
    .file-details__container .file-details .file-details__info {
      color: rgba(0, 0, 0, 0.5); }
  .file-details__container .file-details__preview {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    height: 270px;
    vertical-align: center;
    width: 320px; }
    .file-details__container .file-details__preview img {
      width: 80px; }
    .file-details__container .file-details__preview .file-details__preview-helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle; }

.file-playback__controls {
  -webkit-transition: opacity 0.6s;
  -webkit-transition-delay: 0s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s false false;
  bottom: 0;
  cursor: pointer;
  font-size: 22px;
  position: absolute;
  right: 5px;
  transition: opacity .6s;
  z-index: 2; }
  .file-playback__controls.stop {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }

.view-image__loading {
  background: black;
  min-height: 100px; }

.help-text {
  opacity: .5;
  display: block;
  margin: 10px 0 0; }
  .help-text.no-margin {
    margin: 0; }
  .help-text.no-margin--top {
    margin-top: 0; }
  .help-text ul,
  .help-text ol {
    padding-left: 23px; }
  .help-text .help-link {
    margin-right: 5px; }
  .help-text .btn {
    font-size: 13px; }
  .help-text.remove-filename {
    margin-bottom: 5px;
    position: relative;
    top: -2px; }

.form-control {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px; }
  .form-control:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .form-control.no-padding {
    line-height: 32px;
    padding: 0; }
  .form-control.no-resize {
    resize: none; }
  .form-control[disabled],
  .form-control [readonly] {
    background: rgba(255, 255, 255, 0.1);
    color: inherit;
    cursor: auto; }

fieldset[disabled] .form-control {
  background: rgba(255, 255, 255, 0.1);
  color: inherit;
  cursor: auto; }

.admin-textarea {
  resize: none; }

input[type='radio'], input[type='checkbox'] {
  font-size: 14px;
  margin-top: 3px; }

input::-webkit-file-upload-button {
  display: none; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: inherit;
  opacity: 0.5; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: inherit;
  opacity: 0.5; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: inherit;
  opacity: 0.5; }

:-moz-placeholder {
  /* Firefox 18- */
  color: inherit;
  opacity: 0.5; }

a {
  color: #2389d7;
  cursor: pointer;
  text-decoration: none;
  word-break: break-word; }
  a:hover, a:focus {
    color: #1c6dab; }

.text-danger {
  color: #bf5f5d; }
  .text-danger:hover, .text-danger:focus {
    color: #bf5f5d; }

.mention {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: #2389d7;
  color: white;
  padding-bottom: 2px;
  position: relative;
  z-index: 10; }

.badge {
  background-color: #777;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  min-width: 10px;
  padding: 3px 7px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap; }

.mentions__name {
  overflow: hidden;
  *zoom: 1;
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  padding: 6px 10px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 101;
  display: flex; }
  .mentions__name.no-flex {
    display: block; }
  .mentions__name .admin-setting-user--align {
    margin-right: 5px; }
  .mentions__name .icon {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    display: inline-block;
    margin: 0 7px 0 0;
    position: relative;
    text-align: center;
    top: 2px;
    vertical-align: top;
    width: 16px; }
    .mentions__name .icon.icon__globe svg {
      height: 14px;
      width: 14px; }
  .mentions__name .status {
    font-size: 15px;
    text-align: center;
    vertical-align: bottom;
    width: 20px; }
  .mentions__name .fa-user {
    position: relative;
    top: -1px; }
  .mentions__name .mention--align {
    overflow: hidden;
    *zoom: 1;
    text-overflow: ellipsis;
    margin-right: 5px; }

.mention__image {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  display: block;
  font-size: 15px;
  height: 16px;
  line-height: 16px;
  margin-right: 7px;
  margin-top: 3px;
  text-align: center;
  width: 16px; }
  .mention__image .mention--align {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.mention__fullname {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5; }

.mention--highlight {
  background-color: yellow; }

.mention__purpose {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  line-height: normal;
  margin-left: 5px;
  white-space: normal; }

.mention__channelname {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5; }

.app__body.browser--ie .modal .modal-dialog {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.app__body .modal-body {
  max-height: calc(90vh - 62px);
  overflow: auto;
  padding: 20px 15px; }

.app__body .edit-modal-body {
  overflow: visible; }
  .app__body .edit-modal-body .custom-textarea {
    height: auto;
    max-height: 60vh;
    min-height: 8em; }
    .app__body .edit-modal-body .custom-textarea.custom-textarea--preview {
      display: none; }
    .app__body .edit-modal-body .custom-textarea.textbox-preview-area {
      position: relative; }
  .app__body .edit-modal-body .suggestion-list__content {
    max-height: 150px; }
  .app__body .edit-modal-body .edit-post__actions {
    display: inline-block;
    height: 30px;
    line-height: 34px;
    position: absolute;
    right: 18px;
    text-align: center;
    top: 24px;
    width: 30px; }
    .app__body .edit-modal-body .edit-post__actions .icon {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5;
      cursor: pointer; }
    .app__body .edit-modal-body .edit-post__actions:hover .icon {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7; }
  .app__body .edit-modal-body .edit-post-footer {
    display: inline-block;
    font-size: 13px;
    position: relative; }
    .app__body .edit-modal-body .edit-post-footer .post-error {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=55);
      opacity: 0.55;
      font-size: .85em;
      font-weight: normal;
      margin-bottom: 0;
      position: relative;
      top: 3px; }

.app__body .row--invite .col-sm-6:first-child {
  padding-right: 0; }

.app__body .modal__hint {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: block;
  font-size: .9em;
  margin: 0 0 10px; }

.app__body .modal__error {
  color: #d64946;
  float: left;
  font-size: .95em;
  font-weight: normal;
  margin-top: 6px; }

.app__body .more-table {
  margin: 0;
  table-layout: fixed; }

.app__body .modal {
  color: rgba(0, 0, 0, 0.9);
  width: 100%; }
  .app__body .modal .channel-switch-modal .modal-header {
    background: transparent;
    border: none;
    min-height: 0;
    padding: 0; }
    .app__body .modal .channel-switch-modal .modal-header .close {
      top: 10px; }
  .app__body .modal .modal--overflow .modal-body {
    overflow: visible; }
  .app__body .modal textarea {
    overflow-x: hidden; }
  .app__body .modal.modal-image {
    overflow: hidden; }
    .app__body .modal.modal-image .modal-backdrop.in {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7; }
  .app__body .modal .custom-textarea {
    border-color: rgba(0, 0, 0, 0.15);
    color: inherit;
    padding-right: 30px; }
    .app__body .modal .custom-textarea:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: none; }
  .app__body .modal .btn {
    font-size: 13px; }
    .app__body .modal .btn.btn-default {
      background: transparent;
      border: none; }
  .app__body .modal .info__label {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    font-size: .9em;
    font-weight: 600;
    margin-bottom: 3px; }
  .app__body .modal .info__value {
    padding-left: 10px;
    word-break: break-word; }
  .app__body .modal .team-member-list {
    width: 100%; }
  .app__body .modal .remove__member {
    color: inherit;
    float: right;
    font-size: 20px;
    line-height: 0;
    padding: 6px; }
    .app__body .modal .remove__member:hover {
      color: #d64946; }
  .app__body .modal .modal-dialog {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%; }
    .app__body .modal .modal-dialog.modal-xl {
      width: 800px; }
  .app__body .modal .modal-push-down {
    margin-top: 60px; }
  .app__body .modal .modal-next-bar {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
  .app__body .modal .modal-header {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    overflow: hidden;
    *zoom: 1;
    background: #2389d7;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: white;
    min-height: 56px;
    padding: 14px 15px 11px; }
    .app__body .modal .modal-header .modal-title {
      color: whitesmoke;
      float: left;
      font-size: 17px;
      line-height: 27px;
      max-width: calc(100% - 80px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .app__body .modal .modal-header .modal-title .name {
        color: white;
        font-weight: 600; }
    .app__body .modal .modal-header .modal-action {
      margin: 0;
      padding: 0; }
    .app__body .modal .modal-header .close {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
      -webkit-transition: all 0.25s ease-in;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.25s ease-in;
      -o-transition: all 0.25s ease-in;
      transition: all 0.25s ease-in false;
      color: white;
      height: 30px;
      line-height: 30px;
      position: absolute;
      right: 10px;
      text-shadow: none;
      width: 30px;
      z-index: 5; }
      .app__body .modal .modal-header .close:hover {
        background: rgba(0, 0, 0, 0.1); }
      .app__body .modal .modal-header .close .sr-only {
        display: none; }
      .app__body .modal .modal-header .close span {
        font-family: 'Open Sans', sans-serif;
        line-height: 10px; }
    .app__body .modal .modal-header .btn.btn-primary {
      float: right;
      margin: -4px 33px 0 0;
      position: relative; }
      .app__body .modal .modal-header .btn.btn-primary i {
        margin-right: 5px; }
  .app__body .modal .no-channel-message {
    padding: 2em 1em;
    text-align: center; }
    .app__body .modal .no-channel-message .primary-message {
      font-size: 1.25em; }
    .app__body .modal .no-channel-message .secondary-message {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
      margin: 1em 0 0; }
  .app__body .modal .modal-content {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 2px; }
  .app__body .modal .modal-chevron-icon {
    font-size: 120%;
    top: 50%; }
  .app__body .modal .modal-prev-bar {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0; }
  .app__body .modal .modal-image {
    height: 100%;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 100%; }
    .app__body .modal .modal-image .modal-body {
      overflow: hidden;
      *zoom: 1;
      display: table;
      height: 100%;
      max-height: 100%;
      table-layout: fixed;
      width: 100%; }
    .app__body .modal .modal-image .modal-image__content {
      background: #F3F3F3;
      max-height: 91.5vh;
      max-width: 96vw;
      overflow-x: hidden;
      overflow-y: visible; }
    .app__body .modal .modal-image .post-code__container {
      overflow: auto; }
    .app__body .modal .modal-image .modal-image__wrapper {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      display: table-cell;
      max-width: 90%;
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: 100%; }
      .app__body .modal .modal-image .modal-image__wrapper:hover {
        -webkit-border-radius: 3px 3px 0 0;
        -moz-border-radius: 3px 3px 0 0;
        -ms-border-radius: 3px 3px 0 0;
        -o-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0; }
      .app__body .modal .modal-image .modal-image__wrapper.default {
        height: 80%;
        width: 100%; }
      .app__body .modal .modal-image .modal-image__wrapper .post-code {
        background: white; }
      .app__body .modal .modal-image .modal-image__wrapper audio,
      .app__body .modal .modal-image .modal-image__wrapper canvas,
      .app__body .modal .modal-image .modal-image__wrapper progress,
      .app__body .modal .modal-image .modal-image__wrapper video {
        display: block;
        height: auto;
        max-height: 85vh;
        width: auto;
        margin: 0 auto; }
      .app__body .modal .modal-image .modal-image__wrapper .modal-close {
        -webkit-transition: opacity 0.6s;
        -webkit-transition-delay: 0s;
        -moz-transition: opacity 0.6s;
        -o-transition: opacity 0.6s;
        transition: opacity 0.6s false false;
        -webkit-background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        -o-background-size: 100% 100%;
        background-size: 100% 100%;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        background-image: url(/static/files/c4c7df2f87354cc5b8bfc5eca5431e60.png);
        cursor: pointer;
        height: 37px;
        position: absolute;
        right: -13px;
        top: -13px;
        transition: opacity .6s;
        width: 37px;
        z-index: 9999; }
        .app__body .modal .modal-image .modal-image__wrapper .modal-close.modal-close--show {
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
          opacity: 1; }
      .app__body .modal .modal-image .modal-image__wrapper > div {
        display: inline-block;
        min-height: 100px;
        min-width: 300px;
        position: relative;
        max-width: 100%; }
      .app__body .modal .modal-image .modal-image__wrapper code {
        min-height: 130px;
        min-width: 330px; }
      .app__body .modal .modal-image .modal-image__wrapper pre,
      .app__body .modal .modal-image .modal-image__wrapper code {
        display: inline-block; }
      .app__body .modal .modal-image .modal-image__wrapper .post-body--code {
        max-height: calc(100vh - 80px);
        max-width: calc(100vw - 80px);
        overflow: auto; }
      .app__body .modal .modal-image .modal-image__wrapper img {
        max-height: 91vh;
        max-width: 100%; }
      .app__body .modal .modal-image .modal-image__wrapper .spinner .file__loading {
        left: 50%;
        margin-left: -16px;
        margin-top: -16px;
        position: absolute;
        top: 50%;
        z-index: 2; }
    .app__body .modal .modal-image .modal-content {
      background: rgba(0, 0, 0, 0);
      border: none;
      box-shadow: none;
      height: 100%;
      padding: 0;
      width: 100%; }
    .app__body .modal .modal-image .image-control {
      background: url(/static/files/84b76dee6b27b795e89e3649078a11c2.png) left no-repeat;
      float: left;
      height: 45px;
      margin-top: -23px;
      position: relative;
      top: 50%;
      width: 50px; }
      .app__body .modal .modal-image .image-control.image-next {
        background: url(/static/files/31f15875975aab69085470aabbfec802.png) left no-repeat;
        float: right; }
    .app__body .modal .modal-image .loader-image {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0; }
    .app__body .modal .modal-image .loader-percent {
      bottom: 0;
      color: rgba(255, 255, 255, 0.8);
      height: 20px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 55px; }
    .app__body .modal .modal-image .modal-button-bar {
      overflow: hidden;
      background-color: black;
      line-height: 40px;
      max-width: 100%;
      padding: 0 10px; }
      .app__body .modal .modal-image .modal-button-bar .image-links a,
      .app__body .modal .modal-image .modal-button-bar .image-links span {
        float: right; }
      .app__body .modal .modal-image .modal-button-bar .text {
        bottom: 0;
        color: white;
        margin-left: 5px;
        vertical-align: middle; }
      .app__body .modal .modal-image .modal-button-bar .public-link {
        margin-right: 5px; }
  .app__body .modal.more-channel__modal .modal-body {
    overflow-x: hidden;
    padding: 10px 0 15px; }
  .app__body .modal.more-channel__modal .channel-count {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    float: right;
    margin-top: 5px; }

.app__body .more-modal .Select-input > input {
  padding: 8px 0; }

.app__body .more-modal .status-wrapper {
  flex: 32px 0 0; }

.app__body .more-modal .more-modal__gm-icon {
  align-items: center;
  display: flex;
  flex: 32px 0 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center; }

.app__body .more-modal .user-list {
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative; }

.app__body .more-modal .modal-body {
  overflow-x: hidden;
  padding: 10px 0 15px; }

.app__body .more-modal .filter-row {
  margin: 5px 0 10px;
  width: 300px; }
  .app__body .more-modal .filter-row.filter-row--full {
    width: 100%; }

.app__body .more-modal .member-count {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  float: right;
  margin-top: 5px; }

.app__body .more-modal .member-show {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  float: right;
  margin-right: 3px;
  margin-top: 8px; }

.app__body .more-modal .member-select__container .member-count {
  margin: 12px 0 0 3px; }

.app__body .more-modal .member-select__container select {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  float: right;
  margin: 1px 5px 0 2px;
  width: auto; }

.app__body .more-modal .more-purpose {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7; }

.app__body .modal-tabs {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .app__body .modal-tabs .tab-content {
    flex: 1 1 auto;
    height: auto;
    flex-direction: column;
    display: flex; }
  .app__body .modal-tabs .tab-pane {
    flex: 1 1 auto;
    height: auto;
    display: none; }
    .app__body .modal-tabs .tab-pane.active {
      display: flex; }
  .app__body .modal-tabs .nav-tabs {
    margin: 0 0 16px; }
    .app__body .modal-tabs .nav-tabs > li {
      margin-right: 0px;
      width: 50%;
      text-align: center;
      border: none;
      border-bottom: 2px rgba(0, 0, 0, 0.15) solid; }
      .app__body .modal-tabs .nav-tabs > li.active {
        border-bottom: 2px #2389d7 solid; }
        .app__body .modal-tabs .nav-tabs > li.active a {
          opacity: 1; }
      .app__body .modal-tabs .nav-tabs > li > a {
        opacity: .5;
        color: inherit;
        height: 40px;
        padding-bottom: 12px;
        outline: none;
        border: none;
        display: flex;
        font-weight: 900;
        justify-content: center;
        align-items: center; }
        .app__body .modal-tabs .nav-tabs > li > a:active {
          opacity: 1; }

.more-modal__button .btn {
  font-size: .9em;
  font-weight: 600;
  height: 39px;
  width: 100%; }

.more-modal__list {
  display: flex;
  flex-direction: column; }
  .browser--ie .more-modal__list {
    flex: 20; }
  .more-modal__list > div {
    min-height: 100%;
    overflow: auto; }
  .popover .more-modal__list {
    font-size: .95em; }
    .popover .more-modal__list .more-modal__row {
      padding: 5px 10px; }
  .more-modal__list .more-modal__image {
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 2px;
    max-width: none; }
  .more-modal__list .more-modal__details {
    align-self: center;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    padding-left: 10px;
    text-overflow: ellipsis; }
  .more-modal__list .more-modal__actions {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 5px 0;
    padding-left: 20px; }
  .more-modal__list .more-modal__name {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .more-modal__list .more-modal__description {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .more-modal__list .more-modal__description:empty {
      display: none; }
  .more-modal__list .more-modal__row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 10px 15px;
    align-items: center; }
    .more-modal__list .more-modal__row.clickable {
      cursor: pointer; }
    .more-modal__list .more-modal__row:hover .more-modal__actions--round {
      opacity: .5; }
  .more-modal__list .more-modal__placeholder-row {
    padding: 10px 15px; }
  .more-modal__list .more-modal__actions--round {
    height: 32px;
    line-height: 32px;
    opacity: 0;
    text-align: center;
    width: 32px; }
  .more-modal__list .more-modal__right {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .more-modal__list .more-modal__right .control-label {
      font-weight: normal;
      padding: 5px 0 0 5px; }
  .more-modal__list .more-modal__top {
    display: flex; }
  .more-modal__list .more-modal__bottom > .has-error {
    padding-left: 5px; }
  .more-modal__list p {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    font-size: .9em;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis; }

.member-select__container {
  position: absolute;
  right: 15px;
  top: 15px; }

.filtered-user-list {
  display: flex;
  flex-direction: column;
  height: calc(90vh - 120px);
  width: 100%; }
  .filtered-user-list > div {
    flex: 1 1 auto; }
  .filtered-user-list .more-modal__list {
    flex-grow: 500;
    height: 1px; }
  .filtered-user-list .filter-button .btn {
    height: 34px; }
  .filtered-user-list .filter-controls {
    padding: 1em 1.5em 0; }
    .filtered-user-list .filter-controls .filter-control__next {
      float: right; }
  .filtered-user-list .filter-button {
    margin-left: 0;
    padding-left: 0; }

.team-selector-modal .more-modal__row {
  border: 0px; }
  .team-selector-modal .more-modal__row:nth-child(odd) {
    background: rgba(0, 0, 0, 0.03); }
  .team-selector-modal .more-modal__row:hover {
    background: rgba(0, 0, 0, 0.05); }

.modal-invite-member .btn:hover {
  text-decoration: underline; }

.team-selector-modal .modal-header {
  background: black;
  color: white; }
  .team-selector-modal .modal-header .close {
    color: white;
    opacity: 1; }
    .team-selector-modal .modal-header .close:hover {
      opacity: 1; }

.team-selector-modal .modal-body {
  padding: 15px 0; }
  .team-selector-modal .modal-body .filter-row {
    padding: 0 15px; }
  .team-selector-modal .modal-body .more-modal__row--selected {
    background-color: #cfe6f7; }
    .team-selector-modal .modal-body .more-modal__row--selected .more-modal__actions--round {
      opacity: .5; }
  .team-selector-modal .modal-body .no-channel-message {
    width: 100%;
    text-align: center;
    font-size: 1.25em;
    color: rgba(0, 0, 0, 0.3);
    margin-top: 40px; }
  .team-selector-modal .modal-body .filter-row--full {
    flex-grow: 0; }

.team-selector-modal .filter-control {
  color: #2389d7;
  background: none;
  border: none; }
  .team-selector-modal .filter-control:hover, .team-selector-modal .filter-control:focus, .team-selector-modal .filter-control:active {
    color: #2389d7;
    background: none;
    border: none;
    box-shadow: none; }

.more-public-direct-channels .filtered-user-list {
  height: calc(90vh - 180px); }

.multi-select__container {
  display: table;
  padding: 0 15px;
  width: 100%; }
  .multi-select__container .btn {
    display: table-cell;
    height: 36px;
    min-width: 60px;
    vertical-align: top; }

.multi-select__help {
  padding: 10px 15px 0;
  display: flex;
  justify-content: space-between; }
  .multi-select__help:empty {
    display: none; }
  .multi-select__help > div:not(.multi-select__note),
  .multi-select__help > span {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6; }

.multi-select__note {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  display: table;
  padding: 8px 13px;
  width: 100%; }
  .multi-select__note > div {
    display: table-cell; }
    .multi-select__note > div.note__icon {
      width: 15px; }

.prompt {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 50px auto;
  max-width: 90%;
  padding: 1em 2em 0;
  width: 600px; }
  .prompt .prompt__heading {
    display: table;
    font-size: 1.28571em;
    line-height: normal;
    margin: 1em 0;
    table-layout: fixed;
    width: 100%; }
    .prompt .prompt__heading > div {
      display: table-cell;
      vertical-align: top; }
      .prompt .prompt__heading > div:first-child {
        width: 70px; }
    .prompt .prompt__heading img {
      margin-right: 15px; }
  .prompt .prompt__allow {
    font-size: 1.42857em;
    margin: 1em 0; }
  .prompt .prompt__buttons {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding: 1.5em 0;
    text-align: right; }
  .prompt .prompt__error {
    display: inline-block; }

.popover {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  color: #404040;
  font-family: inherit;
  padding: 0; }
  .popover.bottom > .arrow, .popover.right > .arrow, .popover.top > .arrow, .popover.left > .arrow {
    display: none; }
    .popover.bottom > .arrow:after, .popover.right > .arrow:after, .popover.top > .arrow:after, .popover.left > .arrow:after {
      border-color: transparent; }
  .popover.bottom {
    margin-top: 10px; }
  .popover ul + p,
  .popover ol + p {
    margin-top: .6em; }
  .popover p + ul,
  .popover p + ol {
    margin-top: .6em; }
  .popover .popover-title {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.05);
    max-width: 100%;
    overflow: hidden;
    padding: 8px 10px;
    text-overflow: ellipsis; }
  .popover hr.divider {
    margin: 8px 0; }
  .popover hr.divider--expanded {
    margin: 8px -15px; }
  .popover .popover-content {
    word-break: break-word; }
    .popover .popover-content p:last-child {
      margin-bottom: 5px; }
  .popover .popover__row {
    margin: 0 -15px 0;
    padding: 9px 15px 0; }
    .popover .popover__row.first {
      border-top: 1px solid transparent;
      margin-top: 4px; }
    .popover .popover__row > a > i {
      margin-right: 5px; }

.channel-header__info .popover-content {
  max-height: 250px;
  overflow: auto; }

.user-popover {
  cursor: pointer;
  display: inline-block; }

.code-popover .popover-content {
  padding: 5px; }

.user-popover__username,
.user-popover__username > a {
  display: inline-block;
  max-width: 150px;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

#user-profile-popover .user-popover__username:only-child,
#user-profile-popover .user-popover__username:only-child > a {
  max-width: 100%; }

.user-popover__role {
  display: inline-block;
  float: right;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.8em;
  color: white; }

.user-popover__image {
  -webkit-border-radius: 128px;
  -moz-border-radius: 128px;
  -ms-border-radius: 128px;
  -o-border-radius: 128px;
  border-radius: 128px;
  margin: 5px auto;
  display: block; }

.user-popover__email {
  display: block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis; }

.search-help-popover {
  -webkit-transition: opacity 0.3s ease-in;
  -webkit-transition-delay: 0s;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in false;
  font-size: 0.92857em;
  max-width: 350px;
  top: 36px;
  visibility: hidden;
  width: 100%; }
  .search-help-popover.autocomplete {
    display: block; }
    .search-help-popover.autocomplete .popover-content {
      padding: 10px;
      position: relative;
      -webkit-overflow-scrolling: touch; }
  .search-help-popover .search-autocomplete__divider {
    line-height: 21px;
    margin: 10px 0 5px;
    position: relative; }
    .search-help-popover .search-autocomplete__divider:first-child {
      margin-top: 5px; }
    .search-help-popover .search-autocomplete__divider > span {
      background: white;
      display: inline-block;
      padding-right: 10px;
      position: relative;
      z-index: 5; }
    .search-help-popover .search-autocomplete__divider:before {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
      opacity: 0.2;
      background: rgba(0, 0, 0, 0.5);
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      top: 10px;
      width: 100%; }
  .search-help-popover .search-autocomplete__item {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
    margin: 3px 0 0 5px;
    overflow: hidden;
    padding: 6px 8px;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .search-help-popover .search-autocomplete__item:hover {
      background: rgba(0, 0, 0, 0.1); }
    .search-help-popover .search-autocomplete__item.selected {
      background: rgba(0, 0, 0, 0.2); }
    .search-help-popover .search-autocomplete__item .mention--align {
      display: inline; }
    .search-help-popover .search-autocomplete__item .fa {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5;
      margin-right: 5px; }
    .search-help-popover .search-autocomplete__item .profile-img {
      height: 16px;
      margin-right: 6px;
      margin-top: -1px;
      width: 16px; }
  .search-help-popover.bottom > .arrow {
    border-width: 9px;
    left: 30px;
    top: -18px; }
  .search-help-popover .popover-content {
    max-height: 500px;
    overflow: auto;
    padding: 15px; }
  .search-help-popover h4 {
    font-size: 1.4rem;
    margin-top: 0; }
  .search-help-popover ul {
    padding-left: 17px; }
    .search-help-popover ul span {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8; }
    .search-help-popover ul strong,
    .search-help-popover ul b {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  .search-help-popover .tooltip-inner {
    max-width: 100%; }
  .search-help-popover.visible {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    visibility: visible; }

.member-list__popover {
  margin-left: -95px;
  max-width: initial; }
  .member-list__popover .more-modal__body {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    max-height: 275px;
    overflow: auto;
    position: relative; }
  .member-list__popover .more-modal__header {
    padding: 14px 20px; }
    .member-list__popover .more-modal__header .icon {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
      margin: 0 38px 0 24px;
      position: relative;
      top: 2px; }
    .member-list__popover .more-modal__header span {
      font-weight: 600; }
  .member-list__popover .more-modal__button .btn {
    height: 42px; }
  .member-list__popover .more-modal__list .more-modal__row {
    border: none;
    cursor: pointer;
    height: 50px;
    margin: 1px 0;
    overflow: hidden;
    padding-left: 20px; }
    .member-list__popover .more-modal__list .more-modal__row:hover {
      border-left: 3px solid transparent;
      padding-left: 17px; }
      .member-list__popover .more-modal__list .more-modal__row:hover .more-modal__actions {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      .member-list__popover .more-modal__list .more-modal__row:hover .more-modal__name {
        font-weight: 600; }
    .member-list__popover .more-modal__list .more-modal__row.more-modal__row--button {
      border-left: none;
      height: 40px;
      position: relative; }
      .member-list__popover .more-modal__list .more-modal__row.more-modal__row--button button {
        font-weight: 500;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
  .member-list__popover .more-modal__list .more-modal__name {
    font-size: 13.5px;
    font-weight: normal; }
  .member-list__popover .more-modal__list .more-modal__image {
    margin: 0;
    position: relative;
    top: -1px; }
  .member-list__popover .more-modal__list .more-modal__details {
    line-height: 39px;
    padding-left: 30px; }
  .member-list__popover .more-modal__list .more-modal__actions {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    line-height: 42px;
    margin: 0; }
    .member-list__popover .more-modal__list .more-modal__actions .icon {
      position: relative;
      top: 2px; }
  .member-list__popover .popover-content {
    max-height: 380px;
    padding: 0;
    position: relative;
    width: 260px; }
    .member-list__popover .popover-content .text-nowrap {
      font-size: 13px;
      line-height: 26px;
      overflow: hidden;
      padding: 6px 10px;
      width: 100%; }
    .member-list__popover .popover-content .more__name {
      margin-left: 6px;
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis; }

.channel_search {
  display: flex; }
  .channel_search .search_input {
    flex: 1;
    margin-left: 16px;
    margin-right: 16px; }
  .channel_search .create_button {
    margin-right: 16px; }

.save-button .icon {
  margin-right: 10px; }

::-webkit-scrollbar {
  height: 8px;
  width: 8px; }

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); }

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2); }

body.app__body {
  scrollbar-3dlight-color: #7D7E94;
  scrollbar-arrow-color: #C1C1D1;
  scrollbar-darkshadow-color: #2D2C4D;
  scrollbar-face-color: rgba(0, 0, 0, 0.1);
  scrollbar-highlight-color: #7D7E94;
  scrollbar-shadow-color: #2D2C4D;
  scrollbar-track-color: rgba(0, 0, 0, 0.1); }

body .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  width: 6px !important; }

body .ps:hover > .ps__scrollbar-y-rail:hover {
  background: transparent; }

.scrollbar--horizontal,
.scrollbar--vertical {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5); }

.scrollbar--view {
  -ms-overflow-style: none; }
  .browser--ie .scrollbar--view {
    margin: 0 !important;
    overflow-x: hidden !important; }

.ps-container:hover .ps-scrollbar-x-rail,
.ps-container:hover .ps-scrollbar-y-rail {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: 0.3; }

.ps-container > .ps-scrollbar-y-rail {
  width: 10px; }
  .ps-container > .ps-scrollbar-y-rail:hover .ps-scrollbar-y {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    width: 6px; }

.sidebar--right .search-bar__container .channel-header__links {
  line-height: 25px; }

.icon--rotate {
  -webkit-animation: spin 0.7s infinite linear;
  -moz-animation: spin 0.7s infinite linear;
  -ms-animation: spin 0.7s infinite linear;
  -o-animation: spin 0.7s infinite linear;
  animation: spin 0.7s infinite linear; }

.search-item-snippet {
  overflow: hidden;
  *zoom: 1;
  text-overflow: ellipsis; }
  .search-item-snippet ul,
  .search-item-snippet ol {
    padding-left: 20px; }

.search__icon {
  left: 9px;
  position: absolute;
  top: 6px; }
  .search__icon svg {
    -webkit-transition: all 0.3s ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out false; }

.sidebar__search-clear {
  -webkit-transition: all 0.3s ease-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out false;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  cursor: pointer;
  margin-right: 20px;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 22px;
  margin: 0;
  font-size: 20px; }
  .sidebar__search-clear:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .sidebar__search-clear.visible {
    visibility: visible; }

.search-bar__container {
  padding-left: 8px; }

.search__form {
  padding: 0 30px;
  position: relative; }
  .search-bar__container .search__form {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: all 0.3s ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out false;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 16px 0 0;
    width: 180px; }
  .sidebar--right .search__form {
    width: 100%; }
  .search__form .search-bar {
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 14px;
    height: 28px;
    outline: none;
    padding-bottom: 2px;
    width: 100%; }
  .search__form .fa-spin {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    font-size: 1em;
    position: absolute;
    right: 27px;
    top: 27px; }
    .search-bar__container .search__form .fa-spin {
      right: 7px;
      top: 7px; }

.search-items-container {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 56px);
  padding-top: 10px;
  position: relative; }

.search-items-container div.loading {
  text-align: center; }

.search-items-container img {
  display: inline-block; }

.search-results-header {
  border-bottom: 1px solid #ddd;
  color: #999999;
  font-size: 1em;
  font-weight: 400;
  height: 44px;
  line-height: 44px;
  padding: 0 10px;
  text-transform: uppercase; }

.search-item__container .post {
  margin: 0;
  padding: 0 1em 1em; }
  .search-item__container .post:first-child {
    border: none; }
  .search-item__container .post:hover .post__header {
    padding-right: 105px; }
  .search-item__container .post .post__header {
    padding-right: 70px; }
  .search-item__container .post .search-channel__name {
    font-weight: 600;
    margin: 0 0 10px; }
  .search-item__container .post .search-channel__archived {
    opacity: 0.5;
    float: right; }

.col__controls {
  font-size: 13px;
  position: absolute;
  right: 0;
  top: 0; }
  .col__controls a {
    vertical-align: top; }
  .col__controls .search-item__jump {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    font-size: 13px;
    position: relative;
    padding: 2px 7px 0;
    text-decoration: none; }

.search-item-time {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  font-size: .9em; }

.search-results-none {
  padding: 10px; }

.search-highlight {
  background-color: yellow; }

.status-wrapper {
  display: inline-block;
  height: 32px;
  position: relative;
  align-self: center; }
  .status-wrapper .status {
    border-radius: 100px;
    bottom: -4px;
    height: 15px;
    line-height: 0;
    margin: 0;
    position: absolute;
    right: -3px;
    top: auto;
    width: 15px; }
    .modal .status-wrapper .status {
      bottom: -6px; }
    .status-wrapper .status.status-edit {
      text-align: center; }
      .status-wrapper .status.status-edit i {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        font-size: 10px;
        position: relative;
        top: 4px; }
    .status-wrapper .status svg {
      max-height: 11px;
      position: relative;
      top: 2px; }

.status-selector:hover > .status {
  display: none; }

.status-selector > .status {
  display: inherit; }

.status-selector > .status-edit {
  display: none; }

.status-selector:hover > .status-edit {
  display: inherit; }

.status {
  display: inline-block;
  margin: 0 7px 0 1px;
  position: relative;
  top: 2px;
  width: 15px; }
  .status.status--group {
    border-radius: 2px;
    font-size: 10px;
    font-weight: 600;
    height: 15px;
    left: 1px;
    line-height: 14px;
    margin: 0 9px 0 -1px;
    text-align: center;
    width: 15px; }
    .mentions__name .status.status--group {
      height: 18px;
      left: 0;
      line-height: 18px;
      margin-left: 0;
      margin-right: 6px;
      text-align: center;
      width: 17px; }
  .status .dnd--icon {
    fill: #f74343; }
  .status svg {
    max-height: 14px;
    backface-visibility: hidden; }
  .status i,
  .status path,
  .status ellipse {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5; }
    .status i.online--icon, .status i.away--icon, .status i.dnd--icon,
    .status path.online--icon,
    .status path.away--icon,
    .status path.dnd--icon,
    .status ellipse.online--icon,
    .status ellipse.away--icon,
    .status ellipse.dnd--icon {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  .status .fa-lock {
    margin-left: 1px; }
  .status .fa-globe {
    position: relative;
    top: -1px; }

#status-dropdown {
  cursor: pointer; }

.status-dropdown-extra {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  display: block;
  font-size: 10px; }

.suggestion-list {
  width: 100%;
  z-index: 100; }
  .suggestion-list .suggestion-list__item:hover {
    background: rgba(0, 0, 0, 0.1); }
  .suggestion-list .badge {
    position: absolute;
    top: 8px;
    right: 10px;
    color: #3d3c40;
    background: #CECECF;
    font-size: 10px; }

.suggestion-list--top {
  bottom: 100%;
  position: absolute; }

.suggestion-list--bottom {
  height: 0;
  position: relative; }

.suggestion-list__content {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  border: 1px solid #ddd;
  max-height: 292px;
  overflow-x: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  padding-bottom: 5px;
  width: 100%; }
  .suggestion-list__content .command {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    font-size: .95em;
    line-height: 24px;
    padding: 5px 10px 8px;
    position: relative;
    width: 100%;
    z-index: 101; }
    .suggestion-list__content .command .command__desc {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5;
      line-height: normal;
      margin-left: 5px; }

.suggestion-list__content--top {
  bottom: 0;
  position: absolute; }

.suggestion-list--bottom {
  position: relative; }

.suggestion-loader {
  margin: 6px 11px; }

.suggestion-list__divider {
  line-height: 21px;
  margin: 5px 0 5px 5px;
  position: relative; }
  .suggestion-list__divider:first-child {
    margin-top: 5px; }
  .suggestion-list__divider > span {
    display: inline-block;
    font-size: .9em;
    padding: 0 10px 0 5px;
    position: relative;
    z-index: 5; }
  .suggestion-list__divider:before {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 11px;
    width: 100%; }

.suggestion-list__no-results {
  line-height: 21px;
  margin: 7px 10px 2px;
  position: relative; }
  .suggestion-list__no-results > span {
    display: inline-block;
    font-size: .9em;
    padding: 0 10px 0 5px;
    position: relative;
    z-index: 5; }

.tooltip {
  word-wrap: break-word;
  max-width: 220px; }
  .tooltip .tooltip-inner {
    max-width: 100%;
    font-size: 13px;
    font-weight: 500;
    padding: 3px 10px 4px;
    word-break: break-word; }
  .tooltip.text-nowrap .tooltip-inner {
    white-space: nowrap; }

.tip-backdrop {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999; }

.tip-overlay {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background-color: white;
  max-width: 90%;
  padding: 20px;
  position: absolute;
  width: 350px;
  z-index: 1000; }
  .tip-overlay .arrow {
    border-color: transparent;
    border-style: solid;
    border-width: 10px;
    display: block;
    height: 0;
    position: absolute;
    width: 0; }
  .tip-overlay.tip-overlay--sidebar {
    margin: 50px 0 0 10px;
    max-width: 75%;
    min-height: 330px; }
    .tip-overlay.tip-overlay--sidebar .tutorial__footer {
      bottom: 20px;
      left: 0;
      padding: 0 20px;
      position: absolute;
      width: 100%; }
    .tip-overlay.tip-overlay--sidebar .arrow {
      border-left-width: 0;
      border-right-color: white;
      left: -10px;
      margin-top: -10px;
      top: 80px; }
  .tip-overlay.tip-overlay--header--left {
    margin: 10px 0 0 10px; }
    .tip-overlay.tip-overlay--header--left .arrow {
      border-left-width: 0;
      border-right-color: white;
      left: -10px;
      top: 15px; }
  .tip-overlay.tip-overlay--header--bottom {
    margin-top: -10px; }
    .tip-overlay.tip-overlay--header--bottom .arrow {
      border-bottom-color: white;
      border-top-width: 0;
      left: 50%;
      margin-left: -10px;
      top: -10px; }
  .tip-overlay.tip-overlay--chat {
    margin-top: -10px; }
    .tip-overlay.tip-overlay--chat .arrow {
      border-bottom-width: 0;
      border-top-color: white;
      bottom: -10px;
      left: 50%;
      margin-left: -10px; }
  .tip-overlay h4 {
    font-size: 1.14286em;
    font-weight: 600;
    margin: 5px 0 15px; }
  .tip-overlay p {
    font-size: 13px;
    line-height: 1.6; }
    .tip-overlay p strong {
      font-weight: 600; }
  .tip-overlay .btn {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    color: white;
    margin: 10px 0; }
    .tip-overlay .btn:hover, .tip-overlay .btn:active, .tip-overlay .btn:focus {
      background: rgba(0, 0, 0, 0.4);
      border: none;
      color: white; }
  .tip-overlay .tip-opt {
    font-size: 12px; }
    .tip-overlay .tip-opt span {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
      opacity: 0.9; }
  .tip-overlay .tutorial__circles {
    margin: 1.5em 0 -1.7em -4px; }
    .tip-overlay .tutorial__circles .circle {
      height: 7px;
      margin: 0 4px;
      width: 7px; }

.tip-button {
  cursor: pointer;
  position: relative;
  right: -10px;
  top: -10px;
  z-index: 998; }

.tip-div {
  position: absolute;
  right: 0;
  top: 0; }
  .tip-div.tip-overlay--header {
    right: 2px;
    top: 21px; }
    .tip-div.tip-overlay--header .tip-button {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8; }
  .tip-div.tip-overlay--sidebar {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    left: 0;
    top: -9px; }

.tutorial-steps__container {
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%; }
  .tutorial-steps__container .tutorial__content {
    display: table-cell;
    vertical-align: middle; }
    .tutorial-steps__container .tutorial__content .tutorial__steps {
      display: inline-block;
      margin-bottom: 50px;
      max-width: 310px;
      min-height: 480px;
      position: relative;
      text-align: left; }
  .tutorial-steps__container .tutorial__footer {
    bottom: 0;
    position: absolute; }
  .tutorial-steps__container h1 {
    font-size: 2.85714em;
    font-weight: 600;
    margin: -20px 0 30px; }
  .tutorial-steps__container h3 {
    font-size: 1.71429em;
    font-weight: 600;
    margin-bottom: 30px; }
  .tutorial-steps__container .tutorial__circles {
    bottom: 25px;
    position: absolute; }
  .tutorial-steps__container .tutorial-skip {
    margin-left: 13px; }

.tutorial__circles {
  margin: 2em 0; }
  .tutorial__circles .circle {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    border-radius: 9px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2;
    background: black;
    display: inline-block;
    height: 9px;
    margin: 0 5px;
    width: 9px; }
    .tutorial__circles .circle.active {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
      background: #2389d7; }

.tutorial__app-icons {
  margin: 2em 0; }

.post .video-div {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 8px;
  max-width: 480px;
  overflow: hidden;
  position: relative; }
  .post .video-div iframe,
  .post .video-div video {
    display: block; }
  .post .video-div .video-thumbnail {
    height: auto;
    max-width: 100%; }
  .post .video-div .video-thumbnail__error {
    height: 100%;
    line-height: 2;
    padding: 110px 0;
    text-align: center;
    width: 100%; }
    .post .video-div .video-thumbnail__error .fa {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5; }
    .post .video-div .video-thumbnail__error div {
      font-size: 1.2em; }
  .post .video-div .block {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    height: 150px;
    left: 50%;
    margin: -75px 0 0 -100px;
    position: absolute;
    top: 50%;
    width: 200px; }

.post .video-type {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  font-size: 15px;
  margin: 0;
  padding: 0; }

.post .video-title {
  font-size: 15px;
  margin-top: 3px; }

.post .play-button {
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  border-radius: 14px;
  border: 4px solid rgba(255, 255, 255, 0.4);
  height: 100px;
  position: absolute;
  right: 51px;
  top: 26px;
  width: 100px; }
  .post .play-button span {
    border-bottom: 36px solid transparent;
    border-left: 60px solid rgba(255, 255, 255, 0.4);
    border-top: 36px solid transparent;
    height: 0;
    left: 20px;
    position: absolute;
    top: 10px;
    width: 0; }

.post .img-div {
  -webkit-transition: all 0.1s linear;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear false;
  -moz-force-broken-image-icon: 1;
  border-radius: 5px;
  margin-bottom: 8px;
  max-height: 500px;
  max-width: 450px;
  position: relative; }
  .post .img-div:hover {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1); }
  .post .img-div.placeholder {
    height: 500px; }
  .sidebar--right .post .img-div {
    max-width: 100%; }

.post .video-loading {
  height: 373px; }

.color-input {
  position: relative; }

.color-icon {
  display: inline-block;
  width: 16px;
  height: 16px; }

.color-popover {
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 8px;
  z-index: 12; }

.system-notice {
  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07), 0 14px 20px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07), 0 14px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07), 0 14px 20px rgba(0, 0, 0, 0.07);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: 12px;
  padding: 18px 20px 0;
  position: absolute;
  right: 12px;
  width: 280px;
  z-index: 9999; }

.system-notice__header {
  align-items: flex-start;
  display: flex; }

.system-notice__logo {
  height: 36px; }
  .system-notice__logo svg {
    fill: #166de0;
    height: 36px;
    width: 36px; }

.system-notice__title {
  white-space: nowrap;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  flex: 10 1 auto;
  line-height: 16px;
  overflow: hidden;
  padding: 3px 0 0 8px;
  text-overflow: ellipsis; }

.system-notice__info {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  font-size: 12px;
  margin-bottom: 12px; }
  .system-notice__info .fa {
    margin-right: 4px; }

.system-notice__body {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  line-height: 16px;
  padding: 18px 0 16px; }

.system-notice__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  margin: 0 -20px; }
  .system-notice__footer .btn {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis; }
    .system-notice__footer .btn:hover {
      background: #166de0;
      color: white; }
    .system-notice__footer .btn:first-child {
      -webkit-border-radius: 0 0 0 4px;
      -moz-border-radius: 0 0 0 4px;
      -ms-border-radius: 0 0 0 4px;
      -o-border-radius: 0 0 0 4px;
      border-radius: 0 0 0 4px; }
    .system-notice__footer .btn:last-child {
      -webkit-border-radius: 0 0 4px 0;
      -moz-border-radius: 0 0 4px 0;
      -ms-border-radius: 0 0 4px 0;
      -o-border-radius: 0 0 4px 0;
      border-radius: 0 0 4px 0;
      border-left: 1px solid rgba(0, 0, 0, 0.2); }

@keyframes rowhighlight {
  0% {
    background-color: rgba(0, 0, 0, 0); }
  20% {
    background-color: beige; }
  80% {
    background-color: beige; }
  100% {
    background-color: rgba(0, 0, 0, 0); } }

.permissions-block {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  *zoom: 1;
  font-size: .95em;
  margin: 2em 0;
  padding: 0; }
  .permissions-block .more-schemes {
    width: 100%;
    text-align: center;
    padding: 10px;
    text-decoration: none; }
  .permissions-block .header {
    cursor: pointer;
    align-items: center;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px; }
    .permissions-block .header h3 {
      font-size: 18px;
      font-weight: normal;
      margin: 0;
      padding: 0; }
    .permissions-block .header .button {
      self-align: end;
      margin-left: 10px; }
      .permissions-block .header .button svg {
        transform: rotate(0deg);
        transition: transform 300ms; }
  .permissions-block.closed .header .button svg {
    transform: rotate(90deg);
    transition: transform 300ms; }
  .permissions-block.closed .permissions-tree {
    max-height: 0;
    overflow-y: hidden;
    padding: 0 1.5rem; }
  .permissions-block .no-team-schemes {
    margin: 20px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.3);
    text-align: center; }
  .permissions-block .team-override-unavailable {
    padding: 20px;
    color: #3333337d;
    opacity: 50%; }
    .permissions-block .team-override-unavailable .team-override-unavailable__inner {
      padding: 20px;
      background-color: white; }
  .permissions-block .teams-list {
    padding: 20px; }
    .permissions-block .teams-list .no-team-schemes {
      margin: 0; }
    .permissions-block .teams-list .team {
      display: flex;
      padding: 10px; }
      .permissions-block .teams-list .team:nth-child(even) {
        background: rgba(255, 255, 255, 0.5); }
      .permissions-block .teams-list .team .remove {
        flex-grow: 0; }

.permissions-tree {
  -webkit-transition: all 0.4s ease-in-out;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  max-height: 2000px;
  padding: .8em 1.5rem; }
  .permissions-tree .permissions-tree--header {
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
    font-weight: bold;
    padding: .5em 0; }
    .permissions-tree .permissions-tree--header .permission-name {
      margin-left: 16px;
      min-width: 300px; }
    .permissions-tree .permissions-tree--header .permission-description {
      color: black;
      display: block;
      width: 100%;
      opacity: 1; }
  .permissions-tree .permissions-tree--body {
    background-color: whitesmoke;
    background-image: linear-gradient(transparent 50%, rgba(255, 255, 255, 0.5) 50%);
    background-size: 68px 68px;
    cursor: pointer; }
  .permissions-tree .permission-arrow {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 5px 7px 4px 6px;
    min-height: 10px;
    min-width: 10px;
    transition: transform 300ms; }
    .permissions-tree .permission-arrow.open {
      transform: rotate(90deg);
      transform-origin: 55% 50%;
      transition: transform 300ms;
      margin-top: 6px; }
  .permissions-tree .permission-check {
    align-items: center;
    border-radius: 3px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-around;
    margin-right: 5px;
    min-height: 18px;
    min-width: 18px; }
    .permissions-tree .permission-check.checked, .permissions-tree .permission-check.intermediate {
      border: 0; }
      .permissions-tree .permission-check.checked svg, .permissions-tree .permission-check.intermediate svg {
        background: white;
        fill: #2389d7; }
  .permissions-tree .permission-name {
    display: inline-block;
    min-width: 300px; }
  .permissions-tree .permission-description {
    align-items: center;
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%;
    opacity: .65; }
    .permissions-tree .permission-description span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%; }
  .permissions-tree .permission-group .permission-group-row .permission-name {
    min-width: 268px; }
  .permissions-tree .permission-group .permission-group-permissions {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms; }
    .permissions-tree .permission-group .permission-group-permissions.open {
      max-height: 400px;
      transition: max-height 300ms; }
    .permissions-tree .permission-group .permission-group-permissions .permission-row {
      padding: 0 0 0 48px; }
      .permissions-tree .permission-group .permission-group-permissions .permission-row .permission-name {
        min-width: 242px; }
    .permissions-tree .permission-group .permission-group-permissions .permission-group-row {
      padding: 0 0 0 25px; }
      .permissions-tree .permission-group .permission-group-permissions .permission-group-row.combined {
        padding: 0 0 0 48px; }
      .permissions-tree .permission-group .permission-group-permissions .permission-group-row .permission-name {
        min-width: 244px; }
    .permissions-tree .permission-group .permission-group-permissions .permission-group-permissions .permission-row {
      padding: 0 0 0 78px; }
      .permissions-tree .permission-group .permission-group-permissions .permission-group-permissions .permission-row .permission-name {
        min-width: 213px; }
    .permissions-tree .permission-group .permission-group-permissions .permission-group-permissions .permission-group-row {
      padding: 0 0 0 60px; }
      .permissions-tree .permission-group .permission-group-permissions .permission-group-permissions .permission-group-row.combined {
        padding: 0 0 0 78px; }
      .permissions-tree .permission-group .permission-group-permissions .permission-group-permissions .permission-group-row .permission-name {
        min-width: 192px; }
  .permissions-tree .permission-row,
  .permissions-tree .permission-group-row {
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: row;
    height: 34px; }
    .permissions-tree .permission-row:hover,
    .permissions-tree .permission-group-row:hover {
      background-color: #cfe6f7;
      border-left: 2px solid #2389d7; }
      .permissions-tree .permission-row:hover .permission-description,
      .permissions-tree .permission-group-row:hover .permission-description {
        opacity: 1; }
    .permissions-tree .permission-row.selected,
    .permissions-tree .permission-group-row.selected {
      animation-duration: 3s;
      animation-name: rowhighlight; }
    .permissions-tree .permission-row.read-only .permission-check,
    .permissions-tree .permission-group-row.read-only .permission-check {
      background-color: rgba(0, 0, 0, 0.3);
      border: none; }
      .permissions-tree .permission-row.read-only .permission-check.checked svg, .permissions-tree .permission-row.read-only .permission-check.intermediate svg,
      .permissions-tree .permission-group-row.read-only .permission-check.checked svg,
      .permissions-tree .permission-group-row.read-only .permission-check.intermediate svg {
        background: white;
        fill: rgba(0, 0, 0, 0.3); }

.permissions-scheme-summary {
  margin: 0 20px;
  padding: 10px 20px 0 20px;
  border-left: 2px solid rgba(0, 0, 0, 0);
  cursor: pointer; }
  .permissions-scheme-summary:nth-child(2) {
    margin-top: 20px; }
  .permissions-scheme-summary:last-child {
    margin-bottom: 20px; }
  .permissions-scheme-summary:nth-child(odd) {
    background: rgba(255, 255, 255, 0.5); }
  .permissions-scheme-summary:hover {
    background-color: #cfe6f7;
    border-left: 2px solid #2389d7; }
  .permissions-scheme-summary .permissions-scheme-summary--header {
    display: flex;
    flex-direction: row;
    font-size: 1.1em; }
    .permissions-scheme-summary .permissions-scheme-summary--header .title {
      flex-grow: 1;
      font-weight: bold;
      font-size: 14px; }
    .permissions-scheme-summary .permissions-scheme-summary--header .actions {
      flex-grow: 0; }
      .permissions-scheme-summary .permissions-scheme-summary--header .actions a {
        display: inline-block;
        padding: 0 3px; }
  .permissions-scheme-summary .permissions-scheme-summary--teams {
    padding: 1.5em 0 0.5em; }
    .permissions-scheme-summary .permissions-scheme-summary--teams .team {
      margin-bottom: 10px;
      height: 25px;
      display: inline-flex;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 13px;
      padding: 0 8px;
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.6); }

.team-scheme-extra-teams-overlay .tooltip-inner {
  display: flex;
  flex-direction: column; }

.permission-scheme-summary-error-message {
  color: #d64946; }

.team-scheme-details {
  padding: 20px 20px 5px 20px; }
  .team-scheme-details .form-group {
    display: flex; }
    .team-scheme-details .form-group label {
      width: 240px; }

.team-info-block {
  display: flex;
  flex-grow: 1;
  align-items: center; }
  .team-info-block .icon {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    background-color: #2389d7;
    color: white;
    font-weight: bold;
    height: 44px;
    letter-spacing: -.5px;
    line-height: 42px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    vertical-align: top;
    width: 44px; }
    .team-info-block .icon .team-btn__image {
      border-radius: 4px;
      overflow: hidden;
      background-color: white;
      background-repeat: round;
      background-size: contain;
      height: 100%;
      width: 100%; }
    .team-info-block .icon .team-btn__content {
      bottom: 4px;
      font-size: 8.5px;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 31px; }
  .team-info-block .team-data {
    flex-grow: 1;
    padding: 0 0 0 10px; }
    .team-info-block .team-data .title {
      font-weight: bold; }

.admin-modal .modal-header {
  background-color: black;
  color: white;
  margin: 1px; }

.edit-post-time-limit-button {
  -webkit-transition: all 0.15s ease;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease false;
  color: #5B6672;
  border-color: #5B6672;
  border-radius: 4px;
  height: 26px;
  padding: 0 8px;
  background: transparent; }
  .edit-post-time-limit-button i {
    margin-right: 4px; }
  .edit-post-time-limit-button span {
    display: inline !important; }
  .edit-post-time-limit-button:hover {
    background: #2389d7;
    border-color: #2389d7;
    color: white; }

.edit-post-time-limit-modal label {
  margin-left: 7px;
  margin-right: 7px;
  font-weight: normal; }

.edit-post-time-limit-modal .edit-post-time-limit-modal__error {
  display: inline-block;
  width: 65%;
  color: red;
  text-align: left; }

.app__body .DayPicker {
  font-size: inherit;
  width: 100%;
  margin-top: 0.3rem; }
  .app__body .DayPicker .DayPicker-NavBar {
    position: relative; }
  .app__body .DayPicker .DayPicker-Caption {
    border-bottom: 1px solid transparent;
    margin: 0 0.5rem .7rem;
    padding: 0 0.5rem .7rem; }
    .app__body .DayPicker .DayPicker-Caption > div {
      font-size: inherit; }
  .app__body .DayPicker .DayPicker-Day {
    border-radius: 4px; }
  .app__body .DayPicker .DayPicker-wrapper {
    padding: 0; }
  .app__body .DayPicker .DayPicker-NavButton {
    top: 0;
    right: .5rem;
    margin-top: 2px;
    outline: none; }
  .app__body .DayPicker .DayPicker-Weekday {
    font-weight: bold;
    color: inherit; }
  .app__body .DayPicker .DayPicker-Month {
    width: 100%;
    margin: 0; }

.select-suggestion-container {
  position: relative;
  margin: 8px 8px 0 0; }
  .select-suggestion-container:hover:after {
    opacity: .6; }
  .select-suggestion-container:after {
    -webkit-transition: opacity 0.15s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: opacity 0.15s ease;
    -o-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease false;
    content: '\F107';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 8px;
    position: absolute;
    right: 1rem;
    opacity: .4;
    font-size: 16px;
    pointer-events: none; }
  .select-suggestion-container .form-control {
    cursor: pointer;
    font-size: 13px;
    height: 30px;
    max-width: 100%;
    padding-right: 3rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
    border-color: rgba(61, 60, 64, 0.3); }
  .select-suggestion-container .suggestion-list__content {
    border-radius: 3px 3px 3px 0; }

.inner-wrap.move--left .search-bar__container {
  display: none; }

.inner-wrap.move--left .search-btns {
  display: none; }

.inner-wrap.move--left .header-list__right {
  float: right;
  padding-right: 0px !important;
  position: relative;
  z-index: 6; }

.inner-wrap.move--right .search-bar__container {
  display: none; }

.inner-wrap.move--right .search-btns {
  display: none; }

.inner-wrap.move--right .header-list__right {
  float: right;
  margin-right: -18px;
  padding-right: 0px !important;
  position: relative;
  z-index: 6; }

.app__content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  background: white;
  height: 100%;
  margin-left: 220px;
  padding-top: 50px;
  position: relative; }
  .channel__wrap .app__content {
    padding-top: 0; }

#archive-link-home {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
  font-size: 13px; }
  #archive-link-home .fa {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    font-size: 11px; }
  #archive-link-home a {
    color: inherit;
    display: block;
    padding: 10px 20px;
    width: 100%; }

.delete-message-text {
  margin-top: 10px; }

.col-sm-auto {
  padding-left: 15px;
  padding-right: 15px; }

.footer-pane {
  background: whitesmoke;
  padding-bottom: 1em; }
  .footer-pane .footer-link {
    padding: 0 1.5em; }
    .footer-pane .footer-link.copyright {
      color: rgba(0, 0, 0, 0.5);
      padding-right: 0; }
  .footer-pane .footer-site-name {
    font-size: 14px;
    font-weight: bold;
    padding: 1.5em 0 1em;
    text-transform: uppercase; }

.footer,
.footer-pane {
  height: 89px; }

.radio.margin-bottom--none,
.checkbox.margin-bottom--none {
  margin-bottom: 0; }

.form-control:-ms-input-placeholder {
  color: inherit; }

.form-control:-moz-placeholder {
  color: inherit; }

.form-control::-moz-placeholder {
  color: inherit; }

.form-control::-webkit-input-placeholder {
  color: inherit; }

.form-horizontal .modal-intro {
  margin: -10px 0 30px; }

.form-horizontal .form__label {
  font-size: 1.1em;
  font-weight: 600;
  padding-right: 3px;
  text-align: left; }
  .form-horizontal .form__label.light {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    font-size: 1.05em;
    font-style: italic;
    font-weight: normal;
    padding-top: 2px; }

.form-horizontal .input__help {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  color: inherit;
  margin: 10px 0 0 0;
  word-break: break-word; }
  .form-horizontal .input__help.dark {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .form-horizontal .input__help.error {
    color: #952321; }

.form-horizontal .form-control {
  font-weight: normal; }

.form-horizontal .form-group {
  margin-bottom: 25px; }
  .form-horizontal .form-group.less {
    margin-bottom: 10px; }

.help-block {
  color: rgba(0, 0, 0, 0.5);
  font-size: .95em;
  margin: 10px 0 0; }

.form-group.form-group--small {
  margin-bottom: 10px; }

.has-error .help-block,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #d64946; }

.has-error .form__label.control-label {
  color: inherit !important; }

.has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #d64946; }

.multi-select__radio label {
  display: flex;
  align-items: center; }

.multi-select__radio input[type='radio'] {
  margin-top: 0;
  width: 13px; }

.multi-select__radio .flex-parent {
  display: flex;
  align-items: center; }

.multi-select__radio .icon {
  display: flex;
  height: 21px;
  align-items: center;
  padding-right: 1px;
  margin-right: 6px;
  width: 14px;
  justify-content: center; }
  .multi-select__radio .icon + span {
    font-weight: 600;
    margin-right: 4px; }

.multi-select__radio span:last-child {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8; }

.form-control.inline {
  display: inline-block;
  width: auto; }

.form-control::-moz-placeholder, .form-control:-moz-placeholder {
  color: inherit !important;
  opacity: .6; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f9f9f9; }

.channel-header {
  -webkit-flex: 0 0 63px;
  flex: 0 0 63px;
  border-bottom: 1px solid;
  font-size: 14px;
  position: relative;
  width: 100%;
  z-index: 9; }
  .move--left .channel-header .flex-parent {
    padding-right: 0; }
  .channel-header .channel-header__favorites {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    float: left;
    height: 20px;
    margin: 0 5px 0 0;
    padding-top: 2px;
    position: relative;
    z-index: 1; }
    .channel-header .channel-header__favorites.inactive {
      color: inherit; }
    .channel-header .channel-header__favorites.active {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
    .channel-header .channel-header__favorites i {
      font-size: 16px;
      position: relative;
      top: -1px; }
  .channel-header .channel-header__mute {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    flex: 0;
    float: left;
    height: 20px;
    margin: 0 7px;
    position: relative;
    z-index: 1; }
    .channel-header .channel-header__mute.inactive {
      color: inherit; }
    .channel-header .channel-header__mute.active {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  .channel-header .flex-parent {
    display: flex;
    padding: 0 16px; }
  .channel-header .flex-child:first-child {
    flex: 1;
    min-width: 0;
    padding: 0 8px 0 0; }
  .channel-header .flex-child .markdown__paragraph-inline {
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 50px); }
  .channel-header .channel-header_plugin-dropdown {
    min-width: 200px;
    max-width: 300px;
    padding: 0; }
    .channel-header .channel-header_plugin-dropdown .fa {
      text-align: center;
      width: 20px;
      margin-left: -5px; }
    .channel-header .channel-header_plugin-dropdown svg {
      text-align: center;
      width: 20px;
      margin-left: -5px;
      margin-right: 5px; }
    .channel-header .channel-header_plugin-dropdown a {
      border-left: 3px solid transparent;
      padding: 10px 16px; }
      .channel-header .channel-header_plugin-dropdown a:hover .fa {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      .channel-header .channel-header_plugin-dropdown a:hover svg {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
  .channel-header .channel-header__icon {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    margin: 16px 0 0 8px;
    min-width: 30px;
    position: relative;
    text-align: center;
    width: 30px;
    z-index: 5;
    color: #B1B1B3; }
    .channel-header .channel-header__icon.wide {
      -webkit-border-radius: 37px;
      -moz-border-radius: 37px;
      -ms-border-radius: 37px;
      -o-border-radius: 37px;
      border-radius: 37px;
      line-height: 28px;
      padding: 0;
      white-space: nowrap;
      width: auto; }
      .channel-header .channel-header__icon.wide > div {
        padding: 0 8px; }
    .channel-header .channel-header__icon.text {
      font-weight: 600;
      padding: 0 14px; }
      .channel-header .channel-header__icon.text button {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
        opacity: 0.4; }
      .channel-header .channel-header__icon.text:hover button {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
    .channel-header .channel-header__icon .icon__text {
      font-weight: 600;
      margin-right: 4px;
      position: relative; }
    .channel-header .channel-header__icon a {
      display: block; }
    .channel-header .channel-header__icon .fa {
      font-size: 15px; }
    .channel-header .channel-header__icon + div {
      display: none; }
    .channel-header .channel-header__icon .icon {
      position: relative;
      align-items: center;
      justify-content: center; }
    .channel-header .channel-header__icon .icon__ellipsis {
      padding-top: 5px; }
    .channel-header .channel-header__icon .icon__members {
      top: 2px; }
    .channel-header .channel-header__icon .icon__mentions {
      top: 1px; }
    .channel-header .channel-header__icon .icon__flag {
      top: 1px; }
    .channel-header .channel-header__icon .icon__search {
      top: 1px; }
  .move--left .channel-header .icon--hidden {
    display: none; }
  .channel-header.alt {
    margin: 0; }
    .channel-header.alt th {
      font-weight: normal;
      vertical-align: top; }
      .channel-header.alt th.header-list__right {
        padding-right: 4px;
        white-space: nowrap; }
    .channel-header.alt td {
      border: none; }
  .channel-header .heading {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin: 0 7px 0 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap; }
  .channel-header .caret {
    margin-top: 3px; }
  .channel-header .more {
    color: #81848b;
    display: inline-block;
    font-size: 13px;
    text-transform: capitalize;
    vertical-align: top; }
  .channel-header .disabled {
    color: #999999; }

.row.header {
  position: relative; }
  .channel__wrap .row.header {
    display: none; }

.header-dropdown__icon {
  color: inherit;
  font-size: 19px;
  margin-left: 1px;
  position: relative;
  top: 0; }

.channel-header__info {
  margin-top: 13px;
  white-space: nowrap; }
  .channel-header__info .dropdown-toggle {
    display: flex;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis; }
  .channel-header__info .header-dropdown__icon {
    color: rgba(0, 0, 0, 0.5); }
  .channel-header__info .channel-header__title {
    display: flex; }
    .channel-header__info .channel-header__title .channel-header__favorites {
      flex: 0 auto; }
    .channel-header__info .channel-header__title .channel-header-archived-icon {
      opacity: .5; }
    .channel-header__info .channel-header__title h2 {
      display: flex;
      flex: 0 1 auto;
      min-width: 0; }
    .channel-header__info .channel-header__title > a {
      display: inline-block;
      text-decoration: none;
      width: calc(100% - 65px); }
    .channel-header__info .channel-header__title h2 {
      font-size: inherit;
      line-height: inherit;
      margin: 0; }
    .channel-header__info .channel-header__title .modal {
      white-space: normal; }
  .channel-header__info .channel-header__description {
    font-size: 12px;
    margin-bottom: 3px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    width: calc(100% - 18px);
    height: 20px; }
    .channel-header__info .channel-header__description span[data-emoticon] {
      position: relative;
      top: -1px; }
    .channel-header__info .channel-header__description blockquote {
      font-size: 13px;
      padding: 1px 0 0 23px;
      overflow: hidden;
      text-overflow: ellipsis; }
      .channel-header__info .channel-header__description blockquote:before {
        font-size: 16px;
        left: 1px;
        top: -1px; }
    .channel-header__info .channel-header__description .status {
      margin-right: 3px; }
    .channel-header__info .channel-header__description .markdown__heading {
      font-size: 1em;
      margin: 0; }
    .channel-header__info .channel-header__description code {
      margin: 1px 0 0;
      padding: 2px 5px 0; }
    .channel-header__info .channel-header__description.light {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
      color: inherit; }
      .channel-header__info .channel-header__description.light button:hover .channel-header__info .channel-header__description.light button:focus {
        text-decoration: underline; }
    .channel-header__info .channel-header__description .markdown-inline-img {
      max-height: 45px; }
  .channel-header__info .header-status__text {
    margin-right: 3px; }
    .channel-header__info .header-status__text:after {
      content: '\2022';
      margin: 0 2px 0 5px;
      position: relative;
      top: 1px; }
  .channel-header__info .status {
    width: 18px; }
    .channel-header__info .status svg {
      max-height: 20px;
      width: 16px; }

.channel-header__popover p,
.navbar__popover p {
  white-space: pre-wrap; }

.channel-intro {
  margin: 0 auto 10px;
  padding: 0 15px; }
  .channel-intro.channel-intro--centered {
    max-width: 1020px; }
  .channel-intro .intro-links {
    display: inline-block;
    margin: 0 1.5em 10px 0; }
    .channel-intro .intro-links .fa {
      margin-right: 5px; }
  .channel-intro .channel-intro-profile {
    margin-top: 5px; }
    .channel-intro .channel-intro-profile .user-popover {
      max-width: calc(100% - 100px);
      overflow: hidden;
      text-overflow: ellipsis; }
  .channel-intro .channel-intro-img .status-wrapper {
    height: 50px;
    margin: 0 10px 10px 0; }
  .channel-intro .channel-intro-img img {
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px; }
  .channel-intro .channel-intro__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px; }
  .channel-intro .channel-intro__content {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    background: whitesmoke;
    padding: 10px 15px; }

.sidebar--left .divider,
.sidebar--menu .divider,
.admin-sidebar .divider {
  border-top: 1px solid transparent; }
  .sidebar--left .divider + .divider,
  .sidebar--menu .divider + .divider,
  .admin-sidebar .divider + .divider {
    display: none; }

.sidebar--left .team__header,
.sidebar--menu .team__header,
.admin-sidebar .team__header {
  *zoom: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
  align-items: center;
  flex: 0 0 63px;
  padding: 3px 40px 0 16px;
  position: relative;
  max-height: 63px; }
  .sidebar--left .team__header:after,
  .sidebar--menu .team__header:after,
  .admin-sidebar .team__header:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden; }
  .sidebar--left .team__header:before,
  .sidebar--menu .team__header:before,
  .admin-sidebar .team__header:before {
    -webkit-transition: all 0.05s linear;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.05s linear;
    -o-transition: all 0.05s linear;
    transition: all 0.05s linear false;
    background: none;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .sidebar--left .team__header:hover:before,
  .sidebar--menu .team__header:hover:before,
  .admin-sidebar .team__header:hover:before {
    background: rgba(0, 0, 0, 0.1); }
  .sidebar--left .team__header:hover .user__name,
  .sidebar--menu .team__header:hover .user__name,
  .admin-sidebar .team__header:hover .user__name {
    color: white; }
  .sidebar--left .team__header:hover .sidebar-header-dropdown .sidebar-header-dropdown__toggle,
  .sidebar--menu .team__header:hover .sidebar-header-dropdown .sidebar-header-dropdown__toggle,
  .admin-sidebar .team__header:hover .sidebar-header-dropdown .sidebar-header-dropdown__toggle {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .sidebar--left .team__header:hover .navbar-right .dropdown-toggle,
  .sidebar--menu .team__header:hover .navbar-right .dropdown-toggle,
  .admin-sidebar .team__header:hover .navbar-right .dropdown-toggle {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .sidebar--left .team__header a,
  .sidebar--menu .team__header a,
  .admin-sidebar .team__header a {
    text-decoration: none;
    -webkit-flex-grow: 1;
    flex-grow: 1; }
  .sidebar--left .team__header a.out_of_office,
  .sidebar--menu .team__header a.out_of_office,
  .admin-sidebar .team__header a.out_of_office {
    background-color: transparent !important;
    cursor: default; }
  .sidebar--left .team__header .sidebar-header-dropdown,
  .sidebar--left .team__header .admin-navbar-dropdown,
  .sidebar--menu .team__header .sidebar-header-dropdown,
  .sidebar--menu .team__header .admin-navbar-dropdown,
  .admin-sidebar .team__header .sidebar-header-dropdown,
  .admin-sidebar .team__header .admin-navbar-dropdown {
    font-size: .85em;
    height: 100%;
    left: 0;
    margin-right: -22px;
    position: absolute;
    right: 22px;
    top: 0; }
    .sidebar--left .team__header .sidebar-header-dropdown li,
    .sidebar--left .team__header .admin-navbar-dropdown li,
    .sidebar--menu .team__header .sidebar-header-dropdown li,
    .sidebar--menu .team__header .admin-navbar-dropdown li,
    .admin-sidebar .team__header .sidebar-header-dropdown li,
    .admin-sidebar .team__header .admin-navbar-dropdown li {
      width: 100%; }
    .sidebar--left .team__header .sidebar-header-dropdown .sidebar-header-dropdown__toggle,
    .sidebar--left .team__header .sidebar-header-dropdown .admin-navbar-dropdown__toggle,
    .sidebar--left .team__header .admin-navbar-dropdown .sidebar-header-dropdown__toggle,
    .sidebar--left .team__header .admin-navbar-dropdown .admin-navbar-dropdown__toggle,
    .sidebar--menu .team__header .sidebar-header-dropdown .sidebar-header-dropdown__toggle,
    .sidebar--menu .team__header .sidebar-header-dropdown .admin-navbar-dropdown__toggle,
    .sidebar--menu .team__header .admin-navbar-dropdown .sidebar-header-dropdown__toggle,
    .sidebar--menu .team__header .admin-navbar-dropdown .admin-navbar-dropdown__toggle,
    .admin-sidebar .team__header .sidebar-header-dropdown .sidebar-header-dropdown__toggle,
    .admin-sidebar .team__header .sidebar-header-dropdown .admin-navbar-dropdown__toggle,
    .admin-sidebar .team__header .admin-navbar-dropdown .sidebar-header-dropdown__toggle,
    .admin-sidebar .team__header .admin-navbar-dropdown .admin-navbar-dropdown__toggle {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
      -webkit-transition: all 0.1s linear;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.1s linear;
      -o-transition: all 0.1s linear;
      transition: all 0.1s linear false;
      background: none;
      color: white;
      display: block;
      font-size: 1em;
      height: 100%;
      left: 0;
      line-height: 1.8;
      padding: 10px;
      width: 100%; }
    .sidebar--left .team__header .sidebar-header-dropdown .dropdown-menu,
    .sidebar--left .team__header .admin-navbar-dropdown .dropdown-menu,
    .sidebar--menu .team__header .sidebar-header-dropdown .dropdown-menu,
    .sidebar--menu .team__header .admin-navbar-dropdown .dropdown-menu,
    .admin-sidebar .team__header .sidebar-header-dropdown .dropdown-menu,
    .admin-sidebar .team__header .admin-navbar-dropdown .dropdown-menu {
      margin-left: 10px;
      margin-top: 4px;
      min-width: 199px; }
      .sidebar--left .team__header .sidebar-header-dropdown .dropdown-menu a,
      .sidebar--left .team__header .sidebar-header-dropdown .dropdown-menu button,
      .sidebar--left .team__header .admin-navbar-dropdown .dropdown-menu a,
      .sidebar--left .team__header .admin-navbar-dropdown .dropdown-menu button,
      .sidebar--menu .team__header .sidebar-header-dropdown .dropdown-menu a,
      .sidebar--menu .team__header .sidebar-header-dropdown .dropdown-menu button,
      .sidebar--menu .team__header .admin-navbar-dropdown .dropdown-menu a,
      .sidebar--menu .team__header .admin-navbar-dropdown .dropdown-menu button,
      .admin-sidebar .team__header .sidebar-header-dropdown .dropdown-menu a,
      .admin-sidebar .team__header .sidebar-header-dropdown .dropdown-menu button,
      .admin-sidebar .team__header .admin-navbar-dropdown .dropdown-menu a,
      .admin-sidebar .team__header .admin-navbar-dropdown .dropdown-menu button {
        overflow: hidden;
        padding: 3px 20px;
        text-overflow: ellipsis; }
    .sidebar--left .team__header .sidebar-header-dropdown .sidebar-header-dropdown__icon,
    .sidebar--left .team__header .sidebar-header-dropdown .admin-navbar-dropdown__icon,
    .sidebar--left .team__header .admin-navbar-dropdown .sidebar-header-dropdown__icon,
    .sidebar--left .team__header .admin-navbar-dropdown .admin-navbar-dropdown__icon,
    .sidebar--menu .team__header .sidebar-header-dropdown .sidebar-header-dropdown__icon,
    .sidebar--menu .team__header .sidebar-header-dropdown .admin-navbar-dropdown__icon,
    .sidebar--menu .team__header .admin-navbar-dropdown .sidebar-header-dropdown__icon,
    .sidebar--menu .team__header .admin-navbar-dropdown .admin-navbar-dropdown__icon,
    .admin-sidebar .team__header .sidebar-header-dropdown .sidebar-header-dropdown__icon,
    .admin-sidebar .team__header .sidebar-header-dropdown .admin-navbar-dropdown__icon,
    .admin-sidebar .team__header .admin-navbar-dropdown .sidebar-header-dropdown__icon,
    .admin-sidebar .team__header .admin-navbar-dropdown .admin-navbar-dropdown__icon {
      border-radius: 36px;
      fill: white;
      float: right;
      height: 36px;
      line-height: 36px;
      position: relative;
      right: -5px;
      text-align: center;
      top: 0;
      width: 36px; }
  .sidebar--left .team__header .user__picture,
  .sidebar--menu .team__header .user__picture,
  .admin-sidebar .team__header .user__picture {
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    border-radius: 36px;
    float: left;
    height: 36px;
    width: 36px; }
  .sidebar--left .team__header .header__info,
  .sidebar--menu .team__header .header__info,
  .admin-sidebar .team__header .header__info {
    overflow: hidden;
    *zoom: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    color: white;
    padding-left: 8px;
    position: relative; }
  .sidebar--left .team__header .team__name,
  .sidebar--left .team__header .user__name,
  .sidebar--menu .team__header .team__name,
  .sidebar--menu .team__header .user__name,
  .admin-sidebar .team__header .team__name,
  .admin-sidebar .team__header .user__name {
    display: block;
    font-size: 14px;
    font-weight: 600;
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .sidebar--left .team__header .team__name,
  .sidebar--menu .team__header .team__name,
  .admin-sidebar .team__header .team__name {
    line-height: 22px;
    margin: 0; }
  .sidebar--left .team__header .user__name,
  .sidebar--menu .team__header .user__name,
  .admin-sidebar .team__header .user__name {
    -webkit-transition: all 0.1s linear;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear false;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: 400;
    top: -3px;
    position: relative; }

.sidebar--left .search__clear,
.sidebar--menu .search__clear,
.admin-sidebar .search__clear {
  display: none; }

#navbar .navbar-default .navbar-toggle.menu-toggle {
  padding: 0 1em; }

.channel-header__navbar {
  font-size: 16px; }
  .channel-header__navbar .heading {
    margin-right: 3px; }
  .channel-header__navbar .header-dropdown__icon {
    top: 1px; }

.app__body .channel-header__links {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  height: 30px;
  line-height: 26px;
  margin-right: 1px;
  text-align: center;
  width: 30px; }
  .app__body .channel-header__links th:last-child div {
    margin-right: 10px; }
  .channel__wrap.move--left .app__body .channel-header__links {
    position: absolute;
    right: -400px;
    top: 14px; }
  .app__body .channel-header__links .icon__flag svg {
    height: 19px;
    position: relative;
    top: 2px;
    width: 19px; }
  .app__body .channel-header__links > a {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    -webkit-transition: all 0.1s ease-in;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in false;
    color: inherit;
    text-decoration: none; }
    .app__body .channel-header__links > a:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7; }
    .app__body .channel-header__links > a:focus {
      color: inherit; }
    .app__body .channel-header__links > a.active {
      color: #2389d7;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
      .app__body .channel-header__links > a.active .icon {
        fill: #2389d7; }

.channel-header-archived-icon {
  margin-right: 5px;
  position: relative;
  top: 2px; }

h1 {
  font-size: 28px; }

h2 {
  font-size: 25px; }

h3 {
  font-size: 22px; }

h4 {
  font-size: 19px; }

h5 {
  font-size: 15px; }

h6 {
  font-size: 1em; }

.markdown__heading {
  font-weight: 700;
  line-height: 1.5;
  margin: 10px 0; }
  .markdown__heading:first-child {
    margin: 5px 0 10px; }
  .markdown__heading .emoticon {
    min-height: 1.5em;
    min-width: 1.5em;
    vertical-align: top; }

.markdown__paragraph-inline {
  display: inline; }
  .markdown__paragraph-inline + .markdown__paragraph-inline {
    margin-left: 4px; }

#post-list .markdown-inline-img {
  -moz-force-broken-image-icon: 1;
  margin: 5px 0;
  max-height: 500px; }

.post-code {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative; }
  .post-code code {
    border: 1px solid #dddddd;
    border-radius: .25em;
    display: block;
    font-size: 13px;
    margin: 5px 0;
    overflow-x: auto;
    padding: 6.5px;
    text-align: left;
    white-space: pre; }
  .post-code td {
    padding: 0 .5em;
    vertical-align: top; }
  .post-code:hover .post-code__language {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .post-code--wrap code {
    white-space: pre-wrap; }
  .post-code .hljs {
    position: relative;
    visibility: visible; }

.post-code__language {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity, 0.6s;
  -moz-transition: opacity, 0.6s;
  -o-transition: opacity, 0.6s;
  transition: opacity, 0.6s;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  background: #21586d;
  border-radius: 0 0 0 2px;
  color: white;
  padding: 4px 10px 5px;
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 5; }

.post-code__lineno {
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  border-right: 1px solid #aaa;
  color: #aaa;
  margin-right: .5em;
  text-align: right;
  user-select: none; }

.post-code__search-highlighting {
  color: transparent;
  right: 6.5px;
  left: 6.5px;
  pointer-events: none;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.post__body hr {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
  background-color: #e7e7e7;
  border: 0 none;
  height: 4px;
  margin: 15px 0 16px;
  padding: 0; }

.post__body .codespan__pre-wrap code {
  white-space: pre-wrap; }

.markdown__table {
  background: white;
  margin: 5px 0 10px; }
  .markdown__table th,
  .markdown__table td {
    border: 1px solid #ddd;
    padding: 6px 13px; }
  .markdown__table tbody tr {
    background: white; }
    .markdown__table tbody tr:nth-child(2n) {
      background-color: #f8f8f8; }

blockquote {
  border: none;
  font-size: 16px;
  margin-bottom: 0;
  padding: 10px 10px 10px 38px;
  position: relative; }
  blockquote:before {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    content: '\F10D';
    display: inline-block;
    font-family: FontAwesome;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    left: 8px;
    position: absolute;
    text-decoration: inherit;
    top: 5px; }

pre {
  color: inherit;
  margin: 5px 0;
  padding: 0; }

code {
  background: white;
  color: inherit; }

.help code {
  background: rgba(51, 51, 51, 0.1); }

.help .hljs {
  background: #f8f8f8;
  color: #333;
  display: block;
  overflow-x: auto;
  padding: 0.5em; }

.help .container {
  margin-bottom: 40px; }

.help .links {
  font-size: 1.2em;
  margin-top: 30px; }

.nav > li > a:focus,
.nav > li > a:hover {
  background: transparent; }

.nav-tabs {
  margin-bottom: 10px; }
  .nav-tabs > li {
    margin-right: 5px; }
    .nav-tabs > li > a {
      border-bottom-color: transparent !important;
      padding: 7px 15px; }
      .nav-tabs > li > a .small {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
        opacity: 0.8;
        margin-left: 4px; }

#navbar input {
  margin: 0 5px 0 2px; }

#navbar .navbar-header {
  display: flex; }

#navbar .flex-parent--center {
  display: flex;
  justify-content: center; }

.browser--ie #navbar .navbar-default .navbar-brand {
  overflow: visible;
  padding: 1px; }
  .browser--ie #navbar .navbar-default .navbar-brand .heading {
    max-width: 100px; }

#navbar .navbar-default {
  background: #2389d7;
  border: none;
  min-height: 50px;
  position: absolute; }
  #navbar .navbar-default .navbar-nav > li > a {
    height: 50px;
    padding: 0 1.3em; }
    #navbar .navbar-default .navbar-nav > li > a i {
      line-height: 50px; }
  #navbar .navbar-default .navbar-plugin-button {
    position: relative;
    top: 1px; }
  #navbar .navbar-default .navbar-toggle {
    border-color: transparent;
    border-radius: 0;
    border-width: 0;
    fill: white;
    float: left;
    height: 50px;
    line-height: 48px;
    margin: 0;
    padding: 0 10px;
    width: 50px;
    z-index: 5; }
    #navbar .navbar-default .navbar-toggle.navbar-right__icon {
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      line-height: 0;
      margin: 9px 10px 0 0;
      padding: 0;
      width: 32px; }
    #navbar .navbar-default .navbar-toggle.menu-toggle {
      margin-right: 16px; }
    #navbar .navbar-default .navbar-toggle .icon-bar {
      background: white;
      width: 21px; }
    #navbar .navbar-default .navbar-toggle .icon__search svg {
      left: -1px;
      position: relative;
      width: 17px; }
    #navbar .navbar-default .navbar-toggle.icon__mute {
      width: 30px;
      padding: 0;
      opacity: .6;
      font-size: 15px; }
    #navbar .navbar-default .navbar-toggle .icon--white {
      color: white; }
  #navbar .navbar-default .navbar-brand {
    flex: 1;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    padding: 0;
    display: flex; }
    #navbar .navbar-default .navbar-brand .dropdown {
      max-width: calc(100% - 40px); }
    #navbar .navbar-default .navbar-brand .heading {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto; }
    #navbar .navbar-default .navbar-brand .header-dropdown__icon {
      margin-left: 6px; }
    #navbar .navbar-default .navbar-brand .dropdown-toggle {
      align-items: center;
      color: inherit;
      display: flex;
      overflow: hidden;
      text-decoration: none; }
    #navbar .navbar-default .navbar-brand .description {
      color: inherit;
      display: inline-block;
      margin-right: 1em; }

#navbar .sidebar-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  #navbar .sidebar-name span {
    margin-left: 2px;
    white-space: nowrap; }

#navbar .channel-create-btn {
  margin-right: 15px; }

#navbar .more-channel-table {
  border: 1px solid #dbdbdc;
  width: 100%; }
  #navbar .more-channel-table td {
    padding: 7px; }
  #navbar .more-channel-table button {
    width: 110px; }

.badge-notify {
  background: #d64946;
  left: 4px;
  position: absolute;
  top: 3px;
  z-index: 100; }

.custom-textarea {
  background: transparent;
  border: 1px solid #cccccc;
  height: 100%;
  line-height: 20px;
  min-height: 36px;
  overflow-x: hidden;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word; }
  .custom-textarea:focus {
    border-color: #cccccc;
    box-shadow: none; }
  .custom-textarea.bad-connection {
    background: #ffffac !important;
    color: #d04444 !important; }

.textarea-wrapper {
  min-height: 37px;
  position: relative; }
  .textarea-wrapper .textbox-preview-area {
    box-shadow: none;
    left: 0;
    position: absolute;
    top: 0;
    white-space: normal;
    z-index: 2; }
  .textarea-wrapper .help__text {
    bottom: -23px;
    font-size: 13px;
    position: absolute;
    right: 0;
    text-align: right;
    z-index: 3; }
    .modal .textarea-wrapper .help__text {
      bottom: initial;
      margin-top: -20px;
      position: relative;
      top: 30px; }
    .textarea-wrapper .help__text a {
      margin-left: 10px; }
  .textarea-wrapper .textbox-preview-link {
    margin-right: 8px; }

.help__format-text {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -webkit-transition-delay: 0.5s;
  -moz-transition: all 0.5s ease 0.5s;
  -o-transition: all 0.5s ease 0.5s;
  transition: all 0.5s ease 0.5s;
  display: inline-block;
  font-size: .85em;
  vertical-align: bottom;
  white-space: nowrap; }
  .modal .help__format-text {
    white-space: normal; }
  .help__format-text b,
  .help__format-text i,
  .help__format-text span {
    margin: 0 2px;
    position: relative;
    top: -1px; }
  .help__format-text b {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
    opacity: 0.9; }
  .help__format-text code {
    background: transparent;
    padding: 0; }
  .help__format-text .textbox-preview-link {
    cursor: pointer;
    font-size: 13px;
    margin-left: 15px; }

.date-separator,
.new-separator {
  height: 2em;
  margin: 0;
  position: relative;
  text-align: center;
  z-index: 0; }
  .date-separator:before, .date-separator:after,
  .new-separator:before,
  .new-separator:after {
    content: '';
    display: none;
    height: 1em;
    left: 0;
    position: absolute;
    width: 100%; }
  .date-separator:before,
  .new-separator:before {
    bottom: 0; }
  .date-separator:after,
  .new-separator:after {
    top: 0; }
  .date-separator.hovered--after:before,
  .new-separator.hovered--after:before {
    background: #f5f5f5;
    display: block; }
  .date-separator.hovered--before:after,
  .new-separator.hovered--before:after {
    background: #f5f5f5;
    display: block; }
  .date-separator .separator__hr,
  .new-separator .separator__hr {
    border-color: #cccccc;
    margin: 0;
    position: relative;
    top: 1em;
    z-index: 5; }
  .date-separator .separator__text,
  .new-separator .separator__text {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    background: #ffffff;
    color: #555555;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 2em;
    padding: 0 1em;
    position: relative;
    z-index: 5; }

.new-separator .separator__hr {
  border-color: #ffaf53; }

.new-separator .separator__text {
  color: #ff8800;
  font-weight: normal; }

.file-overlay {
  color: white;
  font-size: 1.42857em;
  font-weight: 600;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 11; }
  .file-overlay .overlay__indent {
    overflow: hidden;
    *zoom: 1;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    position: relative; }
  .file-overlay.right-file-overlay {
    font-size: 1.28571em; }
    .file-overlay.right-file-overlay .overlay__circle {
      height: 300px;
      margin: -150px 0 0 -150px;
      width: 300px; }
    .file-overlay.right-file-overlay .overlay__files {
      margin: 60px auto 15px;
      width: 150px; }
  .file-overlay .overlay__circle {
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    -ms-border-radius: 500px;
    -o-border-radius: 500px;
    border-radius: 500px;
    background: rgba(0, 0, 0, 0.7);
    height: 370px;
    left: 50%;
    margin: -185px 0 0 -185px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 370px; }
  .file-overlay .overlay__files {
    display: block;
    margin: 75px auto 20px; }
  .file-overlay .overlay__logo {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3;
    bottom: 30px;
    left: 50%;
    margin-left: -50px;
    position: absolute; }
  .file-overlay .fa {
    display: inline-block;
    font-size: 1.1em;
    margin-right: 8px; }

#post-list {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 100%;
  overflow-y: hidden;
  position: relative; }
  #post-list .inactive {
    display: none; }
  #post-list .post-list__loading {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    font-size: .9em;
    font-style: italic;
    padding: 1em 0;
    text-align: center; }
    #post-list .post-list__loading i {
      margin-right: 2px; }
    #post-list .post-list__loading.post-list__loading-search {
      cursor: pointer; }
      #post-list .post-list__loading.post-list__loading-search:hover {
        text-decoration: underline; }
  #post-list .post-list-holder-by-time {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow-y: scroll;
    padding: 1em 0 0;
    width: 100%;
    position: absolute; }
    #post-list .post-list-holder-by-time.active {
      display: inline; }
  #post-list .more-messages-text {
    border: none;
    display: block;
    font-size: 13px;
    margin: 5px 0 10px;
    outline: none;
    text-align: center;
    width: 100%; }
  #post-list .new-messages__button {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    font-size: 13.5px;
    left: 50%;
    margin: 5px auto;
    position: absolute;
    text-align: center;
    visibility: hidden;
    z-index: 3; }
    #post-list .new-messages__button .fa {
      font-size: 1.2em;
      font-weight: bold;
      margin-right: .5rem;
      position: relative;
      top: 1px; }
    #post-list .new-messages__button .icon {
      margin-left: 5px; }
    #post-list .new-messages__button div {
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      border-radius: 50px;
      cursor: pointer;
      display: inline-block;
      padding: 0 20px;
      height: 28px;
      display: flex;
      align-items: center; }
    #post-list .new-messages__button.visible {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
      visibility: visible; }
    #post-list .new-messages__button.disabled {
      display: none; }

.post-list__timestamp {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease false;
  -webkit-transform: translateY(-45px);
  -moz-transform: translateY(-45px);
  -ms-transform: translateY(-45px);
  -o-transform: translateY(-45px);
  transform: translateY(-45px);
  display: none;
  left: 0;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 100%;
  z-index: 50; }
  .post-list__timestamp.scrolling {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
    opacity: 0.9; }
  .post-list__timestamp.rhs {
    top: 98px; }
  .post-list__timestamp > div {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    background: #2389d7;
    border: 1px solid;
    color: white;
    display: inline-block;
    font-size: 12px;
    line-height: 25px;
    padding: 0 8px;
    text-align: center; }

.post-list__arrows {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: all 0.6s;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s false false;
  background-repeat: no-repeat;
  bottom: 0;
  display: none;
  fill: #444444;
  height: 40px;
  left: 9px;
  position: absolute;
  text-align: center;
  width: 40px;
  z-index: 50; }
  .post-list__arrows svg {
    color: inherit;
    height: 28px;
    width: 28px; }
  .post-list__arrows.scrolling {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    display: block; }

.post-create-message {
  padding: 15px; }

.post-create__container {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  z-index: 7; }
  .post-create__container label {
    font-weight: normal; }
  .post-create__container form {
    margin: 0 auto;
    padding: 0 15px 0;
    width: 100%; }
  .post-create__container .center {
    max-width: 1028px; }
  .post-create__container .custom-textarea {
    bottom: 0;
    max-height: calc(50vh - 40px);
    overflow: hidden;
    padding: 12px 0 12px 15px;
    resize: none; }
    .post-create__container .custom-textarea:not(.custom-textarea--emoji-picker) {
      padding-right: 40px; }
    .post-create__container .custom-textarea.custom-textarea--emoji-picker {
      padding-right: 80px; }
    .post-create__container .custom-textarea.textbox-preview-area {
      overflow-y: auto; }
    .post-create__container .custom-textarea:-ms-input-placeholder {
      color: inherit; }
    .post-create__container .custom-textarea:-moz-placeholder {
      color: inherit; }
    .post-create__container .custom-textarea::-moz-placeholder {
      color: inherit; }
    .post-create__container .custom-textarea::-webkit-input-placeholder {
      color: inherit; }
  .post-create__container .emoji-picker {
    position: absolute;
    right: 0;
    top: -361px; }
  .post-create__container .scroll .post-body__actions {
    right: 10px; }
  .post-create__container .scroll .custom-textarea {
    -ms-overflow-style: auto;
    -webkit-overflow-scrolling: touch;
    overflow: auto; }
    .post-create__container .scroll .custom-textarea:not(.custom-textarea--emoji-picker) {
      padding-right: 50px; }
    .post-create__container .scroll .custom-textarea.custom-textarea--emoji-picker {
      padding-right: 90px; }
  .post-create__container .post-body__actions {
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
    .post-create__container .post-body__actions > span {
      border-left: 1px solid transparent;
      width: 42px; }
      .post-create__container .post-body__actions > span:first-child {
        border: none; }
      .post-create__container .post-body__actions > span > span,
      .post-create__container .post-body__actions > span div {
        display: block;
        height: 49px;
        line-height: 49px;
        text-align: center;
        width: 100%; }
    .post-create__container .post-body__actions:hover, .post-create__container .post-body__actions:active {
      box-shadow: none; }
    .post-create__container .post-body__actions.btn-file__disabled {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
      opacity: 0.1; }
      .post-create__container .post-body__actions.btn-file__disabled:hover, .post-create__container .post-body__actions.btn-file__disabled:active {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
        opacity: 0.1; }
    .post-create__container .post-body__actions .icon--emoji {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5;
      -webkit-transition: all 0.15s;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.15s;
      -o-transition: all 0.15s;
      transition: all 0.15s false false;
      cursor: pointer; }
      .post-create__container .post-body__actions .icon--emoji:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
        opacity: 0.7; }
      .post-create__container .post-body__actions .icon--emoji svg {
        position: relative;
        top: 1px; }
    .post-create__container .post-body__actions .icon--attachment {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
      opacity: 0.4;
      -webkit-transition: all 0.15s;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.15s;
      -o-transition: all 0.15s;
      transition: all 0.15s false false;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      vertical-align: top; }
      .post-create__container .post-body__actions .icon--attachment:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
        opacity: 0.6; }
      .post-create__container .post-body__actions .icon--attachment input {
        cursor: pointer;
        direction: ltr;
        filter: alpha(opacity=0);
        font-size: 23px;
        height: 100%;
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%; }
      .post-create__container .post-body__actions .icon--attachment svg {
        position: relative;
        top: 2px; }
  .post-create__container .post-create-body {
    padding: 0 0 2px;
    position: relative; }
    .post-create__container .post-create-body .post-body__cell {
      position: relative;
      vertical-align: top; }
    .post-create__container .post-create-body .send-button {
      -webkit-transition: all 0.15s;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.15s;
      -o-transition: all 0.15s;
      transition: all 0.15s false false;
      border-left: 1px solid transparent;
      cursor: pointer;
      display: none;
      font-size: 17px;
      height: 100%;
      line-height: 49px;
      padding-right: 4px;
      text-align: center;
      vertical-align: bottom;
      width: 45px; }
      .post-create__container .post-create-body .send-button:active {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
        opacity: 0.75; }
      .post-create__container .post-create-body .send-button.disabled i {
        color: inherit; }
      .android .post-create__container .post-create-body .send-button,
      .ios .post-create__container .post-create-body .send-button {
        display: block; }
    .post-create__container .post-create-body .icon--emoji-picker {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5;
      -webkit-transition: all 0.15s;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.15s;
      -o-transition: all 0.15s;
      transition: all 0.15s false false;
      cursor: pointer; }
      .post-create__container .post-create-body .icon--emoji-picker:hover, .post-create__container .post-create-body .icon--emoji-picker:active {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
        opacity: 0.9;
        box-shadow: none; }
      .post-create__container .post-create-body .icon--emoji-picker .icon--attachment {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        opacity: 0.5;
        display: inline-block;
        position: relative;
        vertical-align: top; }
        .post-create__container .post-create-body .icon--emoji-picker .icon--attachment input {
          cursor: pointer;
          direction: ltr;
          filter: alpha(opacity=0);
          font-size: 23px;
          height: 100%;
          margin: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%; }
        .post-create__container .post-create-body .icon--emoji-picker .icon--attachment:hover {
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
          opacity: 0.9; }
    .post-create__container .post-create-body textarea {
      box-shadow: none; }
  .post-create__container .post-create-footer {
    overflow: hidden;
    *zoom: 1;
    font-size: 13px;
    padding: 3px 0 0;
    position: relative; }
    .post-create__container .post-create-footer .post-error {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=55);
      opacity: 0.55;
      display: inline-block;
      font-size: .85em;
      font-weight: normal;
      margin-bottom: 0;
      position: absolute;
      top: 4px; }
  .post-create__container .msg-typing {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    display: block;
    font-size: .95em;
    height: 20px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .post-create__container .channel-archived__message {
    padding: 25px;
    border-top-width: 1px;
    border-top-style: solid;
    text-align: center; }
  .post-create__container .channel-archived__close-btn {
    margin: 10px; }

.post-list__table {
  overflow: hidden;
  *zoom: 1;
  display: table;
  height: 100%;
  min-height: 350px;
  padding-bottom: .5em;
  table-layout: fixed;
  width: 100%; }
  .post-list__table .post-list__content {
    display: table-cell;
    vertical-align: bottom; }
    .post-list__table .post-list__content .dropdown-menu.bottom {
      bottom: 19px;
      top: auto; }

.post {
  *zoom: 1;
  max-width: 100%;
  padding: 8px .5em 0 1em;
  position: relative;
  word-wrap: break-word; }
  .post:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden; }
  .browser--ie .post .post__header .col__reply .comment-icon__container {
    flex: 0 1 auto;
    align-items: center; }
  .browser--ie .post .post__header .col__reply > .open, .browser--ie .post .post__header .col__reply > div {
    flex: 0 1 30px;
    align-items: center; }
    .browser--ie .post .post__header .col__reply > .open:first-child, .browser--ie .post .post__header .col__reply > div:first-child {
      flex: 0 1 25px; }
  .post.post--system .post__header .col__reply {
    min-width: 0; }
  .post:hover .dropdown,
  .post:hover .comment-icon__container,
  .post:hover .reacticon,
  .post:hover .reacticon__container,
  .post:hover .flag-icon__container,
  .post:hover .post__reply,
  .post:hover .post-reaction,
  .post:hover .post__remove {
    visibility: visible; }
  .post:hover .permalink-icon {
    visibility: visible; }
  .post:hover .post-add-reaction .post-reaction {
    visibility: visible;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .post.post--hovered .dropdown,
  .post.post--hovered .comment-icon__container,
  .post.post--hovered .post__reply,
  .post.post--hovered .post__remove,
  .post.post--hovered .reacticon__container,
  .post.post--hovered .permalink-icon {
    visibility: visible; }
  .post.post--hovered .post__header .col__reply {
    z-index: 7; }
  .post.post--hovered .post__body {
    background: transparent !important; }
  .post.post--highlight {
    background-color: beige; }
  .post.post--hide-controls .post__header .col__reply {
    display: none; }
  .post.post--thread.same--user {
    padding: 0 .5em 0 1em; }
    .post.post--thread.same--user .post__header {
      margin-bottom: 0; }
      .post.post--thread.same--user .post__header .post__header--info {
        position: absolute;
        left: -5px; }
        .post.post--thread.same--user .post__header .post__header--info .post__time {
          font-size: 0.7em;
          display: inline-block;
          left: -14px;
          line-height: 20px;
          position: absolute;
          text-align: right;
          text-rendering: auto;
          top: 4px;
          width: 51px; }
        .post.post--thread.same--user .post__header .post__header--info .flag-icon__container {
          left: 32px;
          margin-left: 7px;
          position: absolute;
          top: 6px; }
  .post.post--compact.post--thread .post__header {
    padding-top: 3px; }
  .post.post--compact.post--system .status {
    visibility: hidden; }
  .post.post--compact.post--system > div {
    margin-bottom: 0; }
  .post.post--compact.same--root.same--user .post__header {
    height: 0; }
  .post.post--compact .post-message {
    overflow: inherit; }
  .post.post--compact .post-message--collapsed + .post-image__columns:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .post.post--compact .post__img {
    padding-top: 0; }
  .post.post--compact .attachment {
    clear: both;
    padding-top: 1px; }
    .post.post--compact .attachment.attachment--pretext {
      clear: none;
      padding: 0; }
    .post.post--compact .attachment .attachment__body__wrap .btn-close {
      left: -2px; }
  .post.post--compact.post--comment .attachment .attachment__body__wrap .btn-close {
    left: -13px; }
  .post.post--compact .auto-responder {
    background: rgba(255, 255, 255, 0.7); }
  .post.post--compact .status-wrapper {
    cursor: auto;
    height: 14px;
    pointer-events: none; }
    .post.post--compact .status-wrapper .status {
      bottom: auto;
      height: 13px;
      left: -2px;
      position: relative;
      right: auto;
      top: auto;
      width: 13px; }
    .post.post--compact .status-wrapper svg {
      top: 1px; }
    .post.post--compact .status-wrapper:after {
      bottom: 0; }
  .post.post--compact blockquote {
    display: flex;
    padding: 3px 0px 3px 20px;
    flex-direction: column; }
    .post.post--compact blockquote:before {
      font-size: 15px;
      left: 0;
      top: 0; }
  .post.post--compact .search-item-snippet blockquote {
    margin-top: 0; }
  .post.post--compact .markdown__heading {
    clear: both;
    font-size: 1em;
    margin: 0; }
  .post.post--compact .post__header {
    display: block;
    height: 22px;
    margin-bottom: 0;
    padding: 0;
    width: auto; }
  .post.post--compact .post__body {
    background: transparent !important;
    line-height: 1.5;
    padding: 2px 0 0; }
    .post.post--compact .post__body .post--edited {
      position: relative;
      top: -1px; }
    .post.post--compact .post__body .img-div {
      max-height: 150px;
      max-width: 150px; }
    .post.post--compact .post__body p {
      line-height: inherit; }
    .post.post--compact .post__body ol,
    .post.post--compact .post__body ul {
      clear: both;
      padding-left: 20px; }
    .post.post--compact .post__body div {
      margin-bottom: 0; }
    .post.post--compact .post__body .post-image__column {
      margin: 3px 10px 3px 0;
      padding: 0; }
  .post.post--compact .post-image__column {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    font-size: .9em;
    height: 26px;
    line-height: 25px;
    max-width: 250px;
    min-width: 150px;
    padding: 0 7px; }
    .post.post--compact .post-image__column .post-image__thumbnail {
      display: none; }
    .post.post--compact .post-image__column .post-image__details {
      background: transparent;
      border: none;
      padding: 0;
      width: 100%; }
      .post.post--compact .post-image__column .post-image__details span {
        display: none; }
      .post.post--compact .post-image__column .post-image__details svg {
        position: relative;
        top: 2px; }
      .post.post--compact .post-image__column .post-image__details .icon {
        display: block;
        float: left;
        margin-right: 5px;
        opacity: .6; }
    .post.post--compact .post-image__column .post-image__download {
      opacity: 1;
      padding: 0;
      position: relative;
      width: auto; }
    .post.post--compact .post-image__column .post-image__name {
      overflow: hidden;
      *zoom: 1;
      display: block;
      margin: 0;
      padding: 0 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%; }
      .post.post--compact .post-image__column .post-image__name i {
        font-size: .9em;
        margin-right: 5px;
        opacity: .5; }
    .post.post--compact .post-image__column a:hover {
      text-decoration: none; }
  .post.post--compact .post__img {
    padding-top: 1px;
    width: 16px; }
    .post.post--compact .post__img img {
      display: none; }
  .post .post__img:hover {
    cursor: pointer; }
  .post .post--fail {
    position: relative; }
  .post .post-edited__indicator {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    font-size: .87em; }
  .post .emoticon {
    vertical-align: top; }
  .post p {
    font-size: 13.5px;
    line-height: 1.6em;
    margin: 0;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word; }
  .post .post__header--info {
    padding: 0; }
  .post.post--root .comment-icon__container {
    visibility: visible; }
  .post.post--comment .post__body {
    border-left: 4px solid rgba(0, 0, 0, 0.3);
    padding-left: 7px; }
    .post.post--comment .post__body.mention-comment {
      border-left: 4px solid yellow;
      border-color: yellow; }
  .post.post--comment .attachment .attachment__body__wrap .btn-close {
    left: -11px; }
  .post.same--root.same--user {
    padding: 0 .5em 0 1em; }
    .post.same--root.same--user:hover .post__time, .post.same--root.same--user.post--hovered .post__time {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5; }
    .post.same--root.same--user .flag-icon__container {
      left: 32px;
      margin-left: 7px;
      position: absolute;
      top: 6px; }
    .post.same--root.same--user .post__img .status-wrapper {
      display: none; }
    .post.same--root.same--user .post__img img {
      display: none; }
    .post.same--root.same--user .post__header {
      height: 0;
      margin: 0; }
      .post.same--root.same--user .post__header .col__name {
        display: none; }
      .post.same--root.same--user .post__header .col__reply {
        top: -1px; }
  .post.same--root.post--comment .post__link {
    display: none; }
  .post.same--root.post--comment.same--user .post__img img {
    display: none; }
  .post.other--root .comment-icon__container.icon--show {
    visibility: visible; }
  .post.other--root.post--comment .popover {
    margin-top: 84px; }
    .post.other--root.post--comment .popover > .arrow {
      top: 21px !important; }
  .post.other--root.post--comment .post__header .col__reply {
    top: -4px; }
  .post .post__content {
    display: table;
    margin: 0 auto;
    padding: 0 5px;
    position: relative;
    table-layout: fixed;
    width: 100%; }
    .post .post__content > div {
      display: table-cell;
      vertical-align: top; }
  .post .center {
    max-width: 1000px; }
  .post:hover .post__header {
    padding: 0 55px 0 0; }
  .post .post__header {
    display: flex;
    margin-bottom: 2px;
    padding: 0 40px 0 0;
    white-space: nowrap;
    width: 100%; }
    .post .post__header > div {
      display: inline-block;
      vertical-align: top; }
    .post .post__header .col__name {
      font-weight: 600;
      margin-right: 7px;
      overflow: hidden;
      text-overflow: ellipsis; }
      .post .post__header .col__name .user-popover {
        overflow: hidden;
        *zoom: 1;
        text-overflow: ellipsis;
        vertical-align: top;
        white-space: nowrap; }
      .post .post__header .col__name .colon {
        display: none;
        font-weight: 900;
        margin-left: 2px; }
    .post .post__header .col__reply {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
      border: 1px solid transparent;
      display: flex;
      justify-content: flex-end;
      padding: 4px;
      position: absolute;
      right: 0;
      top: -8px;
      white-space: normal;
      z-index: 6; }
      .post .post__header .col__reply > div,
      .post .post__header .col__reply > button {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        display: flex;
        flex: 1;
        height: 24px;
        top: 0;
        width: 24px; }
      .post .post__header .col__reply .comment-icon__container {
        flex: 0;
        justify-content: flex-end;
        padding: 0 4px 0 0;
        width: auto;
        align-items: center; }
      .post .post__header .col__reply .dropdown > div {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center; }
      .post .post__header .col__reply .reacticon__container,
      .post .post__header .col__reply .icon {
        display: flex;
        flex: 1;
        margin: 0;
        top: 0;
        align-items: center; }
      .post .post__header .col__reply .reacticon__container {
        width: 28px; }
      .post .post__header .col__reply > .open, .post .post__header .col__reply > div {
        display: flex;
        flex: 1;
        justify-content: center; }
      .post .post__header .col__reply span svg {
        flex: 1;
        height: 16px;
        top: 0;
        width: 16px; }
      .post .post__header .col__reply svg {
        flex: 1;
        height: 16px;
        top: 0;
        width: 16px; }
      .post .post__header .col__reply.dot_small {
        min-width: auto !important; }
    .post .post__header .col__remove {
      position: absolute;
      right: 10px; }
    .post .post__header .permalink-popover {
      min-width: 0; }
      .post .post__header .permalink-popover .popover-content {
        padding: 5px; }
      .post .post__header .permalink-popover .form-control,
      .post .post__header .permalink-popover .btn {
        font-size: 13px;
        height: 30px;
        line-height: 30px;
        padding: 0 8px; }
  .post .post__img {
    padding-right: 10px;
    text-align: right;
    width: 53px; }
    .post .post__img .icon svg {
      height: 32px;
      width: 32px; }
    .post .post__img path {
      fill: inherit; }
    .post .post__img img {
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      border-radius: 50px;
      height: 32px;
      max-width: none;
      vertical-align: inherit;
      width: 32px; }
  .post .post__embed-container {
    -webkit-transition: max-height 0.5 ease;
    -webkit-transition-delay: 0s;
    -moz-transition: max-height 0.5 ease;
    -o-transition: max-height 0.5 ease;
    transition: max-height 0.5 ease false;
    display: block;
    padding-top: 5px;
    max-height: 1000px;
    overflow: hidden; }
    .post .post__embed-container[hidden] {
      max-height: 0; }
  .post .dropdown {
    display: inline-block;
    top: -1px;
    vertical-align: middle;
    visibility: hidden; }
    .post .dropdown:last-child {
      margin-right: 0; }
    .post .dropdown .dropdown-menu {
      left: auto;
      min-width: 130px;
      padding: 2px 0;
      right: 0;
      top: 20px; }
      .post .dropdown .dropdown-menu li {
        display: block; }
      .post .dropdown .dropdown-menu a {
        padding: 5px 15px; }
  .post .post__dropdown {
    display: flex;
    justify-content: center;
    text-decoration: none;
    vertical-align: middle;
    width: 100%; }
    .post .post__dropdown:after {
      content: '...';
      font-family: 'Open Sans', sans-serif;
      position: relative;
      top: -1px;
      width: 100%; }
  .post .post__remove {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    color: inherit;
    display: inline-block;
    float: right;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
    position: relative;
    right: -10px;
    text-decoration: none;
    vertical-align: top;
    visibility: hidden;
    width: 20px; }
    .post .post__remove:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8; }
  .post .post-add-reaction {
    display: inline-block; }
    .post .post-add-reaction .post-reaction {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      visibility: hidden; }
  .post .post__body {
    *zoom: 1;
    padding: .2em .5em;
    width: 100%;
    word-wrap: break-word; }
    .post .post__body:after {
      content: " ";
      display: block;
      height: 0;
      clear: both;
      overflow: hidden;
      visibility: hidden; }
    .post .post__body.post--ephemeral {
      padding-right: 20px !important; }
    .post .post__body p + p {
      margin: 0.5em 0 0; }
    .post .post__body ul,
    .post .post__body ol {
      font-size: 13.5px; }
      .post .post__body ul p,
      .post .post__body ol p {
        margin-bottom: 0; }
      .post .post__body ul li ul,
      .post .post__body ul li ol,
      .post .post__body ol li ul,
      .post .post__body ol li ol {
        font-size: 1em;
        margin: 0;
        padding: 0 0 0 20px; }
      .post .post__body ul li.list-item--task-list ul,
      .post .post__body ul li.list-item--task-list ol,
      .post .post__body ol li.list-item--task-list ul,
      .post .post__body ol li.list-item--task-list ol {
        margin-left: 20px; }
      .post .post__body ul li.list-item--task-list ul,
      .post .post__body ol li.list-item--task-list ul {
        padding-left: 0; }
      .post .post__body ul li:not(.list-item--task-list) li.list-item--task-list,
      .post .post__body ul li:not(.list-item--task-list) li.list-item--task-list ~ li,
      .post .post__body ol li:not(.list-item--task-list) li.list-item--task-list,
      .post .post__body ol li:not(.list-item--task-list) li.list-item--task-list ~ li {
        margin-left: -20px; }
      .post .post__body ul li input[type='checkbox']:disabled,
      .post .post__body ol li input[type='checkbox']:disabled {
        cursor: default;
        vertical-align: top; }
    .post .post__body ul li.list-item--task-list,
    .post .post__body ul li.list-item--task-list ~ li {
      list-style-type: none; }
    .post .post__body ul li.list-item--task-list ~ li:not(.list-item--task-list) {
      text-indent: 3px; }
      .post .post__body ul li.list-item--task-list ~ li:not(.list-item--task-list):before {
        content: '\2022';
        margin-right: 8px; }
    .post .post__body ul + p,
    .post .post__body ol + p {
      margin-top: .6em; }
    .post .post__body p + ul,
    .post .post__body p + ol {
      margin-top: .6em; }
    .post .post__body .pending-post-actions {
      background: rgba(0, 0, 0, 0.7);
      color: white;
      font-size: .9em;
      padding: 5px 7px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100; }
      .post .post__body .pending-post-actions a {
        color: white; }
    .post .post__body .post-reaction-list {
      min-height: 30px;
      position: relative;
      z-index: 5; }
      .post .post__body .post-reaction-list .post-add-reaction-emoji-picker-open {
        display: inline-block; }
        .post .post__body .post-reaction-list .post-add-reaction-emoji-picker-open .post-reaction {
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
          opacity: 1;
          visibility: visible; }
    .post .post__body .post-reaction {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      border: 1px solid #2389d7;
      cursor: pointer;
      display: inline-block;
      font-size: 11px;
      height: 23px;
      margin-bottom: 5px;
      margin-right: 5px;
      padding: 0 4px 0 3px; }
      .post .post__body .post-reaction .post-reaction__emoji {
        margin: 2px 3px 0 0;
        max-height: 16px;
        max-width: 16px;
        vertical-align: middle; }
      .post .post__body .post-reaction .post-reaction__count {
        line-height: 10px;
        position: relative;
        top: 3px; }
      .post .post__body .post-reaction .post-reaction__add {
        font-size: 20px;
        line-height: 0;
        margin: 0 5px;
        position: relative;
        top: 1px;
        vertical-align: middle; }
      .post .post__body .post-reaction--current-user .post-reaction__count {
        font-weight: 600; }
      .post .post__body .post-reaction--read-only {
        cursor: default; }
  .post .post__link {
    font-size: 13px;
    margin: 2px 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .post .post__link a {
      font-weight: bold; }
  .post .post__embed-visibility {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    margin: 0;
    text-rendering: auto;
    position: relative;
    z-index: 5; }
    .post .post__embed-visibility.pull-left {
      margin: 4px 5px 0 0; }
    .post .post__embed-visibility:hover {
      text-decoration: none; }
    .post .post__embed-visibility:before {
      content: '\F065';
      font-size: 14px; }
    .post .post__embed-visibility[data-expanded='true']:before {
      content: '\F066'; }
  .post .post__visibility {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    font-size: .9em;
    padding: 8px; }
  .post .post__time {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    font-size: .9em; }
  .post .post__permalink {
    color: inherit; }
    .post .post__permalink:hover, .post .post__permalink:focus {
      color: inherit; }
    .post .post__permalink:focus {
      text-decoration: none; }
    .post .post__permalink:hover {
      text-decoration: underline; }
  .post .post-loading-gif {
    height: 10px;
    margin-top: 6px;
    width: 10px; }
  .post .post-fail {
    color: #d58a8a; }
  .post .post-waiting {
    color: #999999; }
  .post .permalink-icon {
    color: #2389d7;
    display: inline-block;
    visibility: hidden; }
  .post .post__reply {
    display: inline-block;
    margin-right: 6px;
    visibility: hidden; }
    .post .post__reply svg {
      fill: inherit;
      position: relative;
      top: 3px;
      width: 18px; }
  .post .comment-icon__container {
    display: inline-block;
    fill: #2389d7;
    position: relative;
    text-decoration: none;
    top: 1px;
    visibility: hidden; }
    .post .comment-icon__container:focus {
      outline: none; }
    .post .comment-icon__container.icon--visible {
      visibility: visible; }
    .post .comment-icon__container svg {
      height: 17px;
      width: 17px; }
    .post .comment-icon__container .comment-count {
      margin-left: 2px; }
    .post .comment-icon__container .comment-icon {
      display: inline-block;
      fill: inherit;
      margin: 0 1px 0 5px;
      position: relative;
      top: 1px;
      vertical-align: top; }
    .post .comment-icon__container path {
      fill: inherit; }
  .post .reacticon__container {
    display: inline-block;
    fill: #2389d7;
    font-size: 16px;
    vertical-align: top;
    visibility: hidden; }
    .post .reacticon__container svg {
      height: 14px;
      position: relative;
      top: 1px;
      width: 14px; }
  .post .flag-icon__container {
    display: inline-block;
    height: 15px;
    margin-left: 7px;
    position: relative;
    top: 2px;
    vertical-align: top;
    visibility: hidden; }
    .post .flag-icon__container.visible {
      visibility: visible; }
    .post .flag-icon__container svg {
      width: 12px; }
    .post .flag-icon__container path {
      fill: inherit; }
    .post .flag-icon__container .fa-star-o {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8; }
    .post .flag-icon__container:focus {
      outline: none; }
    .post .flag-icon__container.icon--visible {
      visibility: visible; }
  .post .web-embed-data {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.05);
    height: 50px;
    overflow: hidden;
    padding: 2px 0 0 10px;
    text-overflow: ellipsis; }
    .post .web-embed-data .embed-title {
      color: #555555;
      font-weight: 600;
      margin: 3px 0 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .post .web-embed-data .embed-description {
      color: #888888;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .post .web-embed-data .embed-link {
      display: none; }

.bot-indicator,
.post__pinned-badge {
  border-radius: 2px;
  font-family: inherit;
  font-size: 10px;
  font-weight: 600;
  padding: 1px 4px; }

.bot-indicator {
  margin: 2px 10px 0 -4px; }

.post__pinned-badge {
  margin-left: 7px;
  position: relative;
  top: -1px; }

.permalink-text {
  overflow: hidden; }

.permalink-popover {
  margin-left: 50px !important;
  min-width: 320px; }

.post-message {
  position: relative;
  overflow: hidden; }

.post-collapse {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  width: 100%; }
  .post-collapse .post-collapse__gradient, .post-collapse .post-attachment-collapse__gradient {
    position: relative; }
  .post-collapse .post-collapse__show-more, .post-collapse .post-attachment-collapse__show-more {
    pointer-events: auto; }

.post-collapse__show-more {
  padding-bottom: 10px; }

.post-collapse__show-more, .post-attachment-collapse__show-more {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5; }
  .post-collapse__show-more .post-collapse__show-more-button, .post-attachment-collapse__show-more .post-collapse__show-more-button {
    -webkit-transition: all 0.15s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease false;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid transparent;
    display: inline-block;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 8px;
    padding: 0 8px;
    position: relative; }
    .post-collapse__show-more .post-collapse__show-more-button .fa, .post-attachment-collapse__show-more .post-collapse__show-more-button .fa {
      font-size: 1.4em;
      font-weight: bold;
      margin-right: 5px;
      position: relative;
      top: 2px; }
      .post-collapse__show-more .post-collapse__show-more-button .fa.fa-angle-up, .post-attachment-collapse__show-more .post-collapse__show-more-button .fa.fa-angle-up {
        top: 1px; }
    .post-collapse__show-more .post-collapse__show-more-button:focus, .post-attachment-collapse__show-more .post-collapse__show-more-button:focus {
      outline: none; }
  .post-collapse__show-more .post-collapse__show-more-line, .post-attachment-collapse__show-more .post-collapse__show-more-line {
    display: inline-block;
    flex-basis: 200px;
    height: 1px;
    margin-top: 12px; }

.post-message--collapsed .post-message__text-container {
  max-height: 600px; }
  .post-message--collapsed .post-message__text-container .all-emoji .emoticon {
    min-height: 32px;
    min-width: 32px;
    vertical-align: top; }

.post-message--collapsed .post-collapse__gradient, .post-message--collapsed .post-attachment-collapse__gradient {
  height: 90px; }

.post-message--collapsed .post-collapse__show-more {
  bottom: 10px; }

.post-message--expanded .post-collapse {
  position: relative; }

.post-message--expanded .post-collapse__show-more, .post-message--expanded .post-attachment-collapse__show-more {
  position: relative;
  padding-top: 10px; }

.post-message--expanded .post-collapse__gradient, .post-message--expanded .post-attachment-collapse__gradient {
  height: 0; }

.file-attachment-menu-item-input {
  cursor: pointer;
  direction: ltr;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 35px;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.post-right__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%; }
  .post-right__container .post-right-root-message {
    padding: 1em 1em 0; }
  .post-right__container .post-right-comments-container {
    position: relative; }
  .post-right__container .help_format_text {
    bottom: -63px;
    right: auto; }
  .post-right__container .post.post--root {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1em; }
    .post-right__container .post.post--root .post__body {
      background: transparent !important; }
  .post-right__container .post .post__header .col__reply {
    text-align: right; }
  .post-right__container .post .post__body {
    width: 100%; }
  .post-right__container .post.post--compact.same--root.post--comment {
    padding-left: 1em; }
    .post-right__container .post.post--compact.same--root.post--comment .post__header {
      margin-left: 7px;
      float: none;
      height: auto; }
      .post-right__container .post.post--compact.same--root.post--comment .post__header .post__time {
        opacity: .6; }
    .post-right__container .post.post--compact.same--root.post--comment .flag-icon__container {
      left: auto;
      position: relative;
      top: 2px; }
  .post-right__container .post.post--compact.same--user .post__header .col__name {
    display: inline-block; }
  .post-right__container hr {
    border: none; }
  .post-right__container .date-separator hr {
    border-top: 1px solid #eee; }
  .post-right__container .post-create__container {
    width: 100%; }
    .post-right__container .post-create__container .btn {
      margin-bottom: 10px; }
      .post-right__container .post-create__container .btn.disabled {
        background: grey !important;
        border-color: transparent !important; }
    .post-right__container .post-create__container .custom-textarea {
      max-height: calc(50vh - 80px);
      min-height: 100px; }
    .post-right__container .post-create__container .msg-typing {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7;
      display: block;
      float: left;
      font-size: 13px;
      height: 20px;
      line-height: 20px;
      margin-top: 3px;
      max-width: 230px;
      min-width: 1px; }
    .post-right__container .post-create__container .post-right-comments-upload-in-progress {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7;
      margin-right: 10px;
      padding: 6px 0; }
    .post-right__container .post-create__container .emoji-picker {
      position: absolute;
      top: -361px;
      right: 0px; }

.post-right-header {
  border-bottom: 1px solid #ddd;
  color: #999;
  font-size: 1em;
  font-weight: 400;
  height: 39px;
  padding: 10px 10px 0 15px;
  text-transform: uppercase; }

.post-right-root-container {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px; }
  .post-right-root-container ul {
    margin-bottom: 2px;
    padding-left: 0; }

.post-right-channel__name {
  font-weight: 600;
  margin: 0 0 15px; }

.post-right-root-container li {
  display: inline;
  list-style-type: none;
  padding-right: 3px; }

.post-right-root-time {
  color: #a8adb7; }

.post-right-create-comment-container {
  bottom: 0;
  left: 0;
  margin-bottom: 18px;
  padding: 5px;
  position: absolute;
  width: 100%; }

.post-right__scroll {
  -webkit-overflow-scrolling: touch;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-top: 10px;
  position: relative; }
  .post-right__scroll .file-preview__container {
    margin-top: 5px; }

.post-right-comment-time {
  color: #a8adb7; }

.sidebar--left {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  height: 100%;
  left: 0;
  position: fixed;
  width: 220px;
  z-index: 10; }
  body.announcement-bar--fixed .sidebar--left {
    height: calc(100% - 24px); }
  .sidebar--left.sidebar--padded {
    padding-top: 44px; }
  .sidebar--left .sidebar--left__icons {
    border-bottom: 1px solid transparent; }
  .sidebar--left .sidebar-item .icon {
    display: inline-block;
    margin: 0 7px 0 1px;
    position: relative;
    text-align: left;
    vertical-align: top;
    width: 15px; }
  .sidebar--left .sidebar-item .icon__lock {
    top: 2px; }
  .sidebar--left .sidebar-item .icon__globe {
    top: 1px; }
  .sidebar--left .sidebar-item .icon__archive {
    top: 2px; }
  .sidebar--left .sidebar-item .icon__draft {
    top: 2px; }
  .sidebar--left .sidebar-item__name {
    display: flex;
    flex: 1;
    padding-right: 10px;
    overflow: hidden;
    vertical-align: top; }
    .sidebar--left .sidebar-item__name > span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%; }
  .sidebar--left .sidebar__divider {
    color: white;
    font-size: .9em;
    height: 20px;
    line-height: 20px;
    margin: 5px 0 6px;
    position: relative;
    z-index: 0; }
    .sidebar--left .sidebar__divider:before {
      background: rgba(0, 0, 0, 0.15);
      content: '';
      height: 1px;
      position: absolute;
      top: 10px;
      width: 100%; }
    .sidebar--left .sidebar__divider .sidebar__divider__text {
      background: #2389d7;
      float: right;
      padding: 0 10px;
      position: relative;
      z-index: 1; }
  .sidebar--left .sidebar__switcher {
    flex: 0 0 45px;
    bottom: 0;
    display: block;
    padding-bottom: 0;
    width: 100%; }
    .sidebar--left .sidebar__switcher button {
      background: transparent;
      display: block;
      font-weight: 600;
      height: 45px;
      line-height: 40px;
      padding: 0;
      text-decoration: none;
      width: 100%; }
      .sidebar--left .sidebar__switcher button > span {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        z-index: 5; }
      .sidebar--left .sidebar__switcher button:hover span {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
    .sidebar--left .sidebar__switcher .switch__shortcut {
      margin-left: 4px; }
  .sidebar--left .dropdown-menu {
    max-height: 80vh;
    max-width: 270px;
    overflow-x: hidden;
    overflow-y: auto; }
  .sidebar--left .search__form {
    display: none;
    margin: 0;
    padding: 1em 1em 0; }
  .sidebar--left .nav-pills > li .badge {
    margin: 0 10px 0 0; }
  .sidebar--left .sidebar--left__list {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
  .sidebar--left .nav-pills__container {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    position: relative;
    width: 100%;
    padding-bottom: 10px; }
  .sidebar--left .nav-pills__unread-indicator {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    font-size: 13.5px;
    cursor: pointer;
    left: 15px;
    margin: 0 auto;
    padding: 4px 0 3px;
    position: absolute;
    text-align: center;
    width: 190px;
    z-index: 1; }
    .sidebar--left .nav-pills__unread-indicator .icon {
      margin-left: 5px; }
  .sidebar--left .nav-pills__unread-indicator-top {
    top: 15px; }
    .sidebar--left .nav-pills__unread-indicator-top .icon svg {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); }
  .sidebar--left .nav-pills__unread-indicator-bottom {
    bottom: 15px; }
  .sidebar--left .nav.nav-stacked > li + li {
    margin: 0; }
  .sidebar--left .nav .public_direct_name {
    flex: 1;
    cursor: pointer; }
  .sidebar--left .nav li > h4 {
    align-items: end;
    display: flex;
    font-size: 1em;
    font-weight: 600;
    margin: 1.5em 0 .7em;
    padding: 0 12px 0 15px;
    text-transform: uppercase; }
    .sidebar--left .nav li > h4 span {
      flex: 1; }
  .sidebar--left .nav li .divider + .divider {
    display: none; }
  .sidebar--left .nav li > .nav-more {
    border-radius: 0;
    display: block;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding: 5px 0 5px 15px;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .sidebar--left .nav li .sidebar-item {
    -webkit-transition: none 1s;
    -webkit-transition-delay: 0s;
    -moz-transition: none 1s;
    -o-transition: none 1s;
    transition: none 1s false false;
    align-items: center;
    border-radius: 0;
    display: flex;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding: 5px 5px 5px 15px;
    text-decoration: none;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
    .sidebar--left .nav li .sidebar-item .status {
      flex: 0 0 auto; }
    .sidebar--left .nav li .sidebar-item .icon.icon__archive, .sidebar--left .nav li .sidebar-item .icon.icon__globe, .sidebar--left .nav li .sidebar-item .icon.icon__draft, .sidebar--left .nav li .sidebar-item .icon.icon__lock {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
      opacity: 0.5; }
    .sidebar--left .nav li .sidebar-item.has-close:hover .btn-close {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
      display: block; }
    .sidebar--left .nav li .sidebar-item.has-close .btn-close {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      display: none;
      font-family: 'Open Sans', sans-serif;
      font-size: 21px;
      font-weight: 600;
      position: relative;
      width: 24px;
      height: 20px;
      line-height: 20px;
      margin-right: 7px;
      text-align: center; }
      .sidebar--left .nav li .sidebar-item.has-close .btn-close:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
    .sidebar--left .nav li .sidebar-item.unread-title {
      font-weight: 600; }
    .sidebar--left .nav li .sidebar-item.muted {
      opacity: 0.5; }
  .sidebar--left .nav li.active .muted {
    opacity: 1; }
  .sidebar--left .nav li.active button:before, .sidebar--left .nav li.active a:before {
    background: black;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px; }
  .sidebar--left .nav li.active .icon.icon__archive, .sidebar--left .nav li.active .icon.icon__globe, .sidebar--left .nav li.active .icon.icon__draft, .sidebar--left .nav li.active .icon.icon__lock {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7; }
  .sidebar--left .nav li.active button, .sidebar--left .nav li.active a,
  .sidebar--left .nav li.active button:hover, .sidebar--left .nav li.active a:hover,
  .sidebar--left .nav li.active button:focus, .sidebar--left .nav li.active a:focus {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0;
    font-weight: 400; }
    .sidebar--left .nav li.active button.unread-title, .sidebar--left .nav li.active a.unread-title,
    .sidebar--left .nav li.active button:hover.unread-title, .sidebar--left .nav li.active a:hover.unread-title,
    .sidebar--left .nav li.active button:focus.unread-title, .sidebar--left .nav li.active a:focus.unread-title {
      font-weight: 600; }
  .sidebar--left .modal-title {
    line-height: 2em; }
  .sidebar--left .add-channel-btn {
    float: right;
    font-size: 1.9em;
    font-weight: 700;
    line-height: 18px;
    margin: -2px 0 0 0;
    outline: none;
    padding: 0 5px;
    text-decoration: none; }
    .sidebar--left .add-channel-btn:hover {
      color: #666666; }
  .sidebar--left .status-wrapper {
    height: 36px;
    width: 36px; }
    .sidebar--left .status-wrapper .status {
      bottom: -4px;
      height: 18px;
      right: -5px;
      width: 18px; }
      .sidebar--left .status-wrapper .status.status-edit .fa {
        top: 4px; }
      .sidebar--left .status-wrapper .status .fa {
        position: relative;
        top: 2px; }
      .sidebar--left .status-wrapper .status .icon__container:after {
        border-radius: 20px;
        content: '';
        height: 10px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 10px; }
      .sidebar--left .status-wrapper .status svg {
        height: 13px;
        left: 3px;
        max-height: initial;
        position: relative;
        top: 3px;
        width: 13px;
        z-index: 1; }

.channel-loading-gif {
  height: 15px;
  margin-top: 2px;
  width: 15px; }

.join-channel-loading-gif {
  height: 25px;
  margin-left: 10px;
  margin-top: 5px;
  width: 25px; }

/* context menu shown when right-clicking on a channel in the LHS when using the desktop app */
.react-contextmenu--visible {
  background: #f0f0f0;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  padding: 2px 0;
  z-index: 100; }

.react-contextmenu-item {
  margin: 1px 0;
  padding: 0 22px; }
  .react-contextmenu-item:hover {
    background: #489DFE;
    color: white; }
  .react-contextmenu-item:focus,
  .react-contextmenu-item span {
    outline: none; }

.sidebar--menu {
  -webkit-overflow-scrolling: touch;
  background: #fafafa;
  border-right: 1px solid #ddd;
  display: none;
  height: 100%;
  overflow: auto;
  padding: 0 0 2em;
  position: absolute;
  right: 0;
  width: 220px; }
  .sidebar--menu .nav-pills__container {
    padding-top: 5px; }
  .sidebar--menu .team__header {
    *zoom: 1;
    display: none;
    padding: 0 15px; }
    .sidebar--menu .team__header:after {
      content: " ";
      display: block;
      height: 0;
      clear: both;
      overflow: hidden;
      visibility: hidden; }
    .sidebar--menu .team__header a {
      color: white; }
    .sidebar--menu .team__header .navbar-right {
      font-size: .85em;
      margin: 16px -5px 0; }
      .sidebar--menu .team__header .navbar-right .dropdown-toggle {
        padding: 0 10px; }
      .sidebar--menu .team__header .navbar-right .dropdown-menu li a {
        color: #555;
        padding: 3 20px; }
      .sidebar--menu .team__header .navbar-right .dropdown__icon {
        -webkit-background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        -o-background-size: 100% 100%;
        background-size: 100% 100%;
        display: inline-block;
        height: 16px;
        width: 4px; }
    .sidebar--menu .team__header .team__name {
      float: left;
      font-size: 1.2em;
      font-weight: 600;
      line-height: 50px;
      max-width: 80%;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .sidebar--menu .nav > li > a,
  .sidebar--menu .nav > li > button {
    background: none !important;
    color: inherit;
    font-size: 15px;
    line-height: 40px;
    padding: 0 15px; }
    .sidebar--menu .nav > li > a svg,
    .sidebar--menu .nav > li > button svg {
      fill-opacity: 0.85;
      height: 18px;
      position: relative;
      top: 4px;
      width: 18px; }
    .sidebar--menu .nav > li > a .icon,
    .sidebar--menu .nav > li > button .icon {
      display: inline-block;
      left: -5px;
      position: relative;
      text-align: center;
      width: 25px; }
    .sidebar--menu .nav > li > a .mentions,
    .sidebar--menu .nav > li > button .mentions {
      font-size: 17px;
      font-weight: bold; }

.sidebar--right {
  -webkit-transform: translateX(400px);
  -moz-transform: translateX(400px);
  -ms-transform: translateX(400px);
  -o-transform: translateX(400px);
  transform: translateX(400px);
  background: white;
  height: 100%;
  padding: 0;
  position: fixed;
  right: 0;
  width: 400px;
  z-index: 8; }
  body.announcement-bar--fixed .sidebar--right {
    height: calc(100% - 24px); }
  .sidebar--right.sidebar--right--expanded {
    z-index: 15; }
  .sidebar--right .sidebar--right__bg {
    -webkit-transition: background-color 0.5s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease false;
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 300%; }
  .sidebar--right.move--left .sidebar--right__bg {
    left: -100%; }
  .sidebar--right .sidebar-right__table {
    display: table; }
    .sidebar--right .sidebar-right__table > div:not(.sidebar-collapse__container) {
      display: table-cell;
      vertical-align: top; }
      .sidebar--right .sidebar-right__table > div:not(.sidebar-collapse__container):last-child .channel-header__icon {
        margin-right: 16px; }
    .sidebar--right .sidebar-right__table .search-form__container {
      width: 100%; }
  .sidebar--right .post-body img {
    max-height: 200px; }
  .sidebar--right .post.post--compact .post__pinned-badge {
    margin: 0 0 0 5px; }
  .sidebar--right .post.post--comment .post__body {
    border-left: none; }
  .sidebar--right .post .post__content {
    padding: 0 10px 0 0; }
  .sidebar--right .post .post__header .col__name .user-popover {
    width: 100%; }
  .sidebar--right .post .post__header .col__name + .col {
    flex: auto; }
  .sidebar--right .post .post__header .col__name.bot-indicator {
    flex: none; }
  .sidebar--right .post .post__header .flag-icon__container {
    z-index: 5; }
  .sidebar--right .post-create__container form {
    padding: .5em 15px 0; }
  .sidebar--right .post-create__container .post-create-footer {
    overflow: hidden;
    *zoom: 1;
    clear: both;
    font-size: 13px;
    overflow: visible;
    position: relative; }
    .sidebar--right .post-create__container .post-create-footer .post-error {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=55);
      opacity: 0.55;
      display: inline-block;
      font-size: .85em;
      font-weight: normal;
      margin-bottom: 0;
      position: absolute;
      top: -25px; }
  .sidebar--right .channel-archived-warning {
    padding: 20px; }
  .sidebar--right .help__format-text {
    display: none; }
  .sidebar--right .sidebar--right__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: 100%; }
  .sidebar--right .sidebar--right__back {
    -webkit-transition: all 0.2s ease-in;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in false;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    color: inherit;
    display: inline-block;
    font-size: 26px;
    margin-left: -14px;
    text-align: center;
    vertical-align: top;
    width: 30px; }
    .sidebar--right .sidebar--right__back:hover, .sidebar--right .sidebar--right__back:active {
      color: inherit;
      opacity: .8; }
    .sidebar--right .sidebar--right__back i {
      position: relative;
      top: -2px; }
  .sidebar--right .sidebar-right__body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #ddd;
    height: calc(100% - 56px); }
  .sidebar--right .sidebar__overlay {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
    opacity: 0.1;
    background-color: yellow;
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 5; }
  .sidebar--right .input-group {
    word-break: break-word; }
  .sidebar--right .sidebar--right__buttons {
    float: right; }
  .sidebar--right .sidebar--right__close, .sidebar--right .sidebar--right__expand {
    -webkit-transition: all 0.2s ease-in;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in false;
    background: none;
    border: none;
    font-size: 20px;
    height: 22px;
    line-height: 0;
    margin: 11px 0 0;
    opacity: .5;
    outline: none;
    padding: 0;
    width: 30px; }
    .sidebar--right .sidebar--right__close:hover, .sidebar--right .sidebar--right__expand:hover, .sidebar--right .sidebar--right__close:active, .sidebar--right .sidebar--right__expand:active {
      opacity: .8; }
    .sidebar--right .sidebar--right__close i, .sidebar--right .sidebar--right__expand i {
      position: relative; }
  .sidebar--right .sidebar--right__expand {
    font-size: 17px; }
    .sidebar--right .sidebar--right__expand i {
      top: -2px; }
    .sidebar--right .sidebar--right__expand .fa-compress {
      display: none; }
  .sidebar--right .sidebar--right__title {
    overflow: hidden;
    *zoom: 1;
    padding-left: 15px; }
  .sidebar--right .sidebar--right__header {
    -webkit-flex: 0 0 44px;
    flex: 0 0 44px;
    border-bottom: 1px solid;
    color: inherit;
    font-size: 1em;
    height: 44px;
    line-height: 44px;
    overflow: hidden;
    padding: 0 5px 0 0;
    text-transform: uppercase; }
  .sidebar--right .sidebar--right__loading {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    text-align: center; }
    .sidebar--right .sidebar--right__loading .fa {
      margin-right: 5px; }
  .sidebar--right .sidebar--right__subheader {
    font-size: 1em;
    padding: .5em 1em 0; }
    .sidebar--right .sidebar--right__subheader h4 {
      font-size: 1em; }
    .sidebar--right .sidebar--right__subheader ul {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7;
      padding: 10px 0 0 30px; }
    .sidebar--right .sidebar--right__subheader li {
      font-size: .95em;
      padding-bottom: 10px; }
    .sidebar--right .sidebar--right__subheader .usage__icon {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
      opacity: 0.9;
      margin: 0 3px;
      position: relative;
      top: 3px; }
  .sidebar--right .suggestion-list__content {
    max-height: 120px; }

.sidebar-right-container {
  height: 100%; }

.sidebar-collapse__container {
  display: none; }

.sidebar-collapse {
  -webkit-transition: all 0.2s linear;
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear false;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  cursor: pointer;
  font-size: 16px;
  height: 48px;
  line-height: 0;
  padding-left: 1px;
  text-align: center;
  width: 45px;
  z-index: 5; }
  .sidebar-collapse .fa {
    position: relative;
    top: 18px;
    right: 2px; }

.team-sidebar {
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  width: 65px;
  z-index: 12; }
  .team-sidebar .fa {
    -webkit-transition: all 0.15s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease false;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    color: white; }
    .team-sidebar .fa:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  .team-sidebar .team-sidebar-bottom-plugin {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 15px; }
  .team-sidebar .team-btn__content {
    -webkit-transition: all 0.25s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease false;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    height: 100%; }
  .team-sidebar .team-btn {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid transparent;
    font-weight: bold;
    height: 44px;
    letter-spacing: -.5px;
    line-height: 42px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    vertical-align: top;
    width: 44px; }
    .team-sidebar .team-btn:hover .team-btn__content {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8; }
    .team-sidebar .team-btn .team-btn__image {
      overflow: hidden;
      *zoom: 1;
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      background-size: 100% 100%;
      background-color: white;
      background-repeat: no-repeat;
      width: 43px;
      height: 100%; }
    .team-sidebar .team-btn.team-btn__add {
      font-size: 25px;
      line-height: 41px;
      vertical-align: middle;
      background-color: rgba(0, 0, 0, 0.3); }
  .team-sidebar .team-wrapper {
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    overflow-x: inherit;
    overflow-y: auto;
    padding-top: 15px; }
    .team-sidebar .team-wrapper .team-container {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      position: relative;
      text-align: center;
      width: 100%; }
      .team-sidebar .team-wrapper .team-container a {
        display: block;
        height: 100%;
        text-decoration: none; }
      .team-sidebar .team-wrapper .team-container.active .team-btn__content {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
      .team-sidebar .team-wrapper .team-container button {
        border: none;
        padding: 0;
        text-decoration: none; }
      .team-sidebar .team-wrapper .team-container.active:before {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        background: black;
        content: '';
        height: 42px;
        left: 0;
        position: absolute;
        top: 1px;
        width: 5px; }
      .team-sidebar .team-wrapper .team-container.unread:before {
        background: black;
        border-radius: 50%;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        top: 18px;
        width: 5px; }
      .team-sidebar .team-wrapper .team-container a.team-disabled {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        opacity: 0.5;
        cursor: not-allowed; }
    .team-sidebar .team-wrapper .team-container a:hover {
      text-decoration: none; }

.multi-teams .sidebar--left {
  left: 65px; }

.multi-teams .app__content {
  margin-left: 285px; }

.webhooks__container {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid;
  margin-top: 10px;
  padding: 0 13px 15px; }

.post-attachment-dropdown .select-suggestion-container:after {
  left: 20rem; }

.post-attachment-dropdown .form-control {
  width: 22rem; }

.webhook__item {
  font-size: 13px;
  position: relative; }
  .webhook__item:last-child .divider-light:last-child {
    display: none; }
  .webhook__item .webhook__remove {
    color: #e05f5d;
    font-size: 22px;
    font-weight: bold;
    height: 30px;
    position: absolute;
    right: -7px;
    text-align: center;
    text-decoration: none;
    top: 8px;
    width: 30px; }
  .webhook__item .webhook__url {
    padding-right: 20px; }

.post.post--comment .attachment .attachment__body__wrap .btn-close {
  left: -11px; }

.sidebar--right .post .attachment .attachment-actions .alert {
  position: relative;
  left: 0;
  top: 0;
  margin: 1rem 0 0.4rem;
  display: inline-block; }

.post .attachment {
  margin-left: -20px;
  position: relative; }
  .post .attachment:hover .attachment__body__wrap .btn-close {
    visibility: visible; }
  .post .attachment.attachment--opengraph {
    max-width: 800px; }
  .post .attachment .attachment__content {
    border-radius: 0 4px 4px 0;
    border-style: solid;
    border-width: 1px;
    margin: 5px 0 5px 20px;
    border-left: none; }
  .post .attachment .attachment__thumb-pretext {
    background: transparent;
    border: none;
    margin-left: 25px; }
  .post .attachment .attachment__container {
    border-left-style: solid;
    border-left-width: 4px;
    padding: 12px; }
    .post .attachment .attachment__container.attachment__container--good {
      border-left-color: #00c100; }
    .post .attachment .attachment__container.attachment__container--warning {
      border-left-color: #dede01; }
    .post .attachment .attachment__container.attachment__container--danger {
      border-left-color: #e40303; }
    .post .attachment .attachment__container.attachment__container--opengraph {
      display: table;
      margin: 0;
      padding-bottom: 13px;
      table-layout: fixed;
      width: 100%; }
      .post .attachment .attachment__container.attachment__container--opengraph div {
        margin: 0; }
    .post .attachment .attachment__container .sitename {
      color: #A3A3A3; }
  .post .attachment .attachment__body__wrap.attachment__body__wrap--opengraph {
    display: table-cell;
    vertical-align: top;
    width: 100%; }
  .post .attachment .attachment__body__wrap .btn-close {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
    background: transparent;
    border: none;
    color: inherit;
    font-size: 21px;
    font-weight: 500;
    height: 20px;
    left: -7px;
    line-height: 20px;
    outline: none;
    padding: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    visibility: hidden;
    width: 20px;
    z-index: 5; }
    .post .attachment .attachment__body__wrap .btn-close span {
      font-family: 'Open Sans', sans-serif;
      line-height: 10px; }
    .post .attachment .attachment__body__wrap .btn-close:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
      opacity: 0.9; }
  .post .attachment .attachment__body__wrap:hover .btn-close {
    visibility: visible; }
  .post .attachment .attachment__body {
    float: left;
    padding-right: 5px;
    width: 80%; }
    .post .attachment .attachment__body.attachment__body--no_thumb {
      width: 100%; }
    .post .attachment .attachment__body.attachment__body--opengraph {
      float: none;
      padding-right: 0;
      width: 100%;
      word-wrap: break-word; }
  .post .attachment .attachment__image__container--opengraph {
    display: table-cell;
    padding-left: 15px;
    padding-top: 3px;
    vertical-align: top;
    width: 95px; }
  .post .attachment .attachment__image {
    margin-bottom: 1em;
    max-height: 300px;
    max-width: 500px;
    border: 1px solid transparent; }
    .post .attachment .attachment__image.attachment__image--opengraph {
      margin-bottom: 0;
      max-height: 80px;
      max-width: 100%; }
      .post .attachment .attachment__image.attachment__image--opengraph.loading {
        height: 80px;
        border-color: transparent; }
      .post .attachment .attachment__image.attachment__image--opengraph.large_image {
        border-radius: 3px;
        margin-top: 10px;
        max-height: 200px;
        max-width: 400px;
        width: 100%; }
        .post .attachment .attachment__image.attachment__image--opengraph.large_image.loading {
          height: 150px;
          border-color: transparent; }
  .post .attachment .attachment__author-name {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6; }
  .post .attachment .attachment__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin: 5px 0;
    padding: 0; }
    .post .attachment .attachment__title.has-link {
      color: #2f81b7;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .post .attachment .attachment__title.attachment__title--opengraph {
      height: auto;
      word-wrap: break-word; }
      .post .attachment .attachment__title.attachment__title--opengraph.is-url {
        word-break: break-all; }
  .post .attachment .attachment__author-icon {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    height: 14px;
    margin-right: 5px;
    width: 14px; }
  .post .attachment .attachment__thumb-container {
    float: right;
    text-align: right;
    width: 80px; }
    .post .attachment .attachment__thumb-container img {
      max-height: 75px;
      max-width: 100%; }
  .post .attachment .attachment-fields {
    width: 100%; }
    .post .attachment .attachment-fields .attachment-field__caption {
      font-weight: 600;
      padding-top: .7em; }
    .post .attachment .attachment-fields .attachment-field p {
      margin: 0; }
  .post .attachment .attachment-actions {
    margin-top: 9px;
    position: relative;
    display: flex;
    flex-wrap: wrap; }
    .post .attachment .attachment-actions button {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      outline: 0;
      margin: 8px 8px 0 0;
      border-width: 1px;
      border-style: solid;
      height: 30px;
      font-size: 13px;
      font-weight: 700;
      white-space: nowrap; }
    .post .attachment .attachment-actions .alert {
      position: absolute;
      top: 0;
      left: 23rem;
      padding: 5px 10px; }

@media screen and (max-width: 1800px) {
  .inner-wrap.move--left .date-separator.hovered--comment:before, .inner-wrap.move--left .date-separator.hovered--comment:after,
  .inner-wrap.move--left .new-separator.hovered--comment:before,
  .inner-wrap.move--left .new-separator.hovered--comment:after {
    background: none; } }

@media screen and (max-width: 1440px) {
  .inner-wrap.move--left .help__format-text {
    display: none; }
  .date-separator.hovered--comment:before, .date-separator.hovered--comment:after,
  .new-separator.hovered--comment:before,
  .new-separator.hovered--comment:after {
    background: none !important; }
  .backstage-content {
    margin: 46px 46px 46px 150px; } }

@media screen and (max-width: 1280px) {
  .inner-wrap.move--left .post-collapse__show-more, .inner-wrap.move--left .post-attachment-collapse__show-more {
    margin-left: 0; }
  .inner-wrap.move--left .post-create__container .channel-archived__close-btn {
    display: block;
    margin: 10px auto 0; } }

@media screen and (max-width: 1140px) {
  .tip-overlay.tip-overlay--chat {
    margin: -10px 0 0 -10px; }
    .tip-overlay.tip-overlay--chat .arrow {
      left: auto;
      right: 15px; }
  .inner-wrap.move--left .file-overlay {
    font-size: 1.28571em; }
    .inner-wrap.move--left .file-overlay .overlay__circle {
      height: 300px;
      margin: -150px 0 0 -150px;
      width: 300px; }
    .inner-wrap.move--left .file-overlay .overlay__files {
      margin: 60px auto 15px;
      width: 150px; } }

@media (max-width: 1024px) {
  .channel-header .search-bar__container .search__form {
    width: 150px; }
  .integration-option {
    width: 280px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 700px; } }

@media screen and (max-width: 1020px) {
  .sidebar--right {
    -webkit-transition: all 0.35s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.35s ease;
    -o-transition: all 0.35s ease;
    transition: all 0.35s ease false;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 10; }
    .sidebar--right.move--left {
      -webkit-transform: translateX(0) !important;
      -moz-transform: translateX(0) !important;
      -ms-transform: translateX(0) !important;
      -o-transform: translateX(0) !important;
      transform: translateX(0) !important; }
      .browser--ie .sidebar--right.move--left {
        -webkit-transform: none   !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        display: block; }
      .sidebar--right.move--left .search-bar__container {
        z-index: 5; }
  .inner-wrap:not(.move--left) {
    margin-right: 0 !important; }
  .inner-wrap.move--left {
    margin-right: 0 !important; }
    .inner-wrap.move--left .channel-header__links {
      position: relative;
      right: auto;
      top: auto; }
  .inner-wrap.move--left .header-list__right, .inner-wrap.move--right .header-list__right {
    z-index: -1; } }

@media screen and (max-width: 960px) {
  .app__body .modal .modal-image .modal-image__wrapper img {
    max-height: calc(100vh - 100px); }
  .app__body .modal .modal-image .modal-image__wrapper > div {
    font-size: 12px;
    min-width: 250px; }
  .app__body .modal .modal-image .modal-image__wrapper .modal-close {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  .app__body .modal .modal-image .modal-image__content {
    max-height: 93vh; }
  .app__body .modal .modal-image .modal-button-bar {
    line-height: 30px;
    padding: 5px 10px 5px 5px;
    bottom: 20px; }
  .post-create__container .channel-archived__close-btn {
    display: block;
    margin: 10px auto 0; }
  .help__format-text {
    display: none; }
  .post-right__scroll .post-create__container .post-create-body .icon__emoji_picker {
    display: none;
    top: -7px; }
  .signup-team__container.branded {
    display: block;
    margin: 0 auto;
    max-width: 380px; }
    .signup-team__container.branded .signup__markdown {
      display: none; }
  .backstage-content {
    margin: 30px;
    max-width: 100%;
    padding: 0; }
  .backstage-sidebar {
    height: auto;
    padding: 30px 15px 0;
    position: relative;
    width: 100%; }
  .post .attachment .attachment__image.attachment__image--opengraph {
    max-height: 70px;
    max-width: 100%; }
    .post .attachment .attachment__image.attachment__image--opengraph.loading {
      height: 70px; } }

@media (min-width: 880px) {
  .post-collapse__show-more {
    padding-right: 60px; }
  .post-attachment-collapse__show-more {
    padding-right: 0; }
  .sidebar--right .post-collapse__show-more, .sidebar--right .post-attachment-collapse__show-more {
    padding-right: 0; } }

@media screen and (min-width: 768px) {
  .col-sm-auto {
    float: left; }
  .second-bar {
    display: none; }
  .channel-header .search-bar__container .search__form.focused {
    width: 300px; }
  .sidebar--right.move--left {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  .sidebar--right.sidebar--right--expanded {
    width: calc(100% - 280px); }
    .sidebar--right.sidebar--right--expanded .sidebar--right__bg {
      background-color: rgba(0, 0, 0, 0.4);
      visibility: visible; }
    .sidebar--right.sidebar--right--expanded .search-bar__container {
      padding-left: 10px; }
    .sidebar--right.sidebar--right--expanded .sidebar-right-container {
      position: relative;
      z-index: 5; }
    .sidebar--right.sidebar--right--expanded .sidebar--right__expand .fa-expand {
      display: none; }
    .sidebar--right.sidebar--right--expanded .sidebar--right__expand .fa-compress {
      display: inline-block; }
  .sidebar--right .sidebar--right__title {
    display: inline-block;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .inner-wrap.move--left {
    margin-right: 400px; }
  .sidebar--right .post.same--root.post--comment:not(.post--compact) .post__pinned-badge {
    margin-left: 7px; }
  .post.post--compact .post-message.post-message--overflow {
    overflow: hidden;
    *zoom: 1;
    margin-top: 22px; }
  .post.post--compact.same--root.same--user .post-message.post-message--overflow {
    margin-top: 0; }
  .post.post--compact.post--comment.other--root .post-message.post-message--overflow {
    margin-top: 0; }
  .post.post--compact.post--thread .post-message.post-message--overflow {
    margin-top: 0; }
  .channel__wrap .post.post--compact .post__time {
    font-size: .85em;
    left: -79px;
    position: absolute;
    text-align: right;
    top: 3px;
    width: 60px; }
  .channel__wrap .post.post--compact .star-icon__container {
    left: -65px;
    position: absolute;
    text-align: right;
    top: 6px;
    width: 60px; }
  .post.post--compact .post__pinned-badge {
    margin-left: 0;
    margin-right: 5px; }
  .post.post--compact:not(.post--thread) {
    padding: 0 .5em 0 77px; }
    .post.post--compact:not(.post--thread) .post__link {
      margin: 3px 0 7px;
      vertical-align: bottom; }
    .post.post--compact:not(.post--thread) span p:last-child {
      margin-bottom: 0; }
    .post.post--compact:not(.post--thread) .post__header {
      float: left;
      height: 21px;
      padding-right: 0;
      padding-top: 2px; }
      .post.post--compact:not(.post--thread) .post__header .col__name {
        font-weight: bold;
        margin-right: 2px;
        padding-right: 5px;
        position: relative;
        z-index: 1; }
      .post.post--compact:not(.post--thread) .post__header .colon {
        display: inline; }
      .post.post--compact:not(.post--thread) .post__header .col__reply {
        top: -6px; }
    .post.post--compact:not(.post--thread).other--root .post__link + .post__body {
      clear: both; }
    .post.post--compact:not(.post--thread).other--root.post--comment .post__header .col__reply {
      top: -6px; }
    .post.post--compact:not(.post--thread) .post-code {
      clear: both; }
    .post.post--compact:not(.post--thread) .post__body {
      width: 100%; }
    .post.post--compact:not(.post--thread) .post__content {
      padding-right: 45px; }
  .post.post--compact .flag-icon__container {
    left: -21px;
    position: absolute;
    top: 4px; }
  .sidebar--right .post.post--compact .flag-icon__container {
    left: auto;
    position: relative;
    top: 1px; }
  .post.post--compact.same--root.same--user {
    padding-left: 77px;
    padding-top: 0; }
    .post.post--compact.same--root.same--user .flag-icon__container {
      left: -21px;
      position: absolute;
      top: 4px; }
    .post.post--compact.same--root.same--user .post__header .col__reply {
      top: -6px; }
    .post.post--compact.same--root.same--user .post__img img {
      display: none; }
    .post.post--compact.same--root.same--user.post--root .post__img img {
      display: block; }
    .post.post--compact.same--root.same--user.post--root .post__time {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
      opacity: 0.6; }
  .post.post--compact.same--root.post--comment {
    padding-top: 1px; }
    .post.post--compact.same--root.post--comment.same--user .post__img img {
      display: none; }
    .post.post--compact.same--root.post--comment .post__header {
      margin-left: 12px; }
  .post.same--root.same--user .post__time {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  .post.same--root.same--user .post__header {
    float: left; }
    .post.same--root.same--user .post__header .col__reply {
      top: -21px; }
    .post.same--root.same--user .post__header .post__pinned-badge {
      margin-right: 5px; }
  .post.same--root.same--user.post--pinned .post__body:before {
    content: '';
    display: block;
    height: 21px;
    width: 1px; }
  .post.same--root.same--user:not(.post--compact) .post__header .post__pinned-badge {
    top: 3px; }
  .sidebar--right .post.same--root.same--user:not(.post--compact) .flag-icon__container {
    left: 26px; }
  .post.same--root.same--user:not(.post--compact) .post__time {
    display: inline-block;
    font-size: 11px;
    left: -18px;
    line-height: 20px;
    position: absolute;
    text-align: right;
    text-rendering: auto;
    top: 4px;
    width: 51px; }
    .sidebar--right .post.same--root.same--user:not(.post--compact) .post__time {
      left: -24px; }
    .post.same--root.same--user:not(.post--compact) .post__time:hover {
      text-decoration: underline; }
  .post.same--root.post--comment:not(.post--compact) .post__pinned-badge {
    margin-left: 10px; } }

@media screen and (max-width: 768px) {
  .textarea-wrapper .help__text {
    display: none; }
  .channel-header .channel-header__icon {
    display: none; }
  .member-role .member-menu,
  .member-drop .member-menu {
    right: 0;
    top: 30px; }
  .post-code {
    word-wrap: normal; }
  .table-responsive {
    border: none; }
  .multi-select__container .btn {
    display: block;
    min-width: 50px; }
  .multi-select__container .Select-value-label {
    max-width: 190px;
    text-overflow: ellipsis; }
  .more-modal__list .more-modal__actions--round {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5; }
  .post-create__container {
    z-index: 2; }
    .post-create__container .post-create-footer {
      padding: 0 10px; }
    .post-create__container form {
      padding: 0; }
    .post-create__container .post-create-body {
      padding: 0; }
      .post-create__container .post-create-body .send-button {
        display: block; }
      .post-create__container .post-create-body .custom-textarea {
        max-height: 162px; }
      .app-content .post-create__container .post-create-body textarea {
        border-left: none;
        border-right: none; }
    .post-create__container .msg-typing {
      display: none; }
    .post-create__container .emoji-picker__container {
      display: none; }
  .suggestion-list__content {
    max-height: 145px; }
  .filtered-user-list .filter-button .btn {
    width: 100%; }
  .settings-modal .modal-body {
    overflow: auto; }
  #header-popover {
    -webkit-transition: all 0.35s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.35s ease;
    -o-transition: all 0.35s ease;
    transition: all 0.35s ease false;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.9);
    border: none;
    height: calc(100% - 50px);
    max-width: 100%;
    position: fixed;
    top: 40px !important;
    width: 100%; }
    #header-popover.in {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    #header-popover a {
      color: #0091ff; }
    #header-popover .arrow {
      display: none; }
    #header-popover .popover-content {
      color: white;
      font-size: 15px;
      padding: 15px 20px 100px; }
      #header-popover .popover-content > div:first-child {
        -webkit-overflow-scrolling: touch;
        height: calc(100vh - 150px);
        overflow: auto; }
    #header-popover .close {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      border: 1px solid white;
      bottom: 25px;
      color: white;
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 23px;
      font-weight: 200;
      height: 30px;
      left: 50%;
      line-height: 0;
      margin-left: -15px;
      opacity: 1;
      padding-top: 13px;
      position: fixed;
      text-align: center;
      text-shadow: none;
      width: 30px; }
  .app__body .edit-modal-body .custom-textarea {
    max-height: 30vh; }
  .video-div.embed-responsive-item {
    height: 0;
    padding-bottom: 75%; }
    .video-div.embed-responsive-item iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .tutorial-steps__container .tutorial__content .tutorial__steps {
    margin-top: 15%;
    margin-bottom: 15%;
    max-height: 70%;
    padding-bottom: 0; }
  .prompt .prompt__heading {
    display: block; }
    .prompt .prompt__heading > div:first-child {
      display: block;
      margin: 0 0 1em; }
  .post-code__language {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    display: block;
    position: relative; }
  .backstage-filters {
    display: block; }
    .backstage-filters .backstage-filter__search {
      margin: 10px 0;
      width: 100%; }
  .compliance-panel .row > .form-group,
  .audit-panel .row > .form-group {
    padding-left: 15px; }
  .user-popover {
    cursor: pointer;
    display: inline-block; }
  .signup-team__container {
    font-size: 1em; }
  .sidebar--left .team__header .user__picture,
  .sidebar--menu .team__header .user__picture {
    display: none; }
  .date-separator.hovered--before:after,
  .date-separator.hovered--after:before,
  .new-separator.hovered--after:before,
  .new-separator.hovered--before:after {
    background: none !important; }
  .channel-intro {
    margin: 0 0 35px; }
  .post:not(.post--compact) .bot-indicator {
    left: 6px;
    top: 31px; }
  .post.post--system .post__header .col__reply {
    min-width: 45px; }
  .post .post-add-reaction {
    display: none; }
  .browser--ie .post .post__header .col__reply .dropdown + div {
    display: none; }
  .post .reacticon__container {
    display: none; }
  .post .post__dropdown {
    display: inline-block;
    height: 28px;
    line-height: 21px;
    text-align: center;
    text-decoration: none;
    width: 28px; }
    .post .post__dropdown:after {
      content: '...';
      font-size: 20px;
      top: -3px; }
  .post .post__remove {
    margin-right: 10px;
    visibility: visible; }
  .post.post--compact .post__img {
    padding-top: 0; }
  .post.post--compact.same--root.same--user .post__header {
    height: auto; }
  .post.post--compact .post-message--overflow {
    overflow: hidden;
    *zoom: 1; }
  .post.post--compact .status-wrapper:after {
    bottom: 3px; }
    .sidebar--right .post.post--compact .status-wrapper:after {
      bottom: 0; }
  .post.post--compact blockquote {
    margin-top: 0; }
  .post.same--root.same--user .flag-icon__container {
    left: auto;
    position: relative;
    top: 1px; }
  .post .post__content {
    padding: 0 10px 0 0; }
  .post.current--user.same--root.same--user .post__header {
    margin-bottom: 7px; }
  .post.current--user.post--comment .post__header {
    margin-bottom: 3px; }
  .post .post__header {
    margin-bottom: 0;
    padding-right: 70px; }
    .post .post__header .col__reply {
      min-width: 45px;
      top: -6px;
      z-index: auto; }
      .post .post__header .col__reply > div {
        width: auto; }
      .post .post__header .col__reply .reacticon__container {
        display: none; }
    .post .post__header .col__name .user-popover {
      max-width: 130px; }
  .post .comment-icon__container {
    display: none;
    visibility: visible; }
    .sidebar--right .post .comment-icon__container {
      display: inline-block; }
    .post .comment-icon__container.icon--show {
      display: inline-block; }
  .post-list__content .post:hover {
    background: transparent; }
  .post-list__content .post .comment-icon__container {
    visibility: visible; }
  .post .dropdown,
  .post .post__reply {
    visibility: visible; }
  .post .post__body {
    width: 100%; }
  .post .post__reply {
    float: right;
    margin-right: 20px; }
    .post .post__reply svg {
      top: 1px; }
  .post.post--comment .post__body {
    margin-top: 5px;
    padding: 2px 0 0 7px; }
  .post.other--root.post--comment .post__header .col__reply {
    top: -6px; }
  .post.other--root .post__reply.post__reply--hide {
    visibility: hidden; }
  .post.current--user .post__link {
    margin: 0 0 8px; }
  .post .star-icon__container {
    left: auto;
    position: relative;
    top: auto; }
    .post .star-icon__container:not(.visible) {
      display: none; }
  .post.same--root .star-icon__container {
    left: auto;
    position: relative;
    top: auto; }
  .post.same--root.same--user.post--compact .status-wrapper:after {
    bottom: -2px; }
  .post.same--root.same--user .post__img img {
    display: block; }
  .post.same--root.same--user .post__header {
    height: auto;
    margin-top: 5px; }
    .post.same--root.same--user .post__header .col__name {
      display: inline-block; }
  .post .post__img {
    width: 40px; }
    .post .post__img img {
      height: 32px;
      width: 32px; }
  .form-control.min-height {
    min-height: 100px; }
  .img-div {
    max-width: 100%; }
  .tip-div {
    left: 15px;
    right: auto; }
  .tip-overlay.tip-overlay--chat {
    margin-left: 10px; }
    .tip-overlay.tip-overlay--chat .arrow {
      left: 32px; }
  .file-details__container {
    display: block; }
    .file-details__container .file-details__preview {
      border-bottom: 1px solid #dddddd;
      border-right: none;
      display: block;
      height: 150px;
      width: 100%; }
      .file-details__container .file-details__preview img {
        height: 64px;
        width: 64px; }
    .file-details__container .file-details {
      height: auto;
      width: 100%; }
  .search-help-popover {
    left: 0;
    max-width: 100%; }
  .modal-direct-channels .member-count,
  .more-channel__modal .member-count {
    display: block;
    float: none;
    margin-top: 10px; }
  .file-overlay {
    font-size: 1.28571em; }
    .file-overlay.center-file-overlay .overlay__indent {
      margin-left: 0; }
    .file-overlay .overlay__circle {
      height: 300px;
      margin: -150px 0 0 -150px;
      width: 300px; }
    .file-overlay .overlay__files {
      margin: 60px auto 15px;
      width: 150px; }
  .date-separator.hovered--after:before,
  .new-separator.hovered--after:before {
    background: none; }
  .date-separator.hovered--before:after,
  .new-separator.hovered--before:after {
    background: none; }
  .post-list__timestamp {
    display: block; }
  .signup-team__container {
    font-size: .9em;
    margin-bottom: 30px;
    padding: 60px 10px 0; }
    .signup-team__container .signup-team__name {
      font-size: 2em; }
    .signup-team__container .btn.btn-full {
      padding-left: 10px; }
    .signup-team__container .btn .icon,
    .signup-team__container .btn .fa {
      margin-right: 6px; }
  .app__body .row--invite .col-sm-6:first-child {
    padding-right: 15px; }
  .app__body .modal .modal--scroll .modal-body {
    max-height: calc(100vh - 62px);
    overflow: auto; }
  .app__body .modal .nav-pills > li.active button {
    background: transparent; }
    .app__body .modal .nav-pills > li.active button:before {
      display: none; }
  .app__body .modal .nav-pills > li button {
    border-bottom: 1px solid;
    padding: 15px; }
  .app__body .modal .info__label {
    padding-bottom: 5px;
    text-align: left; }
  .app__body .modal .modal-header .modal-action {
    margin-top: 10px; }
  .app__body .modal .modal-header .close {
    overflow: hidden;
    font-size: 27px;
    font-weight: normal;
    margin-top: -2px; }
  .app__body .modal .modal-header .modal-title {
    float: none;
    max-width: 90%; }
  .app__body .modal .modal-header .btn.btn-primary {
    display: block;
    float: none;
    margin: 10px 0 6px;
    width: 100%; }
  .app__body .modal .settings-modal .modal-body {
    min-height: 100%; }
  .app__body .modal .settings-modal .nav-pills > li:hover a {
    background: transparent !important; }
  .app__body .modal .settings-modal.display--content .modal-header {
    display: none; }
  .app__body .modal .settings-modal.display--content .settings-table {
    display: block; }
    .app__body .modal .settings-modal.display--content .settings-table .settings-content .section-title {
      padding-right: 15px; }
    .app__body .modal .settings-modal.display--content .settings-table .settings-content .section-edit {
      position: relative;
      right: 0;
      text-align: left;
      top: 0; }
    .app__body .modal .settings-modal.display--content .settings-table .settings-content .appearance-section .theme-elements .element {
      margin-right: 10px; }
      .app__body .modal .settings-modal.display--content .settings-table .settings-content .appearance-section .theme-elements .element:nth-child(2n) {
        margin-right: 10px; }
    .app__body .modal .settings-modal.display--content .settings-table .settings-content.minimize-settings {
      display: block; }
    .app__body .modal .settings-modal.display--content .settings-table .settings-content .section-min:hover {
      background: none; }
    .app__body .modal .settings-modal.display--content .settings-table .settings-content .no-padding--left {
      padding-left: 15px; }
    .app__body .modal .settings-modal.display--content .settings-table .settings-links {
      display: none; }
    .app__body .modal .settings-modal.display--content .settings-table .modal-header {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5; }
      .app__body .modal .settings-modal.display--content .settings-table .modal-header .modal-title {
        max-width: 100%;
        padding: 0 40px;
        text-align: center;
        width: 100%; }
        .app__body .modal .settings-modal.display--content .settings-table .modal-header .modal-title > span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
    .app__body .modal .settings-modal.display--content .settings-table .user-settings .tab-header {
      display: none; }
    .app__body .modal .settings-modal.display--content .settings-table .user-settings .divider-dark.first {
      display: none; }
  .app__body .modal .settings-modal .settings-table {
    padding: 0; }
    .app__body .modal .settings-modal .settings-table .nav {
      position: relative;
      top: auto;
      width: 100%; }
      .app__body .modal .settings-modal .settings-table .nav.position--top {
        top: auto; }
    .app__body .modal .settings-modal .settings-table .settings-content .section-edit {
      text-align: left; }
      .app__body .modal .settings-modal .settings-table .settings-content .section-edit button {
        text-align: left;
        width: 100%; }
      .app__body .modal .settings-modal .settings-table .settings-content .section-edit .fa {
        display: inline-block; }
    .app__body .modal .settings-modal .settings-table .settings-content.minimize-settings {
      display: none;
      padding: 0; }
      .app__body .modal .settings-modal .settings-table .settings-content.minimize-settings .user-settings {
        padding: 70px 20px 30px; }
    .app__body .modal .settings-modal .settings-table .settings-content .section-min:hover {
      background: none !important; }
    .app__body .modal .settings-modal .settings-table .nav > li > a {
      border-bottom: 1px solid transparent;
      font-size: 1.1em;
      line-height: 2.7; }
      .app__body .modal .settings-modal .settings-table .nav > li > a .icon {
        margin: 0 7px; }
  .file-preview__container {
    margin: 10px 0 5px; }
  .file-preview {
    margin-top: 0; }
  .admin-sidebar .navbar-nav {
    margin-top: 0; }
    .admin-sidebar .navbar-nav > li {
      float: left; }
    .admin-sidebar .navbar-nav .dropdown-menu {
      background: white;
      left: auto;
      position: absolute;
      right: 0; }
  .browser--ie #navbar .navbar-default .dropdown-menu .close {
    top: 70px; }
  #navbar .navbar-default .navbar-header {
    float: none;
    margin: 0 -15px; }
    #navbar .navbar-default .navbar-header .dropdown__icon {
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      background-size: 100% 100%;
      display: inline-block;
      height: 16px;
      width: 4px; }
  #navbar .navbar-default .navbar-toggle {
    display: block; }
    #navbar .navbar-default .navbar-toggle:not(.navbar-right__icon):hover {
      background: transparent; }
  #navbar .navbar-default .dropdown.open .dropdown-menu {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  #navbar .navbar-default .dropdown-menu {
    -webkit-transition: all 0.35s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.35s ease;
    -o-transition: all 0.35s ease;
    transition: all 0.35s ease false;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    background: rgba(0, 0, 0, 0.9);
    display: block;
    height: calc(100% - 26px);
    left: 0;
    overflow: auto;
    padding: 2.5em 0 0;
    position: fixed;
    top: 48px;
    visibility: hidden;
    width: 100%;
    padding-bottom: 50px; }
    body.announcement-bar--fixed #navbar .navbar-default .dropdown-menu {
      height: calc(100% - 48px - 24px);
      top: calc(48px + 24px); }
    #navbar .navbar-default .dropdown-menu .close {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      border: 1px solid white;
      color: white;
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 23px;
      font-weight: 200;
      height: 30px;
      line-height: 0;
      margin-left: -15px;
      opacity: 1;
      padding-top: 13px;
      position: fixed;
      right: 20px;
      text-align: center;
      text-shadow: none;
      top: 20px;
      width: 30px; }
    #navbar .navbar-default .dropdown-menu > li > a,
    #navbar .navbar-default .dropdown-menu > li > button {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      color: white;
      font-weight: 600;
      line-height: 35px;
      margin: 0 auto;
      text-align: center;
      width: 70%; }
      #navbar .navbar-default .dropdown-menu > li > a:hover,
      #navbar .navbar-default .dropdown-menu > li > button:hover {
        background: transparent; }
  #navbar .navbar-default .status {
    margin: 0 5px 0 3px;
    top: 2px;
    width: 16px; }
    #navbar .navbar-default .status svg {
      max-height: 20px;
      width: 16px; }
  body.white .inner-wrap > .row.content {
    margin-bottom: -185px; }
  .footer,
  .footer-pane {
    height: 187px; }
  .footer-pane .footer-link {
    display: block;
    line-height: 1.7;
    padding: 0;
    text-align: right;
    width: 100%; }
    .footer-pane .footer-link.copyright {
      width: 100%; }
  .search-bar__container {
    -webkit-flex: 0 0 50px;
    flex: 0 0 50px;
    background: #2389d7;
    color: white;
    padding: 0; }
    .search-bar__container .search-form__container {
      color: black;
      padding: 0 20px 0 0; }
    .search-bar__container .search__form {
      -webkit-transition: all 0.2s linear;
      -webkit-transition-delay: 0s;
      -moz-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear false;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      background: white;
      border: none;
      margin-top: 9px;
      padding-left: 40px;
      width: 100%; }
      .search-bar__container .search__form .fa-spin {
        font-size: 1.1em;
        top: 9px; }
      .search-bar__container .search__form .search-bar {
        font-size: 14px;
        height: 32px; }
      .search-bar__container .search__form .search__icon {
        left: 15px;
        top: 8px; }
        .search-bar__container .search__form .search__icon svg {
          stroke: black;
          stroke-opacity: .4;
          width: 17px; }
      .search-bar__container .search__form .icon--refresh {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
        opacity: 0.6;
        right: 16px;
        top: 9px; }
      .search-bar__container .search__form .form-control {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        background: white;
        border: none;
        color: rgba(0, 0, 0, 0.5);
        padding: 0 31px; }
    .search-bar__container .channel-header__links {
      display: none; }
  .sidebar--menu {
    -webkit-transition: -webkit-transform 0.35s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: -moz-transform 0.35s ease;
    -o-transition: -o-transform 0.35s ease;
    transition: transform 0.35s ease false;
    -webkit-transform: translate3d(290px, 0, 0);
    -moz-transform: translate3d(290px, 0, 0);
    -ms-transform: translate3d(290px, 0, 0);
    -o-transform: translate3d(290px, 0, 0);
    transform: translate3d(290px, 0, 0);
    border: none;
    visibility: hidden;
    width: 290px; }
    .sidebar--menu.visible {
      display: block;
      visibility: visible; }
    .sidebar--menu.move--left {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      display: block;
      visibility: visible; }
  .sidebar--left {
    -webkit-transition: -webkit-transform 0.35s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: -moz-transform 0.35s ease;
    -o-transition: -o-transform 0.35s ease;
    transition: transform 0.35s ease false;
    -webkit-transform: translate3d(-290px, 0, 0);
    -moz-transform: translate3d(-290px, 0, 0);
    -ms-transform: translate3d(-290px, 0, 0);
    -o-transform: translate3d(-290px, 0, 0);
    transform: translate3d(-290px, 0, 0);
    border: none;
    width: 290px; }
    .sidebar--left.sidebar--padded {
      padding-top: 0; }
    .sidebar--left.move--right {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    .sidebar--left .nav-pills__unread-indicator {
      width: 260px; }
    .sidebar--left .team__header {
      overflow: hidden;
      *zoom: 1;
      pointer-events: none; }
      .sidebar--left .team__header .user__name {
        display: none; }
      .sidebar--left .team__header .team__name {
        margin: 10px 0; }
      .sidebar--left .team__header .sidebar-header-dropdown {
        display: none; }
    .sidebar--left .search__form {
      display: block; }
    .sidebar--left .nav li.dropdown.open {
      padding-bottom: 25px; }
      .sidebar--left .nav li.dropdown.open ul {
        background: white;
        border-radius: 3px;
        clear: both;
        position: relative;
        top: 5px; }
        .sidebar--left .nav li.dropdown.open ul li a {
          line-height: 30px; }
    .sidebar--left .nav li .nav-more {
      padding: 12px 0 12px 17px; }
    .sidebar--left .nav li .sidebar-item {
      height: 45px; }
      .sidebar--left .nav li .sidebar-item.has-close .btn-close {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        opacity: 0.5;
        display: block;
        font-size: 25px;
        margin-right: 4px;
        width: 30px;
        height: 30px;
        line-height: 30px; }
    .sidebar--left .nav li h4 {
      margin: 1.5 0 1em; }
    .sidebar--left .nav li > a {
      font-size: 15px;
      line-height: 2.5;
      margin: 0; }
      .sidebar--left .nav li > a:hover, .sidebar--left .nav li > a:focus {
        background: transparent !important; }
  .sidebar--right {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    right: 0;
    width: 100%; }
    .browser--ie .sidebar--right {
      -webkit-transform: none   !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      transform: none !important;
      display: none; }
    .sidebar--right .post-create__container form {
      padding: .5em 1em; }
    .sidebar--right .post-create__container .emoji-rhs {
      display: none;
      position: relative;
      right: -1px;
      top: 1px; }
    .sidebar--right .post-create__container .msg-typing:empty {
      display: none; }
    .sidebar--right .post-create__container .post-create-footer {
      padding-right: 0;
      padding-top: 10px; }
      .sidebar--right .post-create__container .post-create-footer .control-label {
        margin: .5em 0;
        top: 0; }
      .sidebar--right .post-create__container .post-create-footer .post-error {
        left: 0;
        top: 0; }
    .sidebar--right .sidebar-collapse__container {
      display: table-cell;
      vertical-align: top; }
    .sidebar--right .sidebar__search-clear {
      color: inherit;
      height: 32px;
      margin-right: 0;
      text-align: center;
      top: 0;
      width: 42px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .sidebar--right .sidebar__search-clear.visible {
        visibility: visible; }
    .sidebar--right .sidebar__search-clear-x {
      font-size: 21px;
      font-weight: 700;
      line-height: 0;
      position: relative; }
    .sidebar--right .sidebar--right__close, .sidebar--right .sidebar--right__expand {
      display: none; }
    .sidebar--right .sidebar-right__body {
      height: calc(100% - 56px); }
  .search-items-container {
    height: calc(100% - 56px); }
  .inner-wrap {
    -webkit-transition: all 0.35s ease;
    -webkit-transition-delay: 0s;
    -moz-transition: all 0.35s ease;
    -o-transition: all 0.35s ease;
    transition: all 0.35s ease false; }
    .app__body .inner-wrap:before {
      background-color: transparent;
      content: '';
      height: 100%;
      left: -15px;
      position: absolute;
      top: 0;
      transition: background-color .35s ease, z-index 0s ease .35s;
      width: calc(100% + 30px);
      z-index: 0; }
    .inner-wrap.move--right {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
      .inner-wrap.move--right:before {
        -webkit-transition: all 0.35s ease;
        -webkit-transition-delay: 0s;
        -moz-transition: all 0.35s ease;
        -o-transition: all 0.35s ease;
        transition: all 0.35s ease false;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 9999; }
    .inner-wrap.move--left-small {
      -webkit-transform: translate3d(-290px, 0, 0);
      -moz-transform: translate3d(-290px, 0, 0);
      -ms-transform: translate3d(-290px, 0, 0);
      -o-transform: translate3d(-290px, 0, 0);
      transform: translate3d(-290px, 0, 0); }
      .inner-wrap.move--left-small:before {
        -webkit-transition: all 0.35s ease;
        -webkit-transition-delay: 0s;
        -moz-transition: all 0.35s ease;
        -o-transition: all 0.35s ease;
        transition: all 0.35s ease false;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 9999; }
    .inner-wrap.move--left {
      margin: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      -moz-transform: translate3d(-100%, 0, 0);
      -ms-transform: translate3d(-100%, 0, 0);
      -o-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0); }
  .integration-option {
    height: auto;
    margin-left: 0;
    width: 100%; }
  .app__content {
    margin: 0;
    padding-top: 50px; }
    .channel__wrap .app__content {
      padding-top: 50px; }
    .app__content #channel-header {
      display: none; }
  .channel__wrap .row.header {
    display: block; }
  .post-comments {
    padding: 9px 21px 10px 10px !important; }
  .multi-teams .app__content {
    margin-left: 0; }
  .multi-teams .sidebar--left {
    left: 0; }
    .multi-teams .sidebar--left.move--right {
      left: 65px; }
    .multi-teams .sidebar--left .nav-pills__unread-indicator {
      left: 15px; }
  .multi-teams .team-sidebar {
    display: none; }
    .multi-teams .team-sidebar.move--right {
      display: block;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
  .post .attachment .attachment-actions .alert {
    position: relative;
    left: 0;
    top: 0;
    margin: 1rem 0 0.4rem;
    display: inline-block; }
  .post .attachment .attachment__body__wrap .btn-close {
    height: 30px;
    left: -15px;
    top: 7px;
    visibility: visible;
    width: 30px; } }

@media screen and (max-width: 640px) {
  body.browser--ie {
    min-width: 600px; }
  .multi-select__help {
    flex-direction: column; }
    .multi-select__help > span:first-child {
      margin-bottom: 1rem; }
  .post__header .dropdown-menu > li > button {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    border-top: 1px solid;
    padding: 15px 20px; }
  .post__header .dropdown-menu > li:first-child button {
    border: none; }
  .filtered-user-list {
    height: calc(100vh - 80px); }
  .more-modal--action .filtered-user-list {
    height: calc(100vh - 110px); }
  .app__body.announcement-bar--fixed .modal {
    padding-top: 24px; }
  .app__body .modal {
    overflow: hidden;
    padding: 0; }
    .app__body .modal .modal-dialog {
      height: 100%;
      margin: 0;
      max-width: 100%; }
      .app__body .modal .modal-dialog .modal-content {
        height: 100%; }
    .app__body .modal .modal-footer {
      bottom: 0;
      position: fixed;
      width: 100%; }
    .app__body .modal .about-modal .modal-content {
      display: flex;
      flex-direction: column; }
    .app__body .modal .about-modal .modal-header {
      flex: 0 0 70px;
      padding: 0 25px; }
    .app__body .modal .about-modal .modal-footer {
      position: relative; }
    .app__body .modal .about-modal .modal-body {
      max-height: 100%; }
    .app__body .modal .about-modal .about-modal__content {
      display: block; }
    .app__body .modal .about-modal .about-modal__hash p {
      word-break: break-all; }
      .app__body .modal .about-modal .about-modal__hash p:first-child {
        float: none; }
    .app__body .modal .about-modal .about-modal__logo {
      float: none;
      padding: 0;
      text-align: center;
      width: 100%; }
      .app__body .modal .about-modal .about-modal__logo svg {
        height: 100px;
        width: 100px; }
    .app__body .modal .about-modal .about-modal__logo + div {
      padding: 2em 0 0; }
    .app__body .modal .more-modal .modal-content {
      display: flex;
      flex-direction: column; }
    .app__body .modal .more-modal .modal-header {
      flex: 0 0 56px; }
    .app__body .modal .more-modal .modal-body {
      flex: 1 1 auto;
      max-height: 100%;
      display: flex; }
    .app__body .modal .more-modal .filtered-user-list {
      flex: 1 1 auto;
      height: auto; }
  .app__body .post .open > .dropdown-menu__content {
    display: table; }
    .app__body .post .open > .dropdown-menu__content > .dropdown-menu {
      display: table-cell; }
  .app__body .post .dropdown-menu__content {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999; }
    .app__body .post .dropdown-menu__content:before {
      background: rgba(0, 0, 0, 0.3);
      content: '';
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%; }
  .app__body .post .dropdown-menu {
    background: transparent;
    border: none;
    bottom: auto;
    box-shadow: none;
    float: none;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0 2em;
    position: relative;
    top: 0;
    vertical-align: middle;
    width: 100%;
    z-index: 9999; }
    .app__body .post .dropdown-menu.bottom {
      bottom: auto;
      top: 0; }
    .app__body .post .dropdown-menu > li:last-child a {
      border: none; }
    .app__body .post .dropdown-menu a {
      border-bottom: 1px solid;
      line-height: 45px;
      position: relative;
      text-align: center; }
  .access-history__table > div {
    display: block; }
  .access-history__table .access__report {
    margin: 0 0 15px 15px; }
  .access-history__table .access__date div {
    margin-bottom: 15px; }
  .activity-log__table > div {
    display: block; }
  .activity-log__table .activity-log__report {
    width: 100%; }
  .activity-log__table .activity-log__action {
    margin-top: 10px;
    text-align: left; } }

@media screen and (max-width: 550px) {
  .app__body .more-modal.more-system-members .filter-row {
    min-height: 80px;
    width: 100%; }
  .app__body .more-modal.more-direct-channels .member-show {
    display: none; }
  .app__body .more-modal.more-direct-channels select {
    margin: 0;
    width: 100%; }
  .member-select__container {
    left: 15px;
    top: 60px;
    width: calc(100% - 30px); } }

@media screen and (max-width: 480px) {
  #user-profile-popover {
    left: 50px !important; }
  .post .img-div {
    max-width: 100%; }
  .nav-tabs {
    margin-top: 1em; }
    .nav-tabs > li {
      margin-right: 0; }
      .nav-tabs > li a {
        font-size: .9em;
        padding: 6px 11px; }
  .sidebar--right .post.post--compact .post__header {
    height: auto; }
  .backstage-header h1 {
    float: none;
    margin-bottom: 15px; }
  .backstage-header .add-integrations-link {
    float: none; }
  .backstage-list__item {
    display: block; }
    .backstage-list__item .item-actions,
    .backstage-list__item .actions {
      margin-top: 15px;
      padding: 0; }
  .app__body .modal .settings-modal.display--content .modal-body {
    max-height: 90%; }
  .app__body .modal .settings-modal .modal-body {
    max-height: 70%;
    padding-bottom: 0; }
  .app__body .modal .settings-modal .settings-table .security-links {
    display: block;
    margin-bottom: 10px; }
    .app__body .modal .settings-modal .settings-table .security-links:last-child {
      margin-bottom: 0; }
  .tip-overlay.tip-overlay--sidebar {
    min-height: 350px; }
  .member-div {
    padding: 8px 0; }
    .member-div .member-drop,
    .member-div .member-role {
      margin: 0 0 0 44px;
      padding: 5px 0;
      position: relative;
      right: 0;
      top: 0; }
    .member-div .open > .dropdown-menu {
      left: 0;
      right: auto; }
  .sidebar--left {
    -webkit-transform: translate3d(-260px, 0, 0);
    -moz-transform: translate3d(-260px, 0, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    -o-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
    width: 260px; }
    .sidebar--left .nav-pills__unread-indicator {
      width: 230px; }
  .inner-wrap.move--right {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .integration__icon {
    display: none; } }

@media screen and (max-height: 640px) {
  .signup-team__container {
    font-size: .9em;
    margin-bottom: 30px; }
    .signup-team__container .signup-team__name {
      font-size: 2em; } }

@media screen and (max-width: 803px) {
  .signup-team__container .terms-of-service-error__height--fill {
    min-height: calc(100vh - 121px - 4em);
    height: calc(100vh - 121px - 4em); } }

@media screen and (max-width: 449px) {
  .signup-team__container .terms-of-service-error__height--fill {
    min-height: calc(100vh - 138px - 4em);
    height: calc(100vh - 138px - 4em); } }

@media screen and (max-width: 351px) {
  .signup-team__container .terms-of-service-error__height--fill {
    min-height: calc(100vh - 145px - 4em);
    height: calc(100vh - 145px - 4em); } }

@media screen and (max-width: 263px) {
  .signup-team__container .terms-of-service-error__height--fill {
    min-height: calc(100vh - 173px - 4em);
    height: calc(100vh - 173px - 4em); }
  .signup-team__container .terms-of-service__height--fill {
    min-height: calc(100vh - 100px - 3em);
    height: calc(100vh - 100px - 3em); } }

@media screen and (max-width: 350px) {
  .post-attachment-dropdown:after {
    left: auto;
    right: 12px; } }

@media screen and (max-width: 320px) {
  .filter-row .Select--multi .Select-multi-value-wrapper {
    display: block;
    max-height: 50px;
    overflow: auto; }
  .filter-row .Select-input > input {
    padding: 8px 0; }
  .post.post--system .post__header {
    padding: 0; }
  .multi-teams .sidebar--left {
    width: 220px; }
    .multi-teams .sidebar--left .nav-pills__unread-indicator {
      width: 190px; }
  .post .post__header .col__name .user-popover {
    max-width: 105px; }
  .tutorial-steps__container {
    left: 0;
    position: fixed;
    top: 0;
    z-index: 9999; }
    .tutorial-steps__container .tutorial__content .tutorial__steps {
      margin-top: 20px;
      min-height: auto;
      padding: 0 20px;
      width: 100%; }
      .tutorial-steps__container .tutorial__content .tutorial__steps h1 {
        font-size: 2em;
        margin: -5px 0 20px; }
      .tutorial-steps__container .tutorial__content .tutorial__steps h3 {
        font-size: 1.5em;
        margin-bottom: 10px; }
      .tutorial-steps__container .tutorial__content .tutorial__steps .tutorial__app-icons {
        margin: 10px 0; }
      .tutorial-steps__container .tutorial__content .tutorial__steps .tutorial__circles {
        bottom: auto;
        margin: 15px 0 20px;
        position: relative; }
      .tutorial-steps__container .tutorial__content .tutorial__steps .tutorial__footer {
        bottom: auto;
        position: relative; }
  .tip-overlay.tip-overlay--sidebar {
    min-height: 440px; } }

@media screen and (max-width: 380px) and (max-height: 580px) {
  #navbar .navbar-default .dropdown-menu {
    padding-top: 1em; }
    #navbar .navbar-default .dropdown-menu > li > a {
      border: none;
      font-size: 13px;
      line-height: 27px; } }

label span {
  pointer-events: none; }

label span a {
  pointer-events: all; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px; } }
